import {
  Box,
  Typography,
  withStyles,
  TextField,
  Link,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";

function FormAutocomplete(props) {
  const {
    title,
    subText,
    hyperlink,
    isMandatory,
    stepKey,
    setKey,
    value,
    options,
    classes,
    placeholder,
  } = props;
  return (
    <Box>
      <Typography className={classes.title}>
        {title}
        {isMandatory && <span className={classes.mandatory}>*</span>}
      </Typography>
      {subText && (
        <Typography className={classes.subTitle}>{subText}</Typography>
      )}
      {hyperlink && (
        <Box className={classes.hyperlink}>
          <Link
            fullWidth
            href={hyperlink}
            target="_blank"
            style={{ color: "blue" }}
          >
            {hyperlink}
          </Link>
        </Box>
      )}
      <Autocomplete
        disableClearable
        disabled="true"
        options={options}
        onChange={(e, value, reason) => setKey(stepKey, value)}
        value={value}
        placeholder={placeholder}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textField}
            InputProps={{ ...params.InputProps }}
            placeholder={placeholder}
            name={title}
          />
        )}
      />
    </Box>
  );
}

const styles = (theme) => ({
  title: {
    color: "#202124",
    fontWeight: 600,
    fontSize: 15,
    textDecoration: "none rgb(32,33,36)",
    margin: "24px 0 5px 0",
  },
  mandatory: {
    color: "red",
  },
  subTitle: {
    color: "#151514",
    margin: "0 0 6px 0",
    fontSize: 14,
    textDecoration: "none rgb(21,21,20)",
  },
  textFieldRoot: {
    backgroundColor: "rgb(240,240,240)",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    width: "100%",
    padding: 2,
    boxShadow: "none",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontSize: "15px",
    fontWeight: 500,
  },
  hyperlink: {
    color: "blue",
    fontSize: 14,
    textDecoration: "underline",
    marginBottom: 6,
  },
});

export default withStyles(styles)(FormAutocomplete);
