import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles, Grid, Box, Typography, Tooltip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { URL } from "../../config/config";
import { Assistant } from "@material-ui/icons";
const fields = [
  {
    name: "Message",
    key: "message",
    size: 3,
  },
  {
    name: "Reason Code",
    key: "reasonCode",
    size: 3,
  },
  {
    name: "Amount",
    key: "amount",
    size: 3,
  },
  {
    name: "Created On",
    key: "createdAt",
    size: 3,
  },
];
// const quickFilters = [
//   "Last active - 24 hr window",
//   "Created - This week",
//   "Created - This month",
// ];
class BillingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedTemplate: null,
    };
  }

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  openBills = (log) => {
    if (log.assistantId) {
      axios
        .get(URL + `/api/partner-name/${log.assistantId}`)
        .then((response) => {
          const assistantName = response.data.assistantName;
          this.props.history.push({
            pathname: "/billings/" + log._id,
            state: { ...log, assistantName },
          });
        });
    } else {
      this.props.history.push({
        pathname: "/billings/" + log._id,
        state: { log },
      });
    }
  };
  render() {
    const currency = this.props.user.currency;
    const { classes, bills, isLoading, hasReadAccess } = this.props;
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid container item xs={12} md={12} lg={12}>
            <Grid container>
              <Box
                className={classes.tableWrapper}
                mb={{ xs: 2, md: 4 }}
                mx={{ xs: 2, md: 0 }}
              >
                <Grid item container className={classes.tableContainer}>
                  <Grid item xs={12} container className={classes.tableHeader}>
                    {fields.map((e) => (
                      <Grid
                        item
                        xs={e.size}
                        key={e.key}
                        style={{
                          width: "25%",
                        }}
                      >
                        <Box py={2} pl={2}>
                          <Typography variant="h5" color="primary">
                            {e.name}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  {bills.map((log) => (
                    <Grid
                      item
                      xs={12}
                      container
                      onScroll={this.listScrolled}
                      className={classes.tableItem}
                    >
                      {fields.map((field) => (
                        <Grid
                          item
                          xs={field.size}
                          style={{
                            width: "25%",
                          }}
                          onClick={() => {
                            this.openBills(log);
                          }}
                        >
                          <Cell
                            classes={classes}
                            item={log}
                            field={this.props.isLoading ? "Loading" : field.key}
                            currency={currency}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function columnType(classes, columnData, fieldName) {
  const key = fieldName.key;
  switch (key) {
    case "amount":
      if (columnData.action === "SUBTRACT") {
        return (
          <Typography variant="body1" align="center">
            {`-${columnData[key] / 100000}`}
          </Typography>
        );
      } else {
        return (
          <Typography variant="body1" align="center">
            {`+${columnData[key] / 100000}`}
          </Typography>
        );
      }

      break;
    case "reasonCode":
      return (
        <Typography variant="body1" align="center">
          {columnData[key]}
        </Typography>
      );
      break;
    case "message":
      return (
        <>
          <Tooltip title={columnData[key]}>
            <Typography noWrap variant="body1" align="center">
              {columnData[key]}
            </Typography>
          </Tooltip>
        </>
      );
      break;
    case "createdAt":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
      break;
    default:
      return (
        <Typography variant="body1" align="center">
          {columnData[key]}
        </Typography>
      );
  }
}
function Cell({ classes, item, field, openCampaign, currency, ...props }) {
  switch (field) {
    case "amount":
      if (item.action === "SUBTRACT") {
        return (
          <Box py={2} pl={2}>
            <Typography variant="body1">
              {`${currency === "INR" ? "₹" : currency === "USD" ? "$" : ""} -${
                item[field] / 100000
              }`}
            </Typography>
          </Box>
        );
      } else {
        return (
          <Box py={2} pl={2}>
            <Typography variant="body1">
              {`${currency === "INR" ? "₹" : currency === "USD" ? "$" : ""} +${
                item[field] / 100000
              }`}
            </Typography>
          </Box>
        );
      }

      break;
    case "reasonCode":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1">{item[field]}</Typography>
        </Box>
      );
      break;
    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Skeleton
              variant="rect"
              width="100%"
              style={{ borderRadius: "15px" }}
            />
          </Box>
        </Grid>
      );
    case "message":
      return (
        <>
          {item[field]?.length > 30 ? (
            <>
              <Box py={2} pl={2}>
                <Tooltip title={item[field]}>
                  <Typography noWrap variant="body1">
                    {`${item[field].slice(0, 32)}....`}
                  </Typography>
                </Tooltip>
              </Box>
            </>
          ) : (
            <Box py={2} pl={2}>
              <Tooltip title={item[field]}>
                <Typography noWrap variant="body1">
                  {item[field]}
                </Typography>
              </Tooltip>
            </Box>
          )}
        </>
      );
      break;
    case "createdAt":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1">
            {getFormattedDate(item[field])}
          </Typography>
        </Box>
      );
      break;
    default:
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1">{item[field]}</Typography>
        </Box>
      );
  }
}
function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "scroll",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  tableWrapper: {
    // overflowX: "auto",
    width: "100%",
  },

  tableContainer: {
    marginTop: "1.5em",
    minWidth: 1000,
  },

  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    // flexWrap: "nowrap",
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
    },
  },

  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
    // flexWrap: "nowrap",
  },
});
const BillingTableconnect = connect(
  (state) => ({
    isLoading: state.billing.isLoading,
    user: state.login.user,
  }),
  {}
)(BillingTable);
export default withStyles(styles)(withRouter(BillingTableconnect));
