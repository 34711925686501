import axios from "axios";
import { URL } from "../config/config";

const initialState = {
  allBusiness: {},
  isLoading: false,
  error: null,
};

const LOAD_START = "BUSINESS/LOAD_START";
const LOAD_SUCCESS = "BUSINESS/LOAD_SUCCESS";
const LOAD_FAILURE = "BUSINESS/LOAD_FAILURE";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = (payload) => ({
  type: LOAD_SUCCESS,
  payload,
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const fetchBusiness = () => (dispatch, getState) => {
  const { user } = getState().login;
  dispatch(loadStart());
  axios
    .get(
      URL +
        `${
          user.isReferral
            ? `/api/partner-referral-allbusiness-lists?partnerReferralId=${user.id}`
            : `/api/allbusiness-lists`
        }`,
      {
        skip: 0,
        rowsPerPage: 0,
        partnerReferralId: user.id,
      }
    )
    .then((response) => {
      const { business } = response.data;
      let businessObj = {};
      business.forEach((item) => {
        businessObj[item._id] = item;
      });
      dispatch(loadSuccess(businessObj));
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export default function BusinessReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        allBusiness: { ...payload },
        isLoading: false,
        error: null,
      };

    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
