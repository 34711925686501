import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";

class FlagNotification extends Component {
  render() {
    const { classes, message, icon, color, textColor } = this.props;
    return (
      <Alert
        variant="filled"
        severity={icon}
        className={classes.notificationBar}
        style={{ backgroundColor: color, color: textColor }}
      >
        {message}
      </Alert>
    );
  }
}

const styles = (theme) => ({
  notificationBar: {
    justifyContent: "center",
    borderRadius: "0px",
    fontSize: window.innerWidth < 767 ? "12px" : "15px",
    display: "flex",
    alignItems: "center",
    zIndex: 10000,
    padding: "7px",
    height: window.innerWidth < 767 ? "2.5%" : "2%",
    "& .MuiAlert-icon": {
      fontSize: "20px",
      marginRight: "6px",
      position: "relative",
      bottom: "1px",
    },
  },
});

const connectFlagNotification = connect((state) => ({
  partner: state.partner.partner,
}))(FlagNotification);

export default withStyles(styles)(connectFlagNotification);
