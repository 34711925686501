import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import clsx from "clsx";
import AssistantsDetails from "./AssistantsDetails";

const fields = [
  {
    name: "Ticket ID",
    key: "index",
  },
  {
    name: "Assistant Name",
    key: "assistantName",
  },
  {
    name: "WhatsApp Number",
    key: "whatsappNumber",
  },
  {
    name: "Assigned To",
    key: "assignedTo",
  },
  {
    name: "Status",
    key: "status",
  },
  {
    name: "Topic",
    key: "topic",
  },
  {
    name: "Created At",
    key: "createdAt",
  },
  // {
  // 	"name": "App Name",
  // 	"key": "appName"
  // },
  // {
  // 	"name": "Verification Status",
  // 	"key": "isWhatsappVerified"
  // },
  // {
  // 	"name": "Active Plan",
  // 	"key": "activePlan",
  // },
  // {
  // 	"name": "Test Number",
  // 	"key": "testNumber",
  // },
  // {
  // 	"name": "Plan Activated",
  // 	"key": "planActivatedOn",
  // },
  // {
  // 	"name": "Rem Credit",
  // 	"key": "remainingCredit",
  // },
  // {
  // 	"name": "Df Project Id",
  // 	"key": "dfProjectId",
  // },
  // {
  // 	"name": "Max Agents",
  // 	"key": "maxAgents",
  // },
];

const quickFilters = [
  "Last active - 24 hr window",
  "Created - This week",
  "Created - This month",
];
class AssistantsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedTemplate: null,
    };
  }
  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  openTemplate = (e) => {
    const templates = [...this.props.templates];
    const foundIndex = templates.findIndex((x) => x._id == e._id);
    this.setState({ openDialog: true, selectedTemplate: foundIndex });
  };
  render() {
    const { classes, templates, isLoading } = this.props;
    return (
      <div className={classes.root}>
        <table>
          <tbody>
            <tr className={`${classes.row} ${classes.topbar}`}>
              {fields.map((field) => (
                <th className={clsx(classes.column, classes.dataColumn)}>
                  <Typography variant="h5" color="primary" align="center">
                    {field.name.toUpperCase()}
                  </Typography>
                </th>
              ))}
            </tr>
            {isLoading ? (
              <Box my={4} display="flex" justifyContent="center">
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <React.Fragment>
                {templates.map((e, index) => (
                  <tr
                    className={clsx(classes.row, classes.dataRow, {
                      [classes.alterRow]: index % 2 == 0,
                      [classes.stayToLeft]: e.key == "assistantName",
                    })}
                  >
                    {fields.map((field) => (
                      <th
                        className={clsx(classes.column, classes.dataColumn)}
                        onClick={() => this.openTemplate(e)}
                      >
                        {columnType(classes, e, field, index)}
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </table>
        <Dialog
          open={this.state.openDialog}
          onClose={this.toggleDialog}
          aria-labelledby="form-dialog-title"
          // fullScreen
        >
          {/* Assistants Details */}
          <AssistantsDetails
            templateIndex={this.state.selectedTemplate}
            templates={this.props.templates}
            toggleDialog={this.toggleDialog}
            setTemplate={this.props.setTemplate}
          />
        </Dialog>
      </div>
    );
  }
}

function columnType(classes, columnData, fieldName, index) {
  const key = fieldName.key;
  switch (key) {
    case "remainingCredit":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] / 100}
        </Typography>
      );
      break;
    case "createdAt":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
      break;
    case "planActivatedOn":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
      break;
    case "assignedTo":
      return (
        <Typography variant="body1" align="center">
          {columnData.status === "PENDING" ? "-" : columnData[key]}
        </Typography>
      );
      break;
    case "whatsappNumber":
      return (
        <Typography variant="body1" align="center" noWrap>
          {columnData[key] ? "+" + columnData[key] : "-"}
        </Typography>
      );
      break;
    case "index":
      return (
        <Typography variant="body1" align="center" noWrap>
          {`CS-00${index < 10 ? "0" : ""}${index}`}
        </Typography>
      );
      break;
    default:
      return (
        <Typography variant="body1" align="center" noWrap>
          {columnData[key]}
        </Typography>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    boxSizing: "border-box",
    borderRadius: "8px",
    position: "relative",
    border: "1px solid lightgrey",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      // height: "calc(100vh - 60px)",
    },
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      background: "white",
    },
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "rgb(70 20 134 / 15%)",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  dataColumn: {
    width: "200px",
    // minHeight: "80px",
    // display: "flex",
    // background: "red"
    // flexWrap: "wrap"
  },
  stayToLeft: {
    position: "sticky",
    left: 0,
    top: 0,
  },
  checkboxColumn: {
    width: "60px",
    // background: "red"
  },
});

export default withStyles(styles)(AssistantsTable);
