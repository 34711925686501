import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  TextField,
  withStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { partnerURL } from "../../config/config";
import axios from "axios";

function HealthCheck(props) {
  const { classes, assistant, partnerId, setSnackbar, setMetaError } = props;
  const { _id: assistantId } = assistant || {};
  const [isLoading, setLoading] = useState(false);

  const healthCheck = async () => {
    setLoading(true);
    const appId = axios.post(
      partnerURL + `/partner/${partnerId}/waba-app-id-health-check`,
      {
        assistantId,
      }
    );

    const numberId = axios.post(
      partnerURL + `/partner/${partnerId}/waba-number-id-health-check`,
      {
        assistantId,
      }
    );

    const results = await Promise.allSettled([appId, numberId]);
    setLoading(false);
    for (const result of results) {
      if (result.status === "rejected") {
        setMetaError(result.reason);
        return setSnackbar({
          alert: true,
          alertMsg:
            "Health degraded. You to undergo procurement procedure again.",
          alertType: "error",
        });
      }
    }

    setSnackbar({
      alert: true,
      alertMsg: "Health check success. Everything is healthy.",
      alertType: "success",
    });
  };

  return (
    <Box py={2} px={2} my={2} className={classes.container}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Health Check
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: window.innerWidth < 767 ? "center" : "flex-end",
          }}
        >
          <Typography variant="body1" gutterBottom>
            <Button
              style={{ marginRight: 3, marginLeft: 3 }}
              color="primary"
              variant="contained"
              onClick={healthCheck}
              endIcon={
                isLoading && (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )
              }
            >
              Check
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "rgb(70 20 134/0.1)",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});

export default withStyles(styles)(HealthCheck);
