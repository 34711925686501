import axios from "axios";
import { URL } from "../../config/config";
import { StaticRouter } from "react-router";

const initialState = {
  // "business": [],
  business: Array(10)
    .fill(0)
    .map((item) => ({ name: "Loading" })),
  skip: 0,
  totalBusinesses: 0,
  isLoading: true,
  error: null,
  page: 0,
  rowsPerPage: 10,
  allCount: 0,
  archivedCount: 0,
};

const CREATE_CLIENT = "Client/CREATE_CLIENT";
const LOAD_START = "Client/LOAD_START";
const LOAD_SUCCESS = "Client/LOAD_SUCCESS";
const LOAD_MORE_SUCCESS = "Client/LOAD_MORE_SUCCESS";
const LOAD_FAILURE = "Client/LOAD_FAILURE";
const ADD_CLIENT = "Client/ADD_CLIENT";
const CHANGE_PAGE = "Client/CHANGE_PAGE";
const CHANGE_ROWS_PER_PAGE = "Clients/CHANGE_ROWS_PER_PAGE";
const CHANGE_QUERY = "Clients/CHANGE_QUERY";
const CHANGE_STATUS = "Clients/CHANGE_STATUS";
const EXIT_CLIENTS = "TEMPLATE/EXIT_CLIENTS";
const LOAD_DATE = "LOAD_DATE/CLIENTS";
const LOAD_APPLIED = "LOAD_APPLIED/CLIENT";
const LOAD_BUSINESS_COUNTS = "LOAD_BUSINESS_COUNTS/CLIENT";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = ({ business, skip, totalBusinesses }) => ({
  type: LOAD_SUCCESS,
  payload: { business, skip, totalBusinesses },
});

const createClient = ({ business, totalBusinesses, createdAt }) => ({
  type: CREATE_CLIENT,
  payload: { business, totalBusinesses, createdAt },
});

const loadmoresuccess = ({ business, newSkip }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { business, newSkip },
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

const loadBusinessCount = ({ all, archived }) => ({
  type: LOAD_BUSINESS_COUNTS,
  payload: {
    all,
    archived,
  },
});

export const loadClient = (filters) => (dispatch, getState) => {
  const { rowsPerPage } = getState().client;
  const { user } = getState().login;
  const sort = filters.sort;
  const newFilter = { ...filters };
  delete newFilter.sort;
  console.log({ user });

  dispatch(loadStart);
  axios
    .post(
      URL +
        `${
          user.isReferral
            ? `/api/partner-referral-business-lists`
            : `/api/business-lists`
        }?sort=${sort}`,
      {
        skip: 0,
        rowsPerPage,
        ...(user.isReferral ? { partnerReferralId: user.id } : {}),
        ...newFilter,
      }
    )
    .then((response) => {
      const { business, newSkip, totalBusinesses } = response.data;

      dispatch(
        loadSuccess({
          business: business,
          skip: newSkip,
          totalBusinesses: totalBusinesses,
        })
      );
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export const loadSearch = (filter) => (dispatch, getState) => {
  const skip = 0;
  const rowsPerPage = 0;
  const clientSearch = true;
  const { user } = getState().login;
  dispatch(loadStart);
  axios
    .post(
      URL +
        `${
          user.isReferral
            ? `/api/partner-referral-business-lists`
            : `/api/business-lists`
        }`,
      {
        ...(user.isReferral ? { partnerReferralId: user.id } : {}),
        skip,
        rowsPerPage,
        filter,
        clientSearch,
      }
    )
    .then((response) => {
      const { business, newSkip, totalBusinesses } = response.data;
      dispatch(
        loadSuccess({
          business: business,
          skip: newSkip,
          totalBusinesses: totalBusinesses,
        })
      );
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export const loadNext = (event, newPage, filters) => (dispatch, getState) => {
  const { rowsPerPage, skip, business } = getState().client;
  const { user } = getState().login;
  const sort = filters.sort;
  const newFilter = { ...filters };
  delete newFilter.sort;

  if (business.length > newPage * rowsPerPage) {
    dispatch({ type: CHANGE_PAGE, payload: newPage });
  } else {
    dispatch({ type: CHANGE_PAGE, payload: newPage });
    axios
      .post(
        URL +
          `${
            user.isReferral
              ? `/api/partner-referral-business-lists`
              : `/api/business-lists`
          }?sort=${sort}`,
        {
          skip,
          rowsPerPage,
          ...(user.isReferral ? { partnerReferralId: user.id } : {}),
          ...newFilter,
        }
      )
      .then((response) => {
        const { business, newSkip } = response.data;
        dispatch(loadmoresuccess({ business: business, newSkip: newSkip }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(loadFailure(error));
      });
  }
};

export const createnewClient = (business) => (dispatch, getState) => {
  const totalBusinesses = getState().client.totalBusinesses;
  dispatch(
    createClient({
      business: { ...business, _id: business.id },
      totalBusinesses: totalBusinesses,
      createdAt: Date.now(),
    })
  );
};

export const addClient = (client) => (dispatch) => {
  dispatch({ type: ADD_CLIENT, payload: client });
};

export const changeQuery = (nameQuery) => (dispatch) => {
  dispatch({ type: CHANGE_QUERY, payload: nameQuery });
};

export const changeRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: CHANGE_ROWS_PER_PAGE, payload: rowsPerPage });
};

export const unfetchclients = () => (dispatch) => {
  dispatch({ type: EXIT_CLIENTS, payload: null });
};

export const fetchBusinessCounts = () => (dispatch, getState) => {
  const { user } = getState().login;

  axios
    .post(URL + "/api/business-counts", {})
    .then((response) => {
      const { allCount, archivedCount } = response.data[0];
      dispatch(
        loadBusinessCount({
          all: allCount || 0,
          archived: archivedCount || 0,
        })
      );
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export default function ClientReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        business: [...payload.business],
        skip: payload.skip,
        totalBusinesses: payload.totalBusinesses,
        page: 0,
        isLoading: false,
        error: null,
      };
    case LOAD_DATE:
      return {
        ...state,
        applied: {
          ...state.applied,
          createdAt: {
            ...state.applied.createdAt,
            startDate: payload.fromDate,
            endDate: payload.toDate,
          },
        },
      };
    case CREATE_CLIENT:
      const businessList = [...state.business];
      if (businessList.length > 9) {
        businessList.pop();
      }
      return {
        ...state,
        business: [
          {
            ...payload.business,
            displayName: payload.business.display_name,
            createdOn: payload.createdAt,
          },
          ...businessList,
        ],
        totalBusinesses: payload.totalBusinesses + 1,
      };

    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        business: [...state.business, ...payload.business],
        skip: payload.newSkip,
        isLoading: false,
      };

    case CHANGE_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload, page: 0 };

    case CHANGE_PAGE:
      return { ...state, page: payload };

    case LOAD_FAILURE:
      return { ...state, isLoading: true, error: payload };

    case ADD_CLIENT:
      return {
        ...state,
        business: { [payload.name]: payload, ...state.business },
      };

    case LOAD_APPLIED:
      return {
        ...state,
        applied: { ...state.applied, ...payload.applied },
      };

    case LOAD_BUSINESS_COUNTS:
      return {
        ...state,
        allCount: payload.all,
        archivedCount: payload.archived,
      };

    case CHANGE_QUERY:
      return {
        ...state,
        nameQuery: payload,
      };

    case EXIT_CLIENTS:
      return {
        ...initialState,
      };

    case CHANGE_STATUS:
      return {
        ...state,
        business: {
          ...state.business,
          [payload.displayName]: {
            ...state.business[payload.displayName],
            status: payload.status,
          },
        },
      };

    default:
      return state;
  }
}
