import React from "react";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

export default function Toast(props) {
  const { open, closeSnackbar, severity, msg, vertical, horizontal, autoHideDuration } = props;
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: vertical ?? "top",
        horizontal: horizontal ?? "center",
      }}
      onClose={closeSnackbar}
      autoHideDuration={autoHideDuration ?? 3000}
    >
      <Alert severity={severity} onClose={closeSnackbar}>
        {msg}
      </Alert>
    </Snackbar>
  );
}
