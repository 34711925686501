import React, { useState } from "react";
import {
  Button,
  Box,
  Typography,
  TextField,
  withStyles,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { APIURL, partnerURL } from "../../config/config";
import { Alert } from "@material-ui/lab";
import axios from "axios";

function OtpVerification(props) {
  const { classes, assistant, partnerId, setMetaError } = props;
  const { _id: assistantId } = assistant || {};
  const [code, setCode] = useState();
  const [mode, setMode] = useState("SMS");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [msg, setMsg] = useState("");

  const closeSnackbar = () => {
    setOpen(false);
    setSeverity("");
    setMsg("");
  };

  const verifyCode = () => {
    axios
      .post(partnerURL + `/partner/${partnerId}/verify-code`, {
        assistantId,
        verifyCode: code,
      })
      .then((response) => {
        localStorage.removeItem(`lastRegisterCall_${assistantId}`);
        setOpen(true);
        setMsg("Success");
        setSeverity("success");
      })
      .catch((error) => {
        console.log(error);
        setOpen(true);
        setMsg("Could not verify");
        setSeverity("error");
      });
  };

  const triggerOtp = () => {
    axios
      .post(partnerURL + `/partner/:partnerId/trigger-code`, {
        assistantId,
        mode,
      })
      .then((response) => {
        setOpen(true);
        setMsg("OTP sent!");
        setSeverity("success");
      })
      .catch((error) => {
        console.log(error);
        setMetaError(error);
        setOpen(true);
        setMsg("Failed");
        setSeverity("error");
      });
  };

  return (
    <Box py={2} px={2} my={2} className={classes.container}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Request OTP
          </Typography>
        </Grid>
        {window.innerWidth < 767 && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <Autocomplete
              disableClearable
              value={mode}
              options={["SMS", "VOICE"]}
              onChange={(e, value, reason) => setMode(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    padding: "0px 20px",
                    border: "1px solid ",
                    background: "white",
                    width: 120,
                  }}
                  InputProps={{ ...params.InputProps }}
                  placeholder="Verification Mode"
                  name="Verification Mode"
                />
              )}
            />
          </Grid>
        )}

        {window.innerWidth < 767 && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={triggerOtp}
              style={{ width: 100 }}
            >
              Request
            </Button>
          </Grid>
        )}

        {window.innerWidth >= 767 && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Autocomplete
              disableClearable
              value={mode}
              options={["SMS", "VOICE"]}
              onChange={(e, value, reason) => setMode(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    padding: "8px 20px",
                    border: "1px solid ",
                    background: "white",
                    width: 120,
                  }}
                  InputProps={{ ...params.InputProps }}
                  placeholder="Verification Mode"
                  name="Verification Mode"
                />
              )}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={triggerOtp}
              style={{ marginLeft: 10, width: 100 }}
            >
              Request
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container style={{ marginTop: 20 }}>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Verify OTP
          </Typography>
        </Grid>
        {window.innerWidth < 767 && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <TextField
              placeholder="enter code"
              name="verifyCode"
              onChange={(e) => setCode(e.target.value)}
              value={code}
              style={{
                padding: "0px 20px",
                border: "1px solid ",
                background: "white",
                width: 120,
              }}
            />
          </Grid>
        )}

        {window.innerWidth < 767 && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ width: 100 }}
              color="primary"
              variant="contained"
              onClick={verifyCode}
            >
              Verify
            </Button>
          </Grid>
        )}

        {window.innerWidth >= 767 && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TextField
              placeholder="enter code"
              name="verifyCode"
              onChange={(e) => setCode(e.target.value)}
              value={code}
              style={{
                padding: "8px 20px",
                border: "1px solid ",
                background: "white",
                width: 120,
              }}
            />
            <Button
              style={{ marginLeft: 10, width: 100 }}
              color="primary"
              variant="contained"
              onClick={verifyCode}
            >
              Verify
            </Button>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={closeSnackbar}
        autoHideDuration={3000}
      >
        <Alert severity={severity} onClose={closeSnackbar}>
          {msg}
        </Alert>
      </Snackbar>
    </Box>
  );
}

const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "rgb(70 20 134/0.1)",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});

export default withStyles(styles)(OtpVerification);
