function replaceParameters(text, parameters) {
  let formattedText = text || "";

  parameters.forEach((param, index) => {
    const pattern = new RegExp(`\\{\\{${index + 1}\\}\\}`);
    if (param) {
      formattedText = formattedText.replace(pattern, `[${param}]`);
    }
  });
  return formattedText;
}

export default replaceParameters;
