import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  withStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { partnerURL } from "../../config/config";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from "@material-ui/core/Tooltip";

function RegisterPin(props) {
  const {
    classes,
    assistant,
    partnerId,
    setSnackbar,
    setMetaError,
    wabaIsLive,
  } = props;
  const { _id: assistantId } = assistant || {};
  const [isLoading, setLoading] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const [canRegister, setCanRegister] = useState(true);

  const checkRegister = () => {
    const lastRegisterCall = localStorage.getItem(
      `lastRegisterCall_${assistantId}`
    );
    if (!lastRegisterCall) {
      setCanRegister(true);
    } else {
      const timePassed = Date.now() - lastRegisterCall;
      const hoursPassed = timePassed / (1000 * 60 * 60);
      setCanRegister(hoursPassed >= 24);
    }
  };
  useEffect(() => {
    checkRegister();
    const intervalId = setInterval(checkRegister, 1000 * 60 * 30); // check every 30 minutes
    return () => clearInterval(intervalId);
  }, []);

  const registerWithPin = () => {
    setLoading(true);
    setDialog(false);

    if (wabaIsLive) {
      localStorage.setItem(`lastRegisterCall_${assistantId}`, Date.now());
    }

    axios
      .post(partnerURL + `/partner/${partnerId}/register-with-pin`, {
        assistantId,
      })
      .then((response) => {
        setLoading(false);
        checkRegister();
        setSnackbar({
          alert: true,
          alertMsg: "Successfully Registered",
          alertType: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        setMetaError(error);
        setLoading(false);
        checkRegister();
        setSnackbar({
          alert: true,
          alertMsg: "Phone Number registeration failed!",
          alertType: "error",
        });
      });
  };

  return (
    <Box py={2} px={2} my={2} className={classes.container}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Register Phone Number
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: window.innerWidth < 767 ? "center" : "flex-end",
          }}
        >
          <Typography variant="body1" gutterBottom>
            <Tooltip
              title="Try again after some time"
              disableHoverListener={canRegister}
            >
              <span>
                <Button
                  style={{ marginRight: 3, marginLeft: 3 }}
                  color="primary"
                  variant="contained"
                  endIcon={
                    isLoading && (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    )
                  }
                  disabled={!canRegister}
                  onClick={
                    wabaIsLive && canRegister
                      ? () => setDialog(true)
                      : registerWithPin
                  }
                >
                  Register
                </Button>
              </span>
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>

      {/* confirmation dialog */}

      <Dialog
        open={openDialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "40%",
            height: "30%",
          },
        }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ marginBottom: "25px" }}
          >
            Are you sure you want to continue?
          </DialogContentText>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="body1"
              style={{ fontSize: "0.8em", marginBottom: "10px" }}
            >
              <b>• Performing this action would re-register you with Meta.</b>
            </Typography>
            <Typography variant="body1" style={{ fontSize: "0.8em" }}>
              <b>
                • Only perform this action if your business is locked or if you
                have manually
              </b>
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: "0.8em",
                marginBottom: "10px",
                marginLeft: "8px",
              }}
            >
              <b>changed your display name from FBM.</b>
            </Typography>
            <Typography variant="body1" style={{ fontSize: "0.8em" }}>
              <b>• This action can only be performed once in 24 hours.</b>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={registerWithPin} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "rgb(70 20 134/0.1)",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});

export default withStyles(styles)(RegisterPin);
