import React, { useEffect } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

import ErrorPage from "./commons/ErrorPage/ErrorPage";
import ProjectsLayout from "./commons/ProjectsLayout/ProjectsLayout";
import Login from "./pages/Login/Login";
import Embad from "./pages/Embed/EmbedSignup";
import { TENANT_ID, URL } from "./config/config";
import axios from "axios";
import { fetchTenantDetails } from "../src/store/tenantState";
import { fetchPartnerDetails } from "../src/store/partnerState";
import { fetchAgents } from "../src/store/partnerAgentsState";
import RazorpayAuthPage from "./pages/RazorpayAuth/RazorpayAuthPage";

const PrivateRoute = ({
  component,
  isAuthenticated,
  fetchTenantDetails,
  fetchPartnerDetails,
  fetchAgents,
  ...rest
}) => {
  useEffect(() => {
    fetchTenantDetails();
    fetchPartnerDetails();
    fetchAgents();
    if (TENANT_ID) {
      axios
        .post(URL + `/api/get-tenant-profile`, {
          tenantId: TENANT_ID,
        })
        .then((res) => {
          const { faviconUrl, name } = res.data.data;
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.head.appendChild(link);
          }
          link.href = faviconUrl;
          document.title = `${name} - Partner Dashboard`;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      document.title = "AiSensy - Partner Dashboard";
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href =
        "https://wa-tools-plugin.s3.ap-south-1.amazonaws.com/favicon.ico";
    }
  }, []);

  return !isAuthenticated ? (
    <Redirect to={{ pathname: "/login" }} />
  ) : (
    <Route {...rest} component={component} />
  );
};
const PublicRoute = ({
  component,
  isAuthenticated,
  fetchTenantDetails,
  ...rest
}) => {
  useEffect(() => {
    fetchTenantDetails();
    if (TENANT_ID) {
      axios
        .post(URL + `/api/get-tenant-profile`, {
          tenantId: TENANT_ID,
        })
        .then((res) => {
          const { faviconUrl, name } = res.data.data;
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.head.appendChild(link);
          }
          link.href = faviconUrl;
          document.title = `${name} - Partner Dashboard`;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      document.title = "AiSensy - Partner Dashboard";
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href =
        "https://wa-tools-plugin.s3.ap-south-1.amazonaws.com/favicon.ico";
    }
  }, []);
  return <Route {...rest} component={component} />;
};

const App = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/:partnerId/embed/signup" component={Embad} />

        <PublicRoute
          exact
          path="/login"
          component={Login}
          isAuthenticated={props.isAuthenticated}
          fetchTenantDetails={props.fetchTenantDetails}
        />
        <PrivateRoute
          path="/integrations/appstore/app/razorpay/auth"
          component={RazorpayAuthPage}
          isAuthenticated={props.isAuthenticated}
          fetchTenantDetails={props.fetchTenantDetails}
          fetchPartnerDetails={props.fetchPartnerDetails}
          fetchAgents={props.fetchAgents}
        />
        <PrivateRoute
          path="/"
          component={ProjectsLayout}
          isAuthenticated={props.isAuthenticated}
          fetchTenantDetails={props.fetchTenantDetails}
          fetchPartnerDetails={props.fetchPartnerDetails}
          fetchAgents={props.fetchAgents}
        />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: state.login.isAuthenticated,
  }),
  { fetchTenantDetails, fetchPartnerDetails, fetchAgents }
)(App);
