import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { Edit, Clear } from "@material-ui/icons";
import { connect } from "react-redux";
import { Alert, Autocomplete } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { URL } from "../../config/config";
import { addAgentSize } from "../../store/partnerState";
import AgentLimitDialog from "./AgentLimitDialog";
import { checkAccess } from "../../helpers/RBAC";

class AgentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      selectedValue: "a",
      agentValue: null,
      openAgentDialog: false,
      agentSize: null,
    };
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  agentDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openAgentDialog: !this.state.openAgentDialog });
  };

  sizeSubmit = (agentSize) => {
    if (!agentSize || agentSize < 1 || agentSize > 100) {
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "Agents limit should be greater than 1 and less than 100",
      });
      return;
    }
    axios
      .patch(URL + `/api/update-partner-config`, {
        agentSize,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "Agents limit updated successfully",
          openPlanDialog: false,
          agentSize: agentSize,
          openAgentDialog: false,
        });
        this.props.addAgentSize(agentSize);
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "Agents limit family update failed",
          planSubmitLoader: false,
        });
      });
  };

  render() {
    const { classes, match, partner, partnerAgents, user } = this.props;
    const { agentValue, agentSize } = this.state;
    const assistantId = match.params.projectId;

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "write"
    );

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Header businessTitle={"Configure Project Agents"} />
        <Box my={3} />
        {/* Layout */}
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box className={classes.container}>
                  <Typography variant="h4">
                    Select Default Agents Limit
                  </Typography>
                  <Box mt={3} />
                  <Box>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Agents Limit
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {this.props.partner.agentSize ?? "Set agent limit"}
                      </Grid>

                      <Grid item xs={12} md={4} className={classes.cells}>
                        {hasWriteAccess && (
                          <IconButton onClick={this.agentDialog}>
                            <Edit />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  <Box mt={2} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box my={8} />
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
        {this.state.openAgentDialog && (
          <AgentLimitDialog
            openDialog={this.state.openAgentDialog}
            closeDialog={this.agentDialog}
            classes={classes}
            currentValue={agentSize}
            changeValueFn={this.sizeSubmit}
          />
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  cells: {
    padding: 24,
    color: "grey",
    borderBottom: "1px solid #ecebeb",
  },
  plandialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

const connectAgentsPage = connect(
  (state) => ({
    partner: state.partner.partner,
    partnerAgents: state.partnerAgents.partnerAgents,
    user: state.login.user,
  }),
  { addAgentSize }
)(AgentsPage);

export default withStyles(styles)(connectAgentsPage);
