import {
  Box,
  Grid,
  Typography,
  Snackbar,
  Button,
  withStyles,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import {
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
  stepOneState,
  stepTwoState,
  stepThreeState,
  stepFourState,
} from "./FormDetails";
import { Clear } from "@material-ui/icons";
import validateStep from "../../../helpers/wabaFormHelper";
import FormField from "./FormField";
import FormCheckbox from "./FormCheckbox";
import FormAutocomplete from "./FormAutocomplete";
import axios from "axios";
import { URL, partnerURL } from "../../../config/config";

function WabaForm(props) {
  const [step, setStep] = useState(0);
  const { classes, showDialog, toggleDialog, assistantDetails, setSnackbar } =
    props;

  const [alert, setAlert] = useState({ show: false, severity: "", msg: "" });
  const [personalDetails, setPersonalDetails] = useState(
    assistantDetails.personalDetails
  );
  const [businessDetails, setBusinessDetails] = useState(
    assistantDetails.businessDetails
  );
  const [fbBusinessDetails, setFbBusinessDetails] = useState(
    assistantDetails.fbBusinessDetails
  );
  const [whatsappAccountDetails, setWhatsappAccountDetails] = useState(
    assistantDetails.whatsappAccountDetails
  );

  const nextStep = () => {
    let err;
    if (step === 0) err = validateStep.validateFirstStep(personalDetails);
    if (step === 1) err = validateStep.validateSecondStep(businessDetails);
    if (step === 2) err = validateStep.validateThirdStep(fbBusinessDetails);

    if (!err) setStep((prev) => prev + 1);
    else setAlert({ show: true, severity: "error", msg: err });
  };

  const prevStep = () => setStep((prev) => (prev > 1 ? prev - 1 : 0));

  const setStepFields = (applicableStep, key, value) => {
    if (applicableStep === 0)
      setPersonalDetails((prev) => ({ ...prev, [key]: value }));
    if (applicableStep === 1)
      setBusinessDetails((prev) => ({ ...prev, [key]: value }));
    if (applicableStep === 2)
      setFbBusinessDetails((prev) => ({ ...prev, [key]: value }));
    if (applicableStep === 3)
      setWhatsappAccountDetails((prev) => ({ ...prev, [key]: value }));
  };

  const closeSnackbar = () => setAlert((prev) => ({ ...prev, show: false }));

  const StepTitle = ({ title, subTitle }) => {
    return (
      <Box>
        <Typography className={classes.stepTitle}>{title}</Typography>
        <Typography className={classes.stepSubTitle}>{subTitle}</Typography>
      </Box>
    );
  };

  return (
    <Dialog open={showDialog} onClose={toggleDialog} maxWidth="xs" fullWidth>
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-end",
          height: 32,
          padding: "4px 4px 0px 0px",

          boxSizing: "border-box",
        }}
      >
        <Clear onClick={toggleDialog} className={classes.IconButton} />
      </Grid>
      <Box className={classes.root}>
        <Typography className={classes.head}>
          Whatsapp Business Application Form
        </Typography>
        {/* Step 1 - Personal Details */}
        {step == 0 && (
          <Box>
            <StepTitle title={stepOne.title} subTitle={stepOne.subTitle} />
            {Object.keys(stepOne.keys).map((e) => {
              const field = stepOne.keys[e];
              return (
                <Box key={e}>
                  <FormField
                    title={field.title}
                    isMandatory={field.isMandatory}
                    hyperlink={field.hyperlink}
                    subText={field.subTitle}
                    stepKey={e}
                    setKey={setStepFields.bind(this, 0)}
                    value={personalDetails[e]}
                    placeholder={field.placeholder}
                  />
                </Box>
              );
            })}
          </Box>
        )}

        {/* Step 2 - Business Details */}
        {step == 1 && (
          <Box>
            <StepTitle title={stepTwo.title} subTitle={stepTwo.subTitle} />
            {Object.keys(stepTwo.keys).map((e) => {
              const field = stepTwo.keys[e];
              return (
                <Box key={e}>
                  {field.type === "textfield" ? (
                    <FormField
                      title={field.title}
                      isMandatory={field.isMandatory}
                      hyperlink={field.hyperlink}
                      subText={field.subTitle}
                      stepKey={e}
                      setKey={setStepFields.bind(this, 1)}
                      value={businessDetails[e]}
                      placeholder={field.placeholder}
                    />
                  ) : (
                    <FormAutocomplete
                      title={field.title}
                      isMandatory={field.isMandatory}
                      hyperlink={field.hyperlink}
                      subText={field.subTitle}
                      stepKey={e}
                      setKey={setStepFields.bind(this, 1)}
                      value={businessDetails[e]}
                      options={field.options}
                      placeholder={field.placeholder}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        )}

        {/* Step 3 - FB Business Details */}
        {step == 2 && (
          <Box>
            <StepTitle title={stepThree.title} subTitle={stepThree.subTitle} />
            {Object.keys(stepThree.keys).map((e) => {
              const field = stepThree.keys[e];
              return (
                <Box key={e}>
                  {field.type === "textfield" ? (
                    <FormField
                      title={field.title}
                      isMandatory={field.isMandatory}
                      hyperlink={field.hyperlink}
                      subText={field.subTitle}
                      stepKey={e}
                      setKey={setStepFields.bind(this, 2)}
                      value={fbBusinessDetails[e]}
                      placeholder={field.placeholder}
                    />
                  ) : (
                    <FormCheckbox
                      title={field.title}
                      isMandatory={field.isMandatory}
                      hyperlink={field.hyperlink}
                      subText={field.subTitle}
                      stepKey={e}
                      setKey={setStepFields.bind(this, 2)}
                      options={field.options}
                      value={fbBusinessDetails[e]}
                      placeholder={field.placeholder}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        )}

        {/* Step 4 - Whatsapp Account Details */}
        {step == 3 && (
          <Box>
            <StepTitle title={stepFour.title} subTitle={stepFour.subTitle} />
            {Object.keys(stepFour.keys).map((e) => {
              const field = stepFour.keys[e];
              return (
                <Box key={e}>
                  <FormField
                    title={field.title}
                    isMandatory={field.isMandatory}
                    hyperlink={field.hyperlink}
                    subText={field.subTitle}
                    stepKey={e}
                    setKey={setStepFields.bind(this, 3)}
                    value={whatsappAccountDetails[e]}
                    placeholder={field.placeholder}
                  />
                </Box>
              );
            })}
          </Box>
        )}

        {/* Action Button */}
        {step < 3 && (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={step < 3 ? nextStep : nextStep}
            style={{
              marginTop: 24,
              fontSize: "14px",
              padding: "8px 16px",
              fontWeight: 600,
            }}
          >
            {step < 3 ? "Next" : "Submit"}
          </Button>
        )}

        {/* Previous Button */}
        {step > 0 && (
          <Button
            color="primary"
            variant="contained"
            onClick={prevStep}
            fullWidth
            className={classes.prevButton}
          >
            Previous
          </Button>
        )}

        {/* Snackbar based toast */}
        <Snackbar
          open={alert.show}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={closeSnackbar}
          autoHideDuration={3000}
        >
          <Alert severity={alert.severity} onClose={closeSnackbar}>
            {alert.msg}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}

const styles = (theme) => ({
  root: {
    // borderRadius: "10px",
    padding: 32,
    paddingTop: 0,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // boxSizing: "border-box",
  },
  head: {
    width: "100%",
    textAlign: "center",
    fontWeight: 700,
    fontSize: 24,
    margin: "0px 0 20px 0",
  },
  stepTitle: {
    color: "#202134",
    fontSize: 20,
    fontWeight: 700,
    textDecoration: "none rgb(32,33,36)",
    margin: "20px 0 0px 0",
  },
  stepSubTitle: {
    color: "#202134",
    fontSize: 16,
    textDecoration: "none rgb(32,33,36)",
  },

  IconButton: {
    padding: "8px ",
    zIndex: 100,
    "&:hover": {
      borderRadius: "70px",
      background: "#F0F0F0",
      cursor: "pointer",
    },
  },
  prevButton: {
    background: "transparent",
    color: "#000000",
    margin: "8px 0 20px 0",
    fontWeight: 600,
    "&:hover": {
      background: "transparent",
      textDecoration: "underline",
    },
  },
});

export default withStyles(styles)(WabaForm);
