import React, { Component } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CheckOutlined as CheckIcon } from "@material-ui/icons";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { URL } from "../../config/config";
import { connect } from "react-redux";

const examplePayload = {
  sub: "1234567890",
  name: "John Doe",
  iat: 1516239022,
  partnerId: "63ea14ea2567c1f4e8cda742",
  email: "user@example.com",
};

/**
 * @extends {Component<WithStyles<typeof styles>>}
 */
class SSOPage extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      isPassword: true,
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      ssoSecretKey: props.partner.ssoSecretKey || "",
    };
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  showSucessAlert = (msg) => {
    this.setState({
      alert: true,
      alertMsg: msg,
      alertSeverity: "success",
    });
  };

  showErrorAlert = (msg) => {
    this.setState({
      alert: true,
      alertMsg: msg,
      alertSeverity: "error",
    });
  };

  updateSecretKey = async () => {
    try {
      await axios.patch(`${URL}/api/profile-update`, {
        ssoSecretKey: this.state.ssoSecretKey,
      });
      this.showSucessAlert("Secret key updated successfully");
    } catch (error) {
      this.showErrorAlert(error.message);
    }
  };

  generateSecretKey = async () => {
    const secretKey =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    this.setState({ ssoSecretKey: secretKey, isPassword: false });
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  render() {
    const { classes } = this.props;
    const { ssoSecretKey, isPassword } = this.state;

    return (
      <div className={classes.root}>
        <Header businessTitle="Configure Single Sign On" />
        <Box my={3} />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Box className={classes.container}>
              <Typography variant="h3">
                Enabling Single Sign On (SSO)
              </Typography>
              <ol className={classes.list}>
                <li>
                  To enable Single Sign On (SSO), enter your secret key or if
                  you don't have you one can generate.
                </li>
                <li>
                  Upon successful authentication, create a JWT token using the
                  same secret key that contains &nbsp;
                  <tt className={classes.primary}>'email'</tt>
                  &nbsp;and&nbsp;
                  <tt className={classes.primary}>'partnerId'</tt>&nbsp;in
                  payload.
                </li>
                <li>
                  Set the cookie with the name&nbsp;
                  {Object.keys(this.props.tenantDetails).length ? (
                    <tt className={classes.primary}>{this.props.tenantDetails.name}SSOToken</tt>
                  ) : (
                    <tt className={classes.primary}>'aisensySSOToken'</tt>
                  )}
                  .&nbsp; &nbsp;to the generated token.
                </li>
                <li>Ensure that the sub-domain has access to the cookie.</li>
                <li>
                  The cookie will be automatically verified by the system and
                  the user will be logged in.
                </li>
              </ol>
              <Box my={3} />
              <TextField
                className={classes.textField}
                placeholder="Enter your secret key"
                fullWidth
                value={ssoSecretKey}
                name="ssoSecretKey"
                type={`${isPassword ? "password" : "text"}`}
                onChange={this.onChange}
                onFocus={() => this.setState({ isPassword: false })}
                onBlur={() => this.setState({ isPassword: true })}
                InputProps={{
                  endAdornment: (
                    <Button
                      size="small"
                      color="primary"
                      onClick={this.generateSecretKey}
                    >
                      Generate
                    </Button>
                  ),
                }}
              />
              <Box my={2} />
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  <CheckIcon
                    style={{
                      fontSize: 16,
                    }}
                  />
                }
                onClick={this.updateSecretKey}
              >
                Save
              </Button>
              <Box my={4} />
              <Typography variant="h4">Example JWT Payload</Typography>
              <pre className={classes.code}>
                {JSON.stringify(examplePayload, null, 2)}
              </pre>
            </Box>
            <Snackbar
              open={this.state.alert}
              autoHideDuration={4000}
              onClose={this.closeSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={this.closeSnackbar}
                severity={this.state.alertSeverity}
              >
                {this.state.alertMsg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    );
  }
}

/**
 *
 * @param {import("@material-ui/core").Theme} theme
 */
const styles = (theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      background: "rgb(247,247,247)",
      boxSizing: "border-box",
      overflowX: "hidden",
    },
    container: {
      marginTop: 16,
      background: "white",
      borderRadius: 8,
      padding: "24px 36px",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      fontSize: "0.875rem",
    },
    textField: {
      height: 42,
      "& .MuiIconButton-root": {
        padding: 6,
        background: "rgb(70 20 134 / 15%)",
        color: theme.palette.primary.main,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 15,
      },
    },
    code: {
      background: "rgb(240, 240, 240)",
      padding: 16,
      borderRadius: 8,
    },
    primary: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  });

const connectSSOPage = connect((state) => ({
  partner: state.partner.partner,
  tenantDetails: state.tenant.tenant,
}))(SSOPage);

export default withStyles(styles)(connectSSOPage);
