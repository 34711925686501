import React, { Component } from "react";
import { withStyles, Grid, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import NavBar from "./Navbar/NavBar";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import {
  viewModeRoutes,
  topRoutes,
  bottomRoutes,
} from "./Routes/ProjectRoutes";
import FlagNotification from "../Header/notificationBar";
import clsx from "clsx";
import Preloader from "../../static/preloader.gif";
import { TENANT_ID } from "../../config/config";

class ProjectsLayout extends Component {
  state = {
    status: "",
    statusMessage: "",
  };

  planRenewNotify = () => {
    const { partner } = this.props;
    const { partnershipRenewalOn } = partner;
    let partnershipRenewal = new Date(partnershipRenewalOn);
    let today = new Date();

    let differenceInTime = partnershipRenewal.getTime() - today.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);

    const daysUntilRenewal = Math.floor(differenceInDays);
    return daysUntilRenewal;
  };

  render() {
    const { classes, location, agent, partner, isPartnerDetailLoading } =
      this.props;
    const {
      type,
      partnershipFee,
      currency,
      partnershipRenewalOn,
      centralBalance,
      minAllowedBalance,
      addons = {},
    } = partner || {};
    const pathname = location.pathname;
    const routes = agent.isReferral ? viewModeRoutes : topRoutes;
    const fee = partnershipFee / 100000 || 0;

    let totalAddonFee = 0;
    if (Object.keys(addons).length > 0) {
      totalAddonFee = Object.values(addons).reduce((total, addon) => {
        if (addon.aisensyPrice[currency]) {
          return total + addon.aisensyPrice[currency];
        }
        return total;
      }, 0);
    }
    const totalFee = fee + totalAddonFee / 100000 || 0;
    const daysUntilRenewal = this.planRenewNotify();
    const symbol = currency === "USD" ? "$" : "₹";
    const date = new Date(partnershipRenewalOn)?.toLocaleDateString();
    const negetiveBalance = centralBalance < (minAllowedBalance || 0);
    const lowBalance =
      currency === "USD"
        ? centralBalance / 100000 < 48
        : centralBalance / 100000 < 3000;

    const isTenant = TENANT_ID === partner._id;

    return (
      <div id="projects-layout" className={classes.root}>
        {isTenant || isPartnerDetailLoading ? null : daysUntilRenewal <= 10 &&
          daysUntilRenewal > 0 &&
          totalFee !== 0 ? (
          <FlagNotification
            message={`Your plan renewal is in ${daysUntilRenewal} days. ${symbol}${totalFee} will be automatically deducted from your central balance on ${date}`}
            icon={"warning"}
            color="#FAB038"
            textColor="#000"
          />
        ) : daysUntilRenewal === 0 && totalFee !== 0 ? (
          <FlagNotification
            message={`Your plan renewal date is today! ${symbol}${totalFee} will be automatically deducted from your central balance.`}
            icon={"warning"}
            color="#FAB038"
            textColor="#000"
          />
        ) : negetiveBalance ? (
          <FlagNotification
            message={`Your services have been temporarily suspended due to insufficient central balance.`}
            icon={"error"}
            color="#FF1F1F"
            textColor="#FFFFFF"
          />
        ) : lowBalance ? (
          <FlagNotification
            message={`You are low on central balance. Please recharge your account to avoid service suspension.`}
            icon={"error"}
            color="#FF4747"
            textColor="#FFFFFF"
          />
        ) : null}
        <NavBar />
        <ErrorBoundary>
          <div id="route-container" className={classes.routeContainer}>
            <Switch>
              {routes.map((route, index) => {
                if (!route.excludedTypes?.includes(type)) {
                  return (
                    <Route
                      exact={route.exact}
                      key={route.name}
                      path={route.to}
                      component={route.component}
                    />
                  );
                }
              })}
              {bottomRoutes.map((route) => {
                return (
                  <Route
                    exact
                    key={route.name}
                    path={route.to}
                    component={route.component}
                  />
                );
              })}
              <Route component={() => <Redirect to="/error" />} />
            </Switch>
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
      position: "relative",
    },
  },
  routeContainer: {
    width: "calc(100% - 70px)",
    height: "100vh",
    transition: "1s",
    overflow: "hidden",
    marginLeft: 70,
    // background: "green",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "calc(100vh - 50px)",
      // height: "100vh",
      marginLeft: 0,
    },
  },
  fullHeight: {
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  preloader: {
    height: 150,
  },
});

const connectedProjectslayout = connect((state) => ({
  agent: state.login.user,
  partner: state.partner.partner,
  isPartnerDetailLoading: state.partner.isLoading,
  tenantDetails: state.tenant.tenant,
}))(ProjectsLayout);

export default withStyles(styles)(connectedProjectslayout);
