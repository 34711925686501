import axios from "axios";
import { URL } from "../config/config";

const initialState = {
  allWccPlans: {},
  isLoading: false,
  error: null,
};

const LOAD_START = "WccPlan/LOAD_START";
const LOAD_SUCCESS = "WccPlan/LOAD_SUCCESS";
const EDIT_PLAN = "WccPlan/EDIT_PLAN";
const ADD_PLAN = "WccPlan/ADD_PLAN";
const LOAD_FAILURE = "WccPlan/LOAD_FAILURE";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = (payload) => ({
  type: LOAD_SUCCESS,
  payload,
});

const editPlan = (payload) => ({
  type: EDIT_PLAN,
  payload,
});

const addPlan = (payload) => ({
  type: ADD_PLAN,
  payload,
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const fetchWccPlans = () => (dispatch, getState) => {
  const { user } = getState().login;
  if (user.isReferral) {
    return;
  }
  dispatch(loadStart());
  axios
    .get(URL + "/api/get-partner-wcc-plans")
    .then((response) => {
      const { wccPlan } = response.data;
      const plansObj = {};
      for (const value of wccPlan) plansObj[value._id] = value;
      dispatch(loadSuccess(plansObj));
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export const modifyWccPlan = (payload) => (dispatch) => {
  dispatch(editPlan(payload));
};

export const addWccPlan = (payload) => (dispatch) => {
  dispatch(addPlan(payload));
};

export default function WccPlanReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        allWccPlans: { ...payload },
        isLoading: false,
        error: null,
      };

    case EDIT_PLAN:
      return {
        ...state,
        allWccPlans: {
          ...state.allWccPlans,
          [payload.wccPlanId]: {
            ...state.allWccPlans[payload.wccPlanId],
            business_initiated: payload.bic,
            user_initiated: payload.uic,
          },
        },
        isLoading: false,
        error: null,
      };
    case ADD_PLAN:
      return {
        ...state,
        allWccPlans: {
          ...state.allWccPlans,
          [payload._id]: { ...payload },
        },
        isLoading: false,
        error: null,
      };
    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
