import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  withStyles,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import RegisterPin from "./RegisterPin";
import OtpVerification from "./OtpVerification";
import HealthCheck from "./HealthCheck";

function StepBasedSolutions(props) {
  const {
    classes,
    assistant,
    partnerId,
    steps,
    EmbeddedSignup,
    EmbeddedMigration,
    SyncProgress,
    setSnackbar,
    customMsgObject,
    setMetaError,
    wabaIsLive,
  } = props;
  const [step, setStep] = useState(0);
  const [disabledList, setDisabled] = useState(
    Array.from({ length: steps?.length || 0 }, () => false)
  );

  const troubleshoot_options = {
    embedded_signup: {
      component: EmbeddedSignup,
      title: "Embedded Signup",
      message:
        "Please tap on the button to generate an Embedded Signup URL. Complete the process to activate your WhatsApp Business Account",
    },
    migrate_partner_project: {
      component: EmbeddedMigration,
      title: "WABA Migration",
      message:
        "Please tap on the button to generate an Embedded WABA Migration URL. Complete the process to migrate your WhatsApp Business Account",
    },
    sync_progress: {
      component: SyncProgress,
      title: "Sync your progress",
      message: "Please tap on the button to sync your procurement progress.",
    },
    register_pin: {
      component: RegisterPin,
      title: "Phone Number Registeration",
      message:
        "Please tap on the button to register your Phone Number. If this steps fails then proceed to OTP Verification.",
    },
    otp_verification: {
      component: OtpVerification,
      title: "OTP Verification",
      message:
        "This step is optional if your registeration in previous step succeeded. If Registration Failed then tap on the trigger button to generate otp. Once the OTP is recieved fill the code and to complete verification required to complete your WhatsApp Business Account procurement process. After this go back to the previous Register step and register your number. If requesting OTP fails then proceed to next step to restart procurement process. ",
    },
    health_check: {
      component: HealthCheck,
      title: "Phone Number Health Check",
      message:
        "Please tap on the button to check health status of your Phone Number",
    },
    custom_msg: customMsgObject,
  };

  const handleNext = (disabled) => {
    const newDisabledList = [...disabledList];
    newDisabledList[step] = disabled;
    setStep((prevActiveStep) => prevActiveStep + 1);
    setDisabled((prev) => newDisabledList);
  };

  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Accordion
      elevation={0}
      style={{ background: "transparent" }}
      className={`${classes.cells} ${classes.accordian}`}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordianSummary}
      >
        <Grid item xs={12} sm={6} className={classes.celltext}>
          WABA Procurement Troubleshoot
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Stepper
          activeStep={step}
          orientation="vertical"
          style={{ width: "100%" }}
        >
          {steps.map((label, index) => {
            const StepComponent = troubleshoot_options[label].component;
            return (
              <Step key={label} className={classes.step}>
                <StepLabel>{troubleshoot_options[label].title}</StepLabel>
                <StepContent>
                  <Typography>{troubleshoot_options[label].message}</Typography>
                  <StepComponent
                    setSnackbar={setSnackbar}
                    assistant={assistant}
                    partnerId={partnerId}
                    setMetaError={setMetaError}
                    wabaIsLive={wabaIsLive}
                  />
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={step === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {step === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </AccordionDetails>
    </Accordion>
  );
}

const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
  },
  step: {
    "& .MuiStepIcon-text": {
      fill: "white",
    },
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "#EBF5F3",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
  cells: {
    padding: 24,
    color: "grey",
    borderBottom: "1px solid #ecebeb",
  },
  celltext: {
    color: "grey",
  },
  accordianDetail: {
    padding: "0",
    display: "block",
  },
  accordianSummary: {
    paddingLeft: "0px",
    "&.Mui-expanded": {
      minHeight: "0px",
    },
  },
  accordian: {
    "&:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      minHeight: "0px",
      marginTop: "0px",
    },
  },
});

export default withStyles(styles)(StepBasedSolutions);
