import React from "react";
import {
  withStyles,
  Typography,
  CardMedia,
  CardContent,
  Box,
} from "@material-ui/core";
import { Image, PlayCircleFilled, Description } from "@material-ui/icons";
import formatText from "../../helpers/formatText";
import InteractiveAction from "../TemplateMessagePreview/interativeUrlAction";
import ClickToAction from "../TemplateMessagePreview/clickToAction";
import WhatsAppIcon from "../../static/WhatsApp_icon.png";
import { bg_colors, icon_colors } from "../MediaLibrary/mediaState.js";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import replaceParameters from "../../helpers/replaceParameters";
import LocationOnIcon from "@material-ui/icons/LocationOn";

function Media(props) {
  const {
    classes,
    payload,
    type,
    maxHeight,
    bigIcon,
    showDisclaimer,
    selectedTemplate,
  } = props;

  const body = (payload.text || payload.caption || "").split(" | [")[0];
  let disclaimerMargin = payload.templateCTAUrl ? 80 : 16;

  return (
    <>
      {/* Main message box */}
      <div className={`${classes.message} ${classes.received}`}>
        {/* Whatsapp icon */}
        <img
          src={WhatsAppIcon}
          alt="WhatsApp"
          className={!bigIcon ? classes.whatsappIcon : classes.bigWhatsappIcon}
        />
        {/* Header section */}
        {payload.headerText && (
          <Box style={{ overflowWrap: "break-word" }}>
            <Typography className={classes.header}>
              {payload.headerText}
            </Typography>
          </Box>
        )}
        {type !== "TEXT" ? (
          !!payload.url ? (
            type === "AUDIO" ? (
              <audio
                controls
                loop
                src={payload.url}
                style={{ width: "100%" }}
              />
            ) : (
              <CardMedia
                className={classes.media}
                src={payload.url}
                alt="media"
                title={payload.filename || "Download media"}
                component={type === "IMAGE" ? "img" : "iframe"}
              />
            )
          ) : (
            <Box
              height="140px"
              bgcolor={bg_colors[type] ?? "#f8f8f8"}
              style={{
                border: "16px solid white",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {type === "IMAGE" ? (
                <Image
                  className={classes.icon}
                  style={{ color: icon_colors[type] }}
                />
              ) : type === "FILE" ? (
                <Description
                  className={classes.icon}
                  style={{ color: icon_colors[type] }}
                />
              ) : type === "VIDEO" ? (
                <PlayCircleFilled
                  className={classes.icon}
                  style={{ color: icon_colors["VIDEO"] }}
                />
              ) : type === "TEXT" ? (
                <Box style={{ height: 300, width: 300 }} />
              ) : type === "LOCATION" ? (
                <LocationOnIcon
                  className={classes.icon}
                  style={{ color: icon_colors["LOCATION"] }}
                />
              ) : (
                <Box style={{ display: "flex" }}>
                  <Image
                    className={classes.icon}
                    style={{ color: icon_colors["IMAGE"] }}
                  />
                  <PlayCircleFilled
                    className={classes.icon}
                    style={{ color: icon_colors["VIDEO"] }}
                  />
                  <Description
                    className={`${classes.icon} ${classes.fileIcon}`}
                    style={{ color: icon_colors["FILE"] }}
                  />
                </Box>
              )}
            </Box>
          )
        ) : (
          ""
        )}

        {/* No caption in audio type message*/}
        {type !== "AUDIO" ? (
          <CardContent
            style={
              maxHeight
                ? {
                    maxHeight,
                    overflowX: "auto",
                    padding: "8px 16px",
                  }
                : {
                    padding: "8px 16px",
                  }
            }
          >
            <Typography
              variant="h5"
              color="primary"
              align="left"
              style={
                type === "TEXT"
                  ? {
                      paddingTop: "8px",
                      minHeight: 40,
                    }
                  : {}
              }
              className={classes.messageBox}
              dangerouslySetInnerHTML={{
                __html: formatText(body),
              }}
            />
          </CardContent>
        ) : (
          ""
        )}
        {/* Footer section */}
        {payload.footerText && (
          <Box style={{ overflowWrap: "break-word" }}>
            <Typography className={classes.footer}>
              {payload.footerText}
            </Typography>
          </Box>
        )}

        {payload.actionType === "CAT" && (
          <ClickToAction
            payload={payload}
            type={type}
            theme={1}
            preview={true}
            selectedTemplate={selectedTemplate}
          />
        )}
      </div>
      {/* Button based actions */}
      {payload.actionType !== "CAT" && (
        <Box
          width="100%"
          maxWidth="340px"
          className={classes.agentMessageAction}
        >
          <InteractiveAction
            payload={payload}
            type={type}
            theme={1}
            preview={true}
          />
        </Box>
      )}
      {/* Disclaimer text here */}
      <div style={{ height: disclaimerMargin }} />
      {showDisclaimer && (
        <Typography
          className={classes.disclaimer}
          style={{
            color: type === "TEXT" && "transparent",
          }}
        >
          Disclaimer: This is just a graphical representation of the message
          that will be delivered. Actual message will consist of media selected
          and may appear different.
        </Typography>
      )}
    </>
  );
}

const styles = (theme) => ({
  root: {
    background: "#EBF5F3",
    maxWidth: "340px",
    width: "100%",
    // margin: "auto",
  },
  message: {
    clear: "both",
    background: "white",
    maxWidth: "340px",
    width: "100%",
    // margin: "auto",
    position: "relative",
    lineHeight: "24px",
    fontSize: "16px",
    // wordWrap: "break-word",

    "&::after": {
      position: "absolute",
      content: '""',
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  received: {
    background: "white",
    borderRadius: "0px 5px 5px 5px",
    //float: "left",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 0px 0px 0px, rgba(60, 64, 67, 0.15) -0.5px 1.5px 0px 0px",
    filter:
      "drop-shadow(0px 0.5px 0px rgba(60, 64, 67, 0.3)) drop-shadow(1px 1px 2px rgba(60, 64, 67, 0.15))",

    "&::after": {
      content: '""',
      borderWidth: "0px 10px 10px 0",
      borderColor: `transparent #fff transparent transparent`,
      top: "0",
      left: "-10px",
    },
  },
  sent: {
    background: "#EBF5F3",
    borderRadius: "5px 0px 5px 5px",
    //float: "right",
    boxShadow:
      "rgba(60, 64, 67, 0.3) -0.1px 0px 0px 0px, rgba(60, 64, 67, 0.15) 0.5px 1.5px 0px 0px",
    filter:
      "drop-shadow(0px 0.5px 0px rgba(60, 64, 67, 0.3)) drop-shadow(1px 1px 2px rgba(60, 64, 67, 0.15))",

    "&::after": {
      content: '""',
      borderWidth: "0px 0px 10px 10px",
      borderColor: "transparent transparent transparent #EBF5F3",
      top: "0",
      right: "-10px",
    },
  },
  media: {
    height: 140,
    width: "calc(100% - 32px)",
    padding: "16px",
    borderRadius: "20px",
    border: 0,
  },
  messageBox: {
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    color: "#000000",
  },
  agentMessageAction: {
    "& .defaultTheme": {
      borderRadius: "6px",
      maxWidth: "100%",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
  },
  header: {
    fontWeight: "600",
    padding: "8px 16px",
  },
  footer: {
    color: "#A9A9A9",
    fontSize: "14px",
    fontWeight: "500",
    padding: "0px 16px",
    paddingBottom: "8px",
  },
  icon: {
    width: "50px",
    height: "50px",
    color: "#a0a0a0",
  },
  whatsappIcon: {
    width: "30px",
    height: "30px",
    position: "absolute",
    top: -15,
    left: -15,
    zIndex: 1,
  },
  bigWhatsappIcon: {
    width: "50px",
    height: "50px",
    position: "absolute",
    top: -25,
    left: -25,
    zIndex: 1,
  },
  disclaimer: {
    color: "#A9A9A9",
    fontSize: "12px",
    maxWidth: 340,
  },
  fileIcon: {
    marginTop: "3px",
    height: "45px",
  },
});

export default withStyles(styles)(Media);
