import React, { Component } from "react";
import { withStyles, Grid, Typography, Box } from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ManageNavBarDesktop from "./Navbar/ManageNavBarDesktop";
import { renderManageRoute } from "./Routes/ProjectRoutes";
import { fetchPartnerDetails } from "../../store/partnerState";
import { checkAccess } from "../../helpers/RBAC";
import LockIcon from "@material-ui/icons/Lock";
class ManageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchPartnerDetails();
  }

  render() {
    const renderFilteredManageRoutes = this.props.partner.isZohoEnabled
      ? renderManageRoute
      : renderManageRoute.filter((i) => i.name !== "Manage Addons");
    const { classes, agent, partner, user, partnerAgents } = this.props;
    const { type } = partner || {};
    const baseroute = "/manage";

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "write"
    );

    return (
      <Grid container className={classes.root}>
        {hasReadAccess ? (
          <>
            <Grid item>
              <ManageNavBarDesktop />
            </Grid>
            <Grid item xs className={classes.routeWrapper}>
              <div id="route-container" className={classes.routeContainer}>
                <Switch>
                  <Route
                    key={30}
                    exact
                    path={baseroute}
                    render={(routeProps) => (
                      <Redirect
                        to={
                          type !== "DIRECT"
                            ? `/manage/config`
                            : `/manage/agents`
                        }
                        {...routeProps}
                      />
                    )}
                  />
                  {renderFilteredManageRoutes
                    .filter(
                      (route) =>
                        !(
                          this.props.user.agentRole !== "OWNER" &&
                          (route.to === "/roles" ||
                            route.to === "/team" ||
                            route.to === "/sso")
                        )
                    )
                    .map((route, index) => {
                      return route.name ? (
                        <Route
                          key={index}
                          path={baseroute + route.to}
                          component={
                            !!!route.excludedTypes?.includes(type) &&
                            route.component
                          }
                        />
                      ) : (
                        <></>
                      );
                    })}
                  <Route component={() => <Redirect to="/error" />} />
                </Switch>
              </div>
            </Grid>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              height: "80vh",
              width: "100%",
              alignItems: "center",
              // backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <LockIcon
              style={{
                fontSize: 90,
                color: "grey",
                marginBottom: "10px",
              }}
            />
            <Typography variant="h4" color="textSecondary">
              Access Denied
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Looks like you don't have the necessary permissions to view this
              page
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Please contact your administrator for more information.
            </Typography>
          </Box>
        )}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  routeWrapper: {
    height: "100vh",
    overflow: "hidden",
    boxSizing: "border-box",
    background: "white",
  },
  routeContainer: {
    width: "100%",
    height: "100vh",
    transition: "1s",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {},
  },
});

const connectedManageLayout = connect(
  (state) => ({
    user: state.login.user,
    agent: state.login.user,
    partner: state.partner.partner,
    partnerAgents: state.partnerAgents.partnerAgents,
  }),
  { fetchPartnerDetails }
)(ManageLayout);

export default withStyles(styles)(connectedManageLayout);
