import React, { Component } from "react";
import { connect } from "react-redux";
import "./pages.css";
import { loginUser, loginWithToken } from "./loginstate";
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  TextField,
  ThemeProvider,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import Toast from "../../commons/Toast/Toast";
import login from "../../static/login.jpg";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      status: "",
      statusMessage: "",
      showSnackbar: false,
      alertMsg: "",
      alertSeverity: "",
    };
  }

  componentDidMount() {
    this.tokenLogin();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.error) {
      this.setAlert("Invalid login credentials!", "error");
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  closeSnackbar = () => {
    this.setState({ showSnackbar: false });
  };

  setAlert = (msg, severity) => {
    this.setState({
      alertMsg: msg,
      alertSeverity: severity,
      showSnackbar: true,
    });
  };

  tokenLogin() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    if (!token) return;

    this.props.loginWithToken(token);
  }

  render() {
    const { userName, password } = this.state;
    const { isAuthenticated, loginUser } = this.props;
    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <div>
          <Grid
            container
            style={{
              height: "100vh",
              position: "relative",
              background: `url(${login})`,
              backgroundSize: "cover",
              margin: "0px",
              padding: "0px",
            }}
          >
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100vh",
                width: "60vh",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  overflow: "hidden",
                  flexBasis: "100%",
                  flexDirection: "column",
                  margin: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "75%",
                  height: "60%",
                  borderStyle: "solid",
                  borderColor: "#fff",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  borderRadius: "8px",
                  backgroundColor: "#ffffff",
                  zIndex: 100,
                }}
              >
                <Typography
                  style={{
                    marginTop: "56px",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                >
                  Partner Dashboard
                </Typography>
                <Box
                  style={{
                    width: "80%",
                    height: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      variant="h6"
                      style={{
                        textAlign: "center",
                        color: "black",
                        marginBottom: "20px",
                      }}
                    >
                      Sign In
                    </Typography>
                    <TextField
                      onChange={this.handleChange}
                      name="userName"
                      placeholder="Email or User Name"
                      variant="standard"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        marginBottom: "20px",
                        backgroundColor: "#F1F1F1",
                        borderRadius: "8px",
                        width: "calc(100% - 16px)",
                        padding: "8px",
                      }}
                    />
                    <TextField
                      onChange={this.handleChange}
                      variant="standard"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="password"
                      type="password"
                      placeholder="Password"
                      onKeyUp={(event) => {
                        if (event.key == "Enter") loginUser(userName, password);
                      }}
                      style={{
                        marginBottom: "20px",
                        backgroundColor: "#F1F1F1",
                        borderRadius: "8px",
                        width: "calc(100% - 16px)",
                        padding: "8px",
                      }}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      style={{
                        marginBottom: "20px",
                        border: "#a0a0a0",
                        backgroundColor: "#461486",
                        color: "white",
                      }}
                      onClick={() => {
                        loginUser(userName, password);
                      }}
                    >
                      Continue
                    </Button>
                  </ThemeProvider>
                </Box>
              </Box>
            </Container>
          </Grid>

          <Toast
            open={this.state.showSnackbar}
            closeSnackbar={this.closeSnackbar}
            severity={this.state.alertSeverity}
            msg={this.state.alertMsg}
          />
        </div>
      );
    }
  }
}

function Signup() {
  return (
    <>
      <Typography
        variant="subtitle1"
        style={{
          marginTop: "0px",
          textAlign: "center",
          color: "black",
        }}
      >
        OR{" "}
      </Typography>
      <Button
        type="submit"
        fullWidth
        variant="outlined"
        style={{
          color: "gray",
          top: "2%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "#757575",
          backgroundColor: "#ffffff",
          borderRadius: "0%",
          width: "90%",
          height: "50px",
          boxShadow: "3px 3px #F1F1F1",
          marginTop: "20px",
        }}
      >
        Continue With Google
      </Button>
      <Box className=" sign1" align="center">
        <a href="/signup">
          <span
            className="root body1 ColorPrimary align"
            style={{ paddingLeft: "2px" }}
          >
            Sign up for new account
          </span>
        </a>
      </Box>
    </>
  );
}
const theme = createTheme({
  palette: {
    gray: {
      main: "#bdbdbd",
      darker: "#616161",
    },
    black: {
      main: "#212121",
      contrastText: "#424242",
    },
  },
});

export default connect(
  (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    isLoading: state.login.isLoading,
    error: state.login.error,
    errmsg: state.login.errmsg,
  }),
  { loginUser, loginWithToken }
)(Login);
