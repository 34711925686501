import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty, startCase } from "lodash";
import { Autocomplete, Alert } from "@material-ui/lab";
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  TextField,
  Button,
  Divider,
  DialogContent,
  Dialog,
  Snackbar,
  DialogActions,
  CircularProgress,
  IconButton,
  Tooltip,
  Link as LinkButton,
  InputAdornment,
} from "@material-ui/core";
import {
  CheckCircleOutline,
  Clear,
  LockOpenOutlined,
  LockOutlined,
} from "@material-ui/icons";

import { APIURL as baseUrl } from "../../config/config";
import { editAssistants } from "../../store/assistantState";
import { getOptionLabel } from "./adsHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  contentContainer: {
    width: "100%",
    boxSizing: "border-box",
    padding: "40px",
    minHeight: 200,
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "25px 15px",
    },
  },
  projectdialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
      },
    },
  },
  message: {
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "19.8px",
    lineWidth: "0.1px",
    marginTop: "1.5rem",
  },
  autoComplete: {
    '& .MuiAutocomplete-option[aria-disabled="true"]': {
      pointerEvents: "inherit",
      background: "white",
    },
    '& .MuiAutocomplete-option[aria-disabled="true"]:active': {
      pointerEvents: "none",
      background: "white",
    },
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",

    background: "#f9f9f9",
    border: "1px solid grey",
    "& label": {
      background: "#f9f9f9",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  truncate: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textField: {
    width: "100%",
    height: 42,
    marginBottom: "0.25em",
  },
  Actions: {
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center",
    padding: "0 16px 16px",
  },
  Dialog: {
    flex: "1 1 auto",
    padding: "10px 16px",
    overflowY: "auto",
  },
  dialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 4px 0 16px",
  },
  arrow: {
    color: "white",
  },
  snackbar: {
    zIndex: 3000,
    top: 70,
  },
  selectContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
  },
  otpTextField: {
    width: "100%",
    padding: "8px 16px 7px 20px",
    background: "#f9f9f9",
    border: "1px solid grey",
  },
  otpTextFieldDisabled: {
    "& div": {
      color: "black !important",
    },
  },
  lineItemGrid: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "6em",
    },
  },
}));

const SetupMetaAds = (props) => {
  const { updateAssistantStore, partner, assistantDetails } = props;
  const { _id: partnerId } = partner || {};
  const { metaAdsDetails } = assistantDetails || {};
  const { minDailyBudgetLimit, totalAssignedBudget } = metaAdsDetails || {};

  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [snack, setSnack] = useState({});
  const [fbloading, setFbloading] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [submitOtpLoader, setSubmitOtpLoader] = useState(false);
  const [fbaccount, setFbaccount] = useState([]);
  const [fbpages, setFbpages] = useState([]);
  const [accountValue, setAccountValue] = useState({});
  const [pageValue, setPageValue] = useState({});
  const [minDailyLimit, setMinDailyLimit] = useState(minDailyBudgetLimit);
  const [totalBudget, setTotalBudget] = useState(totalAssignedBudget);
  const [assignPageId, setAssignPageId] = useState("");
  const [assignAccountId, setAssignAccountId] = useState("");

  const classes = useStyles();
  const { projectId: assistantId } = useParams();

  const {
    fbUserId,
    isWhatsappVerified,
    whatsappNumber,
    activePlan,
    fbConnected,
    fbConnectedBy,
    wabaProgressStep,
    providerType,
    freezedMetaPageId,
    freezedMetaAdAccountId,
    selectedMetaAdAccountId,
    selectedMetaPageId,
    clientId,
  } = assistantDetails || {};

  const { whatsappNumber: pageWhatsappNumber } = pageValue || {};
  const numberLinked = whatsappNumber === pageWhatsappNumber;
  const fbOwner = !fbConnectedBy || fbConnectedBy === partnerId;
  const hasPlan = activePlan && activePlan !== "NONE";

  useEffect(() => {
    if (fbConnected) {
      getFbAcc(assistantId);
      getFbPages(assistantId);
    }
  }, []);

  const setupconnect = () => {
    if (isWhatsappVerified) {
      setFbloading(true);
      window.FB.login(
        (response) => {
          console.log(response);
          setupClientAccount(response);
        },
        {
          scope:
            "public_profile,email,ads_read,ads_management,pages_show_list,business_management,pages_manage_metadata,pages_read_engagement",
          auth_type: "rerequest",
          return_scopes: true,
        }
      );
    } else {
      setSnack({
        open: true,
        message: "WhatsApp API is not live",
        type: "error",
      });
    }
  };

  const setupClientAccount = (response) => {
    const { authResponse, status } = response;
    const { grantedScopes } = authResponse || {};

    if (status === "connected" && assistantId && grantedScopes) {
      if (!grantedScopes.includes("business_management")) {
        setFbloading(false);
        setSnack({
          open: true,
          message: "Provide Business Management permission!",
          type: "error",
        });
      } else if (!grantedScopes.includes("ads_management")) {
        setFbloading(false);
        setSnack({
          open: true,
          message: "Provide Ads Management permission!",
          type: "error",
        });
      } else if (!grantedScopes.includes("ads_read")) {
        setFbloading(false);
        setSnack({
          open: true,
          message: "Provide Ads Read permission!",
          type: "error",
        });
      } else if (!grantedScopes.includes("pages_show_list")) {
        setFbloading(false);
        setSnack({
          open: true,
          message: "Provide Page Read permission!",
          type: "error",
        });
      } else if (!grantedScopes.includes("pages_manage_metadata")) {
        setFbloading(false);
        setSnack({
          open: true,
          message: "Provide Page Management permission!",
          type: "error",
        });
      } else {
        axios
          .post(`${baseUrl}/meta-ads/fb-connect`, {
            assistantId,
            authResponse,
            status,
          })
          .then((res) => {
            const { assistantData } = res?.data;
            const { fbConnected, fbUserId, fbGrantedScopes, fbConnectedBy } =
              assistantData;

            updateAssistantStore({
              fbConnected,
              fbUserId,
              fbGrantedScopes,
              fbConnectedBy,
            });

            getFbAcc(assistantId);
            getFbPages(assistantId);
            setFbloading(false);
            setSnack({
              open: true,
              message: "Facebook connected!",
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error);
            setFbloading(false);
            setSnack({
              open: true,
              message: error?.response?.data?.message,
              type: "error",
            });
          });
      }
    } else {
      setFbloading(false);
      setSnack({
        open: true,
        message: "Connection Failure!",
        type: "error",
      });
    }
  };

  const disconnectClientAccount = () => {
    if (assistantId && fbUserId) {
      setFbloading(true);
      axios
        .post(`${baseUrl}/meta-ads/fb-disconnect`, { assistantId, fbUserId })
        .then((res) => {
          const { assistantData } = res?.data;
          const {
            fbConnected,
            selectedMetaPageId,
            selectedMetaAdAccountId,
            freezedMetaAdAccountId,
            freezedMetaPageId,
          } = assistantData;

          updateAssistantStore({
            fbConnected,
            selectedMetaPageId,
            selectedMetaAdAccountId,
            freezedMetaAdAccountId,
            freezedMetaPageId,
          });
          setFbloading(false);
          setSnack({
            open: true,
            message: "Facebook disconnected!",
            type: "success",
          });
          setAccountValue({});
          setPageValue({});
          setFbaccount([]);
          setFbpages([]);
        })
        .catch((error) => {
          console.log(error);
          setFbloading(false);
          setSnack({
            open: true,
            message: error?.response?.data?.message,
            type: "error",
          });
        });
    }
  };

  const getFbPages = (assistantId) => {
    if (assistantId) {
      axios
        .post(`${baseUrl}/meta-ads/fetch-pages`, {
          assistantId: assistantId,
        })
        .then((res) => {
          const filteredPages = modifyList(res.data);
          setFbpages(filteredPages);

          if (
            fbConnectedBy &&
            fbConnectedBy !== partnerId &&
            freezedMetaAdAccountId
          ) {
            const selectedPageValue = freezedMetaPageId
              ? res.data?.find(({ pageId }) => pageId === freezedMetaPageId)
              : {};
            setPageValue(selectedPageValue);
          } else if (selectedMetaPageId) {
            const selectedPageValue = res.data?.find(
              ({ pageId }) => pageId === selectedMetaPageId
            );

            setPageValue(selectedPageValue);
          }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  };

  const getFbAcc = (assistantId) => {
    if (assistantId) {
      axios
        .post(`${baseUrl}/meta-ads/fetch-ad-accounts`, { assistantId })
        .then((res) => {
          const filteredAccounts = modifyList(res.data);
          setFbaccount(filteredAccounts);

          if (
            fbConnectedBy &&
            fbConnectedBy !== partnerId &&
            freezedMetaAdAccountId
          ) {
            const selectedAccountValue = res.data?.find(
              ({ accountId }) => accountId === freezedMetaAdAccountId
            );
            setAccountValue(selectedAccountValue);
          } else if (selectedMetaAdAccountId) {
            const selectedAccountValue = res.data?.find(
              ({ accountId }) => accountId === selectedMetaAdAccountId
            );
            setAccountValue(selectedAccountValue);
          }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  };

  const updateWhatsappNumber = (number) => {
    const pages = fbpages.map((p) => {
      if (p.pageId === pageValue?.pageId) {
        p["whatsappNumber"] = number;
      }
      return p;
    });

    setPageValue({ ...pageValue, whatsappNumber: number });
    setFbpages(pages);
  };

  const saveAdProfile = (accountValue, pageValue) => {
    if (!isEmpty(accountValue) && !isEmpty(pageValue)) {
      axios
        .post(`${baseUrl}/meta-ads/select-ad-profile`, {
          assistantId,
          selectedMetaAdAccountId: accountValue.accountId,
          selectedMetaPageId: pageValue.pageId,
        })
        .then((res) => {
          const { assistantData } = res?.data;
          console.log(assistantData);
        })
        .catch((error) => console.log("error:", error));
    }
  };

  const selectAdAccount = (value) => {
    setPageValue({});
    setAccountValue(value);
    updateAssistantStore({
      selectedMetaAdAccountId: value.accountId,
      selectedMetaPageId: "",
    });
  };

  const selectPage = (value) => {
    if (fbOwner || !freezedMetaAdAccountId) {
      setPageValue(value);
      updateAssistantStore({ selectedMetaPageId: value.pageId });
      saveAdProfile(accountValue, value);
    }
  };

  const modifyList = (data, id) => {
    const result = isEmpty(data)
      ? []
      : data?.map((obj) => {
          const { metaBusinessId, isAssigned, pageId, accountId } = obj || {};

          let freezed = false;
          if (pageId && id !== "") {
            id = id || freezedMetaPageId;
            freezed = pageId === id ? true : false;
          } else if (accountId && id !== "") {
            id = id || freezedMetaAdAccountId;
            freezed = accountId === id ? true : false;
          }

          let tag = "Limited";
          if (isAssigned) tag = "Assigned";
          else if (!metaBusinessId) tag = "Personal";
          return { ...obj, tag, freezed };
        });

    return result;
  };

  const freezeAssets = (asset) => {
    const data = {};
    if (asset === "page" && pageValue?.pageId) {
      data.pageId = pageValue?.pageId;
    } else if (asset === "adaccount" && accountValue?.accountId) {
      data.adAccountId = accountValue?.accountId;
    }

    if (!isEmpty(data)) {
      data.assistantId = assistantId;
      axios
        .post(`${baseUrl}/meta-ads/freeze-asset`, data)
        .then((res) => {
          const { freezedMetaAdAccountId, freezedMetaPageId } = res?.data;

          let updatedAccountList = fbaccount;
          let updatedPageList = fbpages;
          if (
            freezedMetaAdAccountId !== assistantDetails?.freezedMetaAdAccountId
          ) {
            updatedAccountList = modifyList(fbaccount, freezedMetaAdAccountId);
          }
          if (freezedMetaPageId !== assistantDetails?.freezedMetaPageId) {
            updatedPageList = modifyList(fbpages, freezedMetaPageId);
          }

          setSnack({
            open: true,
            message: `Successfully freezed ${startCase(asset)} !!`,
            type: "success",
          });
          setFbaccount(updatedAccountList);
          setFbpages(updatedPageList);
          updateAssistantStore({
            freezedMetaPageId,
            freezedMetaAdAccountId,
          });
        })
        .catch((error) => {
          console.log("error:", error);
          setSnack({
            open: true,
            message: `Something went wrong !!`,
            type: "error",
          });
        });
    }
  };

  const unFreezeAssets = (asset) => {
    if (!isEmpty(assistantId)) {
      const data = { assistantId, assets: asset };
      axios
        .post(`${baseUrl}/meta-ads/unfreeze-assets`, data)
        .then((res) => {
          const { freezedMetaAdAccountId, freezedMetaPageId } = res?.data;

          let updatedAccountList = fbaccount;
          let updatedPageList = fbpages;
          if (
            freezedMetaAdAccountId !== assistantDetails?.freezedMetaAdAccountId
          ) {
            updatedAccountList = modifyList(fbaccount, freezedMetaAdAccountId);
          }
          if (freezedMetaPageId !== assistantDetails?.freezedMetaPageId) {
            updatedPageList = modifyList(fbpages, freezedMetaPageId);
          }

          setFbaccount(updatedAccountList);
          setFbpages(updatedPageList);
          updateAssistantStore({
            freezedMetaPageId,
            freezedMetaAdAccountId,
          });
          setSnack({
            open: true,
            message: `Successfully un-freezed ${startCase(asset)} !!`,
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error:", error);
          setSnack({
            open: true,
            message: `Something went wrong !!`,
            type: "error",
          });
        });
    }
  };

  const linkWhatsappNumber = (verify) => {
    if (!pageValue?.pageId) {
      setSnack({
        open: true,
        message: "Select facebook page !!",
        type: "error",
      });
      return;
    }

    // if (!pageValue?.tasks?.includes("MANAGE")) {
    //   setSnack({
    //     open: true,
    //     message: "User don't have MANAGE access on this page !!",
    //     type: "error",
    //   });
    //   return;
    // }

    if (verify && !otp) {
      setSnack({
        open: true,
        message: "Enter OTP !!",
        type: "error",
      });
      return;
    }

    if (verify) setSubmitOtpLoader(true);
    else setOtpLoader(true);

    const verificationCode = verify ? otp : "";
    axios
      .post(`${baseUrl}/meta-ads/update-whatsapp-number`, {
        assistantId,
        verificationCode,
        pageId: pageValue.pageId,
        number: whatsappNumber,
      })
      .then((res) => {
        const { otpState, message } = res?.data;
        console.log(message);
        if (otpState === "send") {
          setOpen(true);
          setOtpLoader(false);
        } else if (otpState === "verified") {
          updateWhatsappNumber(whatsappNumber);
          setOpen(false);
          setOtpLoader(false);
          setSubmitOtpLoader(false);
          setSnack({ open: true, message, type: "success" });
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setOtpLoader(false);
        setSubmitOtpLoader(false);
        setSnack({
          open: true,
          message: error?.response?.data?.message || "Something went wrong",
          type: "error",
        });

        const { action, assistant } = error?.response?.data || {};
        if (action === "reconnect" && assistant) {
          setAccountValue({});
          setFbaccount([]);
          setFbpages([]);
          setPageValue({});

          const {
            fbConnected,
            fbUserId,
            fbGrantedScopes,
            selectedMetaPageId,
            selectedMetaAdAccountId,
            freezedMetaAdAccountId,
            freezedMetaPageId,
          } = assistant || {};
          updateAssistantStore({
            fbConnected,
            fbUserId,
            fbGrantedScopes,
            selectedMetaPageId,
            selectedMetaAdAccountId,
            freezedMetaAdAccountId,
            freezedMetaPageId,
          });
        }
      });
  };

  const updateMetaAdDetails = (field, value) => {
    console.log({ field, value });
    if (!isEmpty(assistantId) && field && value) {
      const data = { assistantId, [field]: value };
      axios
        .post(`${baseUrl}/meta-ads/updateMetaAdDetails`, data)
        .then((res) => {
          const { metaAdsDetails } = res?.data;

          updateAssistantStore({ metaAdsDetails });
          setSnack({
            open: true,
            message: `Successfully updated values !!`,
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error:", error);
          const { message } = error?.response?.data || {};
          setSnack({
            open: true,
            message: message || `Something went wrong !!`,
            type: "error",
          });
        });
    }
  };

  const assignNewAsset = async (field, value) => {
    const urlMap = {
      assignPageId: "assign-meta-page",
      assignAccountId: "assign-ad-account",
    };
    try {
      const url = `${baseUrl}/meta-ads/${urlMap[field]}`;
      const payload = { assistantId, id: value, clientId };
      const { data: res } = await axios.post(url, payload);
      if (res.success) {
        if (field === "assignPageId") {
          setFbpages([...fbpages, res.data]);
        } else {
          setFbaccount([...fbaccount, res.data]);
        }
        setSnack({
          open: true,
          message: `Successfully assigned !!`,
          type: "success",
        });
      } else {
        setSnack({
          open: true,
          message: `Something went wrong !!`,
          type: "error",
        });
      }
    } catch (error) {
      console.log("error:", error);
      const { message } = error?.response?.data || {};
      setSnack({
        open: true,
        message: message || `Something went wrong !!`,
        type: "error",
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item style={{ width: "100%" }}>
          <div className={classes.contentContainer} style={{ opacity: 1 }}>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom>
                  Connect your Facebook account
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <>
                  {fbConnected ? (
                    <Tooltip
                      title={
                        !fbOwner
                          ? "User is not allowed to disconnect Facebook Account"
                          : "This will disconnect your Facebook Account"
                      }
                    >
                      <div>
                        <Button
                          variant="outlined"
                          onClick={disconnectClientAccount}
                          disabled={!fbOwner || fbloading}
                          style={{
                            color: !fbOwner || fbloading ? "grey" : "#fa5c5c",
                            borderColor:
                              !fbOwner || fbloading ? "grey" : "#fa5c5c",
                            padding: "5px 18px",
                          }}
                          size="large"
                          endIcon={
                            fbloading ? (
                              <CircularProgress
                                size={18}
                                style={{ color: "grey" }}
                              />
                            ) : (
                              ""
                            )
                          }
                        >
                          Disconnect
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      interactive
                      title={
                        !isWhatsappVerified ? (
                          <LinkButton
                            href={`/projects/${assistantId}/dashboard`}
                            color="primary"
                            size="small"
                          >
                            <Typography variant="body2">
                              Get WhatsApp Business API approved first →
                            </Typography>
                          </LinkButton>
                        ) : providerType !== "v3" ? (
                          <LinkButton
                            href={`https://wa.aisensy.com/Qg81Gt`}
                            target="_blank"
                            color="primary"
                            size="small"
                          >
                            <Typography variant="body2">
                              Upgrade to new APIs for free to enable this
                              feature →
                            </Typography>
                          </LinkButton>
                        ) : wabaProgressStep !== 9 ? (
                          <LinkButton
                            href={`/projects/${assistantId}/dashboard`}
                            color="primary"
                            size="small"
                          >
                            <Typography variant="body2">
                              WhatsApp Business API Pending, Sync Status →
                            </Typography>
                          </LinkButton>
                        ) : !hasPlan ? (
                          "Purchase a plan to continue"
                        ) : (
                          ""
                        )
                      }
                    >
                      <Box>
                        <Button
                          disabled={
                            fbloading ||
                            !isWhatsappVerified ||
                            providerType !== "v3" ||
                            wabaProgressStep !== 9 ||
                            !hasPlan
                          }
                          style={{
                            backgroundColor:
                              fbloading ||
                              !isWhatsappVerified ||
                              providerType !== "v3" ||
                              wabaProgressStep !== 9 ||
                              !hasPlan
                                ? "lightgrey"
                                : "#1877f2",
                            color: "#fff",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: "bold",
                            padding: "5px 18px",
                          }}
                          size="large"
                          onClick={setupconnect}
                          endIcon={
                            fbloading ? (
                              <CircularProgress
                                size={18}
                                style={{ color: "white" }}
                              />
                            ) : (
                              ""
                            )
                          }
                        >
                          Continue With Facebook
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                </>
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom>
                  Choose your Ad account
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Tooltip
                  title={
                    fbConnectedBy !== partnerId && freezedMetaAdAccountId
                      ? "User is not allowed to change Ad Account"
                      : ""
                  }
                >
                  <Box className={classes.selectContainer}>
                    <Autocomplete
                      disableClearable
                      disabled={
                        !fbConnected ||
                        (fbConnectedBy !== partnerId && freezedMetaAdAccountId)
                      }
                      classes={{ groupUl: classes.autoComplete }}
                      getOptionDisabled={(option) => !option.isAssigned}
                      options={fbaccount}
                      onChange={(e, value) => selectAdAccount(value)}
                      value={accountValue}
                      groupBy={(option) =>
                        option.metaBusinessName || "Other Ad Accounts"
                      }
                      renderOption={(a, b) => getOptionLabel(a, b, classes)}
                      getOptionLabel={(option) => {
                        const { accountName, accountId } = option;
                        if (accountName && accountId)
                          return `${accountName} - ${accountId.split("_")[1]}`;
                        return "";
                      }}
                      name="fbaccount"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Choose your account"
                            classes={{
                              root: classes.textFieldRootAutocomplete,
                            }}
                            InputProps={{ ...params.InputProps }}
                          />
                        );
                      }}
                    />
                  </Box>
                </Tooltip>

                {fbConnectedBy === partnerId && accountValue?.accountId && (
                  <>
                    {freezedMetaAdAccountId === accountValue?.accountId ? (
                      <Tooltip title="Un-Freeze selected Ad Account">
                        <Button
                          color="primary"
                          style={{ marginLeft: "0.3em" }}
                          onClick={() => unFreezeAssets("adaccount")}
                        >
                          <LockOpenOutlined fontSize="small" />
                          <Typography>Un-Freeze</Typography>
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          <div>
                            <span>Freeze selected Ad Account</span>
                            <div>
                              <span>
                                Other agents will be able to access this Ad
                                Account only
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <Button
                          color="primary"
                          style={{ marginLeft: "0.3em" }}
                          onClick={() => freezeAssets("adaccount")}
                        >
                          <LockOutlined fontSize="small" />
                          <Typography>Freeze</Typography>
                        </Button>
                      </Tooltip>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom>
                  Choose your Facebook page
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Tooltip
                  title={
                    fbConnectedBy !== partnerId && freezedMetaPageId
                      ? "User is not allowed to change Facebook Page"
                      : ""
                  }
                >
                  <Box className={classes.selectContainer}>
                    <Autocomplete
                      classes={{ groupUl: classes.autoComplete }}
                      disableClearable
                      getOptionDisabled={(option) => !option.isAssigned}
                      id="grouped-demo"
                      disabled={
                        !fbConnected ||
                        !accountValue?.systemUserId ||
                        (fbConnectedBy !== partnerId && freezedMetaPageId)
                      }
                      renderOption={(a, b) => getOptionLabel(a, b, classes)}
                      options={fbpages.filter(
                        (value) =>
                          value?.metaBusinessId === accountValue?.metaBusinessId
                      )}
                      getOptionLabel={(option) => {
                        const { pageName, pageId } = option;
                        if (pageName && pageId)
                          return `${pageName} - ${pageId}`;
                        return "";
                      }}
                      onChange={(e, value) => selectPage(value)}
                      groupBy={(option) =>
                        option.metaBusinessName || "Other Pages"
                      }
                      value={pageValue}
                      name="fbpage"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Choose your page"
                          classes={{
                            root: classes.textFieldRootAutocomplete,
                          }}
                          InputProps={{ ...params.InputProps }}
                        />
                      )}
                    />
                  </Box>
                </Tooltip>
                {fbConnectedBy === partnerId && pageValue?.pageId && (
                  <>
                    {freezedMetaPageId === pageValue?.pageId ? (
                      <Tooltip title="Un-Freeze selected Facebook Page">
                        <Button
                          color="primary"
                          style={{ marginLeft: "0.3em" }}
                          onClick={() => unFreezeAssets("page")}
                        >
                          <LockOpenOutlined fontSize="small" />
                          <Typography>Un-Freeze</Typography>
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          freezedMetaAdAccountId === accountValue?.accountId ? (
                            <div>
                              <span>Freeze selected Facebook Page</span>
                              <div>
                                <span>
                                  Other agents will be able to access this Page
                                  only
                                </span>
                              </div>
                            </div>
                          ) : (
                            "First freeze selected Ad Account to freeze Page"
                          )
                        }
                      >
                        <div>
                          <Button
                            color="primary"
                            style={{ marginLeft: "0.3em" }}
                            onClick={() => freezeAssets("page")}
                            disabled={
                              freezedMetaAdAccountId !== accountValue?.accountId
                            }
                          >
                            <LockOutlined fontSize="small" />
                            <Typography>Freeze</Typography>
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Link Whatsapp Number</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Tooltip
                  title={
                    !numberLinked
                      ? "WhatsApp API Number"
                      : "WhatsApp API Number is linked with selected Facebook Page"
                  }
                >
                  <Box className={classes.selectContainer}>
                    <TextField
                      disabled
                      className={`${classes.otpTextField} ${
                        fbConnected ? classes.otpTextFieldDisabled : ""
                      }`}
                      name="whatsappNumber"
                      value={whatsappNumber}
                      InputProps={{
                        endAdornment: numberLinked && (
                          <InputAdornment>
                            <CheckCircleOutline style={{ color: "#20A60E" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Tooltip>
                <>
                  {numberLinked ? (
                    <></>
                  ) : (
                    <Tooltip
                      title={
                        !fbConnected
                          ? "Connect your Facebook Account"
                          : !pageValue?.pageId
                          ? "Select a Meta Page to send OTP"
                          : "Click to link your WhatsApp Number"
                      }
                    >
                      <div>
                        <Button
                          color="primary"
                          style={{ marginLeft: "0.3em" }}
                          onClick={() => linkWhatsappNumber()}
                          endIcon={
                            otpLoader ? (
                              <CircularProgress
                                size={18}
                                color="rgba(0, 0, 0, 0.26)"
                              />
                            ) : (
                              ""
                            )
                          }
                          disabled={
                            otpLoader || !fbConnected || !pageValue?.pageId
                          }
                        >
                          <Typography>Send OTP</Typography>
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </>
              </Grid>

              <Box my={4} />
            </Grid>{" "}
            <Box my={2}>
              <Divider />
            </Box>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4">
                  Set Minimum Daily Ad Budget
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Box className={classes.selectContainer}>
                  <TextField
                    className={classes.otpTextField}
                    onChange={(e) => setMinDailyLimit(e.target.value)}
                    name="minDailyLimit"
                    value={minDailyLimit}
                    placeholder="Enter Daily Min Ad Limit"
                  />
                </Box>
                <>
                  <Tooltip
                    title={
                      !fbConnected
                        ? "Connect your Facebook Account"
                        : "Click to set this as minimum daily budget limit"
                    }
                  >
                    <div>
                      <Button
                        color="primary"
                        style={{ marginLeft: "0.3em" }}
                        onClick={() =>
                          updateMetaAdDetails(
                            "minDailyBudgetLimit",
                            minDailyLimit
                          )
                        }
                        disabled={!fbConnected}
                      >
                        <Typography>Confirm</Typography>
                      </Button>
                    </div>
                  </Tooltip>
                </>
              </Grid>

              <Box my={4} />
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Assign Total Budget</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Box className={classes.selectContainer}>
                  <TextField
                    className={classes.otpTextField}
                    onChange={(e) => setTotalBudget(e.target.value)}
                    name="totalBudget"
                    value={totalBudget}
                    placeholder="Enter Total Budget Cap"
                  />
                </Box>
                <>
                  <Tooltip
                    title={
                      !fbConnected
                        ? "Connect your Facebook Account"
                        : "Click to set this as total budget for this account"
                    }
                  >
                    <div>
                      <Button
                        color="primary"
                        style={{ marginLeft: "0.3em" }}
                        onClick={() =>
                          updateMetaAdDetails(
                            "totalAssignedBudget",
                            totalBudget
                          )
                        }
                        disabled={!fbConnected}
                      >
                        <Typography>Confirm</Typography>
                      </Button>
                    </div>
                  </Tooltip>
                </>
              </Grid>
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Assign New Page</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Box className={classes.selectContainer}>
                  <TextField
                    className={classes.otpTextField}
                    onChange={(e) => setAssignPageId(e.target.value)}
                    name="assignPageId"
                    value={assignPageId}
                    placeholder="Enter Page Id"
                  />
                </Box>
                <>
                  <Tooltip
                    title={
                      !fbConnected
                        ? "Connect your Facebook Account"
                        : "Click to Assign new Page"
                    }
                  >
                    <div>
                      <Button
                        color="primary"
                        style={{ marginLeft: "0.3em" }}
                        onClick={() =>
                          assignNewAsset("assignPageId", assignPageId)
                        }
                        disabled={!fbConnected}
                      >
                        <Typography>Assign</Typography>
                      </Button>
                    </div>
                  </Tooltip>
                </>
              </Grid>

              <Box my={4} />
            </Grid>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Assign New Ad Account</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <Box className={classes.selectContainer}>
                  <TextField
                    placeholder="Enter Ad Accout Id - 'act_123456789000'"
                    className={classes.otpTextField}
                    onChange={(e) => setAssignAccountId(e.target.value)}
                    name="assignAccountId"
                    value={assignAccountId}
                  />
                </Box>
                <>
                  <Tooltip
                    title={
                      !fbConnected
                        ? "Connect your Facebook Account"
                        : "Click to Assign new Ad Account"
                    }
                  >
                    <div>
                      <Button
                        color="primary"
                        style={{ marginLeft: "0.3em" }}
                        onClick={() =>
                          assignNewAsset("assignAccountId", assignAccountId)
                        }
                        disabled={!fbConnected}
                      >
                        <Typography>Assign</Typography>
                      </Button>
                    </div>
                  </Tooltip>
                </>
              </Grid>

              <Box my={4} />
            </Grid>
            {/* <Box my={4}>
              <Divider />
            </Box>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
              className={classes.lineItemGrid}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Create Advertisement</Typography>
                <Typography variant="body2" color="textSecondary">
                  Click Create Ad to start receiving leads directly on your
                  WhatsApp.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: "flex" }}
                alignItems="center"
              >
                <CreateAdButton
                  accountValue={accountValue}
                  pageValue={pageValue}
                />
              </Grid>

              <Box my={4} />
            </Grid> */}
          </div>
          <Box my={8} />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        maxWidth="xs"
        aria-labelledby="form-dialog-title"
        className={classes.projectdialogContainer}
        disableBackdropClick
      >
        <Grid className={classes.dialogHeader}>
          <Typography variant="h3">One Time Password</Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
              setOtpLoader(false);
            }}
          >
            <Clear />
          </IconButton>
        </Grid>
        <DialogContent className={classes.Dialog}>
          <TextField
            name="project"
            value={otp}
            className={classes.textField}
            onChange={(e, value) => {
              setOtp(e.target.value);
            }}
            placeholder="Waiting for OTP..."
            gutterBot
          />
          <Typography variant="body2" gutterBottom color="textSecondary">
            OTP recieved on LIVE chat of registered WhatsApp Number
          </Typography>
        </DialogContent>
        <DialogActions className={classes.Actions}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                linkWhatsappNumber(true);
              }}
              disabled={submitOtpLoader}
              endIcon={
                submitOtpLoader ? (
                  <CircularProgress size={18} color="rgba(0, 0, 0, 0.26)" />
                ) : (
                  ""
                )
              }
            >
              Submit
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Snackbar
        className={classes.snackbar}
        open={snack.open}
        onClose={() => {
          setSnack({ open: false });
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={snack.type} onClose={() => setSnack({ open: false })}>
          {snack.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const ConnectedSetupMetaAds = connect(
  (state) => ({
    partner: state.partner.partner,
  }),
  { editAssistants }
)(SetupMetaAds);

export default ConnectedSetupMetaAds;
