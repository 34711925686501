import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  withStyles,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Tabs,
  Tab,
  DialogContent,
  Dialog,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Snackbar,
  CircularProgress,
  Popper,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import {
  NavigateNext,
  NavigateBefore,
  Clear,
  FilterList,
  Add,
  Visibility,
  VisibilityOff,
  InfoOutlined,
  CheckCircleOutlined,
  GetApp,
} from "@material-ui/icons";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react-dates-custom.css";

import AssistantsTable, { fields, getFormattedDate } from "./AssistantsTable";
import { TablePagination } from "@material-ui/core";
// import { assistants } from "../../config/assistants";
import { URL, partnerURL } from "../../config/config";
import axios from "axios";
import {
  fetchAssistants,
  loadNextAssistants,
  loadPrevAssistants,
  changeRowsPerPage,
  fetchAssistantsCounts,
} from "../../store/assistantState";
import { fetchBusiness } from "../../store/businessState";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import { timeZones } from "../../config/timezone";
import dialCodes, {
  countryMap,
  isoMap,
  isoToDialCode,
} from "../../config/dialCodes";
import Header from "../../commons/Header/Header";
import CreateProjectDialog from "../../commons/Dialog/CreateProjectDialog";
import plansHelper from "../../helpers/plansHelper";
import withMediaQuery from "../../helpers/mediaQueryHelper";
import { minBusinessFieldsPartners } from "../Clients/BusinessDetailsForm";
import { fetchPartnerDetails } from "../../store/partnerState";
import { checkAccess } from "../../helpers/RBAC";
import LockIcon from "@material-ui/icons/Lock";
// import { loadNext, loadClient, unfetchclients, SortClient, checkDateInput, setFocus, setDate, clearDate, loadSorts, createnewClient, loadSearch } from "../clients/Clientstore";

class AssistantsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      alertMsg: "",
      rowsPerPage: 15,
      totalCount: null,
      skip: 0,
      timeStamp: new Date(),
      search: "",
      searchDone: false,
      templates: [],
      backupTemplates: [],
      backupSearchTemplates: [],
      err: null,
      isLoading: false,
      page: 0,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focus: null,
        },
        planActivatedOn: {
          startDate: null,
          endDate: null,
          focus: null,
        },
      },
      tab: "All",
      openDialog: false,
      assistantFilter: {
        filter: "All",
        sort: "dec",
      },
      kycFilter: {},
      allCount: 0,
      countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
      liveCount: 0,
      draftCount: 0,
      trialPlanCount: 0,
      monthlyPlanCount: 0,
      yearlyPlanCount: 0,
      deletedCount: 0,
      stoppedCount: 0,
      openProjectDialog: false,
      selectBusiness: "",
      newProjectName: "",
      monthlyPlans: ["ALL", "BASIC_MONTHLY", "PRO_MONTHLY"],
      yearlyPlans: ["ALL", "BASIC_YEARLY", "PRO_YEARLY"],
      selectedMonthlyPlan: "ALL",
      selectedYearlyPlan: "ALL",
      projectSubmitLoader: false,
      clientDialog: false,
      company: "",
      companySize: "",
      industry: "",
      display_name: "",
      contact: "",
      buttonDisable: false,
      timezone: "Asia/Calcutta GMT+05:30",
      currency: this.props.partner?.currency || "INR",
    };
  }
  componentDidMount() {
    this.props.fetchPartnerDetails();
    plansHelper.setAllPlans();
    if (this.props?.location?.state) {
      const { data } = this.props?.location?.state;
      this.props.fetchAssistantsCounts();
      this.props.fetchBusiness();
      this.props.fetchAssistants(this.state.assistantFilter);
    }
    if (this.props.totalAssistants === 0) {
      this.props.fetchAssistantsCounts();
      this.props.fetchBusiness();
      this.props.fetchAssistants(this.state.assistantFilter);
    }
  }
  toggleDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openDialog: !this.state.openDialog });
  };

  handleChangePage = (event, newPage) => {
    const { page } = this.props;
    const step = newPage - page;
    if (step === -1) {
      this.props.loadPrevAssistants();
    } else if (step === 1) {
      this.props.loadNextAssistants(this.state.assistantFilter);
    }
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    this.props.changeRowsPerPage(rowsPerPage);
    this.onTabChange();
  };

  setTemplate = (template) => {
    const templates = [...this.state.templates];
    const foundIndex = templates.findIndex((x) => x._id == template._id);
    // console.log({foundIndex})
    // if(foundIndex > -1) {
    templates[foundIndex] = template;
    this.setState({ templates });
    // } else {
    // }
    // templates.forEach
  };

  handleBusinessInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleEnter = (e) => {
    // const { backupSearchTemplates, search } = this.state;
    if (e.key === "Enter") {
      const { assistantFilter } = this.state;
      const newObj = { ...assistantFilter };
      if (e.target.value) {
        newObj.assistantName = e.target.value;
      } else {
        delete newObj.assistantName;
      }
      this.setState(
        { assistantFilter: newObj, searchDone: true },
        this.onTabChange
      );
    }
  };
  handleSearch = (e) => {
    const { assistantFilter } = this.state;
    const newObj = { ...assistantFilter, assistantName: e.target.value };
    this.setState({
      assistantFilter: newObj,
      search: e.target.value,
    });
  };
  handleClear = () => {
    const { assistantFilter } = this.state;
    delete assistantFilter.assistantName;
    this.setState(
      {
        search: "",
        searchDone: false,
        assistantFilter,
      },
      this.onTabChange
    );
  };

  // methods for date filter
  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };
  applyFilter = () => {
    const { applied, assistantFilter } = this.state;
    const filterObj = { ...assistantFilter };
    if (applied.createdAt.startDate && applied.createdAt.endDate) {
      filterObj.fromDate = applied.createdAt.startDate._d;
      filterObj.toDate = applied.createdAt.endDate._d;
    } else {
      delete filterObj.fromDate;
      delete filterObj.toDate;
    }

    if (applied.planActivatedOn.startDate && applied.planActivatedOn.endDate) {
      filterObj.fromPlanActivatedDate = applied.planActivatedOn.startDate._d;
      filterObj.toPlanActivatedDate = applied.planActivatedOn.endDate._d;
    } else {
      delete filterObj.fromPlanActivatedDate;
      delete filterObj.toPlanActivatedDate;
    }
    this.setState(
      {
        assistantFilter: filterObj,
        openDialog: false,
      },
      () => {
        this.onTabChange();
      }
    );
  };

  downloadReport = async () => {
    try {
      const { applied, assistantFilter } = this.state;
      const filterObj = { ...assistantFilter };
      if (applied.createdAt.startDate && applied.createdAt.endDate) {
        filterObj.fromDate = applied.createdAt.startDate._d;
        filterObj.toDate = applied.createdAt.endDate._d;
      } else {
        delete filterObj.fromDate;
        delete filterObj.toDate;
      }

      if (
        applied.planActivatedOn.startDate &&
        applied.planActivatedOn.endDate
      ) {
        filterObj.fromPlanActivatedDate = applied.planActivatedOn.startDate._d;
        filterObj.toPlanActivatedDate = applied.planActivatedOn.endDate._d;
      } else {
        delete filterObj.fromPlanActivatedDate;
        delete filterObj.toPlanActivatedDate;
      }

      const { data } = await axios.post(
        partnerURL + `/partner/${this.props.partnerId}/projects-report`,
        filterObj
      );

      const report = data?.projects || [];
      const reportData = [];
      const { allBusiness } = this.props;

      const updatedFields = [...fields, { name: "Project ID", key: "_id" }];

      for (const row of report) {
        const obj = {};
        for (const val of updatedFields) {
          if (val.key === "clientId")
            obj[val.name] = allBusiness[row[val.key]]?.name || "";
          else if (val.key === "templateCredit")
            obj[val.name] = Math.round((row[val.key] || 0) / 100000);
          else if (val.key === "createdAt")
            obj[val.name] = getFormattedDate(row[val.key]).replace(",", " ");
          else obj[val.name] = row[val.key];
        }
        reportData.push(obj);
      }

      const headers = updatedFields.map((e) => e.name);
      const csvRows = [];
      csvRows.push(headers.join(","));

      for (const val of reportData) {
        const values = Object.values(val).join(",");
        csvRows.push(values);
      }
      const csv = csvRows.join("\n");
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const bloburl = window.URL.createObjectURL(blob);
      downloadLink.href = bloburl;
      downloadLink.download = "projects.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      this.setState({
        alertType: "success",
        alertMsg: "Successfully donwloaded",
        projectSubmitLoader: false,
        openProjectDialog: false,
      });
    } catch (error) {
      this.setState({
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "something went wrong, tryagain",
        projectSubmitLoader: false,
      });
    }
  };

  onTabChange = () => {
    this.props.fetchAssistants(this.state.assistantFilter);
  };

  projectDialog = (event) => {
    this.setState({ openProjectDialog: !this.state.openProjectDialog });
  };

  toggleclientDialog = () => {
    this.setState({ clientDialog: !this.state.clientDialog });
  };

  projectSubmit = (data) => {
    this.setState({ projectSubmitLoader: true });
    if (data?.checked && data?.checked === true) {
      data.shippingAddress = data.billingAddress;
    }
    axios
      .post(
        partnerURL +
          `/partner/${this.props.partnerId}/business/${this.state.selectBusiness}/project`,
        {
          ...data,
        }
      )
      .then((response) => {
        this.setState({
          alertType: "success",
          alertMsg: "Successfully created",
          projectSubmitLoader: false,
          openProjectDialog: false,
        });
        this.onTabChange();
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
          projectSubmitLoader: false,
        });
      });
  };

  buttondisbale = () => {
    this.setState({ buttonDisable: !this.state.buttonDisable });
  };

  createclient = async () => {
    try {
      const partnerId = this.props.user.id;
      const {
        email,
        display_name,
        company,
        contact,
        currency,
        timezone,
        password,
      } = this.state;
      const queryObj = {
        email,
        password,
        display_name,
        company,
        contact,
        timezone,
        currency,
      };

      let response = await axios.post(
        partnerURL + `/partner/${partnerId}/business/`,
        queryObj
      );
      // this.props.createnewClient(response.data)
      this.props.fetchBusiness();
      this.setState({
        clientDialog: !this.state.clientDialog,
        openProjectDialog: true,
      });
      this.setState({
        status: "success",
        statusMessage: "Business Created Succesfully",
      });
      this.setState({
        email: null,
        display_name: null,
        company: null,
        contact: null,
        currency: this.props.partner?.currency || "INR",
        timezone: "Asia/Calcutta GMT+05:30",
        countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
        industry: null,
        companySize: null,
        password: null,
      });
    } catch (error) {
      this.setState({
        buttonDisable: false,
      });
      const errorResponse = error.response;
      console.error(error);
      const errorMessage = errorResponse?.data?.message;
      this.setState({
        status: "error",
        statusMessage: errorMessage,
      });
    }
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  selectBusiness = (e, value, reason) => {
    if (reason === "select-option") {
      const assistantValue = Object.keys(this.props.allBusiness).find(
        (key) => this.props.allBusiness[key].name === value.split(" - ")[1]
      );
      this.setState({ selectBusiness: assistantValue });
    }
  };

  render() {
    const {
      classes,
      partner,
      partnerPlanFamily,
      partnerWccFamily,
      partnerWhatsappPaymentPlans,
      mediaQuery,
      partnerId,
      user,
      partnerAgents,
    } = this.props;
    const { backupTemplates, tab, assistantFilter, applied } = this.state;
    const { assistants, page, rowsPerPage, totalAssistants } = this.props;

    const PopperMy = function (props) {
      return (
        <Popper {...props} style={styles.popper} placement="bottom-start" />
      );
    };
    const whitelabelTabs = [
      {
        display: "All",
        state: "allCount",
        // icon: <AssessmentOutlined className={classes.tabIcon} />,
        theme: "#f4f4f4",
        list: backupTemplates,
      },
      {
        display: "Signup trial",
        state: "trialPlanCount",
        theme: "#ff000059",
        list: backupTemplates.filter((i) => i.activePlan.includes("TRIAL")),
      },
      {
        display: "API Trial",
        state: "apiTrialPlanCount",
        theme: "#ff000059",
        list: backupTemplates.filter((i) => i.activePlan.includes("TRIAL")),
      },
      {
        display: "Draft",
        state: "draftCount",
        // icon: <Done className={classes.tabIcon} />,
        theme: "#f2c14e3d",
        list: backupTemplates.filter((i) => !i.isWhatsappVerified),
      },
      {
        display: "Live",
        state: "liveCount",
        // icon: <DoneAll className={classes.tabIcon} />,
        theme: "#08cf6533",
        list: backupTemplates.filter((i) => !!i.isWhatsappVerified),
      },
      // {
      //   display: "Trial Plan",
      //   state: "trialPlanCount",
      //   // icon: <DoneAll className={classes.tabIcon} />,
      //   theme: "#bdbf0059",
      //   list: backupTemplates.filter((i) => i.activePlan === "BASIC_TRIAL"),
      // },
      {
        display: "Monthly Plan",
        state: "monthlyPlanCount",
        // icon: <DoneAll className={classes.tabIcon} />,
        theme: "#00b4bf59",
        list: backupTemplates.filter((i) => i.activePlan.includes("MONTHLY")),
      },
      {
        display: "Yearly Plan",
        state: "yearlyPlanCount",
        // icon: <DoneAll className={classes.tabIcon} />,
        theme: "#5000bf59",
        list: backupTemplates.filter((i) => i.activePlan.includes("YEARLY")),
      },
      // {
      //   display: "KYC",
      //   state: "KycNotDoneCount",
      //   // icon: <DoneAll className={classes.tabIcon} />,
      //   theme: "#08cf6533",
      //   list: backupTemplates.filter((i) => !!i.isWhatsappVerified),
      // },
      {
        display: "Stopped",
        state: "stoppedCount",
        theme: "#ff808059",
        list: backupTemplates.filter((i) => i.status === "stopped"),
      },
      {
        display: "Deleted",
        state: "deletedCount",
        theme: "#ff000059",
        list: backupTemplates.filter((i) => i.partnerArchived === true),
      },
    ];

    const directApiTabs = [
      {
        display: "All",
        state: "allCount",
        // icon: <AssessmentOutlined className={classes.tabIcon} />,
        theme: "#f4f4f4",
        list: backupTemplates,
      },
      {
        display: "Draft",
        state: "draftCount",
        // icon: <Done className={classes.tabIcon} />,
        theme: "#f2c14e3d",
        list: backupTemplates.filter((i) => !i.isWhatsappVerified),
      },
      {
        display: "Live",
        state: "liveCount",
        // icon: <DoneAll className={classes.tabIcon} />,
        theme: "#08cf6533",
        list: backupTemplates.filter((i) => !!i.isWhatsappVerified),
      },
      {
        display: "KYC",
        state: "KycNotDoneCount",
        // icon: <DoneAll className={classes.tabIcon} />,
        theme: "#08cf6533",
        list: backupTemplates.filter((i) => !!i.isWhatsappVerified),
      },
      {
        display: "Stopped",
        state: "stoppedCount",
        theme: "#ff808059",
        list: backupTemplates.filter((i) => i.status === "stopped"),
      },
      {
        display: "Deleted",
        state: "deletedCount",
        theme: "#ff000059",
        list: backupTemplates.filter((i) => i.partnerArchived === true),
      },
    ];

    const tabs = partner.type === "WHITELABEL" ? whitelabelTabs : directApiTabs;

    const SIZE = [
      "1 - 10 Employees",
      "10 - 20 Employees",
      "20 - 50 Employees",
      "50 - 200 Employees",
      "200 + Employees",
    ];

    const VERTICALS = [
      "Ecommerce",
      "Education",
      "Automotive",
      "IT Services",
      "Real Estate",
      "SAAS/Apps",
      "Gaming",
      "Entertainment",
      "Finance and Banking",
      "Medical and Health",
      "Hotel and Lodging",
      "Beauty, Spa and Salon",
      "Clothing and Apparel",
      "Event Planning and Service",
      "Food and Grocery",
      "Professional Services",
      "Public Service",
      "Non-profit",
      "Shopping and Retail",
      "Travel and Transportation",
      "Restaurant",
      "Other",
    ];

    const getflag = (langcode) => {
      var first = langcode.charCodeAt(0) + 127397;
      var second = langcode.charCodeAt(1) + 127397;
      var flag = `&#${first};&#${second};`;
      const x = document.createElement("p");
      x.innerHTML = flag;
      return x.innerText;
    };

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "business",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "business",
      "write"
    );

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const isPasswordValid = passwordRegex.test(this.state.password);

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        {hasReadAccess ? (
          <>
            <Grid
              container
              alignItems="center"
              className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
            >
              <Grid item xs={12}>
                <Box className={classes.tabContainer}>
                  <Header
                    hasReadAccess={hasReadAccess}
                    hasWriteAccess={hasWriteAccess}
                    businessTitle={"Projects"}
                    buttonPrefixIcon={<Add />}
                    buttonTitle={user.isReferral ? "" : "Create New Project"}
                    onButtonClick={this.projectDialog}
                    showSearch={true}
                    searchPlaceholder={"Search project name"}
                    handleSearch={this.handleSearch}
                    handleEnter={this.handleEnter}
                    searchDone={this.state.searchDone}
                    handleClear={this.handleClear}
                    filter={this.state.search}
                    toggleFilterDialog={this.toggleDialog}
                    filterColor={
                      (!!applied.createdAt.startDate &&
                        !!applied.createdAt.endDate) ||
                      (!!applied.planActivatedOn.endDate &&
                        !!applied.planActivatedOn.startDate)
                    }
                  />
                  <Box px={{ xs: 2, md: 3 }}>
                    <Tabs
                      value={tab}
                      onChange={(event, newValue) => {
                        if (this.state.tab === newValue) return;
                        const tab = tabs.find((i) => i.display === newValue);
                        if (
                          this.state.assistantFilter.yearlyPlan &&
                          newValue !== "Yearly Plan"
                        ) {
                          delete this.state.assistantFilter.yearlyPlan;
                          this.setState({
                            selectedYearlyPlan: "",
                            selectedMonthlyPlan: "",
                          });
                        }
                        if (
                          this.state.assistantFilter.monthlyPlan &&
                          newValue !== "Monthly Plan"
                        ) {
                          delete this.state.assistantFilter.monthlyPlan;
                          this.setState({
                            selectedMonthlyPlan: "",
                            selectedYearlyPlan: "",
                          });
                        }
                        this.setState(
                          {
                            tab:
                              newValue == "Signup trial"
                                ? "Signup trial"
                                : newValue,
                            templates: tab.list,
                            totalCount: tab.list.length,
                            assistantFilter: {
                              ...this.state.assistantFilter,
                              filter:
                                newValue == "Signup trial" ? "Trial" : newValue,
                            },
                          },
                          this.onTabChange
                        );
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      TabIndicatorProps={{
                        className: classes.tabIndicator,
                      }}
                    >
                      {tabs
                        .filter((item) => {
                          if (item.state == "trialPlanCount") {
                            // return false;
                            return this.props.partner.enableSignUp;
                          }
                          if (item.state == "apiTrialPlanCount") {
                            return this.props.partner.enableTrial;
                          }
                          if (item.state == "KycNotDoneCount") {
                            return partnerId == "646357594e6a060aa94cb99f";
                          }
                          return true;
                        })
                        .map((i) => (
                          <Tab
                            label={
                              <>
                                <Grid container justifyContent="space-between">
                                  <Grid item xs={2}>
                                    <Box
                                      fontSize="16px"
                                      fontWeight="bold"
                                      textAlign="left"
                                      width="100%"
                                      style={{ marginTop: "5px" }}
                                    >
                                      {this.props[i.state]}
                                    </Box>
                                  </Grid>
                                  <Grid item xs={7}>
                                    {i.display === "Monthly Plan" ||
                                    i.display === "Yearly Plan" ? (
                                      <Box
                                        fontSize="16px"
                                        fontWeight="bold"
                                        textAlign="right"
                                        width="100%"
                                      ></Box>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                                <Box
                                  fontSize={"14px"}
                                  width="100%"
                                  textAlign="left"
                                >
                                  {`${i.display}`}
                                </Box>
                              </>
                            }
                            value={i.display}
                            style={{
                              textTransform: "none",
                              alignItems: "end",
                              minWidth: 190,
                              // marginLeft: 16,
                              paddingBottom: 8,
                              background: `linear-gradient(180deg, white, ${
                                this.state.tab === i.display ? i.theme : "white"
                              })`,
                            }}
                          />
                        ))}
                    </Tabs>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box mt={15} />
            {/* Layout */}

            <Grid container justify="center">
              <Grid item xs={12} md={11} lg={10}>
                {/* First section */}
                {/* Scrollable (x & y) table container */}
                <div className={classes.tableContainer}>
                  <AssistantsTable
                    templates={Object.values(assistants).slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )}
                    isLoading={this.state.isLoading}
                    setTemplate={this.setTemplate}
                  />
                </div>
              </Grid>
            </Grid>

            {/* Table footer fixed at bottom */}
            <div className={classes.fixedBottomContainer}>
              <TablePagination
                component="div"
                count={totalAssistants}
                page={this.props.page}
                onChangePage={this.handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              height: "80vh",
              alignItems: "center",
              // backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <LockIcon
              style={{
                fontSize: 90,
                color: "grey",
                marginBottom: "10px",
              }}
            />
            <Typography variant="h4" color="textSecondary">
              Access Denied
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Looks like you don't have the necessary permissions to view this
              page
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Please contact your administrator for more information.
            </Typography>
          </Box>
        )}

        {/* Filter dialog */}
        <Dialog
          open={this.state.openDialog}
          maxWidth={"xs"}
          onClose={this.toggleDialog}
          aria-labelledby="form-dialog-title"
          className={classes.dialogContainer}
          fullScreen={mediaQuery}
          back
        >
          <DialogContent style={{ height: 500 }}>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ paddingRight: 0, paddingTop: 0 }}
                xs={12}
              >
                <Typography variant="h3">Filter</Typography>
                <IconButton onClick={this.toggleDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <DateFilter
                  filterKey="planActivatedOn"
                  filterName="Plan Activated"
                  classes={classes}
                  applied={this.state.applied}
                  setDate={this.setDate}
                  setFocus={this.setFocus}
                  checkDateInput={this.checkDateInput}
                  clearDate={this.clearDate}
                />
              </Grid>
              <Grid item xs={12}>
                <DateFilter
                  filterKey="createdAt"
                  filterName="Created At"
                  classes={classes}
                  applied={this.state.applied}
                  setDate={this.setDate}
                  setFocus={this.setFocus}
                  checkDateInput={this.checkDateInput}
                  clearDate={this.clearDate}
                />
              </Grid>
              <Grid
                container
                style={{ alignItems: "center", paddingLeft: "10px" }}
              >
                {partnerId == "646357594e6a060aa94cb99f" && (
                  <>
                    <Grid item xs={12} sm={3} style={{ marginRight: "15px" }}>
                      <Typography>Kyc Process</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} style={{ marginBottom: "16px" }}>
                      <Autocomplete
                        disableClearable
                        autoHighlight
                        options={[
                          { value: "ALL", display: "ALL" },
                          { value: "Not Applied", display: "Yet to apply" },
                          { value: "KYC", display: "Applicants" },
                          { value: "Applied", display: "Under Review" },
                          {
                            value: "Limit Increased",
                            display: "Limit Increased",
                          },
                          {
                            value: "Rejected",
                            display: "Rejected",
                          },
                        ]}
                        getOptionLabel={(option) => option.display}
                        value={
                          Object.keys(this.state.kycFilter).length !== 0
                            ? this.state.kycFilter
                            : { value: "ALL", display: "ALL" }
                        }
                        onChange={(e, value, reason) => {
                          const updateObj = {
                            ...this.state.assistantFilter,
                          };
                          if (updateObj.filter) delete updateObj.filter;
                          updateObj.kycFilter = value?.value;
                          this.setState({
                            assistantFilter: updateObj,
                            kycFilter: value,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{ ...params.InputProps }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}

                {/*)} */}
                <Grid item xs={12} sm={3} style={{ marginRight: "15px" }}>
                  <Typography>Monthly Plan</Typography>
                </Grid>
                <Grid item xs={12} sm={8} style={{ marginBottom: "16px" }}>
                  <Autocomplete
                    // PopperComponent={PopperMy}
                    disableClearable
                    autoHighlight
                    // classes={{ popper: classes.popper }}
                    options={
                      this.state["monthlyPlans"]
                      // this.props.allBusiness && Object.values(this.props.allBusiness).map(item=>item.company+" - "+item.name)
                    }
                    value={this.state.selectedMonthlyPlan || "ALL"}
                    onChange={(e, value, reason) => {
                      const updateObj = {};
                      updateObj.assistantFilter = {
                        ...this.state.assistantFilter,
                      };
                      if (updateObj.assistantFilter.filter)
                        delete updateObj.assistantFilter.filter;
                      // delete updateObj.assistantFilter.yearlyPlan;
                      updateObj.selectedMonthlyPlan = value;
                      updateObj.assistantFilter.monthlyPlan = value;
                      // updateObj.selectedYearlyPlan = "ALL"

                      if (value === "ALL") {
                        delete updateObj.assistantFilter.monthlyPlan;
                        updateObj.assistantFilter.filter = this.state.tab;
                      }
                      // this.setState(updateObj,this.onTabChange);
                      this.setState(updateObj);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        name="campaignName"
                        style={
                          {
                            // height:"30px",
                            // marginLeft:"5px",
                            // paddingTop:"2px",
                            // width: "100px"
                          }
                        }
                      />
                    )}
                    // style={{ marginLeft:"20px", marginRight: "10px" }}
                  />
                </Grid>

                <Grid item xs={12} sm={3} style={{ marginRight: "15px" }}>
                  <Typography>Yearly Plan</Typography>
                </Grid>
                <Grid item xs={12} sm={8} style={{ marginBottom: "16px" }}>
                  <Autocomplete
                    // PopperComponent={PopperMy}
                    disableClearable
                    autoHighlight
                    // classes={{ popper: classes.popper }}
                    options={
                      this.state["yearlyPlans"]
                      // this.props.allBusiness && Object.values(this.props.allBusiness).map(item=>item.company+" - "+item.name)
                    }
                    value={this.state.selectedYearlyPlan || "ALL"}
                    onChange={(e, value, reason) => {
                      const updateObj = {};
                      updateObj.assistantFilter = {
                        ...this.state.assistantFilter,
                      };
                      if (updateObj.assistantFilter.filter)
                        delete updateObj.assistantFilter.filter;
                      // delete updateObj.assistantFilter.monthlyPlan;
                      updateObj.selectedYearlyPlan = value;
                      updateObj.assistantFilter.yearlyPlan = value;
                      // updateObj.selectedMonthlyPlan = "ALL"
                      if (value === "ALL") {
                        delete updateObj.assistantFilter.yearlyPlan;
                        updateObj.assistantFilter.filter = this.state.tab;
                      }
                      this.setState(updateObj);
                      // this.setState(updateObj,this.onTabChange);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        name="campaignName"
                        style={
                          {
                            // height:"30px",
                            // marginLeft:"5px",
                            // paddingTop:"2px",
                            // width: "100px"
                          }
                        }
                      />
                    )}
                    // style={{ marginLeft:"20px", marginRight: "10px" }}
                  />
                </Grid>

                <Grid item xs={12} sm={3} style={{ marginRight: "15px" }}>
                  <Typography>Sort</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined">
                    <Select
                      native
                      value={assistantFilter.sort}
                      style={{ width: 264 }}
                      className={classes.sortFormControl}
                      onChange={(event) => {
                        const filterObj = {
                          ...this.state.assistantFilter,
                          sort: event.target.value,
                        };
                        this.setState({ assistantFilter: filterObj });
                      }}
                      label="sort"
                    >
                      <option aria-label="None" value="" />
                      <option value={"dec"}>Descending</option>
                      <option value={"asc"}>Ascending</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{ paddingRight: 24, paddingBottom: mediaQuery ? 64 : 16 }}
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={this.downloadReport}
                style={{ marginRight: 8 }}
                startIcon={<GetApp />}
              >
                Download Report
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.applyFilter}
                style={{ margin: 0 }}
              >
                Apply
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>

        {/* project dialog */}
        {this.state.openProjectDialog && (
          <CreateProjectDialog
            showDialog={this.state.openProjectDialog}
            closeDialog={this.projectDialog}
            allBusiness={this.props.allBusiness}
            selectBusiness={this.selectBusiness}
            setProjectName={(e) =>
              this.setState({ newProjectName: e.target.value })
            }
            createBusiness={() =>
              this.setState({
                openProjectDialog: false,
                clientDialog: true,
              })
            }
            onSubmit={this.projectSubmit}
            disableSubmit={this.state.projectSubmitLoader}
            planFamilies={partnerPlanFamily}
            defaultPlanFamily={partner?.defaultPlanFamily}
            defaultPlanName={partner?.defaultPlanName}
            wccPlans={partnerWccFamily}
            paymentPlans={partnerWhatsappPaymentPlans}
            defaultWccPlan={partner?.defaultWccPlan}
            defaultWhatsappPaymentsPlan={partner?.defaultWhatsappPaymentsPlan}
            type={partner?.type}
            partner={partner}
          />
        )}

        {/* Create new business */}
        <Dialog open={this.state.clientDialog}>
          <DialogContent style={{ marginRight: 8, width: 500 }}>
            <Grid container justifyContent="flex-end">
              <IconButton onClick={this.toggleclientDialog}>
                <Clear />
              </IconButton>
            </Grid>

            <Typography
              variant="h3"
              style={{
                marginBottom: "16px",
              }}
              color="primary"
            >
              Create Your Business Here
            </Typography>

            <TextField
              id="name"
              label="Full Name"
              variant="outlined"
              type="text"
              name="display_name"
              onChange={this.handleBusinessInput}
              value={this.state.display_name}
              className={classes.textFieldRoot}
            />
            <TextField
              id="email"
              label="Email Id"
              variant="outlined"
              type="text"
              name="email"
              onChange={this.handleBusinessInput}
              value={this.state.email}
              className={classes.textFieldRoot}
            />
            {this.props.partner.type === "DIRECT" && (
              <div>
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  name="password"
                  onChange={this.handleBusinessInput}
                  value={this.state.password}
                  className={classes.textFieldRoot}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            isPasswordValid
                              ? ""
                              : "Minimum eight characters, at least one letter, one number"
                          }
                        >
                          {isPasswordValid ? (
                            <CheckCircleOutlined
                              className={classes.passwordIcon}
                              style={{ color: "#00d064" }}
                            />
                          ) : (
                            <InfoOutlined
                              className={classes.passwordIcon}
                              style={{ color: "#c3c3c3" }}
                            />
                          )}
                        </Tooltip>
                        <IconButton
                          className={classes.visiblityButton}
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowConfirmPassword}
                        >
                          {this.state.howConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            {!minBusinessFieldsPartners.includes(partnerId) && (
              <TextField
                id="company"
                label="Company Name"
                variant="outlined"
                type="text"
                name="company"
                onChange={this.handleBusinessInput}
                value={this.state.company}
                className={classes.textFieldRoot}
              />
            )}
            {!minBusinessFieldsPartners.includes(partnerId) && (
              <Autocomplete
                disableClearable
                options={SIZE.map((option) => option)}
                onChange={(e, value, reason) => {
                  this.setState({
                    companySize: value,
                  });
                }}
                value={this.state.companySize}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company Size"
                    variant="outlined"
                    classes={{ root: classes.textFieldRootAutocomplete }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            )}
            {!minBusinessFieldsPartners.includes(partnerId) && (
              <Autocomplete
                disableClearable
                options={VERTICALS.map((option) => option)}
                onChange={(e, value, reason) => {
                  this.setState({
                    industry: value,
                  });
                }}
                value={this.state.industry}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Industry"
                    variant="outlined"
                    classes={{ root: classes.textFieldRootAutocomplete }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            )}

            <Box position="relative">
              <Autocomplete
                disableClearable
                options={dialCodes.map((i) => i.dialCode)}
                classes={{ popper: classes.textFieldPopper }}
                getOptionLabel={(option) => {
                  return `${getflag(isoMap[option])} ${option} ${
                    countryMap[option.substring(1)]
                  }`;
                }}
                onChange={(e, value, reason) => {
                  this.setState({
                    countryCode: value,
                  });
                }}
                value={this.state.countryCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    classes={{ root: classes.codeTextFieldRoot }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />

              <TextField
                id="contact"
                placeholder="Mobile Number"
                type="text"
                name="contact"
                onChange={this.handleBusinessInput}
                value={this.state.contact}
                className={classes.phoneTextFieldRoot}
              />
            </Box>

            {!minBusinessFieldsPartners.includes(partnerId) && (
              <Autocomplete
                disableClearable
                options={["INR", "USD"]}
                onChange={(e, value) => {
                  this.setState({
                    currency: value,
                  });
                }}
                value={this.props?.partner?.currency}
                disabled="true"
                name="currency"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Preferred Billing Currency"
                    classes={{ root: classes.textFieldRootAutocomplete }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            )}
            <Autocomplete
              disableClearable
              options={timeZones.map((option) => option)}
              onChange={(e, value) => {
                this.setState({
                  timezone: value,
                });
              }}
              value={this.state.timezone}
              name="timezone"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Timezone"
                  classes={{ root: classes.textFieldRootAutocomplete }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                disabled={
                  !minBusinessFieldsPartners.includes(partnerId)
                    ? !this.state.company ||
                      !this.state.companySize ||
                      !this.state.industry ||
                      !this.state.display_name ||
                      !this.state.contact ||
                      this.state.buttonDisable
                    : !this.state.display_name ||
                      !this.state.email ||
                      !this.state.contact
                }
                color="primary"
                variant="contained"
                onClick={() => {
                  this.buttondisbale();
                  this.createclient();
                }}
                endIcon={
                  this.state.buttonDisable ? <CircularProgress size={18} /> : ""
                }
                style={{ margin: 16 }}
              >
                Create
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

// Navigattion icons used in date fliters
function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box mt={1} mb={1} className={classes.filterTypeContainer}>
      <Grid container alignItems="center">
        {A.startDate && A.endDate ? (
          <Grid item xs={12} md={1}>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} md={3}>
          <Typography variant="body1" style={{ marginRight: 8, minWidth: 100 }}>
            {filterName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 260px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("xs")]: {
      // position top + bottombar height + bottom padding + navbar
      marginTop: 48,
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  textField: {
    width: "100%",
    height: 42,
  },
  sortFormControl: {
    width: 120,
    marginRight: 8,
    height: 32,
    borderRadius: "4px",
    border: "1px solid lightgrey",
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  tabContainer: {
    background: "white",
    boxShadow: "0 0 12px rgb(171 170 170)",
    // padding: "24px",
  },
  tabIndicator: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
  },
  textFieldRootAutocomplete: {
    width: "100%",
    marginTop: "4px",
    marginBottom: "20px",
    height: 32,
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "0px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  dialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "600px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  projectdialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },

  inputRoot: {
    '&[class*="MuiOutlinedInput-root"].MuiAutocomplete-input': {
      padding: 12,
    },
    '&&[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
  },
  popper: {
    width: "fit-content",
    zIndex: 10000,
    height: "10px",
  },
  snackbar: {
    // marginTop:"20px",
    // [theme.breakpoints.down("sm")]: {
    //   marginTop:"40px",
    // },
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },
  codeTextFieldRoot: {
    width: "90px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
});

const connectAssistants = connect(
  (state) => ({
    user: state.login.user,
    assistants: state.assistants.assistants,
    page: state.assistants.page,
    isAssistantsLoading: state.assistants.isLoading,
    assistantError: state.assistants.error,
    rowsPerPage: state.assistants.rowsPerPage,
    totalAssistants: state.assistants.total,
    allCount: state.assistants.allCount,
    liveCount: state.assistants.liveCount,
    draftCount: state.assistants.draftCount,
    trialPlanCount: state.assistants.trialPlanCount,
    apiTrialPlanCount: state.assistants.apiTrialPlanCount,
    monthlyPlanCount: state.assistants.monthlyPlanCount,
    yearlyPlanCount: state.assistants.yearlyPlanCount,
    deletedCount: state.assistants.deletedCount,
    stoppedCount: state.assistants.stoppedCount,
    allBusiness: state.business.allBusiness,
    partnerId: state.partner.partner._id,
    kycDoneCount: state.assistants.kycDoneCount,
    KycNotDoneCount: state.assistants.KycNotDoneCount,
    KycLimitIncreasedCount: state.assistants.KycLimitIncreasedCount,
    KycAppliedCount: state.assistants.KycAppliedCount,
    partner: state.partner.partner,
    partnerSecretHash: state.partner.partner.passwordHash,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    partnerWccFamily: state.wccPlans.allWccPlans,
    partnerWhatsappPaymentPlans: state.whatsappPaymentsPlans.allPlans,
    partnerAgents: state.partnerAgents.partnerAgents,
  }),
  {
    fetchAssistants,
    loadNextAssistants,
    loadPrevAssistants,
    changeRowsPerPage,
    fetchAssistantsCounts,
    fetchBusiness,
    fetchPartnerDetails,
  }
)(AssistantsPage);

export default withStyles(styles)(
  withMediaQuery("(max-width:600px)")(withRouter(connectAssistants))
);
