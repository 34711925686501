import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Grid, Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const fields = [
  {
    name: "Name",
    size: 2,
    key: "displayName",
  },
  {
    name: "Email",
    size: 3,
    key: "email",
  },
  {
    name: "Contact",
    size: 2,
    key: "contact",
  },
  {
    name: "Company",
    size: 3,
    key: "company",
  },
  {
    name: "Created On",
    size: 2,
    key: "createdOn",
  },
];

const quickFilters = [
  "Last active - 24 hr window",
  "Created - This week",
  "Created - This month",
];
class ClientsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedTemplate: null,
    };
  }
  listScrolled = (event) => {
    if (!this.offsetPosition) {
      this.offsetPosition = this.rootEle.current.offsetTop;
    }
    const container = event.currentTarget;
    const currentPos = container.scrollTop;
    this.setState({
      sticky: currentPos > this.offsetPosition,
    });
  };
  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  openClient = (e) => {
    const businesss = [...this.props.business];
    const foundIndex = businesss.findIndex((x) => x._id == e._id);
    const _id = businesss[foundIndex]._id;
    this.props.history.push("/businesses/" + _id);
  };
  render() {
    const { classes, business, isLoading } = this.props;
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid container item xs={12} md={12} lg={12}>
            <Grid container>
              <Box
                className={classes.tableWrapper}
                mb={{ xs: 2, md: 4 }}
                mx={{ xs: 2, md: 0 }}
              >
                <Grid item container className={classes.tableContainer}>
                  <Grid item xs={12} container className={classes.tableHeader}>
                    {fields.map((e) => (
                      <Grid item xs={e.size} key={e.key}>
                        <Box py={2} pl={2}>
                          <Typography variant="h5" color="primary">
                            {e.name}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  {business.map((ads) => (
                    <Grid
                      item
                      xs={12}
                      container
                      onScroll={this.listScrolled}
                      className={classes.tableItem}
                    >
                      {fields.map((field) => (
                        <Grid
                          item
                          xs={field.size}
                          style={{
                            width: "20%",
                          }}
                          onClick={() => {
                            this.openClient(ads);
                          }}
                        >
                          <Cell
                            classes={classes}
                            item={ads}
                            field={this.props.isLoading ? "Loading" : field.key}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
function Cell({ classes, item, field, openCampaign, ...props }) {
  switch (field) {
    case "displayName":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {item[field]}
            </Typography>
          </Box>
        </Grid>
      );
      break;

    case "email":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {item[field]?.startsWith("__archived__")
                ? item[field].replace("__archived__", "")
                : item[field]}
            </Typography>
          </Box>
        </Grid>
      );
      break;
    case "createdOn":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {getFormattedDate(item[field])}
            </Typography>
          </Box>
        </Grid>
      );
      break;

    case "contact":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {item[field]}
            </Typography>
          </Box>
        </Grid>
      );
      break;
    case " company":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {item[field] / 100}
            </Typography>
          </Box>
        </Grid>
      );
    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Skeleton
              variant="rect"
              width="100%"
              style={{ borderRadius: "15px" }}
            />
          </Box>
        </Grid>
      );
      break;
    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5" style={{ wordWrap: "break-word" }}>
              {item[field]}
            </Typography>
          </Box>
        </Grid>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
  },
  tableWrapper: {
    overflowX: "auto",
    width: "100%",
  },

  tableContainer: {
    marginTop: "1.5em",
    minWidth: 1100,
  },

  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
    },
  },

  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
  },
});
const ClientTableconnect = connect((state) => ({
  isLoading: state.client.isLoading,
}))(ClientsTable);
export default withStyles(styles)(withRouter(ClientTableconnect));
