import React from "react";
import { IconButton } from "@material-ui/core";
import { DateRangePicker } from "react-dates";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import moment from "moment";

export default function DateFilter(props) {
  const { startDate, endDate, focusedInput, classes, minDate } = props;
  const { setFocus, setDate } = props;
  return (
    <DateRangePicker
      startDatePlaceholderText="From"
      endDatePlaceholderText="To"
      startDateId="startDate"
      endDateId="endDate"
      startDate={startDate}
      endDate={endDate}
      onDatesChange={({ startDate, endDate }) => setDate(startDate, endDate)}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocus(focusedInput)}
      navPosition="navPositionTop"
      numberOfMonths={1}
      navPrev={
        <NavigationWrapper classes={classes}>
          <NavigateBefore />
        </NavigationWrapper>
      }
      navNext={
        <NavigationWrapper classes={classes}>
          <NavigateNext />
        </NavigationWrapper>
      }
      hideKeyboardShortcutsPanel
      customArrowIcon={null}
      screenReaderInputMessage={" "}
      small
      readOnly
      displayFormat="DD/MM/YYYY"
      minDate={minDate}
      isOutsideRange={(day) =>
        minDate
          ? day.isAfter(moment()) || day.isBefore(minDate)
          : day.isAfter(moment())
      }
    />
  );
}

// Navigattion icons used in date fliters
function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}
