import React, { Component } from "react";
import axios from "axios";
import { URL } from "../../config/config.js";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import billingsHelper from "../../helpers/billingsHelper.js";
import plansHelper from "../../helpers/plansHelper.js";
import Header from "../../commons/Header/Header.js";

class BillingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: "",
      amount: 0,
      reasonCode: "",
      message: "",
      createdAt: "",
      isLoading: true,
      assistantName: "",
      paymentId: "",
      orderId: "",
      invoiceId: "",
      invoiceLink: "",
    };
  }

  getBillingLog = () => {
    const partnerId = this.props.user.id;
    const _id = this.props.match.params.billingId;
    const { partnerPlanFamily, isPlansLoading, partner } = this.props;
    const { type } = partner || {};
    if (!isPlansLoading)
      axios
        .get(URL + `/api/${partnerId}/partnerbilling-lists/${_id}`)
        .then((response) => {
          const assistantName = response.data[1]?.assistantName;
          const modifiedBill =
            type !== "DIRECT"
              ? billingsHelper.mouldBill(response.data[0], partnerPlanFamily)
              : billingsHelper.mouldDirectBill(response.data[0]);
          const {
            action,
            amount,
            reasonCode,
            message,
            createdAt,
            paymentId,
            orderId,
            invoiceId,
            invoiceLink,
          } = modifiedBill;

          this.setState({
            action,
            amount,
            reasonCode,
            message,
            createdAt,
            isLoading: false,
            assistantName: assistantName,
            orderId,
            paymentId,
            invoiceId,
            invoiceLink,
          });
        })
        .catch((error) => {
          console.error(error);
        });
  };
  //merge error
  componentDidMount() {
    if (!this.props.location?.state?.log) this.getBillingLog();
    const log = this.props.location?.state?.log;
    plansHelper.setPlanFamilies();
    if (log) {
      const {
        action,
        amount,
        reasonCode,
        message,
        createdAt,
        assistantName,
        paymentId,
        orderId,
        invoiceId,
        invoiceLink,
      } = log;
      this.setState({
        action,
        amount,
        reasonCode,
        message,
        createdAt,
        isLoading: false,
        assistantName,
        paymentId,
        orderId,
        invoiceId,
        invoiceLink,
      });
    }
  }
  render() {
    const { classes, match, bills, user } = this.props;
    const toggleDialog = () => {
      this.props.history.goBack();
    };

    return (
      <Box px={2} className={classes.dialogContainer}>
        <Header businessTitle={"Billing Details"} onBackPress={toggleDialog} />
        <>
          {" "}
          {this.state.isLoading ? (
            <Box my={4} display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <div className={classes.root}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8}>
                  {this.state.assistantName && (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Project Name
                      </Typography>
                      <Typography variant="h5" paragraph noWrap={false}>
                        {this.state.assistantName}
                      </Typography>
                    </Box>
                  )}
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      ACTION
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.action}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      AMOUNT
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {`${
                        this.props.user.currency === "INR"
                          ? `₹ `
                          : this.props.user.currency === "USD"
                          ? `$ `
                          : ""
                      } ${this.state.amount / 100000}`}
                    </Typography>
                  </Box>

                  {this.state.invoiceId && this.state.invoiceLink && (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                          >
                            INVOICE
                          </Typography>
                          <Typography variant="h5" paragraph noWrap={false}>
                            {this.state.invoiceId}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            href={this.state.invoiceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ height: "30px" }}
                          >
                            Download
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {this.state.paymentId && (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        PAYMENT ID
                      </Typography>
                      <Typography variant="h5" paragraph noWrap={false}>
                        {this.state.paymentId}
                      </Typography>
                    </Box>
                  )}

                  {this.state.orderId && (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        ORDER ID
                      </Typography>
                      <Typography variant="h5" paragraph noWrap={false}>
                        {this.state.orderId}
                      </Typography>
                    </Box>
                  )}

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      REASON CODE
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.reasonCode}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      MESSAGE
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.message}
                    </Typography>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Created At
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {getFormattedDate(this.state.createdAt)}
                    </Typography>
                  </Box>

                  <Box my={8}></Box>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      </Box>
    );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
    "& pre": {
      whiteSpace: "pre-wrap",
      // whiteSpace: "-moz-pre-wrap",
      // whiteSpace: "-pre-wrap",
      // whiteSpace: "-o-pre-wrap",
      wordWrap: "break-word",
      wordBreak: "keep-all",
    },
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "rgb(237, 226, 252)",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});
const billingconnect = connect(
  (state) => ({
    user: state.login.user,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    isPlansLoading: state.planFamilies.isLoading,
    partner: state.partner.partner,
  }),
  {}
)(BillingDetails);
export default withStyles(styles)(withRouter(billingconnect));
