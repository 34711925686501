import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Radio,
  Snackbar,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { updateOnboardMethod } from "../../store/partnerState";
import {
  URL,
  RAZORPAY_CLIENT_ID,
  baseURL,
  appURL,
  TENANT_ID,
} from "../../config/config";
import { checkAccess } from "../../helpers/RBAC";
import ConfirmationDialog from "../Assistants/ConfirmationDialog";
import Partnerbillingaddress from "./WhitelabelBillings/PartnerBillingDialog";
import { stateCodes } from "../../config/dialCodes";
import { Skeleton } from "@material-ui/lab";
class OnboardingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      selectedValue: this.props.partner?.onboardingMethod,
      connectClicked: false,
      razorpayProfile: null,
      openDialog: false,
      disconnectLoader: false,
      openPartnerBillingAddress: false,
      highlight: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchRazorpayProfile();
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  toogleOpenDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  handleChange = (event) => {
    this.setState({
      selectedValue: event.target.value,
    });
  };

  updateOnboardingMethod = () => {
    this.setState({
      planSubmitLoader: true,
    });

    const updateDefaultMethod = this.state.selectedValue;
    if (!updateDefaultMethod) {
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "Select a method",
      });
      return;
    }
    axios
      .patch(URL + `/api/update-partner-config`, {
        onboardingMethod: updateDefaultMethod,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "Successfully changed onboarding method",
          planSubmitLoader: false,
        });
        this.props.updateOnboardMethod({
          onboardingMethod: updateDefaultMethod,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "Onboarding method update failed",
          planSubmitLoader: false,
        });
      });
  };

  fetchRazorpayProfile = () => {
    this.setState({ isLoading: true });
    axios
      .post(URL + `/api/fetch-partner-razorpay-profile`, {
        partnerId: this.props.partner._id,
      })
      .then((response) => {
        this.setState({ razorpayProfile: response.data, isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          connectClicked: false,
          razorpayProfile: null,
          // isLoading: false,
        });
      });
  };

  setupDisconnect = () => {
    this.setState({ disconnectLoader: true });
    axios
      .post(appURL + "/razorpay/partner/t1/disconnect", {
        partnerId: this.props.partner._id,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "Successfully disconnected Razorpay",
          razorpayProfile: null,
          disconnectLoader: false,
        });
        this.toogleOpenDialog();
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "Failed to disconnect Razorpay",
          disconnectLoader: false,
        });
        this.toogleOpenDialog();
      });
  };

  authenticateRazorpay = () => {
    const partnerId = this.props.partner._id;
    const billingAddress = this.props.partner.billingAddress;
    if (!billingAddress) {
      this.setState({
        alert: true,
        alertSeverity: "error",
        alertMsg: "No billing address found!",
      });
    }
    // const callBackUrl = baseURL + "/razorpay/auth";
    const callBackUrl = baseURL + "/integrations/appstore/app/razorpay/auth";
    const RazorpayClientId = RAZORPAY_CLIENT_ID;
    const authorizationUrl = `https://auth.razorpay.com/authorize?response_type=code&client_id=${RazorpayClientId}&redirect_uri=${callBackUrl}&scope=read_write&state=${partnerId}`;
    this.setState({ connectClicked: true }, () => {
      window.open(authorizationUrl, "_blank");
    });
  };

  togglePartnerBillingAddrs = () => {
    this.setState({
      openPartnerBillingAddress: !this.state.openPartnerBillingAddress,
    });
  };

  showSnackbar = (toast) => {
    this.setState(toast);
  };

  handleTooltipClick = () => {
    this.setState({ highlight: true });
    setTimeout(() => this.setState({ highlight: false }), 500);
  };

  render() {
    const { classes, match, partner, partnerAgents, user } = this.props;
    const { billingAddress, isRazorpayEnabled } = partner || {};
    const {
      firstName,
      lastName,
      line1,
      line2,
      city,
      zip,
      stateCode,
      country,
      company,
    } = billingAddress || {};

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "write"
    );

    const isOwner = checkAccess(null, partnerAgents, user, null, null);

    const { razorpayProfile } = this.state;

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Header businessTitle={"Configure Onboarding"} />
        <Box my={3} />
        {/* Layout */}
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box className={classes.container}>
                  <Typography variant="h4">Select Onboarding Method</Typography>
                  <Box mt={5} />
                  <Box>
                    <Grid container>
                      <Grid item xs={12} md={1} className={classes.cells}>
                        <Radio
                          checked={
                            this.state.selectedValue === "WHATSAPP_API_FORM"
                          }
                          onChange={this.handleChange}
                          value="WHATSAPP_API_FORM"
                          name="radio-button-demo"
                          color="primary"
                          inputProps={{ "aria-label": "WHATSAPP_API_FORM" }}
                          disabled={!hasWriteAccess}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.cells}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        WhatsApp API Form
                      </Grid>
                      <Grid item xs={12} md={1} className={classes.cells}>
                        <Radio
                          checked={
                            this.state.selectedValue === "EMBEDDED_SIGNUP"
                          }
                          onChange={this.handleChange}
                          value="EMBEDDED_SIGNUP"
                          name="radio-button-demo"
                          color="primary"
                          inputProps={{ "aria-label": "EMBEDDED_SIGNUP" }}
                          disabled={!hasWriteAccess}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.cells}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        Embedded Signup
                      </Grid>
                      <Grid item xs={12} md={1} className={classes.cells}>
                        <Radio
                          checked={this.state.selectedValue === "NONE"}
                          onChange={this.handleChange}
                          value="NONE"
                          name="radio-button-demo"
                          color="primary"
                          inputProps={{ "aria-label": "NONE" }}
                          disabled={!hasWriteAccess}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.cells}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        None
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: 24,
                        }}
                      >
                        {hasWriteAccess && (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={this.updateOnboardingMethod}
                          >
                            Submit
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} />
                </Box>

                {!TENANT_ID && isOwner && isRazorpayEnabled && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {this.state.isLoading ? (
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height={150}
                          style={{ borderRadius: 8, marginTop: 16 }}
                        />
                      ) : (
                        <>
                          <Box
                            className={classes.container}
                            style={{
                              height: "80%",
                              padding: "20px",
                              paddingBottom: "10px",
                            }}
                          >
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "600" }}
                            >
                              Razorpay Profile Setup
                            </Typography>
                            {razorpayProfile?.status === "LIVE" ? (
                              <Box mt={2}>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                  style={{ marginBottom: "12px" }}
                                >
                                  Merchant ID:{" "}
                                  {razorpayProfile.merchantId?.slice(4)}
                                </Typography>
                              </Box>
                            ) : (
                              <Box mt={1}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  style={{ marginBottom: "15px" }}
                                >
                                  - Connect your Razorpay merchant account to
                                  automate WCC payments.
                                  <br />- Currently available exclusively for
                                  Indian users.
                                </Typography>
                              </Box>
                            )}
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      {razorpayProfile?.status === "LIVE" ? (
                                        <React.Fragment>
                                          <Box
                                            display="flex"
                                            justifyContent="flex-start" // Align button to the left
                                            mt="10px"
                                            width="100%"
                                          >
                                            <Button
                                              variant="outlined"
                                              onClick={this.toogleOpenDialog}
                                              style={{
                                                color: "#fa5c5c",
                                                borderColor: "#fa5c5c",
                                                borderRadius: "6px",
                                                width: "30%", // Adjusted width to be smaller
                                              }}
                                              size="small"
                                            >
                                              Disconnect
                                            </Button>
                                          </Box>
                                        </React.Fragment>
                                      ) : (
                                        <>
                                          <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            width="100%"
                                          >
                                            <Tooltip
                                              placement="bottom"
                                              interactive
                                              title={
                                                !billingAddress ? (
                                                  <Typography
                                                    variant="caption"
                                                    color="primary"
                                                    style={{
                                                      fontSize: "0.70rem",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={
                                                      this.handleTooltipClick
                                                    }
                                                  >
                                                    Please add billing details →
                                                  </Typography>
                                                ) : this.state.connectClicked ? (
                                                  "Razorpay Integration in process"
                                                ) : (
                                                  ""
                                                )
                                              }
                                            >
                                              <span>
                                                <Button
                                                  color="primary"
                                                  variant="contained"
                                                  size="medium"
                                                  style={{
                                                    borderRadius: "6px",
                                                  }}
                                                  disabled={
                                                    !billingAddress ||
                                                    this.state.connectClicked
                                                  }
                                                  onClick={
                                                    this.authenticateRazorpay
                                                  }
                                                  className={
                                                    classes.razorpayConnect
                                                  }
                                                  endIcon={
                                                    this.state
                                                      .connectClicked && (
                                                      <CircularProgress
                                                        size={18}
                                                        style={{
                                                          color: "white",
                                                        }}
                                                      />
                                                    )
                                                  }
                                                >
                                                  Connect Razorpay
                                                </Button>
                                              </span>
                                            </Tooltip>
                                          </Box>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {this.state.isLoading ? (
                        <Skeleton
                          variant="rect"
                          width="100%"
                          height={150}
                          style={{ borderRadius: 8, marginTop: 16 }}
                        />
                      ) : (
                        <>
                          <Box
                            className={classes.container}
                            style={{
                              height: "80%",
                              padding: "20px",
                              paddingBottom: "10px",
                              border: this.state.highlight
                                ? "2px solid #4614862e"
                                : "",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="h5"
                                style={{ fontWeight: "600" }}
                              >
                                Billing Details
                              </Typography>

                              <Button
                                size="small"
                                color="primary"
                                variant="outlined"
                                style={{ height: 28, borderRadius: "6px" }}
                                onClick={() => {
                                  this.setState({
                                    openPartnerBillingAddress: true,
                                  });
                                }}
                              >
                                Edit
                              </Button>
                            </Box>
                            {billingAddress ? (
                              <Box>
                                <Typography
                                  variant="body2"
                                  style={{ marginTop: 12 }}
                                >
                                  {/* {!!firstName && !!lastName && (
                                <>
                                  {firstName} {lastName} <br />
                                </>
                              )} */}
                                  {!!company && (
                                    <>
                                      {company} <br />
                                    </>
                                  )}
                                  {!!line1 && (
                                    <>
                                      {line1},
                                      <br />
                                    </>
                                  )}
                                  {!!line2 && (
                                    <>
                                      {line2},
                                      <br />
                                    </>
                                  )}
                                  {!!city && `${city}, `}
                                  {!!stateCode && `${stateCodes[stateCode]}, `}
                                  {!!country && `${country}`}
                                  {!!zip && ` - ${zip}`}
                                </Typography>
                              </Box>
                            ) : (
                              <>
                                <Typography
                                  variant="body2"
                                  color="error"
                                  style={{ margin: "12px 0" }}
                                >
                                  Add your billing address.
                                </Typography>
                                <Box mt={10} />
                              </>
                            )}
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box my={8} />
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
        <ConfirmationDialog
          openDialog={this.state.openDialog}
          closeDialog={this.toogleOpenDialog}
          title={"Disconnect Razorpay?"}
          body={
            <Typography variant="body1" paragraph style={{ color: "black" }}>
              Are you sure you want to disconnect? Doing so will remove your
              Razorpay merchant account, preventing you from processing
              automated WCC payments for your customers through Razorpay
            </Typography>
          }
          action={this.setupDisconnect}
          loader={this.state.disconnectLoader}
        />
        {/* Partner Billing Address Dialog */}
        <Partnerbillingaddress
          onClose={this.togglePartnerBillingAddrs}
          open={this.state.openPartnerBillingAddress}
          showSnackbar={this.showSnackbar}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  razorpayConnect: {
    backgroundColor: "#3295fe",
    color: "white",
    padding: "6px 25px",
    "&:hover": {
      backgroundColor: "#3295fe",
    },
  },
});

const connectOnboardingPage = connect(
  (state) => ({
    partner: state.partner.partner,
    partnerAgents: state.partnerAgents.partnerAgents,
    user: state.login.user,
  }),
  {
    updateOnboardMethod,
  }
)(OnboardingPage);
export default withStyles(styles)(connectOnboardingPage);
