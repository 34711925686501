import axios from "axios";

import { URL } from "../../config/config.js";

const initialState = {
  partnerBillingLogs: Array(10)
    .fill(0)
    .map((item) => ({ name: "Loading" })),
  skip: 0,
  totalprojects: 0,
  isLoading: true,
  error: null,
  page: 0,
  rowsPerPage: 10,
  applied: {
    createdAt: {
      startDate: null,
      endDate: null,
      focusedInput: null,
    },
  },
  sort: null,
};

const LOAD_START = "Billing/LOAD_START";
const LOAD_SUCCESS = "Billing/LOAD_SUCCESS";
const LOAD_MORE_SUCCESS = "Billing/LOAD_MORE_SUCCESS";
const LOAD_FAILURE = "Billing/LOAD_FAILURE";
const ADD_CLIENT = "Billing/ADD_CLIENT";
const CHANGE_PAGE = "Billing/CHANGE_PAGE";
const CHANGE_ROWS_PER_PAGE = "Billing/CHANGE_ROWS_PER_PAGE";
const CHANGE_QUERY = "Bilings/CHANGE_QUERY";
const CHANGE_STATUS = "Bilings/CHANGE_STATUS";
const EXIT_CLIENTS = "TEMPLATE/EXIT_CLIENTS";
const LOAD_DATE = "LOAD_DATE/Bilings";
const LOAD_APPLIED = "LOAD_APPLIED/billing";
const LOAD_SORT = "LOAD_SORT/billing";
const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = ({
  partnerBillingLogs,
  skip,
  totalprojects,
  assistantId,
}) => ({
  type: LOAD_SUCCESS,
  payload: { partnerBillingLogs, skip, totalprojects, assistantId },
});

const loadSort = (sort) => ({
  type: LOAD_SORT,
  payload: { sort },
});

const loadDate = ({ fromDate, toDate }) => ({
  type: LOAD_DATE,
  payload: { fromDate, toDate },
});

const loadapplied = ({ applied }) => ({
  type: LOAD_APPLIED,
  payload: applied,
});

const loadmoresuccess = ({ partnerBillingLogs, skip, assistantId }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { partnerBillingLogs, skip, assistantId },
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const loadSorts = (value) => (dispatch, getState) => {
  dispatch(loadSort(value));
};

export const loadBills = (assistantId, filters) => (dispatch, getState) => {
  const { rowsPerPage } = getState().billing;
  const sort = filters.sort;
  const newFilter = { ...filters };

  delete newFilter.sort;
  dispatch(loadStart);
  axios
    .post(URL + `/api/partner-billing-activity-lists?sort=${sort}`, {
      skip: 0,
      rowsPerPage,
      assistantId,
      ...newFilter,
    })
    .then((response) => {
      const { partnerBillingLogs, newSkip, totalprojects } = response.data;
      dispatch(
        loadSuccess({
          partnerBillingLogs: partnerBillingLogs,
          skip: newSkip,
          totalprojects: totalprojects,
          assistantId,
        })
      );
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export const loadNext =
  (event, newPage, assistantId, filters) => (dispatch, getState) => {
    const { rowsPerPage, skip, partnerBillingLogs } = getState().billing;
    const sort = filters.sort;
    const newFilter = { ...filters };
    delete newFilter.sort;
    if (partnerBillingLogs.length > newPage * rowsPerPage) {
      dispatch({ type: CHANGE_PAGE, payload: newPage });
    } else {
      dispatch({ type: CHANGE_PAGE, payload: newPage });
      axios
        .post(URL + `/api/partner-billing-activity-lists?sort=${sort}`, {
          skip,
          rowsPerPage,
          assistantId,
          ...newFilter,
        })
        .then((response) => {
          const { partnerBillingLogs, newSkip } = response.data;
          dispatch(
            loadmoresuccess({
              partnerBillingLogs: partnerBillingLogs,
              skip: newSkip,
              assistantId,
            })
          );
        })
        .catch((error) => {
          console.error(error);
          dispatch(loadFailure(error));
        });
    }
  };

export const addBills = (billing) => (dispatch) => {
  dispatch({ type: ADD_CLIENT, payload: billing });
};

export const changeQuery = (nameQuery) => (dispatch) => {
  dispatch({ type: CHANGE_QUERY, payload: nameQuery });
};

export const changeRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: CHANGE_ROWS_PER_PAGE, payload: rowsPerPage });
};

export const unfetchbills = () => (dispatch) => {
  dispatch({ type: EXIT_CLIENTS, payload: null });
};

export default function ClientReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        partnerBillingLogs: [...payload.partnerBillingLogs],
        skip: payload.skip,
        totalprojects: payload.totalprojects,
        page: 0,
        assistantId: payload.assistantId,
        isLoading: false,
        error: null,
      };
    case LOAD_DATE:
      return {
        ...state,
        applied: {
          ...state.applied,
          createdAt: {
            ...state.applied.createdAt,
            startDate: payload.fromDate,
            endDate: payload.toDate,
          },
        },
      };
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        partnerBillingLogs: [
          ...state.partnerBillingLogs,
          ...payload.partnerBillingLogs,
        ],
        skip: payload.skip,
        isLoading: false,
      };

    case CHANGE_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload, page: 0 };

    case CHANGE_PAGE:
      return { ...state, page: payload };

    case LOAD_FAILURE:
      return { ...state, isLoading: true, error: payload };

    case ADD_CLIENT:
      return {
        ...state,
        partnerBillingLogs: {
          [payload.name]: payload,
          ...state.partnerBillingLogs,
        },
      };

    case LOAD_APPLIED:
      return {
        ...state,
        applied: { ...state.applied, ...payload.applied },
      };

    case CHANGE_QUERY:
      return {
        ...state,
        nameQuery: payload,
      };

    case LOAD_SORT:
      return {
        ...state,
        sort: payload.sort,
      };

    case EXIT_CLIENTS:
      return {
        ...initialState,
      };

    case CHANGE_STATUS:
      return {
        ...state,
        partnerBillingLogs: {
          ...state.partnerBillingLogs,
          [payload.displayName]: {
            ...state.partnerBillingLogs[payload.displayName],
            status: payload.status,
          },
        },
      };

    default:
      return state;
  }
}
