import React from "react";
import { Dialog, Typography, Button, Grid, Box } from "@material-ui/core";

function ConfirmationDialog(props) {
  const { open, onClose, taskMessage, onConfirm, body, zIndex } = props;
  const finalQuestion = props.finalQuestion || "Are you sure?";
  return (
    <Dialog
      open={open}
      disableBackdropClick
      onClose={onClose}
      style={{ zIndex: zIndex || 3002 }}
    >
      <Box p={3}>
        <Grid container>
          <Typography variant="h3" gutterBottom style={{ width: "100%" }}>
            {taskMessage}
            {typeof taskMessage === "string" && ". "}
          </Typography>
          {!!body && (
            <Typography variant="body 2" gutterBottom style={{ width: "100%" }}>
              {body}
            </Typography>
          )}
          <Typography variant="h5" paragraph style={{ width: "100%" }}>
            {finalQuestion}
          </Typography>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              style={{ marginRight: "0.75em" }}
            >
              Confirm
            </Button>
            <Button variant="outlined" color="primary" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default ConfirmationDialog;
