import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";

function WccRow(props) {
  const {
    countryCode,
    country,
    aisensyBicPrice,
    partnerBicPrice,
    bicMargin,
    aisensyUicPrice,
    partnerUicPrice,
    uicMargin,
    currency,
    updateBIC,
    updateUIC,
    keyValue,
    partnerServiceInitiated,
    partnerAuthenticationInitiated,
    parterUtilityInitiated,
    partnerMarketingInitiated,
    serviceMargin,
    utilityMargin,
    authenticationMargin,
    marketingMargin,
    updateMc,
    upadteAc,
    updateUc,
    updateSc,
    aisensyAuthenticationInitiated,
    aisensyUtilityInitiated,
    aisensyServiceInitiated,
    aisensyMarketingInitiated,
  } = props;

  return (
    <React.Fragment key={keyValue}>
      <Grid item xs={12} md={1} container alignItems="center">
        <Typography
          required
          // className={classes.}
          variant="h5"
          // align="center"
        >
          {country}
        </Typography>
      </Grid>

      <Grid xs={12} md={11} style={{ display: "flex", alignItems: "center" }}>
        {/* <--- marketing wcc handling ---->*/}
        <Grid item md={3} style={{ display: "flex" }}>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              placeholder="0"
              disabled="true"
              // className={classes.input}
              margin="dense"
              value={aisensyMarketingInitiated}
              name="basicPlatform"
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              // disabled={disableFields}
              placeholder="0"
              // className={classes.input}
              margin="dense"
              onChange={(e) => {
                const value = Number(e.target.value) * 100000 ?? 0;
                const newPrice = aisensyMarketingInitiated * 100000 + value;
                updateMc(newPrice, countryCode);
              }}
              defaultValue={marketingMargin}
              name="basicPlatform"
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              placeholder="0"
              disabled="true"
              // className={classes.input}
              margin="dense"
              name="basicPlatform"
              value={partnerMarketingInitiated}
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
        </Grid>

        {/* <--- Service wcc handling ---->*/}
        <Grid item md={3} style={{ display: "flex" }}>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              placeholder="0"
              disabled="true"
              // className={classes.input}
              margin="dense"
              value={aisensyServiceInitiated}
              name="basicPlatform"
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              // disabled={disableFields}
              placeholder="0"
              // className={classes.input}
              margin="dense"
              onChange={(e) => {
                const value = Number(e.target.value) * 100000 ?? 0;
                const newPrice = aisensyServiceInitiated * 100000 + value;
                updateSc(newPrice, countryCode);
              }}
              defaultValue={serviceMargin}
              name="basicPlatform"
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              placeholder="0"
              disabled="true"
              // className={classes.input}
              margin="dense"
              name="basicPlatform"
              value={partnerServiceInitiated}
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
        </Grid>

        {/* <--- Utility wcc handling ---->*/}
        <Grid item md={3} style={{ display: "flex" }}>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              placeholder="0"
              disabled="true"
              // className={classes.input}
              margin="dense"
              value={aisensyUtilityInitiated}
              name="basicPlatform"
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              // disabled={disableFields}
              placeholder="0"
              // className={classes.input}
              margin="dense"
              onChange={(e) => {
                const value = Number(e.target.value) * 100000 ?? 0;
                const newPrice = aisensyUtilityInitiated * 100000 + value;
                updateUc(newPrice, countryCode);
              }}
              defaultValue={utilityMargin}
              name="basicPlatform"
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              placeholder="0"
              disabled="true"
              // className={classes.input}
              margin="dense"
              name="basicPlatform"
              value={parterUtilityInitiated}
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
        </Grid>

        {/* <--- Authentication wcc handling ---->*/}
        <Grid item md={3} style={{ display: "flex" }}>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              placeholder="0"
              disabled="true"
              // className={classes.input}
              margin="dense"
              value={aisensyAuthenticationInitiated}
              name="basicPlatform"
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              // disabled={disableFields}
              placeholder="0"
              // className={classes.input}
              margin="dense"
              onChange={(e) => {
                const value = Number(e.target.value) * 100000 ?? 0;
                const newPrice =
                  aisensyAuthenticationInitiated * 100000 + value;
                upadteAc(newPrice, countryCode);
              }}
              defaultValue={authenticationMargin}
              name="basicPlatform"
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ marginRight: 4 }}>
            <TextField
              style={{ width: "100%", padding: "3px 0px" }}
              required
              type="Number"
              variant="outlined"
              placeholder="0"
              disabled="true"
              // className={classes.input}
              margin="dense"
              name="basicPlatform"
              value={partnerAuthenticationInitiated}
              InputProps={{
                startAdornment: currency === "USD" ? "$" : "₹",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default React.memo(WccRow);
