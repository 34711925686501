import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import {
  withStyles,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import { Autocomplete, Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { URL } from "../../config/config";
const STATUS = ["PENDING", "APPROVED", "REJECTED"];

function getStatusColor() {
  const templateStatus = "PENDING";
  switch (templateStatus) {
    case "PENDING":
      return "grey";
    case "APPROVED":
      return "#08CF65";
    case "REJECTED":
      return "#ff0000";
    default:
      return "pink";
  }
}

class TemplateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      processed: "",
      callToAction: [],
      quickReplies: [],
      label: "",
      category: "",
      type: "",
      name: "",
      format: "",
      sampleMessage: "",
      actionType: "",
      sampleMediaUrl: "",
      sampleCTAUrl: "",
      parameters: 0,
      header: null,
      footer: null,
      namespace: "",
      rejectedReason: "",
      templateLanguage: "",
      assistantName: "",
      Loading: true,
    };
  }
  componentDidMount() {
    const { templateId: _id, user, partner, templates } = this.props;
    const { type } = partner || {};
    const partnerId = user?.id;
    if (type !== "DIRECT")
      (() => {
        axios
          .get(URL + `/api/${partnerId}/template-detials/${_id}`)
          .then((response) => {
            const {
              status,
              processed,
              callToAction,
              quickReplies,
              label,
              category,
              type,
              name,
              format,
              headerText,
              footerText,
              sampleMessage,
              actionType,
              sampleMediaUrl,
              sampleCTAUrl,
              parameters,
              namespace,
              rejectedReason,
              templateLanguage,
              assistantName,
            } = response.data[0];

            this.setState({
              status: status,
              processed: processed,
              callToAction: callToAction,
              quickReplies: quickReplies,
              label: label,
              category: category,
              type: type,
              name: name,
              format: format,
              sampleMessage: sampleMessage,
              actionType: actionType,
              sampleMediaUrl: sampleMediaUrl,
              sampleCTAUrl: sampleCTAUrl,
              parameters: parameters,
              namespace: namespace,
              rejectedReason: rejectedReason,
              templateLanguage: templateLanguage,
              assistantName: assistantName,
              header: headerText,
              footer: footerText,
              Loading: false,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      })();
    else {
      try {
        const template = templates.find((e) => e._id === _id);
        const {
          status,
          processed,
          callToAction,
          quickReplies,
          label,
          category,
          type,
          name,
          format,
          headerText,
          footerText,
          sampleMessage,
          actionType,
          sampleMediaUrl,
          sampleCTAUrl,
          parameters,
          namespace,
          rejectedReason,
          templateLanguage,
          assistantName,
        } = template;
        this.setState({
          status: status,
          processed: processed,
          callToAction: callToAction,
          quickReplies: quickReplies,
          label: label,
          category: category,
          type: type,
          name: name,
          format: format,
          sampleMessage: sampleMessage,
          actionType: actionType,
          sampleMediaUrl: sampleMediaUrl,
          sampleCTAUrl: sampleCTAUrl,
          parameters: parameters,
          namespace: namespace,
          rejectedReason: rejectedReason,
          templateLanguage: templateLanguage,
          assistantName: assistantName,
          header: headerText,
          footer: footerText,
          Loading: false,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const { classes, match, templates, user, partner } = this.props;
    const { type } = partner || {};
    const toggleDialog = () => {
      // this.props.history.goBack();
      this.props.setTemplateId(null);
    };

    return (
      <Box px={2} className={classes.dialogContainer}>
        <Grid container alignItems="center" className={classes.dialogTopbar}>
          <Grid item>
            <IconButton onClick={toggleDialog}>
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item>
            {this.state.assistantName} : {this.state.name}
          </Grid>
        </Grid>
        <>
          {" "}
          {this.state.Loading ? (
            <Box my={4} display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <div className={classes.root}>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={8}>
                  <Box py={2} px={0} my={2}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={"auto"}>
                        <TextField
                          style={{
                            width: "100%",
                            padding: "3px 0px",
                            marginRight: 10,
                          }}
                          variant="outlined"
                          placeholder="PENDING"
                          onChange={(e) => {
                            this.setState({ contactName: e.target.value });
                          }}
                          className={classes.textField}
                          disabled="true"
                          margin="dense"
                          value={this.state.status}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {this.state.rejectedReason ? (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Failure Reason
                      </Typography>
                      <Typography
                        variant="h5"
                        paragraph
                        noWrap={false}
                        color="error"
                      >
                        {this.state.rejectedReason}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Template Name
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.name}
                    </Typography>
                  </Box>

                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Template Category
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.category}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Template Language
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.language || this.state.templateLanguage}
                    </Typography>
                  </Box>
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Template Type
                    </Typography>
                    <Typography variant="h5" paragraph noWrap={false}>
                      {this.state.type}
                    </Typography>
                  </Box>
                  {this.state.header && (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Template Header
                      </Typography>
                      <pre>
                        <Typography variant="h5" paragraph>
                          {this.state.header}
                        </Typography>
                      </pre>
                    </Box>
                  )}
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Template Format
                    </Typography>
                    {/* <pre> */}
                    <Typography
                      variant="h5"
                      paragraph
                      noWrap={false}
                      component="pre"
                    >
                      {this.state.format}
                    </Typography>
                    {/* </pre> */}
                  </Box>

                  {this.state.footer && (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Template Footer
                      </Typography>
                      <pre>
                        <Typography variant="h5" paragraph>
                          {this.state.footer}
                        </Typography>
                      </pre>
                    </Box>
                  )}
                  <Box py={2} px={2} my={2} className={classes.container}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Template Sample
                    </Typography>
                    <pre>
                      <Typography variant="h5" paragraph>
                        {this.state.sampleMessage}
                      </Typography>
                    </pre>
                  </Box>
                  {this.state.sampleCTAUrl ? (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Sample CTA URL
                      </Typography>
                      <pre>
                        <Typography variant="h5" paragraph>
                          {this.state.sampleCTAUrl}
                        </Typography>
                      </pre>
                    </Box>
                  ) : (
                    ""
                  )}
                  {this.state.sampleMediaUrl ? (
                    <Box py={2} px={2} my={2} className={classes.container}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Sample Media URL
                      </Typography>
                      <pre>
                        <Typography variant="h5" paragraph>
                          {this.state.sampleMediaUrl}
                        </Typography>
                      </pre>
                    </Box>
                  ) : (
                    ""
                  )}
                  <Box my={8}></Box>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      </Box>
    );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
    "& pre": {
      whiteSpace: "pre-wrap",
      // whiteSpace: "-moz-pre-wrap",
      // whiteSpace: "-pre-wrap",
      // whiteSpace: "-o-pre-wrap",
      wordWrap: "break-word",
      wordBreak: "keep-all",
    },
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 100,
  },
  container: {
    background: "rgb(237, 226, 252)",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});
const Templateconnect = connect(
  (state) => ({
    templates: state.template?.templates,
    user: state.login.user,
    partner: state.partner.partner,
  }),
  {}
)(TemplateDetails);
export default withStyles(styles)(Templateconnect);
