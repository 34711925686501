import axios from "axios";
import { URL } from "../../config/config";

const initialState = {
  templates: [],
  skip: 0,
  totalprojects: 0,
  isLoading: false,
  error: null,
  page: 0,
  rowsPerPage: 10,
  after: null,
};

const LOAD_START = "TEMPLATE/LOAD_START";
const LOAD_SUCCESS = "TEMPLATE/LOAD_SUCCESS";
const LOAD_FAILURE = "TEMPLATE/LOAD_FAILURE";
const ADD_TEMPLATE = "TEMPLATE/ADD_TEMPLATE";
const CHANGE_PAGE = "TEMPLATE/CHANGE_PAGE";
const EXIT_TEMPLETE = "TEMPLATE/EXIT_TEMPLETE";
const LOAD_MORE_SUCCESS = "TEMPLATE/LOAD_MORE_SUCCESS";
const LOAD_DATE = "LOAD_DATE/TEMPLATES";
const LOAD_APPLIED = "LOAD_APPLIED/TEMPLATES";
const CREATE_TEMPLATE = "TEMPLATES/CREATE_TEMPLATES";

const loadStart = () => ({
  type: LOAD_START,
});

const createTemplate = ({ templates, totalprojects, createdAt }) => ({
  type: CREATE_TEMPLATE,
  payload: { templates, totalprojects, createdAt },
});

const loadDate = ({ fromDate, toDate }) => ({
  type: LOAD_DATE,
  payload: { fromDate, toDate },
});
const loadapplied = ({ applied }) => ({
  type: LOAD_APPLIED,
  payload: applied,
});

const loadSuccess = ({ templates, skip, totalprojects, after }) => ({
  type: LOAD_SUCCESS,
  payload: { templates, skip, totalprojects, after },
});

const loadmoresuccess = ({ templates, newSkip, after }) => ({
  type: LOAD_MORE_SUCCESS,
  payload: { templates, newSkip, after },
});
const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const fetchTemplateMessages =
  (assistantId, filters, type, limit, after) => (dispatch, getState) => {
    const { rowsPerPage } = getState().template;
    const sort = filters.sort;
    const newFilter = { ...filters };

    delete newFilter.sort;
    dispatch(loadStart());
    const mainURL =
      type === "DIRECT"
        ? URL + `/api/direct-api-partner/templates/project/${assistantId}`
        : URL + `/api/template-lists?sort=${sort}`;

    axios
      .post(
        mainURL,
        type === "DIRECT"
          ? { projectId: assistantId, limit, after }
          : {
              skip: 0,
              rowsPerPage,
              assistantId,
              ...newFilter,
            }
      )
      .then((response) => {
        const { templates, newSkip, totalprojects, after } = response.data;
        dispatch(
          loadSuccess({
            templates: templates,
            skip: newSkip,
            totalprojects: totalprojects,
            after,
          })
        );
      })

      .catch((error) => {
        console.error(error);
        dispatch(loadFailure(error));
      });
  };

export const createnewTemplate = (templates) => (dispatch, getState) => {
  const totalprojects = getState().template.totalprojects;
  dispatch(
    createTemplate({
      templates: templates,
      totalprojects: totalprojects,
      createdAt: Date.now(),
    })
  );
};

export const loadSearch =
  (filter, type, assistantId) => (dispatch, getState) => {
    const skip = 0;
    const rowsPerPage = 0;
    dispatch(loadStart);
    axios
      .post(
        URL +
          (type !== "DIRECT"
            ? "/api/template-lists"
            : "/api/direct-api-partner/templates/search"),
        {
          skip,
          rowsPerPage,
          filter,
          assistantId,
        }
      )
      .then((response) => {
        const { templates, newSkip, totalprojects } = response.data;
        dispatch(
          loadSuccess({
            templates: templates,
            newSkip: newSkip,
            totalprojects: totalprojects,
          })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(loadFailure(error));
      });
  };

export const loadNext =
  (event, newPage, assistantId, filters, type, limit, after, totalprojects) =>
  (dispatch, getState) => {
    const { rowsPerPage, skip, templates } = getState().template;
    const sort = filters.sort;
    const newFilter = { ...filters };
    delete newFilter.sort;
    if (templates.length > newPage * rowsPerPage) {
      dispatch({ type: CHANGE_PAGE, payload: newPage });
    } else {
      dispatch({ type: CHANGE_PAGE, payload: newPage });
      const mainURL =
        type === "DIRECT"
          ? URL + `/api/direct-api-partner/templates/project/${assistantId}`
          : URL + `/api/template-lists?sort=${sort}`;
      axios
        .post(
          mainURL,
          type === "DIRECT"
            ? { projectId: assistantId, limit, after, totalprojects }
            : {
                skip,
                rowsPerPage,
                assistantId,
                ...newFilter,
              }
        )
        .then((response) => {
          const { templates, newSkip, after } = response.data;
          dispatch(
            loadmoresuccess({ templates: templates, newSkip: newSkip, after })
          );
        })
        .catch((error) => {
          console.error(error);
          dispatch(loadFailure(error));
        });
    }
  };

export const addTemplateMessage = (template) => (dispatch) => {
  dispatch({ type: ADD_TEMPLATE, payload: template });
};

export const unfetchTemplateMessages = () => (dispatch) => {
  dispatch({ type: EXIT_TEMPLETE, payload: null });
};

export default function TemplateMessageReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        templates: [...payload.templates],
        totalprojects: payload.totalprojects,
        skip: payload.skip,
        assistantId: payload.assistantId,
        isLoading: false,
        error: null,
        after: payload.after,
      };
    case LOAD_DATE:
      return {
        ...state,
        applied: {
          ...state.applied,
          createdAt: {
            ...state.applied.createdAt,
            startDate: payload.fromDate,
            endDate: payload.toDate,
          },
        },
      };

    case LOAD_APPLIED:
      return {
        ...state,
        applied: { ...state.applied, ...payload.applied },
      };

    case CREATE_TEMPLATE:
      const templateList = [...state.templates];
      if (templateList.length > 9) {
        templateList.pop();
      }
      return {
        ...state,
        templates: [
          { ...payload.templates, createdAt: payload.createdAt },
          ...templateList,
        ],
        totalprojects: payload.totalprojects + 1,
      };

    case CHANGE_PAGE:
      return { ...state, page: payload };

    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        templates: [...state.templates, ...payload.templates],
        skip: payload.newSkip,
        isLoading: false,
        assistantId: payload.assistantId,
        after: payload.after,
      };

    case ADD_TEMPLATE:
      return {
        ...state,
        templates: { ...state.templates, [payload.name]: payload },
      };

    case EXIT_TEMPLETE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
