const stepOne = {
  title: "Step 1",
  subTitle: "Enter Your Personal Details",
  keys: {
    name: {
      type: "textfield",
      title: "Your Name",
      isMandatory: true,
      placeholder: "Enter your name here",
    },
    number: {
      type: "textfield",
      title: "Your Personal Phone Number",
      subTitle: "Enter your number with country code",
      isMandatory: true,
      placeholder: "Enter your phone number here",
    },
    email: {
      type: "textfield",
      title: "Your Email Address",
      isMandatory: true,
      placeholder: "Enter your email here",
    },
  },
};

const stepTwo = {
  title: "Step 2",
  subTitle: "Enter Your Business Details",
  keys: {
    entityName: {
      type: "textfield",
      title: "Business Entity Name",
      isMandatory: true,
      placeholder: "Enter your business entity name here",
    },
    website: {
      type: "textfield",
      title: "Website URL",
      isMandatory: true,
      placeholder: "Enter your website here",
    },
    businessVertical: {
      type: "autocomplete",
      title: "Select Business Vertical",
      options: [
        "Ecommerce",
        "Education",
        "Automotive",
        "IT Services",
        "Real Estate",
        "SAAS/Apps",
        "Gaming",
        "Entertainment",
        "Finance and Banking",
        "Medical and Health",
        "Hotel and Lodging",
        "Beauty, Spa and Salon",
        "Clothing and Apparel",
        "Event Planning and Service",
        "Food and Grocery",
        "Professional Services",
        "Public Service",
        "Non-profit",
        "Shopping and Retail",
        "Travel and Transportation",
        "Restaurant",
        "Other",
      ],
      isMandatory: true,
      placeholder: "Please select a business vertical",
    },
    companySize: {
      type: "autocomplete",
      title: "Company Size",
      options: [
        "1 - 10 Employees",
        "10 - 20 Employees",
        "20 - 50 Employees",
        "50 - 200 Employees",
        "200 - 500 Employees",
        "500 +",
      ],
      isMandatory: true,
      placeholder: "Please select company size",
    },
    headquarters: {
      type: "textfield",
      title: "In which country are your Headquarters",
      placeholder: "Enter your headquarters here",
      isMandatory: true,
    },
    addressLine1: {
      type: "textfield",
      title: "Official Address: Address line 1",
      isMandatory: true,
      placeholder: "Enter your address line 1 here",
    },
    addressLine2: {
      type: "textfield",
      title: "Official Address: Address line 2",
      placeholder: "Enter your address line 2 here",
      isMandatory: false,
    },
    city: {
      type: "textfield",
      title: "City",
      placeholder: "Enter your city here",
      isMandatory: true,
    },
    state: {
      type: "textfield",
      title: "State",
      placeholder: "Enter your state here",
      isMandatory: true,
    },
    zipCode: {
      type: "textfield",
      title: "Zip Code",
      isMandatory: true,
      placeholder: "Enter your zip code here",
    },
    country: {
      type: "textfield",
      title: "Country",
      isMandatory: true,
      placeholder: "Enter your country here",
    },
  },
};

const stepThree = {
  title: "Step 3",
  subTitle: "Facebook Business Details",
  keys: {
    facebookPage: {
      type: "textfield",
      title: "Facebook Page URL",
      placeholder: "Enter your facebook page url here",
      isMandatory: true,
    },
    businessManagerId: {
      type: "textfield",
      title: "Your Facebook Business Manager ID",
      subTitle: "Check it from here -",
      hyperlink: "https://business.facebook.com/settings/info",
      placeholder: "Enter your facebook business manager id here",
      isMandatory: true,
    },
    isManagerVerified: {
      type: "checkbox",
      title: "Is your Facebook Business Manager Verified?",
      subTitle: "Check it from here -",
      hyperlink: "https://business.facebook.com/settings/security",
      isMandatory: true,
      options: ["Yes", "No", "No, Start Verification is Faded"],
      placeholder: "Select one option",
    },
  },
};

const stepFour = {
  title: "Step 4",
  subTitle: "Whatsapp Account Details",
  keys: {
    whatsappNumber: {
      type: "textfield",
      title: "Set Phone Number for WhatsApp",
      subTitle:
        "This number will be approved. All WhatsApp accounts from this number should be deleted.",
      isMandatory: true,
      placeholder: "Enter your whatsapp phone number here",
    },
    displayName: {
      type: "textfield",
      title: "Display Name",
      subTitle: "Set Display Name to be shown on WhatsApp",
      isMandatory: true,
      placeholder: "Enter your whatsapp display name here",
    },
  },
};

const stepOneState = {
  name: "",
  number: "",
  email: "",
};

const stepTwoState = {
  entityName: "",
  website: "",
  businessVertical: "",
  companySize: "",
  headquarters: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
};

const stepThreeState = {
  facebookPage: "",
  businessManagerId: "",
  isManagerVerified: "",
};

const stepFourState = {
  whatsappNumber: "",
  displayName: "",
};

export {
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
  stepOneState,
  stepTwoState,
  stepThreeState,
  stepFourState,
};
