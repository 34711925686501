import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Snackbar,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  Close,
  Facebook,
  FiberManualRecord,
  FileCopyOutlined,
  FilterNone,
  Send,
  VisibilityOutlined,
} from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import CallhippoLogo from "../../static/callhippo_logo.png";
import LOGO from "../../static/logo.jpg";
import WhatsAppLogo from "../../static/f0d373c302b5c689e4445a1c3a9691a3.svg";
import dialCodes from "../../config/dialCodes";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      step: 1,
      hasRead: false,
      countryCode: "+91",
      contact: "",
      otp: "",
      otpSent: false,
    };
  }
  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };
  copyCode = () => {
    navigator.clipboard.writeText(`<!-- WhatsApp API Access Code -->
    <script>
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    ment(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    <noscript><img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=255162156131712&ev=PageView&noscript=1"
    /></noscript>
    <!-- WhatsApp API Access Code -->`);
    this.setState({
      alert: true,
      alertSeverity: "success",
      alertMsg: "Copied to clipboard!",
    });
  };
  render() {
    const { classes, match } = this.props;
    const { step, hasRead, countryCode, contact, otpSent, otp } = this.state;
    const assistantId = match.params.projectId;
    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          justify="center"
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs>
            <Box px={2} display="flex" justifyContent="space-between">
              <img
                src={CallhippoLogo}
                alt="partner"
                style={{ maxHeight: 40 }}
              />
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  maxWidth: "45%",
                  textAlign: "center",
                  height: "40px",
                  overflow: "hidden",
                  fontWeight: 600,
                }}
              >
                AiSensy is a verified WhatsApp Solution Provider and has been
                given the right by Facebook/WhatsApp to grant their clients
                access to the WhatsApp Business API.
              </Typography>
              <img src={WhatsAppLogo} alt="whatsapp" style={{ height: 40 }} />
            </Box>
          </Grid>
        </Grid>
        <Box my={8} />
        {/* Layout */}
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Close />}
          onClick={() => {
            this.props.history.push("/config");
          }}
          style={{
            bottom: 40,
            left: 20,
          }}
        >
          Exit Preview
        </Button>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <Box className={classes.container} textAlign="center">
              <Box my={2} />
              <img
                src={CallhippoLogo}
                alt="partner"
                style={{ maxHeight: 60 }}
              />
              <Box my={2} />
              <Typography variant="body1" color="textSecondary">
                {`Step ${step} of 2`}
              </Typography>
              {step === 1 && (
                <>
                  <Box textAlign="left" px={2}>
                    <Box my={4} />
                    <Typography variant="h4" color="primary" gutterBottom>
                      WhatsApp Business API Account Submission
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      Please provide information about the company and phone
                      number that you would like to connect to the WhatsApp
                      Business API. Please make sure that your company follows
                      Facebook's compliance policy.
                    </Typography>

                    <Box my={4} />
                    <Typography variant="h4" color="primary" gutterBottom>
                      PERSONAL INFORMATION
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Full Name*"
                      className={classes.TextField}
                    />
                    <TextField
                      fullWidth
                      placeholder="Email*"
                      className={classes.TextField}
                    />
                    <TextField
                      fullWidth
                      placeholder="Personal Mobile Number*"
                      className={classes.TextField}
                    />

                    <Box my={4} />
                    <Typography variant="h4" color="primary" gutterBottom>
                      BUSINESS INFORMATION
                    </Typography>
                    <TextField
                      fullWidth
                      placeholder="Company Name*"
                      className={classes.TextField}
                    />

                    <Box my={4} />
                    <Typography variant="h5" color="primary" gutterBottom>
                      Terms & Conditions
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasRead}
                          onChange={(e) => {
                            this.setState({
                              hasRead: e.target.checked,
                            });
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Typography color="textSecondary" variant="body2">
                          I agree to the{" "}
                          <u style={{ cursor: "pointer" }}>
                            AiSensy Terms of Service
                          </u>
                        </Typography>
                      }
                    />
                  </Box>
                  <Button
                    size="large"
                    disabled={!hasRead}
                    startIcon={<Facebook />}
                    onClick={() => {
                      let hubUrl = window.location.origin + "/facebook-verify";

                      const redirect = window.open(hubUrl, "_blank");
                      redirect.focus();

                      this.setState({
                        step: 2,
                      });
                    }}
                    style={{
                      margin: "24px 0",
                      fontSize: 18,
                      color: "white",
                      background: hasRead ? "#1877F2" : "lightgrey",
                      fontWeight: 600,
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Connect to Facebook
                  </Button>
                </>
              )}
              {step === 2 && (
                <>
                  <Box textAlign="left" px={2}>
                    <Box my={4} />
                    <Typography variant="h4" color="primary" gutterBottom>
                      Configure WhatsApp Number
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      Please provide information about the company and phone
                      number that you would like to connect to the WhatsApp
                      Business API. Please make sure that your company follows
                      Facebook's compliance policy.
                    </Typography>

                    <Box my={4} />

                    <Box position="relative">
                      <Autocomplete
                        disableClearable
                        options={dialCodes.map((i) => i.dialCode)}
                        onChange={(e, value, reason) => {
                          this.setState({
                            countryCode: value,
                          });
                        }}
                        value={countryCode}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            classes={{ root: classes.codeTextFieldRoot }}
                            InputProps={{ ...params.InputProps }}
                          />
                        )}
                      />

                      <TextField
                        id="contact"
                        placeholder="Mobile Number"
                        type="text"
                        name="contact"
                        value={contact}
                        onChange={(e) => {
                          this.setState({
                            contact: e.target.value,
                          });
                        }}
                        className={classes.phoneTextFieldRoot}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.otpFieldOverlay} />

                  {otpSent && (
                    <>
                      <Grid
                        container
                        style={{ maxWidth: 254, margin: "auto" }}
                        spacing={2}
                      >
                        {[1, 2, 3, 4].map((i) => (
                          <Grid item xs={3}>
                            <Box
                              style={{
                                border: "2px solid lightgrey",
                                borderRadius: 4,
                                height: 50,
                              }}
                            ></Box>
                          </Grid>
                        ))}
                      </Grid>
                      <TextField
                        id="otp"
                        type="text"
                        placeholder="0000"
                        name="otp"
                        className={classes.otpInputField}
                      />

                      <Typography color="textSecondary" variant="body2">
                        Didn't received code?{" "}
                        <u
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              alertMsg: "OTP Resent Successfully!",
                              alert: true,
                              alertSeverity: "success",
                            });
                          }}
                        >
                          Regenerate
                        </u>
                      </Typography>
                    </>
                  )}

                  <Box>
                    <Button
                      size="large"
                      disabled={!hasRead}
                      onClick={() => {
                        this.setState({
                          otpSent: true,
                          step: otpSent ? 3 : 2,
                          alertMsg: "OTP Sent Successfully!",
                          alert: otpSent ? false : true,
                          alertSeverity: "success",
                        });
                      }}
                      style={{
                        margin: "24px 0",
                        // fontSize: 18,
                        // fontWeight: 600,
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {otpSent ? "Submit OTP" : "Verify Phone Number"}
                    </Button>
                  </Box>
                </>
              )}
              {step === 3 && (
                <>
                  <Box textAlign="center" px={2}>
                    <Box my={4} />
                    <Typography variant="h4" color="primary" gutterBottom>
                      Setup Complete
                    </Typography>

                    <Box my={4} />

                    <TextField
                      fullWidth
                      placeholder="Test Mobile Number*"
                      className={classes.TextField}
                    />
                  </Box>
                  <Box>
                    <Button
                      size="large"
                      startIcon={<Send />}
                      disabled={!hasRead}
                      onClick={() => {
                        this.setState({
                          alertMsg: "Message Sent Successfully!",
                          alert: true,
                          alertSeverity: "success",
                        });
                      }}
                      style={{
                        margin: "24px 0",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Send Test Message
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>

        <Box position="fixed" bottom="5px" right="20px" textAlign="center">
          <Typography variant="body2" color="textSecondary">
            Powered⚡ by
          </Typography>
          <Grid container alignItems="center">
            <Grid item>
              <img alt="" src={LOGO} className={classes.logo} />
            </Grid>
            <Grid item>
              <Typography variant="h4">AiSensy</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box my={8} />
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "#e8ece1",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "fixed",
    left: 0,
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 70,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "#0a474c",
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 40px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  TextField: {
    marginTop: 12,
  },
  codeTextFieldRoot: {
    width: "80px",
    padding: "0 0 0 8px",
    position: "absolute",
    zIndex: 1,
    marginTop: 6,
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "75%", marginLeft: "15%" },
  },
  otpInputField: {
    background: "transparent",
    margin: "auto",
    display: "flex",
    bottom: "60px",
    left: "33px",
    maxWidth: "300px",
    "& input": {
      fontSize: "24px",
      letterSpacing: "50px",
    },
  },
  otpFieldOverlay: {
    height: "60px",
    width: "10px",
    background: "white",
    position: "absolute",
    right: "calc(50% - 160px)",
    zIndex: 1,
  },
  logo: {
    height: 40,
  },
});

export default withStyles(styles)(Preview);
