import axios from "axios";

import { URL } from "../config/config";

const initialState = {
  partner: {},
  isLoading: false,
  error: null,
};

const LOAD_START = "PARTNER/LOAD_START";
const LOAD_SUCCESS = "PARTNER/LOAD_SUCCESS";
const LOAD_FAILURE = "PARTNER/LOAD_FAILURE";
const LOAD_SIZE = "PARTNER/LOAD_SIZE";
const UPDATE_PLAN = "PARTNER/UPDATE_PLAN";
const UPDATE_WCC = "PARTNER/UPDATE_WCC";
const UPDATE_ONBOARD = "PARTNER/UPDATE_ONBOARD";
const UPDATE_WHATSPAPP_PAYMENTS_PLAN = "PARTNER/UPDATE_WHATSPAPP_PAYMENTS_PLAN";
const UPDATE_PARTNER_STORE = "PARTNER/UPDATE_PARTNER_STORE";

const updatePartner = (payload) => ({
  type: UPDATE_PARTNER_STORE,
  payload: payload,
});

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = (partner) => ({
  type: LOAD_SUCCESS,
  payload: partner,
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

const updateplanFamily = (payload) => ({
  type: UPDATE_PLAN,
  payload: payload,
});

const addAgent = (payload) => ({
  type: LOAD_SIZE,
  payload: payload,
});

const updateWcc = (payload) => ({
  type: UPDATE_WCC,
  payload: payload,
});

const updateBoarding = (payload) => ({
  type: UPDATE_ONBOARD,
  payload: payload,
});

const updateWhatsappPaymentsPlanEvent = (payload) => ({
  type: UPDATE_WHATSPAPP_PAYMENTS_PLAN,
  payload: payload,
});

export const updatePartnerStore = (payload) => (dispatch) => {
  dispatch(updatePartner(payload));
};

export const updateOnboardMethod = (payload) => (dispatch) => {
  dispatch(updateBoarding(payload));
};

export const addAgentSize = (payload) => (dispatch) => {
  dispatch(addAgent(payload));
};

export const addPlanFamily = (payload) => (dispatch) => {
  dispatch(updateplanFamily(payload));
};

export const updateWhatsappPaymentsPlanFamily = (payload) => (dispatch) => {
  dispatch(updateWhatsappPaymentsPlanEvent(payload));
};

export const updateWccFamily = (payload) => (dispatch) => {
  dispatch(updateWcc(payload));
};

export const fetchPartnerDetails = () => (dispatch, getState) => {
  dispatch(loadStart());
  axios
    .post(URL + "/api/get-profile")
    .then((response) => {
      const partnerData = response.data.data;
      dispatch(loadSuccess({ partnerData }));
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export default function partnerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        partner: { ...payload.partnerData },
        isLoading: false,
        error: null,
      };

    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    case LOAD_SIZE:
      return {
        ...state,
        isLoading: false,
        error: null,
        partner: { ...state.partner, agentSize: payload },
      };

    case UPDATE_PLAN:
      return {
        ...state,
        isLoading: false,
        error: null,
        partner: {
          ...state.partner,
          defaultPlanFamily: payload.defaultPlanFamily,
          defaultPlanName: payload.defaultPlanName,
        },
      };

    case UPDATE_WCC:
      return {
        ...state,
        isLoading: false,
        error: null,
        partner: {
          ...state.partner,
          defaultWccPlan: payload.defaultWccPlan,
        },
      };

    case UPDATE_ONBOARD:
      return {
        ...state,
        isLoading: false,
        error: null,
        partner: {
          ...state.partner,
          onboardingMethod: payload.onboardingMethod,
        },
      };
    case UPDATE_WHATSPAPP_PAYMENTS_PLAN:
      return {
        ...state,
        isLoading: false,
        error: null,
        partner: {
          ...state.partner,
          defaultWhatsappPaymentsPlan: payload.defaultWhatsappPaymentsPlan,
        },
      };
    case UPDATE_PARTNER_STORE:
      return {
        ...state,
        isLoading: false,
        error: null,
        partner: { ...state.partner, ...payload },
      };
    default:
      return state;
  }
}
