import React from "react";
import {
  Box,
  withStyles,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { OpenInNew, Phone } from "@material-ui/icons";
import templateMsgHelper from "../../helpers/templateMsgHelper";
import TouchAppIcon from "@material-ui/icons/TouchApp";

function ClickToAction(props) {
  const { type, payload, classes, theme, preview, selectedTemplate } = props;
  let isQuickReply = false;
  let list = [];

  const ActionButton = ({ type, action, text, selectedTemplate }) => {
    return (
      <>
        <Divider />
        {type === "phone" ? (
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            title={action ?? ""}
            arrow
          >
            <a href={`tel:${action}`}>
              <Box className={classes.actionBox}>
                <Phone className={classes.actionIcon} />
                <div className={classes.actionText}>{text}</div>
              </Box>
            </a>
          </Tooltip>
        ) : (
          <>
            <a
              href={
                payload.templateCTAUrl
                  ? payload.templateCTAUrl.replace(/[\[\]']+/g, "")
                  : action
              }
              target="_blank"
            >
              <Box className={classes.actionBox}>
                <OpenInNew className={classes.actionIcon} />
                <div className={classes.actionText}>{text}</div>
              </Box>
            </a>
            <Box>
              {payload.templateCTAUrl && (
                <div className={classes.arrowTop}>
                  <Box className={classes.arrowTopMessage}>
                    {payload.templateCTAUrl}
                  </Box>
                  {selectedTemplate.isClickTrackingEnabled && (
                    <Box>
                      <div
                        className={classes.smallScreen}
                        style={{
                          display: "flex",
                          marginTop: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <TouchAppIcon
                          style={{ fontSize: "16px" }}
                          color="disabled"
                        />

                        <Typography
                          style={{
                            marginLeft: 2,
                            fontSize: "11px",
                            marginBottom: "-5px",
                          }}
                          variant="body2"
                          color="textSecondary"
                          paragraph
                        >
                          Click tracking is enabled for this template
                        </Typography>
                      </div>
                    </Box>
                  )}
                </div>
              )}
            </Box>
          </>
        )}
      </>
    );
  };

  if (!payload.callToAction || !payload.callToAction.length) {
    if (type === "TEXT") {
      list = (payload.text || "")
        .split(" | [")
        .map((i) => i.trim().substring(0, i.length - 1));
      list.shift();
    }
    if (type === "QUICK_REPLY_CARD") {
      isQuickReply = true;
      list = (payload.options || "").map(
        (i, index) => i.title || `Button_${index}`
      );
    }
    if (["IMAGE", "VIDEO", "FILE"].includes(type)) {
      list = (payload.caption || "")
        .split(" | [")
        .map((i) => i.trim().substring(0, i.length - 1));
      list.shift();
    }
  }

  return payload.callToAction && payload.callToAction.length ? (
    <>
      <Box>
        {payload.callToAction.map((item, index) => {
          const type = item.type === "Phone Number" ? "phone" : "url";
          return !item.buttonTitle ? (
            <Box key={index} />
          ) : (
            <>
              <Box key={index}>
                <ActionButton
                  selectedTemplate={selectedTemplate}
                  type={type}
                  action={item.buttonValue}
                  text={item.buttonTitle}
                />
              </Box>
            </>
          );
        })}
      </Box>
    </>
  ) : (
    <Box className="root">
      {list.map((item, index) => {
        let type = "text";
        let action = "";
        const validNumberPattern = /^\+*\d+$/;
        let text = item.trim();
        const parts = text.split(",");

        if (!isQuickReply && parts.length > 1) {
          const lastBlock = parts.pop();
          text = parts.join(",");
          if (templateMsgHelper.validateUrl(lastBlock.trim())) {
            type = "url";
            action = lastBlock.replace(/\[([^\]]+)\]/g, (match) =>
              match.substring(1, match.length - 1)
            );
          } else if (validNumberPattern.test(lastBlock.trim())) {
            type = "phone";
            action = lastBlock;
          } else {
            text = [...parts, lastBlock].join(",");
          }
        }

        if (text && text[text.length - 1] === ",")
          text = text.substring(0, text.length - 1);

        return !text ? (
          <Box key={index} />
        ) : (
          <Box key={index}>
            <ActionButton
              type={type}
              action={action}
              text={text}
              selectedTemplate={selectedTemplate}
            />
          </Box>
        );
      })}
    </Box>
  );
}

const styles = (theme) => ({
  root: {
    position: "relative",
  },
  actionIcon: {
    fontSize: "16px",
    margin: "0px 4px",
    color: "#4c9edc",
  },
  actionBox: {
    padding: "10px 4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionText: {
    maxWidth: "210px",
    overflow: "hidden",
    whiteSpace: "prewrap",
    textOverflow: "ellipsis",
    color: "#4c9edc",
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: "500",
  },
  arrowTop: {
    position: "absolute",
    bottom: "calc(70px-100%)",
    width: "100%",
    "&:after": {
      content: '""',
      position: "absolute",
      right: "calc(50%  - 15px)",
      top: "8px",
      borderTop: "none",
      borderRight: "15px solid transparent",
      borderLeft: "15px solid transparent",
      borderBottom: "15px solid #f8f8f8",
    },
  },
  arrowTopMessage: {
    wordWrap: "break-word",
    marginTop: "20px",
    padding: "8px 12px",
    boxSizing: "border-box",
    lineBreak: "anywhere",
    background: "#f8f8f8",
    color: "#0000009c",
    borderRadius: "6px",
    textAlign: "center",
    width: "100%",
    fontSize: "14px",
  },
  smallScreen: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px !important",
    },
  },
  ctaURL: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    maxHeight: "60px",
    overflow: "hidden",
  },
  customTooltip: {
    backgroundColor: "rgb(105,105,105)",
    color: "white",
    boxShadow: "none",
    fontSize: "14px",
    zIndex: 100,
  },
  customArrow: {
    color: "rgb(105,105,105)",
  },
});

export default withStyles(styles)(ClickToAction);
