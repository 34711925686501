import axios from "axios";
import { URL } from "../config/config";

const initialState = {
  isLoading: false,
  partnerAgents: {},
  error: null,
  maxAgents: 10,
  maxRoles: 5,
};

const START_FETCH = "Agents/START_FETCH";
const FETCH_SUCCESS = "Agents/FETCH_SUCCESS";
const UPDATE_SUCCESS = "Agents/UPDATE_SUCCESS";
const FETCH_FAILURE = "Agents/FETCH_FAILURE";
const RESET_ERROR = "Agents/RESET_ERROR";
const RESET_FETCH = "Agents/RESET_FETCH";

const startFetch = () => ({
  type: START_FETCH,
});

const fetchSuccess = (partnerAgents) => ({
  type: FETCH_SUCCESS,
  payload: partnerAgents,
});

const fetchFailure = (payload) => ({
  type: FETCH_FAILURE,
  payload: payload,
});

const resetError = () => ({
  type: RESET_ERROR,
});

const resetFetch = () => ({
  type: RESET_FETCH,
});

export const fetchAgents = (partnerId, noLoading) => (dispatch) => {
  dispatch(resetError());
  if (!noLoading) {
    dispatch(startFetch());
  }

  axios
    .post(URL + "/api/fetch-partner-agents", { partnerId })
    .then((res) => {
      const { partnerAgents, maxAgents, maxRoles } = res.data;
      const payload = { partnerAgents: {}, maxAgents, maxRoles };
      partnerAgents.forEach((agent) => {
        payload.partnerAgents[agent._id] = agent;
      });
      dispatch(fetchSuccess(payload));
    })
    .catch((err) => {
      console.error(err);
      dispatch(fetchFailure({ errmsg: "Unable to setup connection" }));
    });
};

export const unfetchAgents = () => (dispatch) => {
  dispatch(resetFetch());
};

export default function AgentsFetchReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case START_FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        partnerAgents: { ...payload.partnerAgents },
        maxAgents: payload.maxAgents,
        maxRoles: payload.maxRoles,
      };
    case UPDATE_SUCCESS:
      const { agentId, filterObj } = payload;
      state.partnerAgents[agentId].filter = filterObj;
      return {
        ...state,
        partnerAgents: { ...state.partnerAgents },
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case RESET_FETCH:
      return { ...initialState };

    default:
      return state;
  }
}
