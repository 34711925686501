import React from "react";
import { Box, withStyles, Tooltip, Typography } from "@material-ui/core";
import { OpenInNew, Phone } from "@material-ui/icons";
import templateMsgHelper from "../../helpers/templateMsgHelper";

function InteractiveAction(props) {
  const { type, payload, classes, theme, preview } = props;
  let isQuickReply = false;
  let list = [];

  if (type === "TEXT") {
    list = (payload.text || "")
      .split(" | [")
      .map((i) => i.trim().substring(0, i.length - 1));
    list.shift();
  }
  if (type === "QUICK_REPLY_CARD") {
    isQuickReply = true;
    list = (payload.options || "").map(
      (i, index) => i.title || `Button_${index}`
    );
  }
  if (["IMAGE", "VIDEO", "FILE"].includes(type)) {
    list = (payload.caption || "")
      .split(" | [")
      .map((i) => i.trim().substring(0, i.length - 1));
    list.shift();
  }

  return (
    <Box className="root">
      {list.map((item, index) => {
        let type = "text";
        let action = "";
        const validNumberPattern = /^\+*\d+$/;
        let text = item.trim();
        const parts = text.split(",");

        if (!isQuickReply && parts.length > 1) {
          const lastBlock = parts.pop();
          text = parts.join(",");
          if (templateMsgHelper.validateUrl(lastBlock.trim())) {
            type = "url";
            action = lastBlock.replace(/\[([^\]]+)\]/g, (match) =>
              match.substring(1, match.length - 1)
            );
          } else if (validNumberPattern.test(lastBlock.trim())) {
            type = "phone";
            action = lastBlock;
          } else {
            text = [...parts, lastBlock].join(",");
          }
        }

        return !text ? (
          <></>
        ) : type === "text" ? (
          <Box
            key={index}
            className={`defaultTheme ${classes.fullWidth} ${
              theme === 1 ? classes.theme1 : classes.theme2
            }`}
          >
            {text}
          </Box>
        ) : type === "phone" ? (
          <Tooltip
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            title={action ?? ""}
            arrow
          >
            <a href={`tel:${action}`}>
              <Box
                key={index}
                className={`defaultTheme ${classes.fullWidth}  ${
                  theme === 1 ? classes.theme1 : classes.theme2
                }`}
                display="flex"
                alignItems="center"
              >
                <Phone className={classes.actionIcon} />
                <div className={classes.actionText}>{text}</div>
              </Box>
            </a>
          </Tooltip>
        ) : (
          <>
            <a
              href={
                payload.templateCTAUrl
                  ? payload.templateCTAUrl.replace(/[\[\]']+/g, "")
                  : action
              }
              target="_blank"
            >
              <Box
                key={index}
                className={`defaultTheme ${classes.fullWidth} ${
                  theme === 1 ? classes.theme1 : classes.theme2
                }`}
                display="flex"
                alignItems="center"
              >
                <OpenInNew className={classes.actionIcon} />
                <div className={classes.actionText}>{text}</div>
                {payload.templateCTAUrl && (
                  <div className={classes.arrowTop}>
                    <Typography className={classes.ctaURL}>
                      {payload.templateCTAUrl}
                    </Typography>
                  </div>
                )}
              </Box>
            </a>
          </>
        );
      })}
    </Box>
  );
}

const styles = (theme) => ({
  actionIcon: {
    fontSize: "24px",
    margin: "2px 4px -2px",
    color: "#4c9edc",
  },
  theme1: {
    background: "white",
  },
  theme2: {
    background: "white",
  },
  fullWidth: {
    width: "100%",
    borderRadius: "15px",
    margin: "0.5em 0.5em 0.5em 0",
    boxSizing: "border-box",
    padding: "4px 8px 6px",
    textAlign: "center",
    color: "#0a474c",
    cursor: "pointer",
    justifyContent: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "#4c9edc",
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: "500",
  },
  actionText: {
    maxWidth: "210px",
    overflow: "hidden",
    whiteSpace: "prewrap",
    textOverflow: "ellipsis",
    color: "#4c9edc",
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: "500",
  },
  arrowTop: {
    marginTop: "20px",
    padding: "8px 12px",
    boxSizing: "border-box",
    lineBreak: "anywhere",
    background: "#edeaea",
    color: "#0000009c",
    borderRadius: "6px",
    textAlign: "center",
    position: "relative",
    fontSize: "14px",
    "&:after": {
      content: '""',
      position: "absolute",
      right: "calc(50%  - 15px)",
      top: "-12px",
      borderTop: "none",
      borderRight: "15px solid transparent",
      borderLeft: "15px solid transparent",
      borderBottom: "15px solid #edeaea",
    },
  },
  ctaURL: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    maxHeight: "60px",
    overflow: "hidden",
  },
  actionIcon: {
    fontSize: "16px",
    margin: "0px 4px",
    color: "#4c9edc",
  },
  actionBox: {
    padding: "10px 4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  customTooltip: {
    backgroundColor: "rgb(105,105,105)",
    color: "white",
    boxShadow: "none",
    fontSize: "14px",
    zIndex: 100,
  },
  customArrow: {
    color: "rgb(105,105,105)",
  },
});

export default withStyles(styles)(InteractiveAction);
