import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Snackbar,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import QontoStepIcon from "../../../commons/Stepper/QontoStepIcon";
import QontoConnector from "../../../commons/Stepper/QontoConnector";

const STEPS = ["Pricing Details", "Create Plan"];

class CreateWhatsappPaymentsPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerSlabs: [
        ...JSON.parse(JSON.stringify(this.props.aisensySlabs || [])),
      ],
      name: "",
      activeStep: 0,
    };
  }

  setStep = (value) => {
    this.setState({ activeStep: value });
  };

  render() {
    const {
      classes,
      aisensySlabs,
      convertPrice,
      openDialog,
      toggleDialog,
      currency,
      disableFields,
      createPlanFamily,
    } = this.props;

    const { partnerSlabs, name } = this.state;

    return partnerSlabs.length <= 0 ? (
      <CircularProgress />
    ) : (
      <>
        <Dialog
          open={openDialog}
          closeDialog={toggleDialog}
          maxWidth={"sm"}
          fullWidth
        >
          <DialogTitle>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">
                Create WhatsApp Payments Plan Family
              </Typography>
              <IconButton
                color="primary"
                size="small"
                variant="filled"
                onClick={toggleDialog}
              >
                <Clear
                  style={{
                    position: "fixed",
                  }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          <Grid item xs={12} alignItems="center">
            <Stepper
              alternativeLabel
              activeStep={this.state.activeStep}
              connector={<QontoConnector />}
            >
              {STEPS.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Box
            style={{ padding: "8px 16px 16px 24px" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {this.state.activeStep ? (
              <DialogContent>
                <Typography
                  variant="h3"
                  style={{
                    marginBottom: "16px",
                  }}
                  color="primary"
                >
                  Plan Family Name
                </Typography>
                <Tooltip
                  enterTouchDelay={0}
                  style={{ fontSize: "1.2em", width: "100%" }}
                  title="Your family name must be unique."
                >
                  <TextField
                    id="name"
                    placeholder="Your plan family name here"
                    variant="outlined"
                    type="text"
                    name="display_name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                    className={this.props.classes.textFieldRoot}
                  />
                </Tooltip>
              </DialogContent>
            ) : (
              <DialogContent>
                <Grid
                  container
                  alignItems="center"
                  className={classes.content}
                  spacing={0}
                >
                  <Grid container>
                    <Grid item md={3}></Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Typography
                        required
                        className={classes.typo}
                        variant="h5"
                        align="center"
                      >
                        AiSensy Prices
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Typography
                        required
                        className={classes.typo}
                        variant="h5"
                        align="center"
                      >
                        Margin
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Typography
                        required
                        className={classes.typo}
                        variant="h5"
                        align="center"
                      >
                        Your Platform Prices
                      </Typography>
                    </Grid>
                  </Grid>

                  {aisensySlabs?.map((slab, idx) => {
                    const { startAmount, INR } = slab;
                    return (
                      <Grid container>
                        <Grid item xs={12} md={3} container alignItems="center">
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="left"
                          >
                            Slab {idx + 1}
                            <br />
                            <span
                              style={{
                                color: "gray",
                                fontWeight: 500,
                                letterSpacing: 0.5,
                              }}
                            >
                              ₹{startAmount / convertPrice} {" - "}
                              {partnerSlabs?.[idx + 1]?.startAmount
                                ? `₹${
                                    partnerSlabs?.[idx + 1].startAmount /
                                      convertPrice -
                                    1
                                  }`
                                : "beyond"}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} container alignItems="center">
                          <TextField
                            style={{
                              width: "100%",
                              padding: "3px 0px",
                              marginRight: 10,
                            }}
                            required
                            type="Number"
                            variant="outlined"
                            disabled="true"
                            placeholder="0"
                            className={classes.input}
                            error={this.state.displayNameError}
                            margin="dense"
                            value={INR?.fee / convertPrice}
                            InputProps={{ startAdornment: "₹" }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} container alignItems="center">
                          <TextField
                            style={{
                              width: "100%",
                              padding: "3px 0px",
                              marginRight: 10,
                            }}
                            required
                            type="Number"
                            variant="outlined"
                            placeholder="0"
                            disabled={disableFields}
                            onChange={(e) => {
                              let value = Number(e.target.value) * 100000 ?? 0;
                              if (value < 0) value = 0;
                              partnerSlabs[idx].INR.fee = value + INR.fee;

                              this.setState({ partnerSlabs });
                            }}
                            className={classes.input}
                            error={this.state.displayNameError}
                            margin="dense"
                            InputProps={{
                              startAdornment: "₹",
                              inputProps: { min: 0 },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} container alignItems="center">
                          <TextField
                            style={{ width: "100%", padding: "3px 0px" }}
                            required
                            type="Number"
                            variant="outlined"
                            placeholder="0"
                            disabled="true"
                            className={classes.input}
                            error={this.state.displayNameError}
                            margin="dense"
                            value={partnerSlabs[idx].INR.fee / convertPrice}
                            InputProps={{
                              startAdornment: "₹",
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </DialogContent>
            )}

            <DialogActions style={{ padding: "24px 16px 8px 0px" }}>
              <Button
                variant={!this.props.disableFields ? "outlined" : "contained"}
                size="medium"
                color="primary"
                onClick={() => {
                  if (this.state.activeStep) this.setStep(0);
                  else toggleDialog();
                }}
              >
                {this.state.activeStep ? "Back" : "Cancel"}
              </Button>
              {!this.props.disableFields && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={this.state.activeStep && !this.state.name}
                  onClick={() =>
                    this.state.activeStep
                      ? createPlanFamily({
                          name,
                          slabs: partnerSlabs,
                        })
                      : this.setStep(1)
                  }
                >
                  {!this.state.activeStep ? "Next" : "Create"}
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default CreateWhatsappPaymentsPlan;
