import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  Tooltip,
  CircularProgress,
  Link,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Chip,
  Snackbar,
} from "@material-ui/core";
import { Autocomplete, Alert } from "@material-ui/lab";
import { AddOutlined, Clear, Info } from "@material-ui/icons";
import axios from "axios";
import { fetchBusiness } from "../../store/businessState";
import LANGUAGES from "../../config/languages";
import templateMsgHelper from "../../helpers/templateMsgHelper";

import { fetchProject } from "../../store/projectState";
import { createnewTemplate } from "./templateState";
import Switch from "@material-ui/core/Switch";
import { URL, projectURL } from "../../config/config";
import TemplateMessagePreview from "../../commons/TemplateMessagePreview/templateMessagePreview";
import LockIcon from "@material-ui/icons/Lock";
import dialCodes, { isoMap, countryMap } from "../../config/dialCodes";
const TEMPLATE_CATEGORIES = ["UTILITY", "MARKETING", "AUTHENTICATION"];

const MSG_TYPES = [
  {
    type: "TEXT",
    name: "Text",
  },
  {
    type: "IMAGE",
    name: "Image",
  },
  {
    type: "VIDEO",
    name: "Video",
  },
  {
    type: "FILE",
    name: "Document",
  },
  {
    type: "LOCATION",
    name: "Location",
  },
];

class CreateTemplateMsgForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionType: "None",
      callToAction: [],
      quickReplies: [],
      templateLabel: "",
      templateCategory: "",
      templateType: "",
      templateLanguage: "",
      templateName: "",
      templateFormat: "",
      templateSample: "",
      templateMediaURL: "",
      templateCTAUrl: "",
      templateHeader: "",
      templateFooter: "",
      isSubmitting: false,
      err: "",
      success: false,
      ctaUrlParam: 0,
      isClickTrackingEnabled: false,
      parameters: [],
      draftId: "",
      cursor: 0,
      snackbar: {
        open: false,
        message: "",
      },
      alertMsg: "",
      alertType: "",
    };
    this.inputRef = React.createRef();
  }

  componentDidMount = () => {
    const { inDialog, template } = this.props;

    if (template) {
      let params = [];
      const regx = /{{([^}]+)}}/g;
      let curMatch;

      const format = template.format.split(" | ")[0];
      if (template.templateParams) params = template.templateParams;
      else
        while ((curMatch = regx.exec(format))) {
          params.push("");
        }

      for (let i = 0; i < template.callToAction.length; i++) {
        if (template.callToAction[i].type === "Phone Number") {
          //if ext exists update phone val
          if (template.callToAction[i].extension) {
            const ext = template.callToAction[i].extension.length;
            const len = template.callToAction[i].buttonValue.length;
            template.callToAction[i].buttonValue = template.callToAction[
              i
            ].buttonValue.substring(ext, len);
          }
          //set ext if possible
          else if (template.callToAction[i].buttonValue.length > 10) {
            const len = template.callToAction[i].buttonValue.length;
            template.callToAction[i].extension = template.callToAction[
              i
            ].buttonValue.substring(0, len - 10);

            template.callToAction[i].buttonValue = template.callToAction[
              i
            ].buttonValue.substring(len - 10, len);
          }
        }
      }
      //Later: templateCTAUrl sampleCTAUrl
      const action = template.callToAction.find((i) => i.type === "URL");
      const ctaUrlParam = action
        ? (action.buttonValue.match(/\{\{[0-9]+\}\}/g) || []).length
        : 0;

      this.setState({
        templateLabel: template.label,
        templateCategory: template.category,
        templateType: template.type,
        templateLanguage: template.language || template.templateLanguage,
        templateName: template.name,
        templateFormat: format,
        templateSample: template.sampleMessage,
        actionType: template.actionType,
        callToAction: template.callToAction,
        quickReplies: template.quickReplies,
        templateMediaURL: template.templateMediaURL,
        templateCTAUrl: action ? action.buttonValue : "",
        parameters: params,
        templateHeader: template.headerText ?? "",
        templateFooter: template.footerText ?? "",
        draftId: template._id,
        ctaUrlParam,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.templateFormat !== this.state.templateFormat) {
      const cursor = this.state.cursor;
      this.inputRef.current.setSelectionRange(cursor, cursor);
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  extractParams = (e) => {
    const prev = this.state.parameters;
    const params = [];
    const regx = /}}([0-9}]+){{/g;
    let curMatch;
    let format = e.target.value;
    let rev = format.split("").reverse().join("");

    while ((curMatch = regx.exec(rev))) {
      params.push(curMatch[0]);
    }

    //enforce sequence
    for (let i = 0; i < params.length; i++) {
      const revNum = `${i + 1}`.split("").reverse().join("");
      rev = rev.replace(params[params.length - i - 1], `}}${revNum}{{`);
    }

    format = rev.split("").reverse().join("");
    this.setState({
      templateFormat: format,
      parameters: params.map((e, idx) => (idx < prev.length ? prev[idx] : "")),
      cursor: e.target.selectionStart,
    });
  };

  setSampleURL = (e) => {
    const params = [];
    const regx = /{{([0-9}]+)}}/g;
    let curMatch;
    let hasCurly = false;
    while ((curMatch = regx.exec(this.state.templateCTAUrl))) {
      hasCurly = true;
    }

    const formattedURL = ((string, replacement) => {
      return string.replace(
        hasCurly ? /{{(\d+)}}/g : /\[.*?\]/g,
        function (match, g1) {
          return `[${replacement}]`;
        }
      );
    })(this.state.templateCTAUrl, e.target.value);

    this.setState({ templateCTAUrl: formattedURL });
  };

  handleParamsInput = (index, elem) => {
    const params = this.state.parameters;
    params[index] = elem.target.value;

    this.setState({
      parameters: params,
    });
  };

  handleAutoComplete = (fieldName, value, reason) => {
    if (reason === "select-option") {
      this.setState({ [fieldName]: value });
    }
  };

  handleRadioInput = (e) => {
    this.setState({
      actionType: e.target.value,
      callToAction:
        e.target.value === "CAT"
          ? [{ type: "", buttonValue: "", buttonTitle: "" }]
          : [],
      quickReplies: e.target.value !== "CAT" ? [""] : [],
      ctaUrlParam: 0,
      templateCTAUrl: "",
      isClickTrackingEnabled: false,
    });
  };

  catAutoComplete = (index, value, reason) => {
    if (reason === "select-option") {
      const callToAction = [...this.state.callToAction];
      callToAction[index]["type"] = value;
      if (value === "URL") {
        this.setState({
          ctaUrlParam: (
            callToAction[index]["buttonValue"].match(/\{\{[0-9]+\}\}/g) || []
          ).length,
        });
      } else if (index === 0) {
        this.setState({
          ctaUrlParam: 0,
          templateCTAUrl: "",
        });
      }
      this.setState({ callToAction });
    }
  };

  catInput = (index, elem) => {
    const callToAction = [...this.state.callToAction];
    const value = elem.target.value;
    callToAction[index][elem.target.name] = value;
    if (
      elem.target.name === "buttonValue" &&
      callToAction[index].type === "URL"
    ) {
      const params = [];
      const regx = /{{([0-9}]+)}}/g;
      let curMatch;
      let format = elem.target.value;

      while ((curMatch = regx.exec(format))) {
        params.push(curMatch[0]);
      }

      //enforce sequence
      for (let i = 0; i < params.length; i++) {
        format = format.replace(params[i], `{{${i + 1}}}`);
      }

      callToAction[index].buttonValue = format;

      this.setState({
        ctaUrlParam: (value.match(/\{\{[0-9]+\}\}/g) || []).length,
        //templateCTAUrl: format,
      });
    }
    this.setState({ callToAction });
  };

  quickReplyInput = (index, elem) => {
    const quickReplies = [...this.state.quickReplies];
    quickReplies[index] = elem.target.value;

    this.setState({ quickReplies });
  };

  addQuickReply = (index, elem) => {
    const quickReplies = [...this.state.quickReplies, ""];
    this.setState({ quickReplies });
  };

  removeQuickReply = (index, elem) => {
    let quickReplies = [...this.state.quickReplies];
    quickReplies = quickReplies.filter((e, i) => i !== index);
    this.setState({ quickReplies });
  };

  handleChange = (event) => {
    this.setState({
      ...this.state.isClickTrackingEnabled,
      [event.target.name]: event.target.checked,
    });
  };

  addCAT = () => {
    const newAction = { type: "", buttonValue: "", buttonTitle: "" };
    const callToAction = this.state.callToAction;
    this.setState({ callToAction: [...callToAction, newAction] });
  };

  removeCAT = (index) => {
    let callToAction = [...this.state.callToAction];
    let ctaUrlParam = this.state.ctaUrlParam;
    if (callToAction[index].type === "URL") ctaUrlParam = 0;
    callToAction = callToAction.filter((e, i) => i !== index);
    this.setState({ callToAction, templateCTAUrl: "", ctaUrlParam });
  };

  validateName = (e) => {
    let name = e.target.value;
    const snackbar = { message: "", open: false };
    if (name) {
      const lastChar = name[name.length - 1];
      const isAlphanumeric = (string) => /[^A-Za-z0-9_]/.test(string);
      if (isAlphanumeric(lastChar)) {
        snackbar.message =
          "Only lower case alphabets, numbers and underscore is allowed for this field";
        snackbar.open = true;
        name = name.substring(0, name.length - 1);
      }
    }
    this.setState({ templateName: name.toLowerCase(), snackbar });
  };

  validateTemplate = () => {
    const t = this.state;
    const validBracePattern = /\{\{[0-9]+\}\}/g;
    const validTextPattern = /^[a-zA-Z0-9].*$/; // string starting with alphabet & non-empty
    const buttonTextPattern = /^(?!\s*$).{0,25}$/; // string with atleast non-space character, maxlength 25
    const alphanumericPattern = /^[a-z0-9_][a-z0-9_]*$/; // string starting with alphanumeric & non-empty
    const validNumberPattern = /^\d+$/;

    if (!TEMPLATE_CATEGORIES.some((e) => e === t.templateCategory)) {
      return { valid: false, msg: "Select template category" };
    }
    if (!MSG_TYPES.some((e) => e.type === t.templateType)) {
      return { valid: false, msg: "Select template type" };
    }
    if (!LANGUAGES.some((e) => e === t.templateLanguage)) {
      return { valid: false, msg: "Select template language" };
    }
    if (!alphanumericPattern.test(t.templateName)) {
      return {
        valid: false,
        msg: "Template name is required field, only alphanumeric characters & underscores without spaces are allowed.",
      };
    }

    // check header for length
    if (t.templateHeader) {
      if (t.templateHeader.length > 60) {
        return {
          valid: false,
          msg: "Template header length over 60 characters.",
        };
      }
      //check if header contains params
      if (t.templateHeader.match(validBracePattern)) {
        return {
          valid: false,
          msg: "Template header cannot contain paramters",
        };
      }
    }

    //check footer doesn't exceed char limit
    if (t.templateFooter) {
      if (t.templateFooter.length > 60) {
        return {
          valid: false,
          msg: "Template footer length over 60 characters.",
        };
      }
      //check if footer contains params
      if (t.templateFooter.match(validBracePattern)) {
        return {
          valid: false,
          msg: "Template footer cannot contain paramters",
        };
      }
    }
    if (!t.templateFormat) {
      return { valid: false, msg: "Template format is required field" };
    }

    const result = templateMsgHelper.validateFormat(t.templateFormat, "format");
    if (!result.valid) {
      return result;
    }

    for (const params of t.parameters) {
      if (!params)
        return {
          valid: false,
          msg: "Please provide sample values for all the paramters",
        };
    }

    if (t.templateType !== "TEXT" && t.templateType !== "LOCATION") {
      if (!t.templateMediaURL) {
        return {
          valid: false,
          msg: `Sample media URL is required for template type ${t.templateType}`,
        };
      }
      if (!templateMsgHelper.validateUrl(t.templateMediaURL)) {
        return {
          valid: false,
          msg: `Invalid Sample media URL`,
        };
      }
    }

    // validate interactive actions
    if (t.templateName === "shopify_cod_order_confirmation") {
      if (t.quickReplies.length !== 2) {
        return { valid: false, msg: "Need two quick reply" };
      }
    }
    if (t.actionType === "QuickReplies") {
      if (t.quickReplies.length === 0) {
        return { valid: false, msg: "Need atleast one quick reply" };
      }
      const isNotValid = t.quickReplies.some((e) => !buttonTextPattern.test(e)); // check for empty quick reply
      if (isNotValid) {
        return {
          valid: false,
          msg: "Quick replies must be non-empty & max 25 characters with spaces are allowed.",
        };
      }
      const uniqueSet = new Set(t.quickReplies.map((i) => i.trim()));
      if (t.quickReplies.length !== uniqueSet.size)
        return {
          valid: false,
          msg: "Each Quick Reply button must have unique text.",
        };
    }
    if (t.actionType === "CAT") {
      const uniqueSet = new Set(
        t.callToAction.map((i) => i.buttonTitle.trim())
      );
      if (t.callToAction.length !== uniqueSet.size)
        return {
          valid: false,
          msg: "Each Call-To-Action button must have unique text.",
        };
      if (this.state.ctaUrlParam > 1) {
        return { valid: false, msg: "Only one param allowed in CTA URL" };
      }
      if (t.callToAction.length === 0) {
        return { valid: false, msg: "Need atleast one call to action button" };
      }
      const isNotValid = t.callToAction.some((e) => {
        if (e.type === "Phone Number") {
          return (
            !validTextPattern.test(e.type) ||
            !buttonTextPattern.test(e.buttonTitle) ||
            !validNumberPattern.test(e.buttonValue)
          );
        }
        if (e.type === "URL") {
          return (
            !validTextPattern.test(e.type) ||
            !buttonTextPattern.test(e.buttonTitle) ||
            !templateMsgHelper.validateUrl(e.buttonValue)
          );
        }
        return true;
      });
      if (isNotValid) {
        return { valid: false, msg: "Call to action field invalid" };
      }
      const interativeURLAction = t.callToAction.find((e) => e.type === "URL");
      if (interativeURLAction && this.state.ctaUrlParam > 0) {
        if (!t.templateCTAUrl) {
          return {
            valid: false,
            msg: "Sample CTA URL is necessery for URL type actions.",
          };
        }
        if (!templateMsgHelper.validateUrl(t.templateCTAUrl)) {
          return {
            valid: false,
            msg: "Invalid Sample CTA URL.",
          };
        }
        const compare = templateMsgHelper.compareFormatAndSample(
          interativeURLAction.buttonValue,
          t.templateCTAUrl
        );
        if (!compare.valid) {
          return {
            valid: false,
            msg: "Sample action URL doesn't match with template URL format",
          };
        }
        const bodyParamCount = (
          this.state.templateFormat.match(/\{\{[0-9]+\}\}/g) || []
        ).length;
        const ctaDynamicParam =
          interativeURLAction.buttonValue.match(/\{\{[0-9]+\}\}/g) || [];
        if (ctaDynamicParam.length) {
          if (!ctaDynamicParam[0].includes(1)) {
            return {
              valid: false,
              msg: "Invalid parameter in dynamic CTA URL",
            };
          }
        }
      }
    }
    // return { valid: true, msg: "All fields are validated" };
    return { valid: true, msg: "All fields are validated" };
  };

  autoFillAndSubmit = () => {
    //  Fill sample CTA and Media URLS
    const { type } = this.props.partner || {};
    const { templateType, callToAction, ctaUrlParam } = this.state;
    const interativeURLAction = callToAction.find((e) => e.type === "URL");

    const sampleMediaUrl =
      templateType === "IMAGE"
        ? "https://aisensy-demo-media.s3.ap-south-1.amazonaws.com/demo-image.png"
        : templateType === "VIDEO"
        ? "https://aisensy-demo-media.s3.ap-south-1.amazonaws.com/demo-video.mp4"
        : templateType === "FILE"
        ? "https://aisensy-demo-media.s3.ap-south-1.amazonaws.com/demo-doc.pdf"
        : "";

    const sampleCTAUrl =
      interativeURLAction && ctaUrlParam > 0
        ? interativeURLAction.buttonValue.replace(
            /\{\{[0-9]+\}\}/g,
            (match) => "[events]"
          )
        : "";

    this.setState(
      {
        templateMediaURL: sampleMediaUrl,
        templateCTAUrl: sampleCTAUrl,
      },
      type !== "DIRECT" ? this.onSubmit : this.createTemplateFromDirectAPI
    );
  };

  generateTemplate = () => {
    let formattedString = this.state.templateFormat;
    const formattedSampleMsg = ((string, replacementArray) => {
      return string.replace(/{{(\d+)}}/g, function (match, g1) {
        return `[${replacementArray[g1 - 1]}]`;
      });
    })(this.state.templateFormat, this.state.parameters);

    for (const reply of this.state.quickReplies) {
      formattedString += ` | [${reply}]`;
    }

    for (const action of this.state.callToAction) {
      formattedString += ` | [${action.buttonTitle},${action.buttonValue}]`;
    }
    return {
      assistantId: this.props.match.params.projectId,
      label: this.state.templateName,
      category: this.state.templateCategory,
      type: this.state.templateType,
      language: this.state.templateLanguage,
      name: this.state.templateName,
      format: formattedString,
      sampleMessage: formattedSampleMsg,
      actionType: this.state.actionType,
      callToAction: this.state.callToAction,
      quickReplies: this.state.quickReplies,
      sampleMediaUrl: this.state.templateMediaURL,
      sampleCTAUrl: this.state.templateCTAUrl,
      headerText: this.state.templateHeader,
      footerText: this.state.templateFooter,
    };
  };

  onSubmit = () => {
    const assistantId = this.props.match.params.projectId;
    const result = this.validateTemplate();
    const { onComplete } = this.props;

    if (!result.valid) {
      this.setState({ success: false, err: result.msg });
      return;
    } else {
      const t = this.state;
      this.setState({ err: "", isSubmitting: true, success: false });
      const template = {
        ...this.generateTemplate(),
        format: t.templateFormat,
        callToAction: t.callToAction.sort((a, b) => (a.type < b.type ? -1 : 1)),
        sampleCTAUrl: t.ctaUrlParam > 0 ? t.templateCTAUrl : "",
        isClickTrackingEnabled: t.isClickTrackingEnabled,
      };
      // Format call to action btn
      const callToActionBtns = JSON.parse(
        JSON.stringify(template.callToAction)
      );
      for (let i = 0; i < callToActionBtns.length; i++) {
        if (
          callToActionBtns[i].type === "Phone Number" &&
          callToActionBtns[i].extension
        ) {
          callToActionBtns[i].buttonValue =
            callToActionBtns[i].extension + callToActionBtns[i].buttonValue;
        }
      }

      template.callToAction = callToActionBtns;
      const interativeURLAction = callToActionBtns.find(
        (e) => e.type === "URL"
      );
      const payload = {
        label: template.label,
        category: template.category,
        type: template.type,
        language: template.language,
        name: template.name,
        text: template.format,
        sample_text: template.sampleMessage,
        message_action_type: template.actionType,
        call_to_action: template.callToAction,
        quick_replies: template.quickReplies,
        headerText: this.state.templateHeader,
        footerText: this.state.templateFooter,
      };
      axios
        .post(projectURL + `/project/${assistantId}/wa_template`, payload)
        .then((response) => {
          const payload = {
            ...response.data,
            assistantName: response.data.assistant_name,
            _id: response.data.id,
          };
          this.props.createnewTemplate(payload);
          this.setState({
            err: "",
            isSubmitting: false,
            success: true,
            templateCTAUrl: interativeURLAction
              ? interativeURLAction.buttonValue
              : "",
          });
          this.props.callSnackbar("success", "Template submit successful");
          this.props.close();
          typeof onComplete === "function" &&
            onComplete(this.state.templateName, this.state.templateType);
        })
        .catch((error) => {
          console.log(error?.response?.data?.errorMessage);
          this.setState({
            err: error?.response?.data?.errorMessage,
            isSubmitting: false,
            success: false,
            templateCTAUrl: interativeURLAction
              ? interativeURLAction.buttonValue
              : "",

            alertMsg:
              error?.response?.data?.errorMessage ||
              "Something went wrong, try again",
            alertType: "error",
          });
        });
    }
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  createTemplateFromDirectAPI = async () => {
    //Validate Template
    const result = this.validateTemplate();
    if (!result.valid) {
      this.setState({ success: false, err: result.msg });
      return;
    }
    //Find template CTA Url
    const { onComplete } = this.props;
    const interativeURLAction = (this.state.callToAction || []).find(
      (e) => e.type === "URL"
    );

    try {
      const assistantId = this.props.match.params.projectId;
      const { templateName, templateLanguage, templateCategory, callToAction } =
        this.state;

      //Provide correct country code extension to the button
      const callToActionBtns = JSON.parse(JSON.stringify(callToAction));

      for (let i = 0; i < callToActionBtns.length; i++) {
        if (
          callToActionBtns[i].type === "Phone Number" &&
          callToActionBtns[i].extension
        ) {
          callToActionBtns[i].buttonValue =
            callToActionBtns[i].extension + callToActionBtns[i].buttonValue;
        }
      }

      //obtains components based on given data
      const components = templateMsgHelper.generateTemplateComponents({
        ...this.state,
        callToAction: callToActionBtns,
      });

      const response = await axios.post(
        URL + `/api/direct-api-partner/templates`,
        {
          projectId: assistantId,
          name: templateName,
          language: templateLanguage,
          category: templateCategory,
          components,
        }
      );

      if (response.status === 200) {
        this.props.createnewTemplate({ ...response.data, templateLanguage });
        this.setState({
          err: "",
          isSubmitting: false,
          success: true,
          templateCTAUrl: interativeURLAction
            ? interativeURLAction.buttonValue
            : "",
        });
        this.props.callSnackbar("success", "Template submit successful");
        this.props.close();
        typeof onComplete === "function" &&
          onComplete(this.state.templateName, this.state.templateType);
      }
    } catch (error) {
      console.log(error?.response?.data?.errorMessage);
      this.setState({
        err: error?.response?.data?.errorMessage,
        isSubmitting: false,
        success: false,
        templateCTAUrl: interativeURLAction
          ? interativeURLAction.buttonValue
          : "",

        alertMsg:
          error?.response?.data?.errorMessage ||
          "Something went wrong, try again",
        alertType: "error",
      });
    }
  };

  render() {
    const {
      classes,
      inDialog,
      template,
      showDialog,
      toggleDialog,
      isSubmitted,
    } = this.props;

    const { projectId } = this.props.match.params;
    const interativeURLAction = this.state.callToAction.find(
      (e) => e.type === "URL"
    );
    return (
      <Box
        className={classes.root}
        py={{ xs: 2, md: inDialog ? 0 : 8 }}
        px={{ xs: 2, md: inDialog ? 2 : 6 }}
      >
        <Box my={3}>
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Typography variant="h4" gutterBottom>
                  Template Category
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Your template should fall under one of these categories.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  options={TEMPLATE_CATEGORIES.map((option) => option)}
                  onChange={(e, value, reason) =>
                    this.handleAutoComplete("templateCategory", value, reason)
                  }
                  value={this.state.templateCategory}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      InputProps={{ ...params.InputProps }}
                      placeholder="Select message categories"
                      name="categories"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Typography variant="h4" gutterBottom>
                  Template Language
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  You will need to specify the language in which message
                  template is submitted.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  options={LANGUAGES.map((option) => option)}
                  onChange={(e, value, reason) =>
                    this.handleAutoComplete("templateLanguage", value, reason)
                  }
                  value={this.state.templateLanguage}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      InputProps={{ ...params.InputProps }}
                      placeholder="Select message language"
                      name="language"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={6} justify="space-between">
          <Grid item xs={12} md={6} container>
            {/* template name*/}
            <Box width="100%">
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Typography variant="h4" gutterBottom>
                  Template Name
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Name can only be in lowercase alphanumeric characters and
                  underscores. Special characters and white-space are not
                  allowed
                  <br /> e.g. - app_verification_code
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="templateName"
                  className={classes.textArea}
                  placeholder="Enter name"
                  onChange={this.validateName}
                  value={this.state.templateName}
                  disabled={inDialog}
                />
              </Grid>
            </Box>
            {/* HEADER */}
            <Box my={6} width="100%">
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Typography variant="h4" gutterBottom>
                  Template Type
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Your template type should fall under one of these categories.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  options={MSG_TYPES.map((option) => option.type)}
                  onChange={(e, value, reason) => {
                    this.setState({
                      templateMediaURL: "",
                      templateHeader: "",
                    });
                    this.handleAutoComplete("templateType", value, reason);
                  }}
                  value={this.state.templateType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      InputProps={{ ...params.InputProps }}
                      placeholder="Select message type"
                    />
                  )}
                />
                {this.state.templateType === "TEXT" && (
                  <Box mt={6}>
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom>
                        Template Header Text{" "}
                        <span className={classes.optional}>(Optional)</span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Header text is optional and only upto 60 characters are
                        allowed.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="templateHeader"
                        className={classes.textArea}
                        placeholder="Enter header text here"
                        inputProps={{ maxLength: 60 }}
                        FormHelperTextProps={{
                          className: classes.characterCounter,
                        }}
                        helperText={
                          this.state.templateHeader &&
                          `${this.state.templateHeader.length}/60`
                        }
                        onChange={this.handleInput}
                        value={this.state.templateHeader}
                        disabled={inDialog}
                        style={{ marginTop: "8px" }}
                      />
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Box>
            {/* body  */}
            <Box mb={6} width="100%">
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: "8px" }}>
                  <Typography variant="h4" gutterBottom>
                    Template Format
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Use text formatting - *bold* , _italic_ & ~strikethrough~
                    <br />
                    Your message content. Upto 1024 characters are allowed.
                    <br /> e.g. - Hello {`{{1}}`}, your code will expire in{" "}
                    {`{{2}}`} mins.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    inputRef={this.inputRef}
                    multiline
                    rows={2}
                    rowsMax={20}
                    name="templateFormat"
                    className={classes.textArea}
                    placeholder="Enter your message in here..."
                    disabled={false}
                    value={this.state.templateFormat}
                    onChange={this.extractParams}
                    inputProps={{ maxLength: 1024 }}
                    FormHelperTextProps={{
                      className: classes.characterCounter,
                    }}
                    helperText={`${this.state.templateFormat.length}/1024`}
                  />
                </Grid>
              </Grid>
            </Box>

            {this.state.parameters.length > 0 && (
              <Box mb={6}>
                <Grid item xs={12} style={{ marginBottom: "8px" }}>
                  <Typography variant="h4" gutterBottom>
                    Sample Values
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Specify sample values for your parameters. These values can
                    be changed at the time of sending.
                    <br /> e.g. - {`{{1}}`}: Mohit, {`{{2}}`}: 5.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    {this.state.parameters.map((param, index) => (
                      <>
                        <Grid item xs={2} key={index + "_1"}>
                          <Typography variant="h5">
                            {` {{${index + 1}}} `}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{ marginBottom: 8 }}
                          key={index + "_2"}
                        >
                          <TextField
                            className={classes.textArea}
                            placeholder="Sample value"
                            name={index}
                            value={this.state.parameters[index]}
                            onChange={(e) => this.handleParamsInput(index, e)}
                          />
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* Footer */}

            <Box width="100%">
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Typography variant="h4" gutterBottom>
                  Template Footer{" "}
                  <span className={classes.optional}>(Optional)</span>
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Your message content. Upto 60 characters are allowed.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="templateFooter"
                  className={classes.textArea}
                  placeholder="Enter footer text here"
                  onChange={this.handleInput}
                  inputProps={{ maxLength: 60 }}
                  FormHelperTextProps={{
                    className: classes.characterCounter,
                  }}
                  helperText={
                    this.state.templateFooter &&
                    `${this.state.templateFooter.length}/60`
                  }
                  value={this.state.templateFooter}
                  disabled={inDialog}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              className={classes.preview}
              width="100%"
              style={{ display: window.innerWidth < 767 && "inline-block" }}
            >
              <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Typography variant="h4" gutterBottom>
                  Template Preview
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Your template message preview. It will update as you fill in
                  the values in the form.
                </Typography>
              </Grid>
              <div
                style={{
                  minWidth: window.innerWidth >= 1200 && "340px",
                  display: "inline-grid",
                  justifyContent: "center",
                  padding: "0 16px",
                  marginTop: "40px",
                }}
              >
                <TemplateMessagePreview
                  parameters={this.state.parameters}
                  media={""}
                  bigIcon={true}
                  selectedTemplate={{
                    ...this.generateTemplate(),
                    callToAction: JSON.parse(
                      JSON.stringify(this.state.callToAction ?? [])
                    ).sort((a, b) => (a.type < b.type ? -1 : 1)),
                    templateCTAUrl:
                      interativeURLAction && interativeURLAction.buttonValue,
                  }}
                  showDisclaimer={true}
                />
              </div>
            </Box>
          </Grid>
        </Grid>

        <Box my={6}>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: "8px" }}>
              <Typography variant="h4" gutterBottom>
                Interactive Actions
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                In addition to your message, you can send actions with your
                message.
                <br />
                Maximum 25 characters are allowed in CTA button title & Quick
                Replies.
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <RadioGroup
                row
                name="action"
                value={this.state.actionType}
                defaultValue="None"
                onChange={this.handleRadioInput}
              >
                <FormControlLabel
                  value={"None"}
                  control={<Radio color="primary" />}
                  label="None"
                />
                <FormControlLabel
                  value={"CAT"}
                  control={<Radio color="primary" />}
                  label="Call to Actions"
                />
                <FormControlLabel
                  value={"QuickReplies"}
                  control={<Radio color="primary" />}
                  label="Quick Replies"
                />
              </RadioGroup>
            </Grid>
            <InteractiveActions
              classes={classes}
              actionType={this.state.actionType}
              quickReplies={this.state.quickReplies}
              callToAction={this.state.callToAction}
              catAutoComplete={this.catAutoComplete}
              catInput={this.catInput}
              addCAT={this.addCAT}
              removeCAT={this.removeCAT}
              quickReplyInput={this.quickReplyInput}
              addQuickReply={this.addQuickReply}
              removeQuickReply={this.removeQuickReply}
            />
          </Grid>
        </Box>

        <Box my={6} />
        {this.state.err ? (
          <Box>
            <Typography
              variant="body2"
              color="error"
              style={{ marginBottom: 12 }}
            >
              {this.state.err}
            </Typography>
            {this.state.err.includes("Floating") && (
              <Link
                href="https://m.aisensy.com/how-to-fix-floating-parameter-issue-in-template-message"
                target="_blank"
                color="primary"
                size="small"
              >
                <Typography variant="body2">
                  How to fix floating parameter issue?
                </Typography>
              </Link>
            )}
          </Box>
        ) : (
          ""
        )}
        {this.state.success ? (
          <Box>
            <Typography variant="h3" color="primary">
              Template successfully submitted for approval !
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <Box my={4}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {this.state.isSubmitting ? (
                <CircularProgress />
              ) : (
                <>
                  {!(inDialog && this.state.success) && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.autoFillAndSubmit}
                      endIcon={
                        this.state.isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          ""
                        )
                      }
                      disabled={this.state.isSubmitting}
                      style={{
                        marginRight: 8,
                        fontSize: "14px",
                        padding: "8px 16px",
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        {/* Show snackbar */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.snackbar.open}
          onClose={() =>
            this.setState({ snackbar: { open: false, message: "" } })
          }
          message={this.state.snackbar.message}
          key={"snack"}
          autoHideDuration={3000}
        >
          <Alert
            open={this.state.snackbar.open}
            onClose={() =>
              this.setState({ snackbar: { open: false, message: "" } })
            }
            severity="error"
          >
            {this.state.snackbar.message}
          </Alert>
        </Snackbar>
        {/* Show dialog on backpress */}
        <Dialog
          open={showDialog && !isSubmitted}
          onClose={toggleDialog}
          maxWidth={"xs"}
        >
          <Box className={classes.dialogWrapper}>
            <DialogTitle className={classes.dialogTitle}>
              <Typography variant="h3">Discard Template</Typography>
            </DialogTitle>
            <Clear className={classes.icon} onClick={toggleDialog} />
          </Box>
          <DialogContent>
            <DialogContentText
              style={{
                color: "rgb(0,0,0,0.67)",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              If you discard this template then all changes made by you will be
              lost. You can also save this template as a draft and modify it at
              a later point.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ paddingBottom: "16px", paddingRight: "24px" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                toggleDialog();
                this.props.history.push(
                  "/projects/" + projectId + "/manage/template-message"
                );
              }}
            >
              Discard template
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                toggleDialog();
                this.props.history.push(
                  "/projects/" + projectId + "/manage/template-message"
                );
              }}
              autoFocus
            >
              Save to Draft
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

function InteractiveActions(props) {
  const {
    classes,
    actionType,
    callToAction,
    quickReplies,
    catAutoComplete,
    catInput,
    addCAT,
    removeCAT,
    quickReplyInput,
    addQuickReply,
    removeQuickReply,
  } = props;
  function getOptions(e, index) {
    const options = ["Phone Number", "URL"];
    if (callToAction.length === 1) {
      return options;
    } else {
      return options.filter((k) => k !== callToAction[Number(!index)].type);
    }
  }
  switch (actionType) {
    case "None":
      return <div></div>;
    case "QuickReplies":
      return (
        <React.Fragment>
          {quickReplies.map((e, index) => {
            return (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                spacing={2}
                key={index}
              >
                <Grid item xs={12} md={"auto"}>
                  <Typography variant="h5">
                    Quick Reply {index + 1} :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    name="quickReplies"
                    className={classes.textArea}
                    placeholder="Button title"
                    value={e}
                    onChange={(elem) => quickReplyInput(index, elem)}
                  />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <IconButton onClick={() => removeQuickReply(index)}>
                    <Clear />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <Grid item container xs={12} alignItems="center">
            <Box my={3}>
              {quickReplies.length < 3 ? (
                <Button
                  startIcon={<AddOutlined />}
                  variant="outlined"
                  onClick={addQuickReply}
                >
                  Quick Reply
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </React.Fragment>
      );
    case "CAT":
      return (
        <React.Fragment>
          {callToAction.map((e, index) => {
            return (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                spacing={2}
                key={index}
              >
                <Grid item xs={12} md={2} style={{ display: "contents" }}>
                  <Typography variant="h5">
                    Call to Action {index + 1} :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disableClearable
                    options={getOptions(e, index).map((option) => option)}
                    onChange={(e, value, reason) =>
                      catAutoComplete(index, value, reason)
                    }
                    value={e.type}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // className={classes.textField}
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select action type"
                        name="callToAction"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    name="buttonTitle"
                    className={classes.textArea}
                    placeholder="Button title"
                    value={e.buttonTitle}
                    onChange={(elem) => catInput(index, elem)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    name="buttonValue"
                    className={classes.textArea}
                    placeholder="Button value"
                    value={e.buttonValue}
                    onChange={(elem) => catInput(index, elem)}
                  />
                </Grid>
                <Grid item xs={12} md={"auto"} style={{ width: 0 }}>
                  <IconButton onClick={() => removeCAT(index)}>
                    <Clear />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <Grid item container xs={12} alignItems="center">
            <Box my={2}>
              {callToAction.length < 2 ? (
                <Button
                  startIcon={<AddOutlined />}
                  variant="outlined"
                  onClick={addCAT}
                >
                  Call to Action
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </React.Fragment>
      );
    default:
      return "";
  }
}

const styles = (theme) => ({
  root: {
    background: "white",
    borderRadius: "8px",
    minHeight: "200px",
  },
  textField: {
    width: "100%",
    "& .MuiAutocomplete-input": {
      textOverflow: "clip",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textArea: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  previewWrapper: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
  },
  previewContainer: {
    minHeight: 160,
    background: "#EBF5F3",
    width: "100%",
    borderRadius: 8,
  },
  preview: {
    display: "inline-grid",
    width: "100%",
    //justifyContent: "center",
    position: "sticky",
    top: "100px",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
  },
  optional: {
    fontSize: "14px",
    color: "#808080",
    fontWeight: "500",
  },
  inlineCharacterCounter: {
    marginLeft: "auto",
    height: 0,
    bottom: "25px",
    position: "absolute",
    right: "10px",
  },
  characterCounter: {
    height: 0,
    position: "absolute",
    right: 0,
    bottom: "-5px",
  },
  dialogWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    marginRight: 24,
    "&:hover": {
      cursor: "pointer",
    },
  },
  dialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: 20,
      fontWeight: 600,
      color: "rgb(0,0,0,0.87)",
    },
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  qualityStatus: {
    "& .MuiChip-label": {
      color: "white",
    },
  },
});

const CreateTemplateconnect = connect(
  (state) => ({
    allBusiness: state.business.allBusiness,
    allProjects: state.projects.allProjects,
    partner: state.partner.partner,
  }),
  { fetchBusiness, fetchProject, createnewTemplate }
)(CreateTemplateMsgForm);

export default withStyles(styles)(withRouter(CreateTemplateconnect));
