import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import {
  FiberManualRecord,
  FileCopyOutlined,
  FilterNone,
  VisibilityOutlined,
  Edit,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import Header from "../../commons/Header/Header";

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
    };
  }
  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };
  copyCode = () => {
    navigator.clipboard.writeText(`<!-- WhatsApp API Access Code -->
    <script>
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    ment(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    <noscript><img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=255162156131712&ev=PageView&noscript=1"
    /></noscript>
    <!-- WhatsApp API Access Code -->`);
    this.setState({
      alert: true,
      alertSeverity: "success",
      alertMsg: "Copied to clipboard!",
    });
  };
  render() {
    const { classes, match } = this.props;
    const assistantId = match.params.projectId;
    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Header businessTitle={"Configuration & Embed"} />
        <Box my={3} />
        {/* Layout */}
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className={classes.container}>
                  <Typography variant="h4" color="primary">
                    WhatsApp API Embed Code
                  </Typography>
                  <Box mt={3} />

                  <Typography variant="h5" color="textSecondary">
                    Embed this Code on your Website to help your Customers get
                    the WhatsApp Cloud API Seamlessly
                  </Typography>

                  <Box
                    className={classes.container}
                    style={{ background: "#eeeeee" }}
                  >
                    <IconButton
                      style={{
                        float: "right",
                        position: "relative",
                        bottom: 12,
                        left: 18,
                      }}
                      onClick={this.copyCode}
                    >
                      <FileCopyOutlined />
                    </IconButton>
                    <Typography variant="body1" color="textPrimary">
                      {`<!-- WhatsApp API Access Code -->`}
                      <br />
                      {`<script>`}
                      <br />
                      {`!function(f,b,e,v,n,t,s)`}
                      <br />
                      {`{if(f.fbq)return;n=f.fbq=function(){n.callMethod?`}
                      <br />
                      {`ment(e);t.async=!0;`}
                      <br />
                      {`t.src=v;s=b.getElementsByTagName(e)[0];`}
                      <br />
                      {`s.parentNode.insertBefore(t,s)}(window, document,'script',`}
                      <br />
                      {`<noscript><img height="1" width="1" style="display:none"`}
                      <br />
                      {`src="https://www.facebook.com/tr?id=255162156131712&ev=PageView&noscript=1"`}
                      <br />
                      {`/></noscript>`}
                      <br />
                      {`<!-- WhatsApp API Access Code -->`}
                    </Typography>
                  </Box>

                  <Box mt={2} />

                  <Button
                    startIcon={<VisibilityOutlined />}
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      this.props.history.push("/preview");
                    }}
                  >
                    Preview
                  </Button>
                  <Button
                    startIcon={<FileCopyOutlined />}
                    variant="contained"
                    color="primary"
                    onClick={this.copyCode}
                  >
                    Copy Embed Code
                  </Button>
                </Box>

                <Box
                  className={classes.containerButton}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h4" color="textPrimary">
                    Setup Your WhiteLabel Dashboard
                  </Typography>
                  <Button variant="contained" color="primary">
                    Apply Now
                  </Button>
                </Box>

                <Box
                  className={classes.containerButton}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h4" color="textPrimary">
                    Explore WhatsApp API's
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href="https://aisensy.stoplight.io/docs/partner-api/67fb491f16f99-getting-started"
                  >
                    Explore Now
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box className={classes.container}>
                  <Typography variant="h3" color="textPrimary">
                    Explore AiSensy Whitelabel Dashboard
                  </Typography>
                  <Box mt={2} />
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    Sell Complete WhatsApp Engagement Solution to your Customers
                    with your branding and on your Subdomain or Embedded within
                    you portal
                  </Typography>
                  <Box mt={2} />
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    • Unlimited Agent Dashboards
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    • Retargeting Campaigns
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    • Powerful Campaign Manager
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    • Live Chat & Chatbots
                  </Typography>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    • Seamless WhatsApp API
                  </Typography>
                </Box>
                <div className={classes.containerPlans}>
                  <Typography variant="h3" color="textPrimary">
                    Plans Setup
                  </Typography>
                  <Box mt={2} />
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    Setup your plan according to your requirment. Our platform
                    give access to partner's to set there own price for plans
                  </Typography>
                  <Box mt={2} />
                  <Grid xs={12} style={{ background: "white", height: "100%" }}>
                    <Typography variant="h4" color="primary" noWrap>
                      <FiberManualRecord
                        style={{
                          color: "#08CF65",
                          fontSize: 16,
                          position: "relative",
                          top: 3,
                          right: 3,
                        }}
                      />
                      WhatsApp Plan
                    </Typography>
                    <Box mt={1} />
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "30px",
                      }}
                    >
                      <Grid>Active Plan</Grid>
                      <Grid style={{ marginRight: "1.5em" }}>Pro Plan</Grid>
                      <Grid>
                        <IconButton
                          style={{ padding: "0px" }}
                          //  onClick={this.planDialog}
                        >
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Typography variant="h4" color="primary" noWrap>
                      <FiberManualRecord
                        style={{
                          color: "#08CF65",
                          fontSize: 16,
                          position: "relative",
                          top: 3,
                          right: 3,
                        }}
                      />
                      PWC Credit
                    </Typography>
                    <Box mt={1} />
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "30px",
                      }}
                    >
                      <Grid>Amount</Grid>
                      <Grid>
                        464567
                        {/* Number(this.state.assistant.partnerWhatsappCredit / 100000).toFixed(2) */}
                        {/* {parseInt(this.state.assistant.partnerWhatsappCredit.toString().slice(0,-5))} */}
                      </Grid>
                      <Grid>
                        <IconButton
                          style={{ padding: "0px" }}
                          // onClick={this.pwcDialog}
                        >
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Typography variant="h4" color="primary" noWrap>
                      <FiberManualRecord
                        style={{
                          color: "#08CF65",
                          fontSize: 16,
                          position: "relative",
                          top: 3,
                          right: 3,
                        }}
                      />
                      WCC Credit
                    </Typography>
                    <Box mt={1} />
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>Amount</Grid>
                      <Grid>
                        4573
                        {/* Number(this.state.assistant.templateCredit / 100000).toFixed(2) */}
                        {/* {parseInt(this.state.assistant.templateCredit.toString().slice(0,-5))} */}
                      </Grid>
                      <Grid>
                        <IconButton
                          style={{ padding: "0px" }}
                          // onClick={this.wccDialog}
                        >
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box my={8} />
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
});

export default withStyles(styles)(Config);
