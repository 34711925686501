import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Snackbar,
  Button,
} from "@material-ui/core";
import Header from "../../commons/Header/Header";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { URL } from "../../config/config";
import axios from "axios";
import PopUp from "./SubscriptionPlans/Popup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Add, Edit as EditIcon } from "@material-ui/icons";
import WccTable from "./WccPlans/WccTable";
import { Alert } from "@material-ui/lab";
import { fetchPartnerDetails } from "../../store/partnerState";
import { fetchPlanFamilies, addNewPlan } from "../../store/planFamilyState";
import { fetchWccPlans, addWccPlan } from "../../store/wccPlanState";
import {
  fetchWhatsappPaymentsPlans,
  addWhatsappPaymentPlan,
} from "../../store/whatsappPaymentsPlanState";
import CreateWccPlan from "./WccPlans/CreateWccPlan";
import CreatePlanFamily from "./SubscriptionPlans/CreatePlanFamily";
import CreateWhatsappPaymentsPlan from "./WhatsappPaymentsPlans/CreateWhatsappPaymentPlan";
import plansHelper from "../../helpers/plansHelper";
import WhatsappPaymentsDialogPopupDetails from "./WhatsappPaymentsPlans/WhatsappPaymentsDialog";
import { checkAccess } from "../../helpers/RBAC";
import LockIcon from "@material-ui/icons/Lock";

const subscriptionFields = [
  {
    name: "Name",
    size: 4,
    key: "name",
  },
  {
    name: "Created At",
    size: 4,
    key: "createdAt",
  },
  {
    name: "Actions",
    size: 4,
    key: "actions",
  },
];

const wccFields = [
  {
    name: "Name",
    size: 4,
    key: "name",
  },
  {
    name: "Created At",
    size: 4,
    key: "createdAt",
  },
  {
    name: "Actions",
    size: 4,
    key: "actions",
  },
];

const whatsappPaymentsFields = [
  {
    name: "Name",
    size: 4,
    key: "name",
  },
  {
    name: "Created At",
    size: 4,
    key: "createdAt",
  },
  {
    name: "Actions",
    size: 4,
    key: "actions",
  },
];

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedDialog: "",
      partnerPlanFamily: [],
      tab: "",
      aisensyPlans: {},
      aisensyWhatsappPaymentsSlabs: [],
      currency: "",
      activeId: "",
      partnerWccFamily: [],
      user_initiated: {},
      business_initiated: {},
      wholeWccItem: {},
      aisensyWcc: {},
      partner_user_initiated: {},
      partner_business_initiated: {},
      aisensy_user_initiated: {},
      aisensy_business_initiated: {},
      aisensy_service: {},
      aisensy_authentication: {},
      aisensy_marketing: {},
      aisensy_utility: {},
      reqWccId: "",
      alertType: "",
      alertMsg: "",
      disableFields: "",
    };
  }
  listScrolled = (event) => {
    if (!this.offsetPosition) {
      this.offsetPosition = this.rootEle.current.offsetTop;
    }
    const container = event.currentTarget;
    const currentPos = container.scrollTop;
    this.setState({
      sticky: currentPos > this.offsetPosition,
    });
  };

  editPlan = (dialog, ...props) => {
    //Close if already opened
    if (dialog === this.state.openedDialog) {
      dialog = undefined;
    }

    if (
      ["OpenSubscriptionPlanFamily", "OpenWhatsappPaymentFamily"].includes(
        dialog
      )
    ) {
      const [id, planType, hasWriteAccess] = props;
      this.setState({
        openedDialog: dialog,
        activeId: id,
        disableFields: !hasWriteAccess,
        planType,
      });
    } else if (dialog === "OpenWccFamily") {
      const [
        business,
        user,
        item,
        id,
        authentication_initiated,
        service_initiated,
        utility_initiated,
        marketing_initiated,
        hasWriteAccess,
      ] = props;
      this.setState({
        user_initiated: user,
        business_initiated: business,
        openedDialog: dialog,
        wholeWccItem: item,
        reqWccId: id,
        authentication_initiated,
        service_initiated,
        utility_initiated,
        marketing_initiated,
        disableFields: !hasWriteAccess,
      });
    } else {
      this.setState({
        openedDialog: dialog,
      });
    }
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  getAisensyPlanFamilyData = async () => {
    const promises = [
      axios.get(URL + `/api/get-aisensy-plan-family-pricing/:projectId`),
      axios.get(URL + `/api/get-aisensy-wcc-pricing/xyz`),
      axios.get(URL + `/api/aisensy-whatsapp-payments-plans`),
    ];

    const planTypes = ["Subscription", "WCC", "Whatsapp Payment"];

    const results = await Promise.allSettled(promises);
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      if (result.status === "rejected") {
        this.setState({
          alertType: "error",
          alertMsg: `Cannot fetch Aisensy ${planTypes[i]} Plans`,
        });
      } else {
        const data = result.value.data;
        if (i === 0) {
          this.setState({
            aisensyPlans: data.plans.plans,
          });
        } else if (i === 1) {
          this.setState({
            aisensyWcc: data.aisensyWccPlan,
            aisensy_user_initiated: data.aisensyWccPlan.user_initiated,
            aisensy_business_initiated: data.aisensyWccPlan.business_initiated,
            aisensy_service: data.aisensyWccPlan.service,
            aisensy_authentication: data.aisensyWccPlan.authentication,
            aisensy_marketing: data.aisensyWccPlan.marketing,
            aisensy_utility: data.aisensyWccPlan.utility,
          });
        } else if (i === 2) {
          this.setState({
            aisensyWhatsappPaymentsSlabs: data.aisensyWhatsappPaymentsSlabs,
          });
        }
      }
    }
  };

  componentDidMount() {
    // this.props.fetchPartnerDetails();
    this.getAisensyPlanFamilyData();
    plansHelper.setAllPlans();

    const reqCurrency = this.props.partner.currency;
    this.setState({
      tab: reqCurrency,
      currency: reqCurrency,
    });

    axios
      .get(URL + `/api/get-aisensy-wcc-pricing/xyz`)
      .then((response) => {
        const data = response.data.aisensyWccPlan;

        this.setState({
          aisensyWcc: data,
          aisensy_user_initiated: data.user_initiated,
          aisensy_business_initiated: data.business_initiated,
          aisensy_service: data.service,
          aisensy_authentication: data.authentication,
          aisensy_marketing: data.marketing,
          aisensy_utility: data.utility,
        });
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg: "",
        });
      });
  }

  triggerAlert = (obj) => {
    const { alertType, alertMsg } = obj;
    this.setState({
      alertType: alertType,
      alertMsg: alertMsg,
    });
  };

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue });
    this.setState({ currency: newValue });
  };

  createPlanFamily = (payload) => {
    payload.partnerId = this.props.partner._id;
    payload.planType = this.props.partner.planType;
    axios
      .post(URL + `/api/create-partner-plan-family`, payload)
      .then((response) => {
        const data = response.data;
        const { planFamily } = data;
        this.props.addNewPlan(planFamily);
        this.setState({
          openedDialog: undefined,
          alertType: "success",
          alertMsg: "Plan Family created successfully",
        });
      })
      .catch((error) => {
        this.setState({
          openedDialog: undefined,
          alertType: "error",
          alertMsg: "Unable to create Plan Family. Something went wrong.",
        });
      });
  };

  createWccPlan = (wccPlan) => {
    wccPlan.partnerId = this.props.partner._id;
    axios
      .post(URL + `/api/create-partner-wcc-plan`, wccPlan)
      .then((response) => {
        const data = response.data.partnerWccPlan;
        this.props.addWccPlan(data);
        this.setState({
          openedDialog: undefined,
          alertType: "success",
          alertMsg: "Wcc Plan created successfully",
        });
      })
      .catch((error) => {
        this.setState({
          openedDialog: undefined,
          alertType: "error",
          alertMsg: "Unable to create Wcc Plan. Something went wrong.",
        });
      });
  };

  createWhatsappPaymentsPlan = (payload) => {
    payload.partnerId = this.props.partner._id;
    axios
      .post(URL + `/api/whatsapp-payments-plans`, payload)
      .then((response) => {
        const data = response.data.whatsappPaymentsPlan;
        this.props.addWhatsappPaymentPlan(data);
        this.setState({
          openedDialog: undefined,
          alertType: "success",
          alertMsg: "Whatsapp Payment Plan Family created successfully",
        });
      })
      .catch((error) => {
        this.setState({
          openedDialog: undefined,
          alertType: "error",
          alertMsg:
            "Unable to create Whatsapp Payment Plan Family. Something went wrong.",
        });
      });
  };

  toggleOpenedDialog = (dialog) => {
    //Close if already opened
    if (dialog === this.state.openedDialog) {
      dialog = undefined;
    }

    this.setState({ openedDialog: dialog });
  };

  render() {
    const {
      classes,
      partnerPlanFamily,
      partnerWccFamily,
      partnerWhatsappPaymentsFamily,
      isLoading,
      partnerAgents,
      partner,
      user,
    } = this.props;
    const { openedDialog } = this.state;
    const convertPrice = 100000;

    const { addons: partnerAddons } = partner;
    const { WHATSAPP_PAY } = partnerAddons || {};

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "plans",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "plans",
      "write"
    );

    return (
      <>
        <div className={classes.root}>
          {hasReadAccess ? (
            <>
              <Header
                businessTitle={"Plan Details"}
                hasReadAccess={hasReadAccess}
                hasWriteAccess={hasWriteAccess}
              />

              {/* Whitelabel Plan Family */}
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  md={11}
                  lg={10}
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <PlanFamilyHeader
                    title={"Subscription Plan Family"}
                    toggleCreatePlanDialog={() =>
                      this.toggleOpenedDialog("CreateSubscriptionPlanFamily")
                    }
                    hasWriteAccess={hasWriteAccess}
                  />
                  <TableRows
                    familyType={"OpenSubscriptionPlanFamily"}
                    classes={classes}
                    planFamilies={partnerPlanFamily}
                    fields={subscriptionFields}
                    listScrolled={this.listScrolled}
                    isLoading={isLoading}
                    editPlan={this.editPlan}
                    openPlanDialog={() =>
                      this.toggleOpenedDialog("OpenSubscriptionPlanFamily")
                    }
                    hasWriteAccess={hasWriteAccess}
                  />
                </Grid>
              </Grid>

              {/* WCC Plan Family */}
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  md={11}
                  lg={10}
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <PlanFamilyHeader
                    title={"WhatsApp Conversation Charges (WCC) Family"}
                    toggleCreatePlanDialog={() =>
                      this.toggleOpenedDialog("CreateWccFamily")
                    }
                    hasWriteAccess={hasWriteAccess}
                  />
                  <TableRows
                    familyType={"OpenWccFamily"}
                    classes={classes}
                    planFamilies={partnerWccFamily}
                    fields={wccFields}
                    listScrolled={this.listScrolled}
                    isLoading={isLoading}
                    editPlan={this.editPlan}
                    openPlanDialog={() =>
                      this.toggleOpenedDialog("OpenWccFamily")
                    }
                    hasWriteAccess={hasWriteAccess}
                  />
                </Grid>
              </Grid>

              {/* WhatsApp Payments Plan Family */}
              {WHATSAPP_PAY?.status === "active" && (
                <Grid container justifyContent="center">
                  <Grid
                    item
                    xs={12}
                    md={11}
                    lg={10}
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <PlanFamilyHeader
                      title={"WhatsApp Payments Plan Family"}
                      toggleCreatePlanDialog={() =>
                        this.toggleOpenedDialog("CreateWhatsappPaymentFamily")
                      }
                      hasWriteAccess={hasWriteAccess}
                    />

                    <TableRows
                      familyType={"OpenWhatsappPaymentFamily"}
                      classes={classes}
                      planFamilies={partnerWhatsappPaymentsFamily}
                      fields={whatsappPaymentsFields}
                      listScrolled={this.listScrolled}
                      isLoading={isLoading}
                      editPlan={this.editPlan}
                      openPlanDialog={() =>
                        this.toggleOpenedDialog("OpenWhatsappPaymentFamily")
                      }
                      hasWriteAccess={hasWriteAccess}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                height: "80vh",
                alignItems: "center",
                // backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <LockIcon
                style={{
                  fontSize: 90,
                  color: "grey",
                  marginBottom: "10px",
                }}
              />
              <Typography variant="h4" color="textSecondary">
                Access Denied
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Looks like you don't have the necessary permissions to view this
                page
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Please contact your administrator for more information.
              </Typography>
            </Box>
          )}

          {openedDialog === "OpenSubscriptionPlanFamily" && (
            <PopUp
              handleChange={this.handleChange}
              tab={this.props.partner.currency}
              classes={classes}
              aisensyPlans={this.state.aisensyPlans}
              partnerPlans={partnerPlanFamily[this.state.activeId].plans}
              currency={this.props.partner.currency}
              convertPrice={convertPrice}
              openDialog={openedDialog}
              toggleDialog={() =>
                this.toggleOpenedDialog("OpenSubscriptionPlanFamily")
              }
              disableFields={this.state.disableFields}
              activeId={this.state.activeId}
              triggerAlert={this.triggerAlert}
            />
          )}

          {openedDialog === "OpenWhatsappPaymentFamily" && (
            <WhatsappPaymentsDialogPopupDetails
              handleChange={this.handleChange}
              tab={this.props.partner.currency}
              classes={classes}
              aisensySlabs={this.state.aisensyWhatsappPaymentsSlabs}
              partnerSlabs={
                partnerWhatsappPaymentsFamily[this.state.activeId].slabs
              }
              currency={this.props.partner.currency}
              convertPrice={convertPrice}
              openDialog={openedDialog}
              toggleDialog={() =>
                this.toggleOpenedDialog("OpenWhatsappPaymentFamily")
              }
              disableFields={this.state.disableFields}
              activeId={this.state.activeId}
              triggerAlert={this.triggerAlert}
            />
          )}

          {openedDialog === "OpenWccFamily" && (
            <WccTable
              currency={this.props.partner.currency}
              classes={classes}
              triggerAlert={this.triggerAlert}
              openDialog={openedDialog}
              toggleDialog={() => this.toggleOpenedDialog("OpenWccFamily")}
              aisensyWcc={this.state.aisensyWcc}
              business_initiated={this.state.business_initiated}
              user_initiated={this.state.user_initiated}
              service_initiated={this.state.service_initiated}
              authentication_initiated={this.state.authentication_initiated}
              utility_initiated={this.state.utility_initiated}
              marketing_initiated={this.state.marketing_initiated}
              wholeWccItem={this.state.wholeWccItem}
              aisensy_user_initiated={this.state.aisensy_user_initiated}
              aisensy_business_initiated={this.state.aisensy_business_initiated}
              aisensy_service={this.state.aisensy_service}
              aisensy_authentication={this.state.aisensy_authentication}
              aisensy_utility={this.state.aisensy_utility}
              aisensy_marketing={this.state.aisensy_marketing}
              reqWccId={this.state.reqWccId}
              disableFields={this.state.disableFields}
            />
          )}

          {openedDialog === "CreateSubscriptionPlanFamily" && (
            <CreatePlanFamily
              classes={classes}
              aisensyPlans={this.state.aisensyPlans}
              currency={this.props.partner.currency}
              convertPrice={convertPrice}
              openDialog={openedDialog}
              toggleDialog={() =>
                this.toggleOpenedDialog("CreateSubscriptionPlanFamily")
              }
              disableFields={this.state.disableFields}
              createPlanFamily={this.createPlanFamily}
            />
          )}

          {openedDialog === "CreateWccFamily" && (
            <CreateWccPlan
              aisensy_user_initiated={this.state.aisensy_user_initiated}
              aisensy_business_initiated={this.state.aisensy_business_initiated}
              currency={this.props.partner.currency}
              openDialog={openedDialog}
              toggleDialog={() => this.toggleOpenedDialog("CreateWccFamily")}
              createWccPlan={this.createWccPlan}
              aisensy_service={this.state.aisensy_service}
              aisensy_authentication={this.state.aisensy_authentication}
              aisensy_utility={this.state.aisensy_utility}
              aisensy_marketing={this.state.aisensy_marketing}
              classes={classes}
            />
          )}

          {openedDialog === "CreateWhatsappPaymentFamily" && (
            <CreateWhatsappPaymentsPlan
              classes={classes}
              aisensySlabs={this.state.aisensyWhatsappPaymentsSlabs}
              currency={this.props.partner.currency}
              convertPrice={convertPrice}
              openDialog={openedDialog}
              toggleDialog={() =>
                this.toggleOpenedDialog("CreateWhatsappPaymentFamily")
              }
              disableFields={this.state.disableFields}
              createPlanFamily={this.createWhatsappPaymentsPlan}
            />
          )}

          <Snackbar
            open={!!this.state.alertMsg}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.onSnackbarClose}
            className={classes.snackbar}
            autoHideDuration={3000}
          >
            <Alert
              severity={this.state.alertType}
              onClose={this.onSnackbarClose}
            >
              {this.state.alertMsg}
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  }
}

function Cell({
  classes,
  item,
  field,
  allBusiness,
  editPlan,
  planType,
  familyType,
  editWccPlan,
  openPlanDialog,
  hasWriteAccess,
  ...props
}) {
  switch (field) {
    case "remainingCredit":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100000}</Typography>
          </Box>
        </Grid>
      );
      break;

    case "templateCredit":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {item[field] ? item[field] / 100000 : 0}
            </Typography>
          </Box>
        </Grid>
      );
      break;
    case "createdAt":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {getFormattedDate(item[field])}
            </Typography>
          </Box>
        </Grid>
      );

      break;
    case "clientId":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1" noWrap>
            {item[field] && allBusiness[item[field]]?.name}
            {/* {columnData[key]} */}
            {/* {agents.find((i) => i._id === columnData[key])?.displayName || "-"} */}
          </Typography>
        </Box>
      );
      break;
    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            {Array.from(Array(20).keys()).map((i) => {
              return (
                <div key={i} className={classes.tableItem1}>
                  <Skeleton className={classes.skeleton} />
                </div>
              );
            })}
          </Box>
        </Grid>
      );
      break;
    case "whatsappNumber":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1" noWrap>
            {item[field] ? "+" + item[field] : "-"}
          </Typography>
        </Box>
      );
      break;

    case "excessUsage":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100000}</Typography>
          </Box>
        </Grid>
      );
      break;
    case " company":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100}</Typography>
          </Box>
        </Grid>
      );
      break;

    case "actions":
      let visibilityPayload = [];
      let editPayload = [];
      switch (familyType) {
        case "OpenSubscriptionPlanFamily":
          visibilityPayload = [familyType, item._id, planType];
          editPayload = [familyType, item._id, planType, hasWriteAccess];
          break;
        case "OpenWccFamily":
          visibilityPayload = [
            familyType,
            item.business_initiated,
            item.user_initiated,
            item,
            item._id,
            item.authentication,
            item.service,
            item.utility,
            item.marketing,
          ];
          editPayload = [
            familyType,
            item.business_initiated,
            item.user_initiated,
            item,
            item._id,
            item.authentication,
            item.service,
            item.utility,
            item.marketing,
            hasWriteAccess,
          ];
          break;
        case "OpenWhatsappPaymentFamily":
          visibilityPayload = [familyType, item._id, planType];
          editPayload = [familyType, item._id, planType, hasWriteAccess];
          break;
        default:
          visibilityPayload = [familyType, item._id, planType];
          editPayload = [familyType, item._id, planType, hasWriteAccess];
      }

      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <VisibilityIcon
              onClick={() => {
                return editPlan(...visibilityPayload);
              }}
              color="primary"
            />
            {hasWriteAccess && (
              <EditIcon
                color="primary"
                style={{ marginLeft: 16 }}
                onClick={() => editPlan(...editPayload)}
              />
            )}
          </Box>
        </Grid>
      );
    case "actionsWcc":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <VisibilityIcon
              onClick={() => {
                return editWccPlan(
                  true,
                  item.business_initiated,
                  item.user_initiated,
                  item,
                  item._id,
                  true,
                  item.authentication,
                  item.service,
                  item.utility,
                  item.marketing,
                  false
                );
              }}
              color="primary"
            />
            {hasWriteAccess && (
              <EditIcon
                color="primary"
                style={{ marginLeft: 16 }}
                onClick={() => {
                  return editWccPlan(
                    true,
                    item.business_initiated,
                    item.user_initiated,
                    item,
                    item._id,
                    false,
                    item.authentication,
                    item.service,
                    item.utility,
                    item.marketing,
                    hasWriteAccess
                  );
                }}
              />
            )}
          </Box>
        </Grid>
      );
    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field]}</Typography>
          </Box>
        </Grid>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

function PlanFamilyHeader(props) {
  const { title, toggleCreatePlanDialog, hasWriteAccess } = props;
  return (
    <Box
      mb={{ xs: 2, md: 4 }}
      mx={{ xs: 2, md: 0 }}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h3" style={{ flex: 1 }}>
        {title}
      </Typography>
      <Button
        onClick={toggleCreatePlanDialog}
        disabled={!hasWriteAccess}
        color="primary"
        variant="contained"
        startIcon={<Add />}
        style={{
          marginRight: 8,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        Create
      </Button>
    </Box>
  );
}

function TableRows(props) {
  const {
    classes,
    planFamilies,
    fields,
    listScrolled,
    isLoading,
    editPlan,
    openDialog,
    familyType,
    hasWriteAccess,
  } = props;

  return (
    <div className={classes.tableContainer}>
      <Grid container>
        <Grid container item xs={12} md={12} lg={12}>
          <Grid container>
            <Box
              className={classes.tableWrapper}
              mb={{ xs: 2, md: 4 }}
              mx={{ xs: 2, md: 0 }}
            >
              <Grid item container className={classes.billTableContainer}>
                <Grid item xs={12} container className={classes.tableHeader}>
                  {fields.map((e) => (
                    <Grid
                      item
                      xs={e.size}
                      key={e.key}
                      style={{
                        width: "20%",
                      }}
                    >
                      <Box py={2} pl={2}>
                        <Typography variant="h5" color="primary">
                          {e.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                {Object.keys(planFamilies).map((e) => (
                  <Grid
                    item
                    xs={12}
                    container
                    onScroll={listScrolled}
                    className={classes.tableItem}
                  >
                    {fields.map((field) => (
                      <Grid
                        item
                        xs={field.size}
                        style={{
                          width: "20%",
                        }}
                      >
                        <Cell
                          classes={classes}
                          item={planFamilies[e]}
                          field={isLoading ? "Loading" : field.key}
                          partnerPlans={planFamilies}
                          activeId={e}
                          editPlan={editPlan}
                          openDialog={openDialog}
                          familyType={familyType}
                          hasWriteAccess={hasWriteAccess}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  tableWrapper: {
    overflowX: "auto",
    width: "100%",
  },

  billTableContainer: {
    marginTop: "1.5em",
    width: "100%",
  },
  tableContainer: {
    top: "60px",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      // height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  tabIndicator: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    marginBottom: 6,
  },
  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
    },
  },
  tableItem1: {
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
  },
  tabHeader: {
    textTransform: "none",
    // borderBottom: "2px solid #f0f0f0",
  },
  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
    flexWrap: "nowrap",
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "rgb(70 20 134 / 15%)",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  overRideStyling: {
    border: "1px solid white",
    borderRadius: "8px",
  },

  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
});

// export default withStyles(styles)(withRouter(AssistantsTable));
const ConnectPlanDetails = connect(
  (state) => ({
    allBusiness: state.business.allBusiness,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    partnerWccFamily: state.wccPlans.allWccPlans,
    partnerWhatsappPaymentsFamily: state.whatsappPaymentsPlans.allPlans,
    isLoading: state.assistants.isLoading,
    partner: state.partner.partner,
    partnerAgents: state.partnerAgents.partnerAgents,
    user: state.login.user,
  }),
  {
    fetchPartnerDetails,
    fetchPlanFamilies,
    fetchWccPlans,
    addNewPlan,
    addWccPlan,
    fetchWhatsappPaymentsPlans,
    addWhatsappPaymentPlan,
  }
)(PlanDetails);

export default withStyles(styles)(withRouter(ConnectPlanDetails));
