import React, { Component } from "react";
import {
  Button,
  Grid,
  Typography,
  withStyles,
  Box,
  Dialog,
  CircularProgress,
} from "@material-ui/core";

class ConfirmationDialog extends Component {
  render() {
    const { classes, openDialog, closeDialog, title } = this.props;
    const { body, action, loader } = this.props;
    const mobileScreen = window.innerWidth < 767 ? true : false;
    return (
      <Dialog
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={mobileScreen}
      >
        {openDialog && (
          <div className={classes.root}>
            <Typography variant="h3" paragraph>
              {title}
            </Typography>
            <>{body}</>
            <Box my={6} />
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button onClick={closeDialog}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={action}
                  disabled={loader}
                  endIcon={loader ? <CircularProgress size={20} /> : ""}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "500px",
    minHeight: 150,
    padding: "20px",
    // height: "70vh",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: 200,
    },
  },
});

export default withStyles(styles)(ConfirmationDialog);
