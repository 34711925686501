export function checkAccess(partnerDetail, partnerAgents, user, page, action) {
  const agentRole = partnerAgents[user.agentId]?.agentRole;

  if (agentRole === "OWNER") {
    return true;
  }

  if (!partnerDetail || !partnerAgents || !user || !page || !action) {
    return false;
  }

  return (
    partnerDetail?.roles?.[agentRole]?.accessLevel?.[page]?.[action] === true
  );
}
