import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog";
import axios from "axios";
import { partnerURL } from "../../../config/config";
import { Edit } from "@material-ui/icons";

// const ADDONS = { webhooks: "Webhooks", meta_ads: "Meta Ads" };

export default function AddonsManagement(props) {
  const {
    classes,
    partner,
    assistantId,
    assistantAddons,
    setSnackbar,
    tenantDetails,
    hasWriteAccess,
  } = props;
  const ADDONS = partner?.addons || {};
  const [addons, setAddons] = useState(assistantAddons || {});
  const [showDialog, setShowDialog] = useState(false);
  const [currentAddon, setCurrentAddon] = useState();
  const partnerAddons = partner?.addons || {};
  const [partnerPlans, setPartnerPlans] = useState();
  const [flowCount, setFlowCount] = useState(
    addons?.FLOW_BUILDER?.flowCount || 5
  );
  const [action, setAction] = useState("ADD");
  const aisensyPlansClone = JSON.parse(
    JSON.stringify(partnerAddons[currentAddon]?.aisensyPlans || {})
  );

  const toggleDialog = () => setShowDialog((prev) => !prev);

  const activateAddon = async (addonKey) => {
    try {
      const payload = {
        addon: addonKey,
      };

      if (addonKey === "FLOW_BUILDER") {
        payload.flowCount = flowCount;
        payload.partnerPlans = partnerPlans || aisensyPlansClone;
      }

      const { data } = await axios.post(
        partnerURL + `/partner/${partner._id}/project/${assistantId}/addon`,
        payload
      );

      setAddons((prev) => ({
        ...prev,
        [currentAddon]: data.addon,
      }));
      setSnackbar({
        alert: true,
        alertType: "success",
        alertMsg: `Addon activated successfully!`,
      });
    } catch (error) {
      const errMsg = error?.response?.data?.message || "";
      setSnackbar({
        alert: true,
        alertType: "error",
        alertMsg: `Unable to active addon: ${errMsg}`,
      });
    }
  };

  const modifyAddon = async (addonKey) => {
    try {
      const payload = {
        addon: addonKey,
      };

      if (partnerPlans) payload.partnerPlans = partnerPlans;
      if (addonKey === "FLOW_BUILDER") payload.flowCount = flowCount;

      const { data } = await axios.patch(
        partnerURL + `/partner/${partner._id}/project/${assistantId}/addon`,
        payload
      );
      console.log(data);
      setAddons((prev) => ({
        ...prev,
        [currentAddon]: data.addon,
      }));
      setSnackbar({
        alert: true,
        alertType: "success",
        alertMsg: `Addon updated successfully!`,
      });
    } catch (error) {
      const errMsg = error?.response?.data?.message || "";
      setSnackbar({
        alert: true,
        alertType: "error",
        alertMsg: `Unable to update addon: ${errMsg}`,
      });
    }
  };

  const disableAddon = async (addonKey) => {
    try {
      await axios.delete(
        partnerURL + `/partner/${partner._id}/project/${assistantId}/addon`,
        { data: { addon: addonKey } }
      );
      setAddons((prev) => {
        return {
          ...prev,
          [currentAddon]: { ...prev[currentAddon], status: "paused" },
        };
      });
      setSnackbar({
        alert: true,
        alertType: "success",
        alertMsg: `Addon disabled successfully!`,
      });
    } catch (error) {
      const errMsg = error?.response?.data?.message || "";
      setSnackbar({
        alert: true,
        alertType: "error",
        alertMsg: `Unable to disable addon: ${errMsg}`,
      });
    }
  };

  const updateAddons = () => {
    if (action === "REMOVE") disableAddon(currentAddon);
    if (action === "MODIFY") modifyAddon(currentAddon);
    if (action === "ACTIVATE") activateAddon(currentAddon);

    toggleDialog();
  };

  const toggleSwitch = (addon, event) => {
    setAction(event.target.checked ? "ACTIVATE" : "REMOVE");
    setCurrentAddon(addon);
    toggleDialog();
  };

  const AddOn = ({ addon, classes }) => {
    const exists = addons[addon] && addons[addon].status === "active";
    const name = ADDONS[addon].name;
    return (
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.cells}>
          {name}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: window.innerWidth >= 768 && "flex-end",
            alignItems: "center",
          }}
          className={classes.cells}
        >
          <Switch
            checked={!!exists}
            onChange={(e) => toggleSwitch(addon, e)}
            color="primary"
            disabled={!hasWriteAccess}
            name={name}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          {exists && ["FLOW_BUILDER"].includes(addon) && (
            <IconButton
              color="primary"
              onClick={() => {
                setAction("MODIFY");
                setCurrentAddon(addon);
                toggleDialog();
              }}
            >
              <Edit />
            </IconButton>
          )}
        </Grid>
      </Grid>
    );
  };

  const FlowCountUI = () => {
    return (
      <>
        <div style={{ marginTop: "8px", marginBottom: "16px" }}>
          <Box>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.planBody}
            >
              No. of flows
            </Typography>
            <TextField
              disabled
              placeholder="Enter flow count here"
              value={flowCount}
              onChange={setFlowCount}
              style={{ width: "100%" }}
            />
          </Box>

          <Grid
            container
            style={{
              marginTop: 12,
            }}
          >
            <Button
              style={{ marginRight: 8, marginBottom: 8 }}
              disabled={flowCount <= (addons?.FLOW_BUILDER?.flows || 5)}
              variant="outlined"
              onClick={() => {
                setFlowCount((prev) => Number(prev) - 10);
              }}
            >
              -10
            </Button>

            <Button
              style={{ marginRight: 8, marginBottom: 8 }}
              variant="outlined"
              onClick={() => {
                setFlowCount((prev) => Number(prev) + 10);
              }}
            >
              +10
            </Button>
            <Button
              style={{ marginRight: 8, marginBottom: 8 }}
              variant="outlined"
              onClick={() => {
                setFlowCount((prev) => Number(prev) + 20);
              }}
            >
              +20
            </Button>
            <Button
              style={{ marginRight: 8, marginBottom: 8 }}
              variant="outlined"
              onClick={() => {
                setFlowCount((prev) => Number(prev) + 40);
              }}
            >
              +40
            </Button>
            <Button
              style={{ marginRight: 8, marginBottom: 8 }}
              variant="outlined"
              onClick={() => {
                setFlowCount((prev) => Number(prev) + 80);
              }}
            >
              +80
            </Button>
          </Grid>
        </div>
      </>
    );
  };

  const PlanUI = () => {
    const aisensyPlans = partnerAddons[currentAddon].aisensyPlans;
    const partnerPlan =
      partnerPlans || addons[currentAddon]?.partnerPlans || aisensyPlansClone;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="h4"
              align="center"
              style={{
                alignItems: "center",
                paddingRight: 8,
                background: "#461486",
                color: "white",
              }}
            >
              {Object.keys(tenantDetails).length
                ? tenantDetails.name
                : "AiSensy"}{" "}
              Charges
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h4"
              align="center"
              style={{
                alignItems: "center",
                paddingRight: 8,
                background: "#461486",
                color: "white",
              }}
            >
              Your Charges
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ paddingTop: "16px" }}
        >
          First 5 Flow Charge
        </Typography>
        <Grid container spacing={2}>
          {Object.keys(aisensyPlans.tier1.plans)
            .filter((e) => e !== "QUARTERLY")
            .map((plan) => {
              return (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ paddingTop: "8px", color: "#343434" }}
                      >
                        {plan} PLAN(INR)
                      </Typography>
                      <FormHelperText>Enter the amount in INR</FormHelperText>
                      <TextField
                        defaultValue={
                          aisensyPlans.tier1.plans[plan].INR / 100000
                        }
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        disabled
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ paddingTop: "8px", color: "#343434" }}
                      >
                        {plan} PLAN(USD)
                      </Typography>
                      <FormHelperText>Enter the amount in USD</FormHelperText>
                      <TextField
                        defaultValue={
                          aisensyPlans.tier1.plans[plan].USD / 100000
                        }
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ paddingTop: "8px", color: "#343434" }}
                      >
                        {plan} PLAN(INR)
                      </Typography>
                      <FormHelperText>Enter the amount in INR</FormHelperText>
                      <TextField
                        defaultValue={
                          partnerPlan.tier1.plans[plan].INR / 100000
                        }
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          partnerPlan.tier1.plans[plan].INR = Math.round(
                            Number(e.target.value) * 100000
                          );
                          setPartnerPlans(partnerPlan);
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ paddingTop: "8px", color: "#343434" }}
                      >
                        {plan} PLAN(USD)
                      </Typography>
                      <FormHelperText>Enter the amount in USD</FormHelperText>
                      <TextField
                        defaultValue={
                          partnerPlan.tier1.plans[plan].USD / 100000
                        }
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          partnerPlan.tier1.plans[plan].USD = Math.round(
                            Number(e.target.value) * 100000
                          );
                          setPartnerPlans(partnerPlan);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              );
            })}
        </Grid>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ paddingTop: "16px" }}
        >
          Subsequent 10 Flow Charge
        </Typography>
        {Object.keys(aisensyPlans.tier2.plans)
          .filter((e) => e !== "QUARTERLY")
          .map((plan) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ paddingTop: "8px", color: "#343434" }}
                    >
                      {plan} PLAN(INR)
                    </Typography>
                    <FormHelperText>Enter the amount in INR</FormHelperText>
                    <TextField
                      defaultValue={aisensyPlans.tier2.plans[plan].INR / 100000}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ paddingTop: "8px", color: "#343434" }}
                    >
                      {plan} PLAN(USD)
                    </Typography>
                    <FormHelperText>Enter the amount in USD</FormHelperText>
                    <TextField
                      defaultValue={aisensyPlans.tier2.plans[plan].USD / 100000}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ paddingTop: "8px", color: "#343434" }}
                    >
                      {plan} PLAN(INR)
                    </Typography>
                    <FormHelperText>Enter the amount in INR</FormHelperText>
                    <TextField
                      defaultValue={partnerPlan.tier2.plans[plan].INR / 100000}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        partnerPlan.tier2.plans[plan].INR = Math.round(
                          Number(e.target.value) * 100000
                        );
                        setPartnerPlans(partnerPlan);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography
                      variant="body1"
                      gutterBottom
                      style={{ paddingTop: "8px", color: "#343434" }}
                    >
                      {plan} PLAN(USD)
                    </Typography>
                    <FormHelperText>Enter the amount in USD</FormHelperText>
                    <TextField
                      defaultValue={partnerPlan.tier2.plans[plan].USD / 100000}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        partnerPlan.tier2.plans[plan].USD = Math.round(
                          Number(e.target.value) * 100000
                        );
                        setPartnerPlans(partnerPlan);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
      </>
    );
  };

  return (
    <Box>
      <Typography variant="h4" color="primary" noWrap style={{ padding: 20 }}>
        Addons
      </Typography>

      <Grid container>
        {Object.keys(ADDONS)
          .filter((addon) => ADDONS[addon].status === "active")
          .map((e) => {
            return (
              <React.Fragment key={e}>
                <AddOn addon={e} classes={classes} />
              </React.Fragment>
            );
          })}
      </Grid>
      {currentAddon && (
        <ConfirmationDialog
          openDialog={showDialog}
          closeDialog={toggleDialog}
          classes={classes}
          action={updateAddons}
          title={
            (action === "ACTIVATE"
              ? "Enable"
              : action === "MODIFY"
              ? "Modify"
              : "Disable") + ` ${ADDONS[currentAddon].name}`
          }
          body={
            <>
              {action !== "REMOVE" &&
              ["FLOW_BUILDER"].includes(currentAddon) ? (
                <>
                  {"FLOW_BUILDER" === currentAddon &&
                    addons[currentAddon]?.status !== "paused" && (
                      <FlowCountUI />
                    )}
                  <PlanUI />
                </>
              ) : (
                <Typography
                  variant="body1"
                  style={{ color: "black" }}
                  display="inline"
                >
                  Are you sure that you want to{" "}
                  {action === "ACTIVATE"
                    ? "enable"
                    : action === "MODIFY"
                    ? "modify"
                    : "disable"}{" "}
                  this Add-On for this project?
                </Typography>
              )}
            </>
          }
        />
      )}
    </Box>
  );
}
