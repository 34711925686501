import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Link,
} from "@material-ui/core";
import RazorpayAppIcon from "../../static/razorpay-icon.png";
import { appURL } from "../../config/config";
import axios from "axios";
import { CheckCircle, Code, Error, SwapHoriz } from "@material-ui/icons";
import { connect } from "react-redux";

const Logo =
  "https://ucarecdn.com/b8669641-d835-4ec0-a725-c454d90b8aba/-/crop/1600x416/0,585/-/preview/";

class RazorpayAuthPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: true,
      errMsg: "",
    };
  }
  componentDidMount() {
    const queryString = this.props.location.search;
    const searchParams = new URLSearchParams(queryString);
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const integrationPagePath = `/manage/onboarding`;

    // Check authorization status
    if (code) {
      console.log("Code Found!");
      axios
        .post(appURL + "/razorpay/partner/t1/connect", {
          state,
          code,
          domain: window.location.origin,
          partnerId: state,
        })
        .then((response) => {
          this.setState({ isSubmitting: false }, () => {
            setTimeout(() => {
              this.props.history.push(integrationPagePath);
            }, 2000);
          });
        })
        .catch((error) => {
          const { response } = error;
          const errMessage =
            response?.data?.error?.description ||
            response?.data ||
            "Something went wrong! Unable to authenticate";
          this.setState({
            errMsg: errMessage,
            isSubmitting: false,
          });
        });
    } else {
      console.log("Code not found");
      this.setState({
        errMsg: "Something went wrong! Unable to authenticate",
        isSubmitting: false,
      });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item>
            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <img src={Logo} className={classes.icon} />
              </Grid>
              <Grid item>
                <SwapHoriz className={classes.swapIcon} />
              </Grid>
              <Grid item>
                <img src={RazorpayAppIcon} className={classes.icon} />
              </Grid>
            </Grid>
          </Grid>
          {this.state.isSubmitting ? (
            <React.Fragment>
              <Grid item>
                <Box my={7} />
                <Typography
                  variant="h5"
                  paragraph
                  style={{ fontWeight: "bold" }}
                >
                  Don't refresh or close this tab
                </Typography>
              </Grid>
              <Grid item>
                <Box my={4} />
                <Typography variant="h5" paragraph>
                  Please wait! We're setting up your account...
                </Typography>
              </Grid>
              <Grid item>
                <Box my={4} />
                <CircularProgress color="primary" />
              </Grid>
            </React.Fragment>
          ) : !this.state.errMsg ? (
            <React.Fragment>
              <Grid item>
                <Box my={7} />
                <CheckCircle style={{ color: "green", fontSize: 60 }} />
              </Grid>
              <Grid item>
                <Box my={4} />
                <Typography
                  variant="h5"
                  paragraph
                  style={{ fontWeight: "bold" }}
                >
                  Connection Successful !
                </Typography>
              </Grid>
              <Grid item>
                <Box my={4} />
                <Typography variant="h5" paragraph>
                  Redirecting you back to dashboard...
                </Typography>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item>
                <Box my={7} />
                <Error style={{ color: "red", fontSize: 60 }} />
              </Grid>
              <Grid item>
                <Box my={4} />
                <Typography
                  variant="h5"
                  paragraph
                  style={{ fontWeight: "bold" }}
                >
                  {this.state.errMsg}
                </Typography>
              </Grid>
              <Grid item>
                <Box my={4} />
                <Typography variant="h5" paragraph>
                  Try again! Go back to{" "}
                  <Link href={window.location.origin} color="primary">
                    dashboard
                  </Link>
                </Typography>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    boxSizing: "border-box",
    minHeight: "100vh",
    padding: "40px 20px",
  },
  icon: {
    height: 40,
    [theme.breakpoints.down("sm")]: {
      height: 20,
    },
  },
  swapIcon: {
    fontSize: 25,
    color: "grey",
  },
});

const connectedRazorpayAuthPage = connect(
  (state) => ({
    partnerDetail: state.partner.partner,
    agent: state.login.user,
    tenantDetails: state.tenant.tenant,
    partnerAgents: state.partnerAgents.partnerAgents,
  }),
  {}
)(RazorpayAuthPage);

export default withStyles(styles)(connectedRazorpayAuthPage);
