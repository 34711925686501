import React, { Component } from "react";
import {
  withStyles,
  Box,
  Typography,
  Dialog,
  IconButton,
  Snackbar,
  Tooltip,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Visibility, DeleteOutlined, Edit, Clear } from "@material-ui/icons";
import clsx from "clsx";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import DeleteConfirm from "./ConfirmationDialog";
import { withRouter } from "react-router-dom";
import { fetchAssistantsWebhooks } from "../../store/webhookState";
import { connect } from "react-redux";
import axios from "axios";
import { projectURL } from "../../config/config";

const FIELDS = [
  {
    name: "Topics",
    key: "topics",
    width: "largeWidth",
  },
  {
    name: "Webhook Url",
    key: "webhook_url",
    width: "mediumWidth",
  },
  {
    name: "Created At",
    key: "created_at",
    width: "smallWidth",
  },
  {
    name: "Actions", // This is table actions
    key: "tableActions",
    width: "smallWidth",
  },
];

const allTopics = [
  "contact.created",
  "contact.attribute.updated",
  "contact.chat.intervened",
  "contact.chat.requesting",
  "contact.chat.closed",
  "contact.campaign.sent",
  "contact.campaign.read",
  "contact.first_message.added",
  "contact.first_message.removed",
  "contact.tag.added",
  "contact.tag.removed",
];
// const COLORS = {
//   ENABLED: { main: "green", light: "#d4f1d4" },
//   DISABLED: { main: "grey", light: "#fdeeee" },
// };

class WebhookMappingsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      alertMsg: "",
      openDialog: false,
      deleteIndex: null,
      isLoading: true,
      webhooks: [],
      deleteDialog: false,
      webhookDeleteId: "",
      deleteLoading: false,
      deleteWebhookName: "",
      updateDialog: false,
      webhookUpdateId: "",
      updateWebhookName: "",
      updateLoading: false,
      webhookUpdateUrl: "",
      sharedSecret: "",
    };
  }

  openAction = () => {};

  handleDeleteClick = (webhookId, webhookName) => {
    // Pop-up confirmation dialog
    this.setState({
      webhookDeleteId: webhookId,
      deleteWebhookName: webhookName,
      deleteDialog: true,
    });
  };

  deleteConfirm = () => {
    this.setState({ deleteLoading: true });
    axios
      .delete(
        projectURL +
          `/project/${this.props.assistantId}/webhook/${this.state.webhookDeleteId}`
      )
      .then((response) => {
        this.setState(
          {
            webhookDeleteId: "",
            deleteDialog: false,
            deleteLoading: false,
            alertType: "success",
            alertMsg: "Successfully deleted webhook",
          },
          this.props.fetchWebhook
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          deleteLoading: false,
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
        });
      });
  };

  copySnack = () => {
    this.setState({
      alertType: "success",
      alertMsg: "Shared Secret Copied",
    });
  };

  cancelDeletion = (event, reason) => {
    reason !== "backdropClick" && this.setState({ deleteDialog: false });
  };

  handleUpdateClick = (webhookId, webhookName, webhookUrl, shared_secret) => {
    // Pop-up confirmation dialog
    this.setState({
      webhookUpdateId: webhookId,
      updateWebhookName: webhookName,
      updateDialog: true,
      webhookUpdateUrl: webhookUrl,
      sharedSecret: shared_secret,
    });
  };

  updateConfirm = () => {
    this.setState({ updateLoading: true });
    axios
      .patch(
        projectURL +
          `/project/${this.props.assistantId}/webhook/${this.state.webhookUpdateId}`,
        {
          webhook_url: this.state.webhookUpdateUrl,
          topics: this.state.updateWebhookName,
        }
      )
      .then((response) => {
        this.setState(
          {
            updateDialog: false,
            webhookUpdateId: "",
            updateWebhookName: "",
            updateLoading: false,
            alertType: "success",
            alertMsg: "Successfully Updated webhook",
          },
          this.props.fetchWebhook
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          updateLoading: false,
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
        });
      });
  };

  cancelUpdate = (event, reason) => {
    reason !== "backdropClick" && this.setState({ updateDialog: false });
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  // viewEventMapping = (data) => {
  //   const { projectId } = this.props.match.params;
  //   const path = `/projects/${projectId}/integrations/appstore/app/razorpay/set-campaigns/view/${data._id}`;
  //   this.props.history.push(path);
  // };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  render() {
    const { classes, eventMappings, isLoading } = this.props;
    const { deleteIndex } = this.state;
    const mobileScreen = window.innerWidth < 767 ? true : false;
    return (
      <div className={classes.root}>
        <table>
          <tbody>
            <tr className={`${classes.row} ${classes.topbar}`}>
              {FIELDS.map((field, index) => (
                <th
                  key={index}
                  className={clsx(
                    classes.column,
                    classes.dataColumn,
                    classes[field.width]
                  )}
                >
                  <Typography variant="h5" color="primary">
                    {field.name}
                  </Typography>
                </th>
              ))}
            </tr>
            {!this.props.isLoading ? (
              this.props.webhooks && this.props.webhooks.length === 0 ? (
                <div className={classes.noEventsContainer}>
                  <Typography color="textSecondary">
                    {" "}
                    No Activites set yet!
                  </Typography>
                </div>
              ) : (
                <React.Fragment>
                  {this.props.webhooks &&
                    this.props.webhooks.map((e, rowIndex) => (
                      <tr
                        key={rowIndex}
                        className={clsx(classes.row)}
                        // onClick={() => this.openAction(e)}
                      >
                        {FIELDS.map((field, index) => (
                          <th
                            key={index}
                            className={clsx(
                              classes.column,
                              classes.dataColumn,
                              classes[field.width]
                            )}
                          >
                            {this.columnType(e, field, index, rowIndex)}
                          </th>
                        ))}
                      </tr>
                    ))}
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                {[1, 2, 3, 4].map((e, index) => (
                  <tr className={clsx(classes.row)} key={index}>
                    {FIELDS.map((field, index) => (
                      <th
                        key={index}
                        className={clsx(
                          classes.column,
                          classes.dataColumn,
                          classes[field.width]
                        )}
                      >
                        <Skeleton style={{ width: "100%" }} height={40} />
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </table>
        <DeleteConfirm
          openDialog={this.state.deleteDialog}
          closeDialog={this.cancelDeletion}
          deleteLoading={this.state.deleteLoading}
          webhook={this.state.deleteWebhookName}
          action={this.deleteConfirm}
        />
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth={mobileScreen ? "sm" : "md"}
          fullWidth={mobileScreen}
        >
          {/* Template Dteails */}
        </Dialog>
        <Dialog
          open={this.state.updateDialog}
          maxWidth="sm"
          // onClose={this.cancelUpdate}
          aria-labelledby="form-dialog-title"
          className={classes.plandialogContainer}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item container justifyContent="flex-end" xs={12}>
                <IconButton onClick={this.cancelUpdate}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{ alignItems: "center", marginTop: "15px" }}
              >
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Topics</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    multiple
                    options={allTopics}
                    defaultValue={this.state.updateWebhookName}
                    filterSelectedOptions
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        this.setState({ updateWebhookName: value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>WebHook Url</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="webhook url"
                    placeholder="webhook url"
                    defaultValue={this.state.webhookUpdateUrl}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      this.setState({ webhookUpdateUrl: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Shared Secret</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="webhook url"
                    placeholder="webhook url"
                    disabled={true}
                    defaultValue={this.state.sharedSecret}
                    style={{ width: "100%" }}
                    // onChange={(e) => {
                    //   this.setState({ webhookUpdateUrl: e.target.value });
                    // }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Click to copy shared secret">
                          <span>
                            <IconButton
                              size="small"
                              disabled={!this.state.sharedSecret}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.sharedSecret
                                );
                                this.copySnack();
                              }}
                            >
                              <FileCopyIcon fontSize="small" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.updateConfirm}
                style={{ margin: 16 }}
                disabled={this.state.updateLoading}
                endIcon={
                  this.state.updateLoading ? <CircularProgress size={20} /> : ""
                }
              >
                Submit
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }

  columnType = (columnData, field, rowIndex) => {
    const key = field.key;
    switch (key) {
      case "topics":
        return (
          <Tooltip title={columnData[field.key].join().replaceAll(",", ", ")}>
            <Typography variant="body1" display="block" noWrap>
              {columnData[field.key][0]}{" "}
              {columnData[field.key].length > 1
                ? " +" + (columnData[field.key].length - 1)
                : ""}
            </Typography>
          </Tooltip>
        );

      case "created_at":
        return (
          <Typography variant="body1" display="block" noWrap>
            {getFormattedDate(columnData[field.key])}
          </Typography>
        );

      case "tableActions":
        return (
          <Box textAlign="center">
            <IconButton
              color="primary"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() =>
                this.handleUpdateClick(
                  columnData.id,
                  columnData.topics,
                  columnData.webhook_url,
                  columnData.shared_secret
                )
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() =>
                this.handleDeleteClick(columnData.id, columnData.topics.join())
              }
            >
              <DeleteOutlined />
            </IconButton>
          </Box>
        );

      default:
        return (
          <Typography variant="body1" display="block" noWrap>
            {columnData[field.key]}
          </Typography>
        );
    }
  };
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date ? d.toLocaleDateString("en-US", options) : "";
}

const styles = (theme) => ({
  root: {
    // height: "100%",
    width: "100%",
    overflowX: "auto",
    // overflowY: "auto",
    // background: theme.palette.secondary.main,
    boxSizing: "border-box",
    // borderRadius: "8px",
    position: "relative",
    // border: "1px solid lightgrey",
    [theme.breakpoints.down("sm")]: {
      // height: "calc(100vh - 60px)",
    },
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      // background: "pink"
    },
  },
  header: {},
  row: {
    background: "white",
    display: "flex",
    margin: "15px 0px",
    borderRadius: "8px",
    padding: "10px 10px",
    boxSizing: "border-box",
  },
  alterRow: {
    // background: "rgb(245,245,245)"
  },
  topbar: {
    background: "rgb(255,255,255)",
    padding: "0px 10px",
    cursor: "auto",
    // position: "sticky",
    // top: "0",
    // zIndex: "100"
  },
  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  dataColumn: {
    // width: "30%",
    // minHeight: "80px",
    // display: "flex",
    // background: "red"
    // flexWrap: "wrap"
  },
  smallWidth: {
    width: "20%",
    minWidth: "100px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "180px",
    },
  },
  mediumWidth: {
    width: "30%",
    minWidth: 200,
    [theme.breakpoints.down("sm")]: {
      minWidth: "180px",
    },
  },
  largeWidth: {
    width: "30%",
    minWidth: 250,
    [theme.breakpoints.down("sm")]: {
      minWidth: "200px",
    },
  },
  checkboxColumn: {
    width: "60px",
    // background: "red"
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      background: "rgb(252,252,252)",
    },
  },
  noEventsContainer: {
    display: "flex",
    minHeight: "12em",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  plandialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

// export default withStyles(styles)(withRouter(WebhookMappingsTable));

const connectWebhookMappingsTable = connect(
  (state) => ({
    // webhooks: state.webhooks,
  }),
  {
    fetchAssistantsWebhooks,
  }
)(WebhookMappingsTable);

export default withStyles(styles)(withRouter(connectWebhookMappingsTable));
