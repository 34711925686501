import React from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

export default function AgentLimitDialog(props) {
  const { openDialog, closeDialog, classes, currentValue, changeValueFn } =
    props;
  const [value, setValue] = React.useState(currentValue || 1);
  const [isLoading, setLoading] = React.useState(false);
  return (
    <Dialog
      open={openDialog}
      maxWidth="xs"
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className={classes.plandialogContainer}
    >
      <Grid
        item
        container
        justifyContent="flex-end"
        xs={12}
        style={{
          height: 30,
          marginBottom: 10,
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 16,
          paddingRight: 8,
        }}
      >
        <Typography variant="h4">Configure Agents Limit</Typography>
        <IconButton onClick={closeDialog}>
          <Clear />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            container
            style={{
              alignItems: "center",
              paddingRight: "8px",
              marginTop: "15px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
              <Typography>Enter Agents Limit</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter Agents Limit"
                type="number"
                InputProps={{
                  inputProps: { min: 1, max: 100 },
                }}
                name="agentValue"
                onChange={(e) => {
                  let agentSize = e.target.value || 1;
                  if (e.target.value < 1) agentSize = 1;
                  if (e.target.value > 100) agentSize = 100;
                  setValue(agentSize);
                }}
                defaultValue={value}
                style={{ width: "calc(100%)" }}
              />
              {/* style={{ width: "calc(100%)", paddingTop: "0px" }} */}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          xs={12}
          style={{
            alignItems: "flex-end",
            justifyContent: "end",
            paddingRight: "15px",
            marginBottom: "8px",
          }}
        >
          <Button
            // color="primary"
            variant="outlined"
            onClick={closeDialog}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          {/* // assistant.wabaProgressStep === 9 && assistant.familyId */}
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              setLoading(true);
              await changeValueFn(value);
              setLoading(false);
            }}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={20} /> : ""}
          >
            Change Agents Limit
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
