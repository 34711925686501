import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  MenuItem,
  makeStyles,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import dialCodes from "../../../config/dialCodes";
import { stateCodes } from "../../../config/dialCodes";
import axios from "axios";
import { URL } from "../../../config/config";
import { updatePartnerStore } from "../../../store/partnerState";
import LOGO from "../../../static/full_logo.png";

const PartnerBillingAddressDialog = ({
  open,
  onClose,
  showSnackbar,
  ...props
}) => {
  const classes = useStyles();
  const { partnerDetails, tenantDetails } = props;
  const { billingAddress } = partnerDetails || {};

  const initialState = {
    firstName: billingAddress?.firstName || "",
    lastName: billingAddress?.lastName || "",
    email: billingAddress?.email || "",
    phone: billingAddress?.phone || "",
    company: billingAddress?.company || "",
    line1: billingAddress?.line1 || "",
    line2: billingAddress?.line2 || "",
    city: billingAddress?.city || "",
    zip: billingAddress?.zip || "",
    country: billingAddress?.country || "India",
    state: billingAddress?.stateCode || "",
    gstin: billingAddress?.gstin || "",
  };

  const [value, setState] = useState(initialState);
  const [isloading, setLoading] = useState(false);

  const updateState = (key, value) =>
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const updateBillingAddressInDb = () => {
    setLoading(true);
    const {
      firstName,
      lastName,
      email,
      phone,
      company,
      line1,
      line2,
      city,
      zip,
      country,
      state,
      gstin,
    } = value;

    const billingAddress = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim().toLowerCase(),
      phone: phone.trim(),
      company: company.trim(),
      line1: line1.trim(),
      line2: line2.trim(),
      city: city.trim(),
      zip: zip.trim(),
      country: country.trim(),
      stateCode: String(state),
      gstin: gstin.trim(),
    };
    axios
      .post(URL + "/api/update-partner-billing-details", {
        billingAddress,
      })
      .then((response) => {
        props.updatePartnerStore({
          billingAddress: billingAddress,
        });
        showSnackbar({
          alert: true,
          alertMsg: "Billing details updated successfully!",
          alertSeverity: "success",
        });
        onClose();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorResponse = error?.response;
        const errorMessage =
          errorResponse?.data?.errorMessage ||
          "Error updating billing details!";
        showSnackbar({
          alert: true,
          alertMsg: errorMessage,
          alertSeverity: "error",
        });
      });
  };

  const truncateStateName = (stateName) => {
    return stateName.length > 2 ? stateName.substring(0, 2) + "..." : stateName;
  };

  const isDisabled =
    value.firstName.length === 0 ||
    value.lastName.length === 0 ||
    value.city.length === 0 ||
    value.zip.length === 0 ||
    value.country.length === 0 ||
    value.state.length === 0 ||
    value.phone.length === 0 ||
    value.gstin.length === 0 ||
    value.email.length === 0 ||
    value.company.length === 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        classes: {
          root: classes.customDialog,
        },
      }}
    >
      <Card className={classes.card}>
        <CardContent>
          <img
            src={
              Object.keys(tenantDetails).length ? tenantDetails.brandLogo : LOGO
            }
            alt="Logo"
            className={classes.headerImage}
          />
          <Typography
            variant="h5"
            color="black"
            className={classes.dialogTitle}
          >
            Update your billing details
          </Typography>
        </CardContent>
      </Card>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="firstName"
              placeholder="First Name *"
              variant="outlined"
              className={classes.textField}
              required
              onChange={(e) => updateState("firstName", e.target.value)}
              value={value.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="lastName"
              placeholder="Last Name *"
              variant="outlined"
              className={classes.textField}
              required
              onChange={(e) => updateState("lastName", e.target.value)}
              value={value.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              placeholder="Email *"
              required
              variant="outlined"
              className={classes.textField}
              onChange={(e) => updateState("email", e.target.value)}
              value={value.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phone"
              placeholder="Phone *"
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  updateState("phone", value);
                }
              }}
              inputProps={{ maxLength: 10 }}
              className={classes.textField}
              value={value.phone}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="company"
              placeholder="Company Name *"
              variant="outlined"
              required
              onChange={(e) => updateState("company", e.target.value)}
              className={classes.textField}
              value={value.company}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="line1"
              placeholder="Address Line 1 "
              variant="outlined"
              className={classes.textField}
              onChange={(e) => updateState("line1", e.target.value)}
              value={value.line1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="line2"
              placeholder="Address Line 2 "
              variant="outlined"
              className={classes.textField}
              onChange={(e) => updateState("line2", e.target.value)}
              value={value.line2}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="city"
              placeholder="City *"
              variant="outlined"
              className={classes.textField}
              onChange={(e) => updateState("city", e.target.value)}
              value={value.city}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="zip"
              placeholder="Zip *"
              variant="outlined"
              className={classes.textField}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 6) {
                  updateState("zip", value);
                } else {
                  showSnackbar({
                    alert: true,
                    alertMsg: "Please enter a valid postal code!",
                    alertSeverity: "error",
                  });
                }
              }}
              value={value.zip}
              inputProps={{ maxLength: 6 }}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="country"
              placeholder="Country *"
              select
              variant="outlined"
              className={classes.textField}
              required
              disabled={true}
              defaultValue=""
              value={value.country}
              onChange={(e) => updateState("country", e.target.value)}
            >
              {dialCodes.map((country) => (
                <MenuItem key={country.isoCode} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="state"
              variant="outlined"
              className={classes.textField}
              required
              onChange={(e) => updateState("state", e.target.value)}
              value={value.state}
              select
              InputLabelProps={{
                shrink: true,
              }}
              label="State"
            >
              {Object.entries(stateCodes)
                .sort(([keyA], [keyB]) =>
                  keyA.localeCompare(keyB, undefined, { numeric: true })
                )
                .map(([key, stateName]) => (
                  <MenuItem key={key} value={key}>
                    {stateName}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="gstin"
              placeholder="GSTIN *"
              variant="outlined"
              onChange={(e) => {
                const value = (e.target.value || "").toUpperCase();
                if (/^[A-Z0-9]*$/.test(value)) {
                  updateState("gstin", value);
                }
              }}
              required
              className={classes.textField}
              value={value.gstin}
              inputProps={{ maxLength: 15 }}
            />
          </Grid>
        </Grid>

        <Tooltip
          interactive
          enterTouchDelay={0}
          style={{ fontSize: "1.2em" }}
          placement="top"
          title={isDisabled ? "Required fields missing" : ""}
        >
          <span>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.updateButton}
              onClick={() => updateBillingAddressInDb()}
              disabled={isDisabled || isloading}
            >
              {isloading ? <CircularProgress size={24} /> : "Update Billing"}
            </Button>
          </span>
        </Tooltip>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  customDialog: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "25px",
    width: "auto",
    padding: "15px",
  },
  dialogTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },
  card: {
    boxShadow: theme.shadows[3],
    marginBottom: theme.spacing(2),
  },
  MainCard: {
    padding: theme.spacing(2),
  },
  textField: {
    width: "100%",
    height: "100%",
    borderRadius: "3px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      height: "31px",
    },
    "& .Mui-disabled": {
      cursor: "not-allowed",
    },
  },
  updateButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const Partnerbillingaddress = connect(
  (state) => ({
    partnerDetails: state.partner.partner,
    tenantDetails: state.tenant.tenant,
  }),
  { updatePartnerStore }
)(PartnerBillingAddressDialog);

export default Partnerbillingaddress;
