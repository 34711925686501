import React, { Component } from "react";
import axios from "axios";
import Fuse from "fuse.js";
import {
  withStyles,
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Avatar,
  Select,
  Badge,
  MenuItem,
  Snackbar,
  Dialog,
  Tooltip,
  Chip,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Add, Delete, FilterList, Search } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import { connect } from "react-redux";
import { fetchAgents } from "../../../store/partnerAgentsState";
import { URL } from "../../../../src/config/config";
import CreateAgentDialog from "./CreateAgent";
import ConfirmationDialog from "../ConfirmationDialog";
import Header from "../../../commons/Header/Header";
import { Link } from "react-router-dom";

class PartnerAgentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dialogOpen: false,
      alert: "",
      alertMessage: "",
      confirmTask: "",
      confirmId: "",
      filterAnchor: null,
      openFilter: false,
      searchString: "",
      tempSearch: "",
      agentId: null,
      agentName: null,
      query: "",
      searchResults: [],
    };
  }

  componentDidMount() {
    const partnerId = this.props.partnerDetail._id;
    this.props.fetchAgents(partnerId, true);
  }

  onClose = () => {
    this.setState({ dialogOpen: false });
  };

  onConfirmationDialogClose = () => {
    this.setState({ confirmTask: "" });
  };

  confirmDelete = (_id, name) => {
    this.setState({
      confirmId: _id,
      confirmTask: `Deleting Member ${name}`,
    });
  };

  onDelete = (agentId) => {
    const { partnerAgents } = this.props;
    const agents = Object.values(partnerAgents || {});
    const agent = agents.find((agent) => agent._id === agentId);
    axios
      .post(URL + "/api/disable-partner-agent", { agentId })
      .then((response) => {
        this.setState(
          {
            alert: "success",
            alertMessage: `Successfully deleted Member ${agent.name}`,
            confirmTask: "",
            confirmId: "",
          },
          this.loadData
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alert: "error",
          alertMessage: `Network error! Could't delete Member ${agent.name}`,
          confirmTask: "",
          confirmId: "",
        });
      });
  };

  loadData = () => {
    const partnerId = this.props.partnerDetail._id;
    this.props.fetchAgents(partnerId, true);
  };

  onChange = (event) => {
    const query = event.target.value;
    const myfuse = new Fuse(Object.values(this.props.partnerAgents || {}), {
      keys: ["name"],
      minMatchCharLength: 1,
      threshold: 0.6,
    });
    const results = myfuse.search(query);
    const searchResults = results.map((result) => result.item);
    this.setState({
      [event.target.name]: event.target.value,
      searchResults,
    });
  };

  handleRoleChange = (agent, newValue) => {
    const role = newValue;
    const agentId = agent._id;

    axios
      .post(URL + "/api/modify-partner-agent", { agentId, role })
      .then((response) => {
        this.setState(
          {
            alert: "success",
            alertMessage: `Member role modified to ${role}`,
          },
          this.loadData
        );
      })
      .catch((error) => {
        this.setState({
          alert: "error",
          alertMessage: "Unable to change role!",
        });
      });
  };

  onSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    this.setState({ alert: "", alertMessage: "" });
  };

  setAlert = (alert, alertMessage) => {
    this.setState({ alert, alertMessage });
  };

  handleFilterIconClick = (event, agent) => {
    this.setState({
      agentId: agent._id,
      agentName: agent.name,
      filterAnchor: event.currentTarget,
      openFilter: !this.state.openFilter,
    });
  };

  handleFilterClose = (event, reason) => {
    if (reason === "backdropClick") return;
    this.setState({ filterAnchor: null, openFilter: false, agentId: null });
  };

  clearFilters = () => {
    const agentId = this.state.agentId;
    const assistantId = this.props.match.params.projectId;
    this.setState({
      tempSearch: "",
      searchString: "",
    });
  };

  apply = (allFilters) => {
    const agentId = this.state.agentId;

    const assistantId = this.props.match.params.projectId;
    this.setState({
      tempSearch: "",
      searchString: "",
    });
  };

  getFilterState = (allFilters) => {
    this.setState({ allFiltersLocal: { ...allFilters } });
  };

  render() {
    const { classes, loggedAgent, maxAgents, partnerAgents, partnerDetail } =
      this.props;

    const { openFilter, searchResults, query } = this.state;
    const partnerId = partnerDetail._id;
    const agents = !query ? Object.values(partnerAgents || {}) : searchResults;
    const { dialogOpen } = this.state;
    const assistantId = this.props.match.params.projectId;
    const agentQuota = maxAgents || 10;
    return (
      <div className={classes.root}>
        <Header businessTitle={"Team Management"} />
        {/* <Grid
          container
          justify="center"
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs>
            <Box pl={{ xs: 2, md: 3 }}>
              <Typography variant="h3">Agents</Typography>
            </Box>
          </Grid>
        </Grid> */}
        <Box my={3} />

        <Grid container justify="center">
          <div className={classes.QuotaDisplay}>
            <Grid container>
              <Grid item xs>
                <Box pb={1} width="100%">
                  <Typography variant="h5">Total Quota</Typography>
                </Box>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item>
                    <Typography color="primary" variant="h3">
                      {agentQuota}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" color="textSecondary">
                      Members
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Box display="flex" alignItems="center">
                <TextField
                  placeholder="Search"
                  color="primary"
                  value={query}
                  name="query"
                  onFocus={() => {
                    this.setState({ inputFocused: true });
                  }}
                  onBlur={() => {
                    this.setState({ inputFocused: false });
                  }}
                  className={classes.textFieldRoot}
                  onChange={this.onChange}
                  onKeyDown={this.handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ fontSize: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Tooltip
                  placement="top"
                  interactive
                  title={
                    !partnerDetail.roles ||
                    !Object.keys(partnerDetail.roles).length ? (
                      <Link to="/manage/roles">
                        {" "}
                        <Typography variant="caption" color="primary">
                          Create a role to add members →
                        </Typography>
                      </Link>
                    ) : agents.filter((a) => !a.disabled).length > maxAgents ? (
                      "Max Members reached"
                    ) : loggedAgent.agentRole !== "OWNER" ? (
                      "Owner can only add members"
                    ) : (
                      ""
                    )
                  }
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Add />}
                      disabled={
                        !partnerDetail.roles ||
                        !Object.keys(partnerDetail.roles).length ||
                        loggedAgent.agentRole !== "OWNER" ||
                        agents.filter((a) => !a.disabled).length > maxAgents
                      }
                      onClick={() => {
                        this.setState({ dialogOpen: true });
                      }}
                    >
                      Add Member
                    </Button>
                  </div>
                </Tooltip>
              </Box>

              {/* <Box display="flex" alignItems="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    href="https://wa.aisensy.com/iHmkul"
                    target="_blank"
                    // onClick={this.toggleUprade}
                    className={classes.margin}
                  >
                    Request More Agents
                  </Button>
                </Box> */}
            </Grid>
          </div>
          <Box m="1em 2em" width="100%" borderRadius="8px">
            {/* <Grid container justify="space-between" alignItems="center">
              <Grid item xs>
                <Typography variant="body1" gutterBottom color="textSecondary">
                  Your team for management.
                </Typography>
              </Grid>
              <TextField
                placeholder="Search"
                color="primary"
                value={query}
                name="query"
                onFocus={() => {
                  this.setState({ inputFocused: true });
                }}
                onBlur={() => {
                  this.setState({ inputFocused: false });
                }}
                className={classes.textFieldRoot}
                onChange={this.onChange}
                onKeyDown={this.handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ fontSize: 20 }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Tooltip
                placement="top"
                interactive
                title={
                  !partnerDetail.roles ||
                  !Object.keys(partnerDetail.roles).length ? (
                    <Link to="/manage/roles">
                      {" "}
                      <Typography variant="caption" color="primary">
                        Create a role to add members →
                      </Typography>
                    </Link>
                  ) : agents.filter((a) => !a.disabled).length > maxAgents ? (
                    "Max Members reached"
                  ) : loggedAgent.agentRole !== "OWNER" ? (
                    "Owner can only add members"
                  ) : (
                    ""
                  )
                }
              >
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    disabled={
                      !partnerDetail.roles ||
                      !Object.keys(partnerDetail.roles).length ||
                      loggedAgent.agentRole !== "OWNER" ||
                      agents.filter((a) => !a.disabled).length > maxAgents
                    }
                    onClick={() => {
                      this.setState({ dialogOpen: true });
                    }}
                  >
                    Add Member
                  </Button>
                </div>
              </Tooltip>
            </Grid> */}
          </Box>
          <Grid container className={classes.container} spacing={2}>
            {agents.map((agent, index) => {
              if (!agent) {
                return null;
              }
              return agent.disabled ? (
                ""
              ) : (
                <Grid item xs={12} md={4}>
                  <div className={classes.card}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        margin: "20px 0px",
                      }}
                    >
                      <Avatar
                        alt="A"
                        className={classes.avatar}
                        style={{
                          color: "black",
                        }}
                      >
                        {agent.name.substring(0, 1).toUpperCase()}
                      </Avatar>

                      <Typography variant="h4" color="primary">
                        {agent.name.toUpperCase()}
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        marginBottom: "20px",
                        height: "60px",
                      }}
                    >
                      <Box width={"65%"}>
                        <Typography variant="h6" gutterBottom>
                          Username
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{
                            marginLeft: "0px",
                            wordWrap: "break-word",
                          }}
                        >
                          {agent.loginUsername}
                        </Typography>
                      </Box>
                    </div>

                    {agent.agentRole === "OWNER" ? (
                      <Box display="flex" height={"40px"}>
                        <Typography variant="h4" color="primary" align="center">
                          Owner
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        boxSizing="border-box"
                      >
                        <Autocomplete
                          value={agent.agentRole}
                          options={
                            partnerDetail?.roles
                              ? Object.keys(partnerDetail.roles)
                              : []
                          }
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) =>
                            this.handleRoleChange(agent, newValue)
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                          className={classes.autocomplete}
                          clearOnEscape={false}
                          disableClearable
                        />
                        <IconButton
                          onClick={() => {
                            this.confirmDelete(agent._id, agent.name);
                          }}
                        >
                          <Box display="flex" color="red">
                            <Delete />
                          </Box>
                        </IconButton>
                      </Box>
                    )}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <CreateAgentDialog
          dialogOpen={dialogOpen}
          onClose={this.onClose}
          loadData={this.loadData}
          setAlert={this.setAlert}
          partnerId={partnerId || ""}
          allRoles={partnerDetail.roles || {}}
        />
        <ConfirmationDialog
          open={!!this.state.confirmTask}
          taskMessage={this.state.confirmTask}
          onClose={this.onConfirmationDialogClose}
          onConfirm={() => this.onDelete(this.state.confirmId)}
        />
        <Snackbar
          open={!!this.state.alert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
        >
          <Alert severity={this.state.alert} onClose={this.onSnackbarClose}>
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
        <Dialog
          open={openFilter}
          onClose={this.handleFilterClose}
          maxWidth="md"
          fullWidth={true}
          classes={{ root: classes.dialogContainer }}
        >
          <Box p="2em 5%">
            <Grid container justify="flex-end">
              <Button variant="outlined" onClick={this.handleFilterClose}>
                Close
              </Button>
            </Grid>
          </Box>
        </Dialog>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
  },
  qualityStatus: {
    padding: "4px",
    color: "white",
    fontWeight: "600",
  },
  dialogContainer: {
    zIndex: "3400 !important",
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    boxShadow: "0 0 11px rgb(230 230 230)",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  progress: {
    position: "fixed",
    top: "8em",
    right: "44.5vw",
  },
  select: {
    width: "7em",
    padding: "0px",
    "& .MuiSelect-select": {
      padding: "0px !important",
    },
  },
  autocomplete: {
    width: "43%",
    "& .MuiTextField-root": {
      padding: "0px !important",
      backgroundColor: "transparent",
    },
  },
  card: {
    // margin: "0.7em",
    backgroundColor: "white",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    padding: "18px 8px 8px 24px",
    justifyContent: "center",
    alignItems: "start",
    borderRadius: "8px",
    height: "15em",
    position: "relative",
  },
  container: {
    padding: "0em 2em",
    boxSizing: "border-box",
    marginBottom: "7em",
  },
  avatar: {
    fontSize: "2em",
    height: "2em",
    width: "2em",
    backgroundColor: "#F5EFDF",
  },
  QuotaDisplay: {
    padding: "15px 20px",
    backgroundColor: "rgb(237, 226, 252)",
    borderRadius: "8px",
    width: "100%",
    margin: "0 2em",
    marginBottom: "0em",
  },
  agentName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    boxSizing: "border-box",
  },
  guideContainer: {
    background: "white",
    borderRadius: "8px",
  },
  textFieldRoot: {
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      marginBottom: "8px",
      marginRight: "6px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0px 8px",
    },
  },
});

const connectedAgentsPage = connect(
  (state) => ({
    loggedAgent: state.login.user,
    partnerDetail: state.partner.partner,
    maxAgents: state.partnerAgents.maxAgents,
    maxRoles: state.partnerAgents.maxRoles,
    partnerAgents: state.partnerAgents.partnerAgents,
    isLoading: state.partnerAgents.isLoading,
  }),
  { fetchAgents }
)(PartnerAgentsPage);

export default withStyles(styles)(connectedAgentsPage);
