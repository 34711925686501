import React from "react";
import { withStyles, Typography, Box, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import getFormattedDate from "../../helpers/formatDate";

function ProjectCard(props) {
  const { classes, project, history, isRedirectedFrom360 } = props;
  const {
    isWhatsappVerified,
    activePlan,
    status,
    templateCredit,
    subscriptionScheduled,
    planStoppedOn,
  } = project;

  const plan =
    !activePlan || activePlan === "NONE"
      ? subscriptionScheduled?.planName
        ? subscriptionScheduled.planName
        : "No plan yet"
      : activePlan;

  let type = "NA";
  if (plan)
    for (const val of ["Basic", "Pro", "Enterprise"])
      if (plan.toLowerCase().includes(val.toLowerCase())) {
        type = val;
        break;
      }

  const subscriptionStatus =
    status === "stopped"
      ? "Stopped"
      : subscriptionScheduled
      ? "Inactive"
      : "Active";

  return (
    <div className={classes.root}>
      <Grid container style={{ height: "auto" }}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom className={classes.projectName}>
            {project.assistantName}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box pt={{ xs: 2, md: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Subscription Status
                </Typography>
                <Typography
                  variant="h4"
                  style={{ marginLeft: "0px", color: statusColor(project) }}
                >
                  {subscriptionStatus}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box pt={{ xs: 2, md: 2 }}>
                <Typography variant="h5" gutterBottom>
                  WCC Credit
                </Typography>
                <Typography
                  variant="h4"
                  style={{ marginLeft: "0px", fontWeight: 600 }}
                  color="primary"
                >
                  {(templateCredit ?? 0) / 100000}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box pt={{ xs: 2, md: 2 }}>
                <Typography variant="h5" gutterBottom>
                  WABA Status
                </Typography>
                <Typography
                  variant="h4"
                  style={{ marginLeft: "0px", color: statusColor(project) }}
                >
                  {isWhatsappVerified ? "Live" : "Not Live"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box pt={{ xs: 2, md: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Plan Type
                </Typography>
                <Typography
                  variant="h4"
                  style={{ marginLeft: "0px", fontWeight: 600 }}
                  color="primary"
                >
                  {type}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box pt={{ xs: 5, md: 5 }}>
            <Typography variant="body2" component="span" color="textSecondary">
              Created at {getFormattedDate(project.createdAt)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end" style={{ height: "100%" }}>
            <Button
              variant="outlined"
              size="medium"
              margin={0}
              className={classes.formButton}
              color="primary"
              fullWidth
              onClick={() => {
                history.push("/assistants/" + project._id);
              }}
            >
              View
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

function statusColor(project) {
  if (project.isWhatsappVerified) {
    return "green";
  } else {
    return "grey";
  }
}

const styles = (theme) => ({
  root: {
    width: "calc(100% - 40px)",
    borderRadius: "8px",
    height: "calc(100% - 40px)",
    padding: "20px",
    // cursor: "pointer",
    backgroundColor: "white",
    // transition: "0.3s",
    // "&:hover": {
    // 	backgroundColor: "whitesmoke"
    // }
  },
  formButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // backgroundColor: "rgb(237, 226, 252)",
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: "2px solid rgb(70, 20, 134) ",
    "&:hover": {
      backgroundColor: "rgb(237, 226, 252)",
    },
  },
  projectName: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
});

export default withStyles(styles)(withRouter(ProjectCard));
