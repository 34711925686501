import React, { Component } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  withStyles,
  Grid,
  Button,
  Typography,
  InputAdornment,
  Box,
} from "@material-ui/core";

import { URL } from "../../../../src/config/config";
import dialCodes from "../../../../src/config/dialCodes";
import { Autocomplete } from "@material-ui/lab";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;

class CreateAgentDialog extends Component {
  state = {
    userName: "",
    name: "",
    password: "",
    email: "",
    country: "India",
    role: "",
    phoneNumber: "",
    nameError: false,
    emailError: false,
    displayNameError: false,
    passwordError: true,
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === "") {
      this.setState({ [event.target.name + "Error"]: true });
    } else {
      if (event.target.name === "userName") {
        if (emailRegex.test(event.target.value)) {
          this.setState({ [event.target.name + "Error"]: true });
        } else {
          this.setState({ [event.target.name + "Error"]: false });
        }
      } else if (event.target.name === "email") {
        if (!emailRegex.test(event.target.value)) {
          this.setState({ [event.target.name + "Error"]: true });
        } else {
          this.setState({ [event.target.name + "Error"]: false });
        }
      } else {
        this.setState({ [event.target.name + "Error"]: false });
      }
    }
  };

  onCancel = () => {
    this.setState(
      {
        userName: "",
        name: "",
        password: "",
        email: "",
        phoneNumber: "",
        country: "India",
        nameError: false,
        emailError: false,
        displayNameError: false,
        passwordError: true,
      },
      this.props.onClose()
    );
  };

  handleAutoComplete = (field, value, reason) => {
    if (reason == "select-option") {
      this.setState({ country: value });
    }
  };

  onSubmit = () => {
    const { userName, name, password, email, country, phoneNumber, role } =
      this.state;
    const { partnerId } = this.props;

    const newPartnerAgent = {
      name,
      email,
      loginUsername: userName,
      loginPassword: password,
      contact: this.getDialCode(country).slice(1) + phoneNumber,
      partnerId,
      agentRole: role,
    };

    axios
      .post(URL + "/api/create-partner-agent", newPartnerAgent)
      .then((response) => {
        this.props.setAlert("success", `Member Created Succesfully`);
        this.props.loadData();
        this.onCancel();
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          const message = error.response?.data?.message;
          if (message === "User Name already exist!") {
            this.setState({ userName: "", password: "" });
          }
          this.props.setAlert("error", message);
        } else {
          this.props.setAlert("error", "some error occured");
        }
      });
  };

  getDialCode = () => {
    const result = dialCodes.filter((e) => e.name == this.state.country);
    return result[0].dialCode;
  };

  render() {
    const { classes, dialogOpen, onClose, allRoles } = this.props;
    const {
      nameError,
      emailError,
      displayNameError,
      passwordError,
      userName,
      email,
      phoneNumber,
      name,
      password,
      country,
      role,
    } = this.state;

    const roleOptions = Object.keys(allRoles).map((role) => {
      return role;
    });

    return (
      <Dialog open={dialogOpen} onClose={onClose} disableBackdropClick>
        <DialogTitle style={{ padding: "28px 32px 8px" }}>
          <Typography variant="h3">Create Member</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" className={classes.content}>
            <TextField
              type="text"
              variant="outlined"
              placeholder="Member's Name"
              onChange={this.onChange}
              className={classes.input}
              error={this.state.displayNameError}
              margin="dense"
              name="name"
            />
            <TextField
              type="text"
              variant="outlined"
              placeholder="Member's Email"
              onChange={this.onChange}
              className={classes.input}
              error={this.state.emailError}
              margin="dense"
              name="email"
              helperText={emailError ? "Invalid email" : ""}
            />
            <Box margin="0 0.5em">
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Autocomplete
                    disableClearable
                    value={country}
                    options={dialCodes.map((option) => option.name)}
                    onChange={(e, value, reason) =>
                      this.handleAutoComplete("country", value, reason)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ padding: "15px 15px 11px" }}
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select country"
                        name="Dial code"
                        margin="dense"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    classes={{
                      root: `${classes.textFieldRoot} ${classes.numberField}`,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="body1">
                            {this.getDialCode()}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    onChange={this.onChange}
                    name="phoneNumber"
                    placeholder="Member's WhatsApp Number"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box margin="0 0.5em">
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  value={role}
                  options={roleOptions}
                  onChange={(e, value, reason) =>
                    this.setState({ ...this.state, role: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ padding: "15px 15px 11px" }}
                      InputProps={{ ...params.InputProps }}
                      placeholder="Select role"
                      name="role"
                      margin="dense"
                    />
                  )}
                />
              </Grid>
            </Box>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ margin: "24px 0 12px 12px" }}
            >
              Login details
            </Typography>
            <TextField
              type="text"
              variant="outlined"
              value={userName}
              placeholder="User Name"
              onChange={this.onChange}
              className={classes.input}
              margin="dense"
              autoFocus
              name="userName"
              error={nameError}
              helperText={
                nameError ? "Username can't be empty or in email format" : ""
              }
            />
            <TextField
              type="password"
              variant="outlined"
              value={password}
              placeholder="Password"
              onChange={this.onChange}
              className={classes.input}
              error={this.state.passwordError}
              margin="dense"
              name="password"
              autoComplete="new-password"
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: "8px 32px 16px" }}>
          <Button variant="outlined" onClick={this.onCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.onSubmit}
            disabled={
              !role ||
              !userName ||
              !name ||
              !password ||
              !email ||
              phoneNumber.length < 7 ||
              nameError ||
              displayNameError ||
              emailError ||
              passwordError
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  input: {
    margin: "0.5em",
  },
  content: {
    width: "70vw",
    maxWidth: "500px",
  },
});

export default withStyles(styles)(CreateAgentDialog);
