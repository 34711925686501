import axios from "axios";
import { URL } from "../config/config";

const initialState = {
  allPlanFamilies: {},
  isLoading: false,
  error: null,
};

const LOAD_START = "PlanFamilies/LOAD_START";
const LOAD_SUCCESS = "PlanFamilies/LOAD_SUCCESS";
const EDIT_PLAN = "PlanFamilies/EDIT_PLAN";
const ADD_PLAN = "PlanFamilies/ADD_PLAN";
const LOAD_FAILURE = "PlanFamilies/LOAD_FAILURE";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = (payload) => ({
  type: LOAD_SUCCESS,
  payload,
});

const editPlan = (payload) => ({
  type: EDIT_PLAN,
  payload,
});

const addPlan = (payload) => ({
  type: ADD_PLAN,
  payload,
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const fetchPlanFamilies = () => (dispatch, getState) => {
  const { user } = getState().login;
  if (user.isReferral) {
    return;
  }
  dispatch(loadStart());
  axios
    .get(URL + "/api/get-partner-plan-families")
    .then((response) => {
      const { planFamily } = response.data;
      const plansObj = {};
      for (const value of planFamily) plansObj[value._id] = value;
      dispatch(loadSuccess(plansObj));
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export const modifyPlan = (payload) => (dispatch) => {
  dispatch(editPlan(payload));
};

export const addNewPlan = (payload) => (dispatch) => {
  dispatch(addPlan(payload));
};

export default function PlanFamilyReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        allPlanFamilies: { ...payload },
        isLoading: false,
        error: null,
      };

    case EDIT_PLAN:
      return {
        ...state,
        allPlanFamilies: {
          ...state.allPlanFamilies,
          [payload.planId]: {
            ...state.allPlanFamilies[payload.planId],
            plans: payload.plans,
          },
        },
        isLoading: false,
        error: null,
      };

    case ADD_PLAN:
      return {
        ...state,
        allPlanFamilies: {
          ...state.allPlanFamilies,
          [payload._id]: { ...payload },
        },
        isLoading: false,
        error: null,
      };

    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
