import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { URL } from "../../../config/config";
import { modifyWhatsappPaymentPlan } from "../../../store/whatsappPaymentsPlanState";
import { connect } from "react-redux";

class WhatsappPaymentsDialogComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      alertMsg: "",
      partnerSlabs: [...(this.props.partnerSlabs || [])],
      currency: "",
    };
  }

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  savePlan = () => {
    const slabs = this.state.partnerSlabs;
    const activeId = this.props.activeId;
    const payload = { slabs };
    axios
      .patch(URL + `/api/whatsapp-payments-plans/${activeId}`, payload)
      .then((_) => {
        this.props.triggerAlert({
          alertType: "success",
          alertMsg: "Whatsapp Payment Plan updated successfully",
        });
        this.props.toggleDialog();
        this.props.modifyWhatsappPaymentPlan({ ...payload, id: activeId });
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, try again",
        });
      });
  };

  render() {
    const {
      classes,
      aisensySlabs,
      convertPrice,
      openDialog,
      toggleDialog,
      currency,
      disableFields,
    } = this.props;

    const { partnerSlabs, margins } = this.state;

    return partnerSlabs?.length <= 0 ? (
      <CircularProgress />
    ) : (
      <>
        <Dialog open={openDialog} closeDialog={toggleDialog} maxWidth={"sm"}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle className={classes.dialogTitle}>
              <Typography variant="h3">
                Whatsapp Payments Plan Family Details
              </Typography>
            </DialogTitle>
          </Box>
          <Box
            style={{ padding: "24px 16px 16px 24px" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <DialogContent>
              <Grid
                container
                alignItems="center"
                className={classes.content}
                spacing={0}
              >
                <Grid container>
                  <Grid item md={3}></Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      AiSensy Prices
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      Margin
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      Your Platform Prices
                    </Typography>
                  </Grid>
                </Grid>
                {aisensySlabs?.map((slab, idx) => {
                  const { startAmount, INR } = slab;
                  return (
                    <Grid container>
                      <Grid item xs={12} md={3} container alignItems="center">
                        <Typography
                          required
                          className={classes.typo}
                          variant="h5"
                          align="left"
                        >
                          Slab {idx + 1}
                          <br />
                          <span
                            style={{
                              color: "gray",
                              fontWeight: 500,
                              letterSpacing: 0.5,
                            }}
                          >
                            ₹{startAmount / convertPrice} {" - "}
                            {partnerSlabs?.[idx + 1]?.startAmount
                              ? `₹${
                                  partnerSlabs?.[idx + 1].startAmount /
                                    convertPrice -
                                  1
                                }`
                              : "beyond"}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} container alignItems="center">
                        <TextField
                          style={{
                            width: "100%",
                            padding: "3px 0px",
                            marginRight: 10,
                          }}
                          required
                          type="Number"
                          variant="outlined"
                          disabled="true"
                          placeholder="0"
                          className={classes.input}
                          error={this.state.displayNameError}
                          margin="dense"
                          value={INR?.fee / convertPrice}
                          InputProps={{ startAdornment: "₹" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} container alignItems="center">
                        <TextField
                          style={{
                            width: "100%",
                            padding: "3px 0px",
                            marginRight: 10,
                          }}
                          required
                          type="Number"
                          variant="outlined"
                          placeholder="0"
                          disabled={disableFields}
                          onChange={(e) => {
                            let value = Number(e.target.value) * 100000 ?? 0;
                            if (value < 0) value = 0;
                            partnerSlabs[idx].INR.fee = value + INR.fee;

                            this.setState({ partnerSlabs });
                          }}
                          className={classes.input}
                          error={this.state.displayNameError}
                          defaultValue={
                            (partnerSlabs[idx].INR.fee - INR.fee) / convertPrice
                          }
                          margin="dense"
                          InputProps={{
                            startAdornment: "₹",
                            inputProps: { min: 0 },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} container alignItems="center">
                        <TextField
                          style={{ width: "100%", padding: "3px 0px" }}
                          required
                          type="Number"
                          variant="outlined"
                          placeholder="0"
                          disabled="true"
                          className={classes.input}
                          error={this.state.displayNameError}
                          margin="dense"
                          value={partnerSlabs[idx].INR.fee / convertPrice}
                          InputProps={{
                            startAdornment: "₹",
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "24px 16px 8px 0px" }}>
              <Button
                variant={!this.props.disableFields ? "outlined" : "contained"}
                size="medium"
                color="primary"
                onClick={() => {
                  toggleDialog();
                }}
              >
                {this.props.disableFields ? "Close" : "Cancel"}
              </Button>
              {!this.props.disableFields && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={this.savePlan}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const WhatsappPaymentsDialog = connect(
  (state) => ({
    partnerWhatsappPaymentsFamily: state.whatsappPaymentsPlans.allPlans,
  }),
  {
    modifyWhatsappPaymentPlan,
  }
)(WhatsappPaymentsDialogComponent);

export default WhatsappPaymentsDialog;
