const mouldBillingMessages = (props, type) => {
  const {
    partnerPlanFamily,
    partnerBillingLogs,
    page,
    rowsPerPage,
    isPlansLoading,
    isBillingLoading,
  } = props;

  const bills = JSON.parse(
    JSON.stringify(
      partnerBillingLogs.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    )
  );

  if (isPlansLoading || isBillingLoading) {
    return Array(10)
      .fill(0)
      .map((item) => ({ name: "Loading" }));
  }

  const mouldMethod = type !== "DIRECT" ? mouldBill : mouldDirectBill;
  for (let i = 0; i < bills.length; i++) {
    bills[i] = mouldMethod(bills[i], partnerPlanFamily);
  }

  return bills;
};

const mouldBill = (bill, partnerPlanFamily) => {
  const arr = bill.message.split('"');
  if (bill.reasonCode === "PLAN_PURCHASE") {
    //Purchased new plan "basic_one_month" from the plan family: "639b1988585e2ebbe8fe95c"
    if (arr.length < 4) return;
    const id = arr[3];
    const planKey = arr[1];
    arr[3] = partnerPlanFamily[id]?.name ?? "NA Family";
    arr[1] = partnerPlanFamily[id]?.plans[planKey]?.name ?? arr[1];
    bill.message = arr.join(" ");
  }
  if (bill.reasonCode === "PLAN_SWITCH") {
    //Plan switched from "basic_one_month" of "63a961c944ed1931ac617113" to "pro_one_month" of "63a961c944ed1931ac617113"
    if (arr.length < 8) return;
    const id1 = arr[3];
    const id2 = arr[7];
    const planKey1 = arr[1];
    const planKey2 = arr[5];
    arr[1] = partnerPlanFamily[id1]?.plans[planKey1].name ?? arr[1];
    arr[3] = partnerPlanFamily[id1]?.name ?? "NA FAMILY";
    arr[5] = partnerPlanFamily[id2]?.plans[planKey2]?.name ?? arr[5];
    arr[7] = partnerPlanFamily[id2]?.name ?? "NA FAMILY";
    bill.message = arr.join("");
  }
  if (bill.reasonCode === "PLAN_RENEWED") {
    //Renewed plan "basic_one_month" from the plan family: "639b1988585e2e4bbe8fe95c"
    if (arr.length < 4) return;
    const id = arr[3];
    const planKey = arr[1];
    arr[3] = partnerPlanFamily[id]?.name ?? "NA FAMILY";
    arr[1] = partnerPlanFamily[id]?.plans[planKey]?.name ?? arr[1];
    bill.message = arr.join(" ");
  }

  return bill;
};

const mouldDirectBill = (bill) => {
  if (bill.reasonCode === "PLAN_PURCHASE") {
    //Purchased new plan "basic_one_month"
    bill.message = `Purchased new plan "Basic Monthly"`;
  }
  if (bill.reasonCode === "PLAN_RENEWED") {
    //Renewed plan "BASIC_MONHTLY"
    bill.message = `Renewed plan "Basic Monthly"`;
  }

  return bill;
};

export default {
  mouldBillingMessages,
  mouldBill,
  mouldDirectBill,
};
