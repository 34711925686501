import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
} from "@material-ui/core";
import {
  FiberManualRecord,
  FileCopyOutlined,
  FilterNone,
  VisibilityOutlined,
  Edit,
  Clear,
  Flag,
} from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import { connect } from "react-redux";
import { getUnavailablePlanFamilies } from "../../store/planFamilyState";
import { toHaveStyle } from "@testing-library/jest-dom";
import { fetchWccPlans } from "../../store/wccPlanState";
import { URL } from "../../config/config";
import axios from "axios";
import {
  addPlanFamily,
  updateWccFamily,
  updateWhatsappPaymentsPlanFamily,
  fetchPartnerDetails,
} from "../../store/partnerState";
import plansHelper from "../../helpers/plansHelper";
import { checkAccess } from "../../helpers/RBAC";
class PlanFamilyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      isLoading: true,
      openPlanDialog: false,
      planFamily: {},
      changePlan: "",
      slectedPlans: "",
      openSelectPlanDialog: false,
      openWccDialog: false,
      changePlanFamily: "",
      wccPlanFamily: {},
      changeWccPlanFamily: "",
      planSubmitLoader: false,
      defaultWccPlan: "",
      defaultPlanName: "",
      defaultPlanFamily: "",
      defaultWhatsappPaymentsPlan: "",
      dialog: null,
      isToggleLoading: { signUpEnabled: false, trialEnabled: false },
      trialAmount: this.props.partner.trialAmount / 100000 || 0,
    };
  }

  componentDidMount() {
    plansHelper.setAllPlans();
    this.setState({
      isLoading: false,
      trialAmount: this.props.partner.trialAmount / 100000 || 0,
    });
  }
  planSubmit = () => {
    this.setState({
      planSubmitLoader: true,
    });
    const firstPlan =
      this.state.planFamily &&
      this.state.planFamily[Object.keys(this.state.planFamily)[0]]?.plans;
    const renderFirstPlan = firstPlan && Object.keys(firstPlan)[0];

    const updatePlanFamily = this.state.changePlanFamily
      ? this.state.changePlanFamily
      : this.state.defaultPlanFamily
      ? this.state.defaultPlanFamily
      : Object.keys(this.state.planFamily)[0];

    const updateDefualtPlan = this.state.changePlan
      ? this.state.changePlan
      : this.state.defaultPlanName
      ? this.state.defaultPlanName
      : renderFirstPlan;

    axios
      .patch(URL + `/api/update-partner-config`, {
        defaultPlanFamily: updatePlanFamily,
        defaultPlanName: updateDefualtPlan,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "Plan family updated successfully",
          planSubmitLoader: false,
          openPlanDialog: false,
          defaultPlanFamily: updatePlanFamily,
          defaultPlanName: updateDefualtPlan,
        });
        this.props.addPlanFamily({
          defaultPlanFamily: updatePlanFamily,
          defaultPlanName: updateDefualtPlan,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "Plan family update failed",
          planSubmitLoader: false,
        });
      });
  };

  wccPlanSubmit = () => {
    this.setState({
      planSubmitLoader: true,
    });

    const updateDefaultWcc = this.state.changeWccPlanFamily
      ? this.state.changeWccPlanFamily
      : this.state.defaultWccPlan
      ? this.state.defaultWccPlan
      : Object.keys(this.state?.wccPlanFamily)[0];

    axios
      .patch(URL + `/api/update-partner-config`, {
        defaultWccPlan: updateDefaultWcc,
      })
      .then((response) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "WCC Plan family updated successfully",
          planSubmitLoader: false,
          openWccDialog: false,
          defaultWccPlan: updateDefaultWcc,
        });
        this.props.updateWccFamily({ defaultWccPlan: updateDefaultWcc });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "WCC Plan family update failed",
          planSubmitLoader: false,
        });
      });
  };

  changeWhatsappPaymentDefaultPartnerPlan = () => {
    this.setState({
      planSubmitLoader: true,
    });

    const { defaultWhatsappPaymentsPlan } = this.state;
    axios
      .patch(URL + `/api/update-partner-config`, {
        defaultWhatsappPaymentsPlan,
      })
      .then((_) => {
        this.setState({
          alert: true,
          alertSeverity: "success",
          alertMsg: "WhatsApp Payments Plan family updated successfully",
          planSubmitLoader: false,
          dialog: undefined,
        });
        this.props.updateWhatsappPaymentsPlanFamily({
          defaultWhatsappPaymentsPlan,
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg: "WhatApp Payments Plan family update failed",
          planSubmitLoader: false,
        });
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState(
        {
          trialAmount: this.props.partner.trialAmount / 100000 || 0,
          planFamily: { ...this.props?.planFamilies },
          defaultPlanFamily: this.props?.partner?.defaultPlanFamily
            ? this.props?.partner?.defaultPlanFamily
            : Object.keys(this.props?.planFamilies)[0],
          defaultPlanName: this.props?.partner?.defaultPlanName,
          defaultWccPlan: this.props?.partner?.defaultWccPlan,
          wccPlanFamily: { ...this.props.wccPlans },
        },
        () => {
          this.setState({
            plans:
              this.props?.planFamilies?.[this?.state?.defaultPlanFamily]?.[
                "plans"
              ],
          });
        }
      );
    }
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  planDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openPlanDialog: !this.state.openPlanDialog });
  };

  SelectWccDialog = (event, reason) => {
    this.setState({ openWccDialog: !this.state.openWccDialog });
  };

  selectPlanDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openSelectPlanDialog: !this.state.openSelectPlanDialog });
  };

  toggleDialog = (dialog) => {
    if (this.state.dialog === dialog) {
      dialog = undefined;
    }

    this.setState({ dialog });
  };

  render() {
    const {
      classes,
      match,
      partner,
      partnerAgents,
      user,
      whatsappPaymentPlans,
    } = this.props;
    const isSignUpEnabled = partner?.signUpEnabled;
    const isTrialEnabled = partner?.trialEnabled;
    const {
      planFamily,
      changePlanFamily,
      changePlan,
      plans,
      changeWccPlanFamily,
      defaultWccPlan,
      wccPlanFamily,
      defaultPlanFamily,
      defaultPlanName,
      defaultWhatsappPaymentsPlan,
      dialog,
    } = this.state;

    const { addons: partnerAddons } = partner;
    const { WHATSAPP_PAY } = partnerAddons || {};

    const firstPlan =
      planFamily && planFamily[Object.keys(planFamily)[0]]?.plans;

    const renderFirstPlan = firstPlan && Object.keys(firstPlan)[0];

    const renderPlanName =
      planFamily?.[defaultPlanFamily]?.["plans"]?.[defaultPlanName]?.["name"];

    const whatsappPaymentPlanName =
      whatsappPaymentPlans?.[partner?.defaultWhatsappPaymentsPlan]?.name;

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "write"
    );

    const toggleAutoResolve = (e) => {
      const name = e.target.name;
      this.setState({
        isToggleLoading: { ...this.state.isToggleLoading, [name]: true },
      });
      axios
        .patch(URL + `/api/update-partner-config`, {
          [name]: e.target.checked,
        })
        .then((response) => {
          this.props.partner[name] = !this.props.partner[name];
          this.setState({
            alert: true,
            alertSeverity: "success",
            alertMsg: `${name == "signUpEnabled" ? "Sign Up" : "Trial"} ${
              response.data.updatedPartner[name] ? "enabled" : "disabled"
            } successfully`,
            isToggleLoading: { ...this.state.isToggleLoading, [name]: false },
          });
          this.props.fetchPartnerDetails();
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertSeverity: "error",
            alertMsg: "Operation Failed",
            isToggleLoading: { ...this.state.isToggleLoading, [name]: false },
          });
          this.props.fetchPartnerDetails();
        });
    };

    const setTrialAmount = () => {
      axios
        .patch(URL + `/api/update-partner-config`, {
          trialAmount: this.state.trialAmount * 100000 || 0,
        })
        .then((response) => {
          this.setState({
            alert: true,
            alertSeverity: "success",
            alertMsg: `Trial amount updated successfully`,
            trialAmount: response.data.updatedPartner.trialAmount || 0,
          });
          this.props.fetchPartnerDetails();
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertSeverity: "error",
            alertMsg: "some thing went wrong",
          });
          this.props.fetchPartnerDetails();
        });
    };

    return this.state.isLoading ? (
      <CircularProgress />
    ) : (
      <div className={classes.root}>
        <Header
          businessTitle={"Configure Plan Family"}
          hasReadAccess={hasReadAccess}
          hasWriteAccess={hasWriteAccess}
        />
        <Box my={3} />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Grid container spacing={2}>
              {/* Default Subscription Plan */}
              <Grid item xs={12} md={12}>
                <Box className={classes.container}>
                  <Typography variant="h4">
                    Select Default Subscription Plan Family
                  </Typography>
                  <Box mt={3} />
                  <Box>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Active Plan Family
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {planFamily?.[defaultPlanFamily]?.["name"] ??
                          "Select a plan family"}
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.cells}>
                        {hasWriteAccess && (
                          <IconButton onClick={this.planDialog}>
                            <Edit />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Active Plan
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {renderPlanName ?? "Select a plan"}
                      </Grid>

                      <Grid item xs={12} md={4} className={classes.cells}>
                        {hasWriteAccess && (
                          <IconButton onClick={this.planDialog}>
                            <Edit />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} />
                </Box>
              </Grid>
              {/* Default WCC Plan */}
              <Grid item xs={12} md={12}>
                <Box className={classes.container}>
                  <Typography variant="h4">
                    Select Default WhatsApp Conversation Charges (WCC) Family
                  </Typography>
                  <Box mt={3} />
                  <Box>
                    <Grid container style={{ marginBottom: "30px" }}>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Active Plan
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.cells}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {wccPlanFamily[defaultWccPlan]?.name ??
                          "Select a plan first"}
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.cells}>
                        {hasWriteAccess && (
                          <IconButton onClick={this.SelectWccDialog}>
                            <Edit />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2} />
                </Box>
              </Grid>
              {/* Default Whatsapp Payments Plan */}
              {WHATSAPP_PAY?.status === "active" && (
                <Grid item xs={12} md={12}>
                  <Box className={classes.container}>
                    <Typography variant="h4">
                      Select Default WhatsApp Payments Plan Family
                    </Typography>
                    <Box mt={3} />
                    <Box>
                      <Grid container style={{ marginBottom: "30px" }}>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className={classes.cells}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Active Plan
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className={classes.cells}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {whatsappPaymentPlanName || "Select a plan first"}
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.cells}>
                          {hasWriteAccess && (
                            <IconButton
                              onClick={() =>
                                this.toggleDialog("WhatsappPaymentsPlan")
                              }
                            >
                              <Edit />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2} />
                  </Box>
                </Grid>
              )}

              {this.props.partner.enableSignUp && (
                <Grid item xs={12} md={12}>
                  <Box className={classes.container}>
                    <Typography variant="h4">Sign up Management</Typography>
                    <Box mt={3} />
                    <Grid container alignItems="center">
                      <Grid md={10} xs={10} className={classes.InfoText}>
                        Enable sign up page for seamless user onboarding
                      </Grid>
                      <Grid md={2} xs={2}>
                        <Grid container>
                          {this.state.isToggleLoading.signUpEnabled ? (
                            <CircularProgress
                              style={{ padding: "6px 12px 6px" }}
                              className={classes.switch}
                              size={25}
                            />
                          ) : (
                            <Switch
                              className={classes.switch}
                              checked={!!isSignUpEnabled}
                              disabled={!hasWriteAccess}
                              name="signUpEnabled"
                              onChange={(e) => {
                                if (hasWriteAccess) {
                                  toggleAutoResolve(e);
                                }
                              }}
                              color="primary"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box mt={2} />
                  </Box>
                </Grid>
              )}

              {this.props.partner.enableTrial && (
                <Grid item xs={12} md={12}>
                  <Box className={classes.container}>
                    <Typography variant="h4">Trial Management</Typography>
                    <Box mt={3} />
                    <Grid container alignItems="center">
                      <Grid md={10} xs={10} className={classes.InfoText}>
                        Enable to offer 7 days free trial on sign up
                      </Grid>
                      <Grid md={2} xs={2}>
                        <Grid md={2}>
                          {this.state.isToggleLoading.trialEnabled ? (
                            <CircularProgress
                              style={{ padding: "6px 12px 6px" }}
                              className={classes.switch}
                              size={25}
                            />
                          ) : (
                            <Switch
                              className={classes.switch}
                              checked={!!isTrialEnabled}
                              name="trialEnabled"
                              disabled={!hasWriteAccess}
                              onChange={(e) => {
                                if (hasWriteAccess) {
                                  toggleAutoResolve(e);
                                }
                              }}
                              color="primary"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box mt={2} />
                  </Box>
                </Grid>
              )}
              {this.props.partner.enableTrialAmount && (
                <Grid item xs={12} md={12}>
                  <Box className={classes.container}>
                    <Typography variant="h4">Free WCC Management</Typography>
                    <Box mt={3} />

                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid
                        md={12}
                        xs={12}
                        ma
                        className={classes.InfoText}
                        style={{ marginBottom: "15px" }}
                      >
                        Configure free WCC credits for new signed up users
                      </Grid>

                      <Grid
                        md={12}
                        xs={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item xs={8} md={6} alignItems="center">
                          <TextField
                            className={classes.textFieldRoot}
                            InputProps={{
                              startAdornment:
                                this.props.partner.currency === "USD"
                                  ? "$ "
                                  : "₹ ",
                            }}
                            placeholder="Enter Trial Wcc Amount"
                            name="TrialAmount"
                            value={this.state.trialAmount}
                            onChange={(e) => {
                              this.setState({ trialAmount: e.target.value });
                            }}
                            style={{
                              width: "100%",
                              marginRight: 10,
                            }}
                          />
                        </Grid>
                        {hasWriteAccess && (
                          <Grid item xs={4}>
                            <Typography variant="body1">
                              <Button
                                disabled={
                                  this.props.partner.trialAmount ==
                                    this.state.trialAmount * 100000 ||
                                  this.state.trialAmount < 0
                                }
                                style={{ marginLeft: 10, height: "41px" }}
                                color="primary"
                                variant="contained"
                                onClick={setTrialAmount}
                              >
                                Update
                              </Button>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Box mt={2} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box my={8} />

        {this.state.openPlanDialog && (
          <Dialog
            open={this.state.openPlanDialog}
            maxWidth="xs"
            onClose={this.planDialog}
            aria-labelledby="form-dialog-title"
            className={classes.plandialogContainer}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{
                height: 30,
                marginBottom: 10,
                marginTop: 4,
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 16,
                paddingRight: 8,
              }}
            >
              <Typography variant="h4">Select Plan Family</Typography>
              <IconButton onClick={this.planDialog}>
                <Clear />
              </IconButton>
            </Grid>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    paddingRight: "8px",
                    marginTop: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                    <Typography>Plan Family</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      fullWidth
                      options={Object.keys(planFamily).map((option) => option)}
                      getOptionLabel={(option) => {
                        return planFamily[option].name;
                      }}
                      onChange={(e, value, reason) => {
                        if (reason === "select-option") {
                          this.setState({
                            changePlanFamily: value,
                            plans: planFamily[value]?.plans,
                          });
                        }
                      }}
                      defaultValue={defaultPlanFamily}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select a message"
                          name="campaignName"
                        />
                      )}
                      style={{ width: "calc(100%)", paddingTop: "0px" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                    <Typography>Plans</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      fullWidth
                      options={
                        plans && Object.keys(plans).map((option) => option)
                      }
                      getOptionLabel={(option) => {
                        return plans && plans[option]?.name;
                      }}
                      onChange={(e, value, reason) => {
                        if (reason === "select-option") {
                          this.setState({ changePlan: value });
                        }
                      }}
                      defaultValue={
                        defaultPlanName ? defaultPlanName : renderFirstPlan
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select a message"
                          name="campaignName"
                        />
                      )}
                      style={{ width: "calc(100%)", paddingTop: "0px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                item
                container
                xs={12}
                style={{
                  alignItems: "flex-end",
                  justifyContent: "end",
                  paddingRight: "15px",
                  marginBottom: "8px",
                }}
              >
                <Button
                  // color="primary"
                  variant="outlined"
                  onClick={() => (
                    this.planDialog(),
                    this.setState({
                      planSubmitLoader: false,
                    })
                  )}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.planSubmit}
                  disabled={this.state.planSubmitLoader}
                  endIcon={
                    this.state.planSubmitLoader ? (
                      <CircularProgress size={20} />
                    ) : (
                      ""
                    )
                  }
                >
                  Change Plan
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )}

        {this.state.openWccDialog && (
          <Dialog
            open={this.state.openWccDialog}
            maxWidth="xs"
            onClose={this.SelectWccDialog}
            aria-labelledby="form-dialog-title"
            className={classes.plandialogContainer}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{
                height: 30,
                marginBottom: 10,
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 16,
                paddingRight: 8,
              }}
            >
              <Typography variant="h4">Select Wcc Plan Family</Typography>
              <IconButton onClick={this.SelectWccDialog}>
                <Clear />
              </IconButton>
            </Grid>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    paddingRight: "8px",
                    marginTop: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                    <Typography>Plans</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      fullWidth
                      options={Object.keys(wccPlanFamily).map(
                        (option) => option
                      )}
                      getOptionLabel={(option) => {
                        return wccPlanFamily[option]?.name;
                      }}
                      onChange={(e, value, reason) => {
                        if (reason === "select-option") {
                          this.setState({ changeWccPlanFamily: value });
                        }
                      }}
                      defaultValue={
                        defaultWccPlan ?? Object.keys(wccPlanFamily)[0]
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select a message"
                          name="campaignName"
                        />
                      )}
                      style={{ width: "calc(100%)", paddingTop: "0px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                item
                container
                xs={12}
                style={{
                  alignItems: "flex-end",
                  justifyContent: "end",
                  paddingRight: "15px",
                  marginBottom: "8px",
                }}
              >
                <Button
                  // color="primary"
                  variant="outlined"
                  onClick={this.SelectWccDialog}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.wccPlanSubmit}
                  disabled={this.state.planSubmitLoader}
                  endIcon={
                    this.state.planSubmitLoader ? (
                      <CircularProgress size={20} />
                    ) : (
                      ""
                    )
                  }
                >
                  Change Plan
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )}

        {dialog === "WhatsappPaymentsPlan" && (
          <Dialog
            open={true}
            maxWidth="xs"
            onClose={() => this.toggleDialog("WhatsappPaymentsPlan")}
            aria-labelledby="form-dialog-title"
            className={classes.plandialogContainer}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{
                height: 30,
                marginBottom: 10,
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 16,
                paddingRight: 8,
              }}
            >
              <Typography variant="h4">
                Select WhatsApp Payments Plan Family
              </Typography>
              <IconButton
                onClick={() => this.toggleDialog("WhatsappPaymentsPlan")}
              >
                <Clear />
              </IconButton>
            </Grid>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    paddingRight: "8px",
                    marginTop: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                    <Typography>Plans</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      fullWidth
                      options={Object.keys(whatsappPaymentPlans).map(
                        (option) => option
                      )}
                      getOptionLabel={(option) => {
                        return whatsappPaymentPlans?.[option]?.name;
                      }}
                      onChange={(e, value, reason) => {
                        if (reason === "select-option") {
                          this.setState({ defaultWhatsappPaymentsPlan: value });
                        }
                      }}
                      defaultValue={
                        defaultWhatsappPaymentsPlan ??
                        Object.keys(whatsappPaymentPlans || {})?.[0]
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select a message"
                          name="campaignName"
                        />
                      )}
                      style={{ width: "calc(100%)", paddingTop: "0px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                item
                container
                xs={12}
                style={{
                  alignItems: "flex-end",
                  justifyContent: "end",
                  paddingRight: "15px",
                  marginBottom: "8px",
                }}
              >
                <Button
                  // color="primary"
                  variant="outlined"
                  onClick={() => this.toggleDialog("WhatsappPaymentsPlan")}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.changeWhatsappPaymentDefaultPartnerPlan}
                  disabled={this.state.planSubmitLoader}
                  endIcon={
                    this.state.planSubmitLoader ? (
                      <CircularProgress size={20} />
                    ) : (
                      ""
                    )
                  }
                >
                  Change Plan
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )}

        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  textField: {
    height: 41,
    "& .MuiIconButton-root": {
      padding: 6,
      background: "rgb(70 20 134 / 15%)",
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 15,
    },
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  cells: {
    padding: 24,
    color: "grey",
    borderBottom: "1px solid #ecebeb",
  },
  InfoText: {
    color: "grey",
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  plandialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

const connectPlanFamilyPage = connect(
  (state) => ({
    partner: state.partner.partner,
    planFamilies: state.planFamilies.allPlanFamilies,
    wccPlans: state.wccPlans.allWccPlans,
    whatsappPaymentPlans: state.whatsappPaymentsPlans.allPlans,
    partnerAgents: state.partnerAgents.partnerAgents,
    user: state.login.user,
  }),
  {
    fetchWccPlans,
    addPlanFamily,
    updateWccFamily,
    updateWhatsappPaymentsPlanFamily,
    fetchPartnerDetails,
  }
)(PlanFamilyPage);

export default withStyles(styles)(connectPlanFamilyPage);
