import React, { Component } from "react";
import {
  withStyles,
  Typography,
  Dialog,
  DialogContent,
  Box,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { CloseOutlined, Visibility } from "@material-ui/icons";
import TemplateMessagePreview from "../../commons/TemplateMessagePreview/templateMessagePreview";

const quickFilters = [
  "Last active - 24 hr window",
  "Created - This week",
  "Created - This month",
];
class TemplateMsgTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      openPreviewDialog: false,
      selectedTemplate: null,
    };
  }
  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  openTemplate = (e) => {
    const templates = [...this.props.templates];
    const foundIndex = templates.findIndex((x) => x._id == e._id);

    this.props.setTemplateId(templates[foundIndex]._id.toString());
  };

  togglePreviewDialog = (templateIdx) => {
    this.setState({
      openPreviewDialog: !this.state.openPreviewDialog,
      selectedTemplate: templateIdx,
    });
  };

  getFields = () => {
    return [
      {
        name: "Project Name",
        key: "assistantName",
      },
      {
        name: "Created At",
        key: "createdAt",
      },
      {
        name: "Template Name",
        key: "name",
      },
      {
        name: "Status",
        key: "status",
      },
      {
        name: "Rejected Reason",
        key: "rejectedReason",
      },
      {
        name: "Action",
        key: "action",
      },
    ];
  };

  render() {
    const { classes, templates, isLoading, type } = this.props;
    const { selectedTemplate, openPreviewDialog } = this.state;
    const fields = this.getFields();
    if (type === "DIRECT") fields.splice(1, 1);
    return (
      <div className={classes.root}>
        <table>
          <tbody>
            <tr className={`${classes.row} ${classes.topbar}`}>
              {fields.map((field, index) => (
                <th
                  key={index}
                  className={clsx(classes.column, classes.dataColumn)}
                >
                  <Typography variant="h5" color="primary" align="center">
                    {field.name.toUpperCase()}
                  </Typography>
                </th>
              ))}
            </tr>
            {isLoading ? (
              <React.Fragment>
                {[1, 2, 3, 4, 5, 6, 7].map((e, index) => (
                  <tr className={clsx(classes.row)} key={index}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((field, index) => (
                      <th
                        key={index}
                        className={clsx(
                          classes.column,
                          classes.dataColumn,
                          classes[field.width]
                        )}
                      >
                        <Skeleton style={{ width: "100%" }} height={40} />
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {templates.map((e, index) => (
                  <tr
                    key={index}
                    className={clsx(classes.row, classes.dataRow, {
                      [classes.alterRow]: index % 2 == 0,
                    })}
                  >
                    {fields.map((field, idx) => (
                      <th
                        key={idx}
                        style={{ width: "20%", overflow: "hidden" }}
                        className={clsx(classes.column, classes.dataColumn)}
                        onClick={() => this.openTemplate(e)}
                      >
                        {columnType(
                          classes,
                          e,
                          index,
                          field,
                          this.togglePreviewDialog
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </table>
        {openPreviewDialog && (
          <PreviewDialog
            template={templates[selectedTemplate]}
            togglePreviewDialog={this.togglePreviewDialog}
          />
        )}
      </div>
    );
  }
}

function columnType(classes, columnData, idx, fieldName, togglePreviewDialog) {
  const key = fieldName.key;
  switch (key) {
    case "assistantName":
      return (
        <Typography variant="body1" align="center">
          {columnData[key]}
        </Typography>
      );
      break;
    case "createdAt":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
      break;
    case "name":
      return (
        <Typography variant="body1" align="center" noWrap>
          {columnData[key]}
        </Typography>
      );
      break;
    case "status":
      return (
        <Typography variant="body1" align="center">
          {columnData[key]}
        </Typography>
      );
      break;
    case "rejectedReason":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] ? columnData[key] : "Unmarked"}
        </Typography>
      );
      break;
    case "action":
      return (
        <Visibility
          onClick={(e) => {
            togglePreviewDialog(idx);
            e.stopPropagation();
          }}
        />
      );

      break;
    default:
      return (
        <Typography variant="body1" align="center">
          {columnData[key] || "-"}
        </Typography>
      );
  }
}

const PreviewDialog = ({ template, togglePreviewDialog }) => {
  return (
    <Dialog open={true}>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <Box
          style={{
            width: "100%",
            height: "20px",
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <CloseOutlined onClick={togglePreviewDialog} />
        </Box>
        <Box
          style={{
            width: window.innerWidth >= 768 ? "300px" : "200px",
            padding:
              window.innerWidth >= 768
                ? `40px 60px ${template?.sampleCTAUrl ? 0 : "60px"} 60px`
                : window.innerWidth >= 350
                ? `20px 40px ${template?.sampleCTAUrl ? 0 : "20px"} 40px`
                : 0,
          }}
        >
          <TemplateMessagePreview
            parameters={[]}
            media={""}
            bigIcon={true}
            selectedTemplate={template}
            showDisclaimer={false}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    boxSizing: "border-box",
    borderRadius: "8px",
    position: "relative",
    border: "1px solid lightgrey",
    background: "white",
    paddingBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      // height: "calc(100vh - 60px)",
    },
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      background: "white",
    },
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "rgb(237, 226, 252)",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  dataColumn: {
    width: "200px",
    // minHeight: "80px",
    // display: "flex",
    // background: "red"
    // flexWrap: "wrap"
  },
  checkboxColumn: {
    width: "60px",
    // background: "red"
  },
});

export default withStyles(styles)(withRouter(TemplateMsgTable));
