import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import { Autocomplete, Alert } from "@material-ui/lab";
import axios from "axios";

import { URL } from "../../config/config";

const PLANS = ["FREE", "LITE", "PRO", "PLUS", "PREMIUM"];

function getStatusColor() {
  const templateStatus = "PENDING";
  switch (templateStatus) {
    case "PENDING":
      return "grey";
    case "APPROVED":
      return "#08CF65";
    case "REJECTED":
      return "#ff0000";
    default:
      return "pink";
  }
}

class Assistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "PENDING",
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      remainingCredit: 0,
      activePlan: null,
    };
  }
  handleInput = (e) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) });
  };
  updateCredit = () => {
    let template = this.props.templates[this.props.templateIndex];
    const amount = template.remainingCredit + this.state.remainingCredit * 100;

    // console.log(template.remainingCredit, this.state.remainingCredit)
    if (this.state.activePlan) {
      template.activePlan = this.state.activePlan;
      template.planActivatedOn = new Date();
    }

    template.remainingCredit = amount;

    this.setTemplateInDb({
      remainingCredit: amount,
      activePlan: template.activePlan,
      planActivatedOn: template.planActivatedOn,
    })
      .then((response) => {
        this.props.setTemplate(template);
        this.setState({
          alert: true,
          alertMsg: "Assistant status updated!",
          alertSeverity: "success",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertMsg: "Unable to update template status",
          alertSeverity: "error",
        });
      });
  };
  handleAutoComplete = (fieldName, value, reason) => {
    if (reason == "select-option") {
      this.setState({ [fieldName]: value });
    }
  };
  handleMark = (mark, temp) => {
    const template = { ...temp };
    if (mark) {
      // set process to new Date()
      this.setTemplateInDb({ processed: new Date() })
        .then((response) => {
          template.processed = new Date();
          this.props.setTemplate(template);
          this.setState({
            alert: true,
            alertMsg: "Template marked processed!",
            alertSeverity: "success",
          });
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertMsg: "Unable to mark, Check your internet connection.",
            alertSeverity: "error",
          });
        });
    } else {
      this.setTemplateInDb({ processed: null })
        .then((response) => {
          template.processed = null;
          this.props.setTemplate(template);
          this.setState({
            alert: true,
            alertMsg: "Template marked un-processed!",
            alertSeverity: "success",
          });
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertMsg:
              "Unable to fulfill request, Check your internet connection.",
            alertSeverity: "error",
          });
        });
    }
  };
  setTemplateInDb = (fields) => {
    // try {
    const t = this.props.templates[this.props.templateIndex];
    const queryObj = {
      assistantId: t._id,
      fields,
    };
    return axios.post(URL + "/superadmin/set-assistant-field", { ...queryObj });
  };
  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };
  render() {
    const { classes, toggleDialog, templateIndex, templates, setTemplate } =
      this.props;
    const template = templates[templateIndex];
    return (
      <Box px={2} pt={1} className={classes.dialogContainer}>
        <Grid container alignItems="center" className={classes.dialogTopbar}>
          <Grid item>
            <IconButton onClick={toggleDialog}>
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item>
            {`CS-00${templateIndex < 10 ? "0" : ""}${templateIndex}`}
          </Grid>
        </Grid>
        <Box width="400px" p={4}>
          <Typography variant="h3" color="primary">
            {template.assistantName}
          </Typography>
          <Box mt={1} />
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" color="textSecondary">
                Topic :
              </Typography>
              <Typography variant="body1" gutterBottom>
                {template.topic}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4" color="textSecondary">
                Assigned To :
              </Typography>
              <Typography variant="body1">{template.assignedTo}</Typography>
            </Grid>

            <Box className={classes.chatArea}>
              <Box className={classes.chatContainer}>
                <Box className={classes.chatBubble}>Hi There!</Box>
              </Box>
              <Box className={classes.chatContainer}>
                <Box
                  className={classes.chatBubble}
                >{`I'm facing issue regarding ${(
                  template.topic || ""
                ).toLowerCase()}`}</Box>
              </Box>
            </Box>
            <Box mt={1} width="100%" textAlign="right">
              <TextField
                fullWidth
                placeholder="Reply"
                style={{ marginBottom: 8 }}
              />
              <Button size="small" color="primary" variant="contained">
                Send
              </Button>
            </Box>
          </Grid>
        </Box>
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "rgb(70 20 134 / 15%)",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
  chatContainer: {
    width: "100%",
    justifyContent: "end",
    display: "flex",
  },
  chatBubble: {
    padding: 12,
    borderRadius: 8,
    background: "#eeeeee",
    marginTop: 4,
    height: "fit-content",
  },
  chatArea: {
    width: "100%",
    border: "1px solid lightgrey",
    borderRadius: 8,
    padding: 12,
    height: "200px",
    overflowY: "scroll",
  },
});

export default withStyles(styles)(Assistant);
