function replaceBold(match) {
  return "<b>" + match.substring(1, match.length-1) + "</b>"
}
function replaceItalic(match) {
  return "<i>" + match.substring(1, match.length-1) + "</i>"
}
function formatText(text) {
  const boldPattern = /\*(\**[^*\n]+\**)\*/g
  const italicPattern = /_(_*[^_\n]+_*)_/g
  let formattedText = ""
  formattedText = text.replace(boldPattern, replaceBold)
  formattedText = formattedText.replace(italicPattern, replaceItalic)
  return formattedText
}

export default formatText