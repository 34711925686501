import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  withStyles,
  IconButton,
  Box,
} from "@material-ui/core";

import { Clear, Publish } from "@material-ui/icons";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";

const TotalFields = [
  {
    id: "GstNumber",
    label: "Gst Number",
    required: true,
  },
];

const KycDialog = (props) => {
  const fileInputRef = useRef(null);
  const { toggleBvbpDialog, bvbpDialog, classes, onKycInfoSave } = props;
  const [data, setData] = useState({});
  const [files, setFiles] = useState([]);
  const [required, setRequired] = useState();
  const [FileUrlObject, setFileUrlObject] = useState({});
  const [fBMInfo, setFBMInfo] = useState({});

  const onchangeHandler = (e, id, group) => {
    e.persist();
    if (group) {
      setData((prevState) => ({
        ...prevState,
        [group]: {
          ...prevState[group],
          [id]: e.target.value,
        },
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        [id]: e.target.value,
      }));
    }
  };

  const onCancelHandler = () => {
    setData({});
    setFiles([]);
    toggleBvbpDialog();
  };
  const removeFileHandler = (fileName) => {
    const tempFileArray = files.filter((file) => file.name != fileName);
    setFiles(tempFileArray);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    let validated = true;
    TotalFields.forEach((element, index) => {
      if (element.required) {
        if (element.group) {
          validated =
            validated && data[element.group]
              ? data[element.group][element.id]
                ? data[element.group][element.id].trim() != ""
                : false
              : false;
        } else {
          validated =
            validated &&
            (data[element.id] ? data[element.id].trim() != "" : false);
        }
      }
    });
    validated = validated && files.length <= 3 && files.length != 0;
    setRequired(validated);
  }, [data, files]);

  const handleFileChange = (event) => {
    if (event.target?.files?.length > 0) {
      const selectedFiles = event.target.files[0];
      setFiles((prevFiles) => [selectedFiles]);
    }

    event.target.value = null;
  };

  const onSaveHandler = () => {
    onKycInfoSave({ data, files });
  };

  return (
    <>
      <Dialog onClose={toggleBvbpDialog} open={bvbpDialog}>
        <DialogTitle id="simple-dialog-title" style={{ padding: "16px" }}>
          <Typography variant="h3">Kyc verification</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {TotalFields.map((field) => (
              <>
                <Grid
                  container
                  md={12}
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Grid item xs={4} md={4} container alignItems="center">
                    <Typography required variant="h5">
                      {field.label}
                      {field.required && (
                        <span style={{ color: "red", marginLeft: "1px" }}>
                          *
                        </span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={8}
                    md={8}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Grid
                      item
                      md={12}
                      style={{ display: "flex", width: "100%" }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ marginRight: 4, width: "100%" }}
                      >
                        <TextField
                          style={{ width: "100%", padding: "3px 0px" }}
                          required
                          variant="outlined"
                          id={`${field.id}`}
                          classes={{
                            root: classes.textFieldRoot,
                          }}
                          className={classes.textFieldRoot}
                          margin="dense"
                          onChange={(e) =>
                            onchangeHandler(e, field.id, field?.group)
                          }
                          value={
                            (field.group
                              ? data[field.group]
                                ? data[field.group][field.id]
                                : ""
                              : data[field.id]) || ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ))}
            <Grid
              container
              md={12}
              xs={12}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Grid item xs={4} md={4} container alignItems="center">
                <Typography required variant="h5">
                  Gst Documents
                  <span style={{ color: "red", marginLeft: "1px" }}>*</span>
                </Typography>
              </Grid>
              <Grid
                xs={8}
                md={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Grid item md={12} style={{ display: "flex", width: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ marginRight: 4, width: "100%" }}
                  >
                    <div style={{ width: "100%", padding: "3px 0px" }}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".pdf,.jpeg,.jpg,.png"
                        onChange={handleFileChange}
                      />
                    </div>
                    <Box onClick={handleClick}>
                      <Grid
                        item
                        md={12}
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                        classes={{
                          root: classes.textFieldRoot,
                        }}
                      >
                        <Typography required variant="h5">
                          Upload File
                        </Typography>
                        <IconButton
                          color="primary"
                          size="small"
                          variant="filled"
                        >
                          <Publish />
                        </IconButton>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {files.length > 0 &&
            files.map((file) => (
              <FileDisplay
                classes={classes}
                name={file.name}
                url={FileUrlObject[file.name]}
                removeFile={removeFileHandler}
              ></FileDisplay>
            ))}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onSaveHandler}
            variant="contained"
            color="primary"
            disabled={!required}
          >
            Confirm
          </Button>
          <Button onClick={onCancelHandler} color="primary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FileDisplay = (props) => {
  const { classes, name, removeFile, url } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={4} md={4}></Grid>
        <Grid item md={8} xs={8} style={{ display: "flex", width: "100%" }}>
          <Grid
            item
            md={12}
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              margin: "8px 4px 0px 0px ",
            }}
            classes={{
              root: classes.textFieldRoot,
            }}
          >
            <Typography required variant="h5">
              {name.length > 20 ? name.substring(0, 20) + "..." : `${name}`}
            </Typography>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => removeFile(name || "")}
            >
              <Clear />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  textFieldRoot: {
    backgroundColor: "rgb(240,240,240)",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    padding: "4px 12px",
    width: "100%",
    boxShadow: "none",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontSize: "8px",
    fontWeight: 500,
  },
  box: {
    padding: "10px",
  },
  docBox: {
    marginTop: "8px",
    marginBottom: "4px",
  },
});

export default withStyles(styles)(KycDialog);
