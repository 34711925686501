import axios from "axios";
import { URL } from "../config/config";

const initialState = {
  allProjects: {},
  isLoading: false,
  error: null,
};

const LOAD_START = "Projects/LOAD_START";
const LOAD_SUCCESS = "Projects/LOAD_SUCCESS";
const LOAD_FAILURE = "Projects/LOAD_FAILURE";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = (payload) => ({
  type: LOAD_SUCCESS,
  payload,
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const fetchProject = (businessId) => (dispatch, getState) => {
  const { user } = getState();
  dispatch(loadStart());
  const skip = 0;
  const rowsPerPage = 0;
  axios
    .post(
      URL +
        `${
          user.isReferral
            ? `/api/partner-referral-project-lists`
            : `/api/project-lists`
        }?sort=dec`,
      {
        skip,
        rowsPerPage,
        ...(user.isReferral ? { partnerReferralId: user.id } : {}),
        businessId,
      }
    )
    .then((response) => {
      const { projects } = response.data;
      dispatch(loadSuccess(projects));
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export default function ProjectReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        allProjects: { ...payload },
        isLoading: false,
        error: null,
      };

    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
