import axios from "axios";
import { projectURL, URL } from "../config/config";
const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  .fill(0)
  .map((item) => ({ name: "Loading" }));
const initialState = {
  assistants: { arr },
  webhooks: {},
  isLoading: false,
  error: null,
  skip: 0,
  page: 0,
  rowsPerPage: 10,
  total: 0,
  nameQuery: "",
  allCount: 0,
  liveCount: 0,
  draftCount: 0,
  trialPlanCount: 0,
  apiTrialPlanCount: 0,
  monthlyPlanCount: 0,
  yearlyPlanCount: 0,
  deletedCount: 0,
  stoppedCount: 0,
};

const LOAD_START = "ASSISTANT/LOAD_START";
const LOAD_SUCCESS = "ASSISTANT/LOAD_SUCCESS";
const LOAD_FAILURE = "ASSISTANT/LOAD_FAILURE";
const CHANGE_PAGE = "ASSISTANT/CHANGE_PAGE";
const LOAD_MORE_SUCCESS = "ASSISTANT/LOAD_MORE_SUCCESS";
const CHANGE_ROWS_PER_PAGE = "ASSISTANT/CHANGE_ROWS_PER_PAGE";
const LOAD_PROJECT_COUNTS = "ASSISTANT/LOAD_PROJECT_COUNTS";
const EDIT_PROJECT = "ASSISTANT/EDIT_PROJECT";
const PROJECT_WEBHOOKS = "ASSISTANT/PROJECT_WEBHOOKS";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = ({ assistants, skip, total }) => ({
  type: LOAD_SUCCESS,
  payload: { assistants, skip, total },
});

const loadProjectCount = ({
  all,
  live,
  draft,
  trialPlan,
  apiTrialPlan,
  KycLimitIncreased,
  KycApplied,
  kycDone,
  KycNotDone,
  monthlyPlan,
  yearlyPlan,
  deleted,
  stopped,
}) => ({
  type: LOAD_PROJECT_COUNTS,
  payload: {
    all,
    live,
    draft,
    trialPlan,
    apiTrialPlan,
    KycLimitIncreased,
    KycApplied,
    kycDone,
    KycNotDone,
    monthlyPlan,
    yearlyPlan,
    deleted,
    stopped,
  },
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const editAssistants = (id, field, value) => (dispatch) => {
  dispatch({ type: EDIT_PROJECT, payload: { id, field, value } });
};

export const changeRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: CHANGE_ROWS_PER_PAGE, payload: rowsPerPage });
};

export const fetchAssistantsCounts = () => (dispatch, getState) => {
  const { user } = getState().login;

  axios
    .post(
      URL +
        `${
          user.isReferral
            ? `/api/partner-referral-project-counts`
            : `/api/project-counts`
        }`,
      {
        ...(user.isReferral ? { partnerReferralId: user.id } : {}),
      }
    )
    .then((response) => {
      const {
        allCount,
        liveCount,
        draftCount,
        trialCount,
        KycLimitIncreasedCount,
        KycAppliedCount,
        kycDoneCount,
        KycNotAppliedCount,
        apiTrialCount,
        monthlyCount,
        yearlyCount,
        deletedCount,
        stoppedCount,
      } = response.data[0];
      dispatch(
        loadProjectCount({
          all: allCount || 0,
          live: liveCount || 0,
          draft: draftCount || 0,
          trialPlan: trialCount || 0,
          KycLimitIncreased: KycLimitIncreasedCount || 0,
          KycApplied: KycAppliedCount || 0,
          kycDone: kycDoneCount || 0,
          KycNotDone: KycNotAppliedCount || 0,
          apiTrialPlan: apiTrialCount || 0,
          monthlyPlan: monthlyCount || 0,
          yearlyPlan: yearlyCount || 0,
          deleted: deletedCount || 0,
          stopped: stoppedCount || 0,
        })
      );
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export const fetchAssistants = (filters) => (dispatch, getState) => {
  const { rowsPerPage } = getState().assistants;
  const { user } = getState().login;
  const sort = filters.sort;
  const newFilter = { ...filters };
  const ft = { ...newFilter };
  delete newFilter.sort;

  dispatch(loadStart());
  axios
    .post(
      URL +
        `${
          user.isReferral
            ? `/api/partner-referral-project-lists`
            : `/api/project-lists`
        }?sort=` +
        sort,
      {
        skip: 0,
        rowsPerPage: rowsPerPage || 10,
        ...(user.isReferral ? { partnerReferralId: user.id } : {}),
        ...newFilter,
      }
    )
    .then((response) => {
      let assistants = response.data;
      let assistantsObj = {};
      assistants.projects.forEach((assistant) => {
        assistantsObj[assistant._id] = assistant;
      });
      dispatch(
        loadSuccess({
          assistants: assistantsObj,
          skip: assistants.newSkip,
          total: assistants.totalprojects,
        })
      );
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export const loadNextAssistants = (filter) => (dispatch, getState) => {
  const { rowsPerPage, skip, assistants, page } = getState().assistants;
  const { user } = getState().login;
  const sort = filter.sort;
  const newFilter = { ...filter };
  delete newFilter.sort;
  if (Object.values(assistants).length > (page + 1) * rowsPerPage) {
    dispatch({ type: CHANGE_PAGE, payload: page + 1 });
  } else {
    dispatch(loadStart());
    dispatch({ type: CHANGE_PAGE, payload: page + 1 });
    axios
      .post(
        URL +
          `${
            user.isReferral
              ? `/api/partner-referral-project-lists`
              : `/api/project-lists`
          }?sort=` +
          sort,
        {
          skip,
          rowsPerPage,
          ...(user.isReferral ? { partnerReferralId: user.id } : {}),
          ...newFilter,
        }
      )
      .then((response) => {
        const { projects, newSkip } = response.data;
        const assistantsObj = {};
        projects.forEach((assistant) => {
          assistantsObj[assistant._id] = assistant;
        });
        dispatch({
          type: LOAD_MORE_SUCCESS,
          payload: { assistants: assistantsObj, skip: newSkip },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch(loadFailure(error));
      });
  }
};

export const loadPrevAssistants = () => (dispatch, getState) => {
  const { page } = getState().assistants;
  dispatch({ type: CHANGE_PAGE, payload: page - 1 });
};

export const fetchAssistantsWebhooks = (projectId) => (dispatch, getState) => {
  dispatch(loadStart());
  axios
    .get(projectURL + `/project/${projectId}/webhooks`)
    .then((response) => {
      let webhooks = response.data;
      let webhooksObj = {};
      webhooks.forEach((assistant) => {
        webhooksObj[assistant.project_id] = assistant;
      });
      dispatch({
        type: PROJECT_WEBHOOKS,
        payload: { webhooks: webhooksObj },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export default function AssistantsMessageReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        assistants: { ...payload.assistants },
        isLoading: false,
        error: null,
        skip: payload.skip,
        total: payload.total,
        page: 0,
      };

    case LOAD_FAILURE:
      return { ...state, isLoading: true, error: payload };

    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        assistants: { ...state.assistants, ...payload.assistants },
        skip: payload.skip,
        isLoading: false,
      };

    case CHANGE_PAGE:
      return { ...state, page: payload };

    case CHANGE_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload, page: 0 };

    case LOAD_PROJECT_COUNTS:
      return {
        ...state,
        allCount: payload.all,
        liveCount: payload.live,
        draftCount: payload.draft,
        trialPlanCount: payload.trialPlan,
        KycLimitIncreasedCount: payload.KycLimitIncreased,
        KycAppliedCount: payload.KycApplied,
        kycDoneCount: payload.kycDone,
        KycNotDoneCount: payload.KycNotDone,
        apiTrialPlanCount: payload.apiTrialPlan,
        monthlyPlanCount: payload.monthlyPlan,
        yearlyPlanCount: payload.yearlyPlan,
        deletedCount: payload.deleted,
        stoppedCount: payload.stopped,
      };

    case EDIT_PROJECT:
      const newObj = {};

      if (
        payload.field === "address" ||
        payload.field === "description" ||
        payload.field === "email" ||
        payload.field === "websites" ||
        payload.field === "vertical"
      ) {
        newObj.whatsAppBusinessProfile = {
          ...state.assistants[payload.id].whatsAppBusinessProfile,
          [payload.field]: payload.value,
        };
      } else {
        newObj[payload.field] = payload.value;
      }
      return {
        ...state,
        assistants: {
          ...state.assistants,
          [payload.id]: {
            ...state.assistants[payload.id],
            ...newObj,
          },
        },
      };

    case PROJECT_WEBHOOKS:
      return { ...state, webhooks: payload.webhooks };

    default:
      return state;
  }
}
