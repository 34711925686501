import React, { Component, useState, useEffect } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Radio,
  Snackbar,
  TextField,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  InputAdornment,
  DialogTitle,
} from "@material-ui/core";
import { Edit, Clear } from "@material-ui/icons";
import { connect } from "react-redux";
import { Alert, Autocomplete } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { URL } from "../../config/config";
import { addAgentSize } from "../../store/partnerState";
import ColorPicker from "../../commons/ColorPicker/ColorPicker";
import { Skeleton } from "@material-ui/lab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Edit as EditIcon } from "@material-ui/icons";
import { URL as AddonURl } from "../../config/config";
import { fetchPartnerDetails } from "../../store/partnerState";

const fields = [
  {
    name: "Name",
    size: 6,
    key: "name",
  },
  {
    name: "Actions",
    size: 6,
    key: "actions",
  },
];

class AddonsPage extends Component {
  // Get colors
  // Case 1: Found
  // colors: primary, second
  // update obj
  // save (selective)

  // Case 2: not found -> Object.keys(response.data.colors) === 0
  // colors = {} - default values use
  // updateobj =  {...colors};
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      planSubmitLoader: false,
      updateObj: {},
      colorSaveLoader: false,
      addons: {},
      openDialog: false,
      activeId: "",
      disableFields: false,
      openFlowDialog: false,
      toggleFlowDialog: false,
    };
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  componentDidMount = () => {
    this.setState({
      addons: this.props.partner?.addons,
    });
  };

  setColor = (value, name) => {
    this.setState({
      colors: { ...this.state.colors, [name]: value },
      updateObj: { ...this.state.updateObj, [name]: value },
    });
  };

  saveTheme = () => {
    this.setState({
      colorSaveLoader: true,
    });
  };

  editPlan = (isTrue, item, isFalse) => {
    console.log(isFalse);
    this.setState(
      {
        reqAddon: item,
        disableFields: isFalse,
      },
      () => {
        this.setState({ openDialog: true });
      }
    );
  };

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  editFlowPlan = (isTrue, item, isFalse) => {
    console.log(item);
    this.setState(
      {
        reqAddon: item,
        disableFields: isFalse,
      },
      () => {
        this.setState({ openFlowDialog: true });
      }
    );
  };

  toggleFlowDialog = () => {
    this.setState({ openFlowDialog: !this.state.openFlowDialog });
  };

  render() {
    const { classes, match, partner } = this.props;
    const { currency } = partner;
    return (
      <>
        <Header businessTitle={"Plan Details"} />
        <div className={classes.tableContainer}>
          <Grid container>
            <Grid container item xs={12} md={12} lg={12}>
              <Grid container>
                <Box
                  className={classes.tableWrapper}
                  mb={{ xs: 2, md: 4 }}
                  mx={{ xs: 2, md: 0 }}
                >
                  <Grid item container className={classes.billTableContainer}>
                    <Grid
                      item
                      xs={12}
                      container
                      className={classes.tableHeader}
                    >
                      {fields.map((e) => (
                        <Grid
                          item
                          xs={e.size}
                          key={e.key}
                          style={{
                            width: "20%",
                          }}
                        >
                          <Box py={2} pl={2}>
                            <Typography variant="h5" color="primary">
                              {e.name}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    {Object.keys(this.state?.addons).map((e) => (
                      <Grid
                        item
                        xs={12}
                        container
                        onScroll={this.listScrolled}
                        className={classes.tableItem}
                      >
                        {fields.map((field) => {
                          return (
                            <Grid
                              item
                              xs={field.size}
                              style={{
                                width: "20%",
                              }}
                            >
                              <Cell
                                classes={classes}
                                item={{ ...this.state?.addons[e], e }}
                                field={
                                  this.props.isLoading ? "Loading" : field.key
                                }
                                partnerPlans={this.state?.addons[e]}
                                activeId={e}
                                //   getAisenyPrice={this.getAisenyPrice}
                                editPlan={this.editPlan}
                                openPlanDialog={this.openPlanDialog}
                                editFlowPlan={this.editFlowPlan}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {this.state.openDialog && (
          <MyDialog
            item={this.state.reqAddon}
            toggleDialog={this.toggleDialog}
            openDialog={this.state.openDialog}
            classes={this.props.classes}
            currency={currency}
            disableFields={this.state.disableFields}
            fetchPartnerDetails={this.props.fetchPartnerDetails}
          />
        )}
        {this.state.openFlowDialog && (
          <MyFlowDialog
            item={this.state.reqAddon}
            toggleDialog={this.toggleFlowDialog}
            openDialog={this.state.openFlowDialog}
            classes={this.props.classes}
            currency={currency}
            disableFields={this.state.disableFields}
            fetchPartnerDetails={this.props.fetchPartnerDetails}
          />
        )}
      </>
    );
  }
}

function MyDialog(props) {
  const [monthlyPlanPrice, setMonthlyPlanPrice] = useState({});
  const [yearlyPlanPrice, setYearlyPlanPrice] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const item = props.item;
  const currency = props.currency;
  const classes = props.classes;
  const toggleDialog = props.toggleDialog;
  let openDialog = props.openDialog;
  const myarr = [];
  let itemPrice = item.aisensyPrice[currency];
  let monthlyPrice = +(item.aisensyPrice[currency] / 1200000).toFixed(2);
  console.log(monthlyPrice);
  let yearlyPrice = +(item.aisensyPrice[currency] / 100000).toFixed(2);
  console.log(yearlyPrice);
  let partnerPrice;

  if (item.partnerPlans) {
    partnerPrice = item.partnerPlans;
  }

  useEffect(() => {
    const newArr = [];
    const arrayToIter = ["Monthly", "Yearly"];

    for (let i = 0; i < arrayToIter.length; i++) {
      const myObj = {};
      myObj.plan = arrayToIter[i];
      if (arrayToIter[i] === "Monthly") {
        myObj[`${arrayToIter[i]}aisensyPrice`] = monthlyPrice;
      } else {
        myObj[`${arrayToIter[i]}aisensyPrice`] = yearlyPrice;
      }

      if (partnerPrice) {
        myObj[`${arrayToIter[i]}margin`] = +(
          partnerPrice[arrayToIter[i]] - myObj[`${arrayToIter[i]}aisensyPrice`]
        ).toFixed(2);
        myObj[`${arrayToIter[i]}partnerPrice`] =
          +partnerPrice[arrayToIter[i]].toFixed(2);
      } else {
        myObj[`${arrayToIter[i]}margin`] = 0;
        myObj[`${arrayToIter[i]}partnerPrice`] =
          myObj[`${arrayToIter[i]}aisensyPrice`];
      }
      let newObj = { ...myObj };
      if (arrayToIter[i] === "Monthly") {
        setMonthlyPlanPrice(newObj);
      } else {
        setYearlyPlanPrice(newObj);
      }
      newArr.push(newObj);
    }

    console.log(newArr);
  }, []);

  const savePlan = () => {
    setIsLoading(true);
    const partnerPlans = {};
    partnerPlans.Monthly = monthlyPlanPrice.MonthlypartnerPrice;
    partnerPlans.Yearly = yearlyPlanPrice.YearlypartnerPrice;
    const key = props.item.e;

    axios
      .post(AddonURl + "/api/update-addon-plan", { partnerPlans, key })
      .then((res) => {
        setIsLoading(false);
        props.fetchPartnerDetails();
        setAlertType("success");
        setAlertMsg("plan update success");
        toggleDialog();
      })
      .catch((error) => {
        setIsLoading(false);
        setAlertType("error");
        setAlertMsg("plan update failed");
      });
  };

  const onSnackbarClose = () => {
    setAlertType("");
    setAlertMsg("");
  };

  return (
    <>
      <Dialog open={openDialog} closeDialog={toggleDialog} maxWidth={"sm"}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="h3">{props.item.name}</Typography>
          </DialogTitle>
        </Box>
        <Box
          style={{ padding: "24px 16px 16px 24px" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <DialogContent>
            <Grid
              container
              alignItems="center"
              className={classes.content}
              spacing={0}
            >
              <Grid container>
                <Grid item md={3}></Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    {Object.keys(this.props.tenantDetails).length
                      ? this.props.tenantDetails.name
                      : "AiSensy"}{" "}
                    Prices
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    Margin
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    Your Platform Prices
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    {monthlyPlanPrice.plan}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    disabled="true"
                    margin="dense"
                    value={monthlyPlanPrice.MonthlyaisensyPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  {
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      value={monthlyPlanPrice.Monthlymargin}
                      onChange={(e) => {
                        let value = Number(e.target.value) * 100000 ?? 0;
                        const partnerPrice =
                          monthlyPlanPrice.MonthlyaisensyPrice;
                        setMonthlyPlanPrice({
                          ...monthlyPlanPrice,
                          Monthlymargin: e.target.value,
                          MonthlypartnerPrice:
                            Number(partnerPrice) + Number(e.target.value),
                        });
                      }}
                      className={classes.input}
                      margin="dense"
                      // value={margins.BASIC_MONTHLY}
                      disabled={props.disableFields}
                      defaultValue={"i.margin"}
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    margin="dense"
                    value={monthlyPlanPrice.MonthlypartnerPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    {yearlyPlanPrice.plan}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    disabled="true"
                    margin="dense"
                    value={yearlyPlanPrice.YearlyaisensyPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  {
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      value={yearlyPlanPrice.Yearlymargin}
                      onChange={(e) => {
                        let value = Number(e.target.value) * 100000 ?? 0;
                        const partnerPrice = yearlyPlanPrice.YearlyaisensyPrice;
                        setYearlyPlanPrice({
                          ...yearlyPlanPrice,
                          Yearlymargin: e.target.value,
                          YearlypartnerPrice:
                            Number(partnerPrice) + Number(e.target.value),
                        });
                      }}
                      className={classes.input}
                      margin="dense"
                      // value={margins.BASIC_MONTHLY}
                      disabled={props.disableFields}
                      defaultValue={yearlyPlanPrice.Yearlymargin}
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    margin="dense"
                    value={yearlyPlanPrice.YearlypartnerPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: "24px 16px 8px 0px" }}>
            <Button
              variant={!props.disableFields ? "outlined" : "contained"}
              size="medium"
              color="primary"
              onClick={() => {
                toggleDialog();
              }}
            >
              {props.disableFields ? "Close" : "Cancel"}
            </Button>
            {!props.disableFields && (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={savePlan}
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                Save
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={alertMsg}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={"onSnackbarClose"}
        className={classes.snackbar}
        autoHideDuration={3000}
      >
        <Alert severity={alertType} onClose={onSnackbarClose}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

function MyFlowDialog(props) {
  console.log(props);
  const [tier1PlanPrice, setTier1PlanPrice] = useState({});
  const [tier2PlanPrice, setTier2PlanPrice] = useState({});
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const item = props.item;
  const currency = props.currency;
  const classes = props.classes;
  const toggleDialog = props.toggleDialog;
  let openDialog = props.openDialog;
  console.log(openDialog);
  const myarr = [];
  let itemPrice = item.aisensyPlans.tier1.plans;
  let itemPrice2 = item.aisensyPlans.tier2.plans;
  let monthlyPriceTier1 = +(itemPrice.MONTHLY[currency] / 100000).toFixed(2);
  let yearlyPriceTier1 = +(itemPrice.YEARLY[currency] / 1200000).toFixed(2);
  let monthlyPriceTier2 = +(itemPrice2.MONTHLY[currency] / 100000).toFixed(2);
  let yearlyPriceTier2 = +(itemPrice2.YEARLY[currency] / 1200000).toFixed(2);
  console.log(monthlyPriceTier1, monthlyPriceTier2);
  let partnerPrice;

  if (item.partnerPlans) {
    partnerPrice = item.partnerPlans;
  }

  useEffect(() => {
    const newArr = [];
    console.log("effect hook run");
    const arrayToIter = ["Tier1", "Tier2"];
    const terms = ["Monthly", "Yearly"];
    const myArr = [];
    for (let i = 0; i < arrayToIter.length; i++) {
      for (let j = 0; j < terms.length; j++) {
        const myObj = {};
        myObj.plan = terms[j];
        if (arrayToIter[i] === "Tier1") {
          if (terms[j] === "Monthly") {
            myObj[`${terms[j]}${arrayToIter[i]}aisensyPrice`] =
              monthlyPriceTier1;
          } else {
            myObj[`${terms[j]}${arrayToIter[i]}aisensyPrice`] =
              yearlyPriceTier1;
          }
        } else {
          if (terms[j] === "Monthly") {
            myObj[`${terms[j]}${arrayToIter[i]}aisensyPrice`] =
              monthlyPriceTier2;
          } else {
            myObj[`${terms[j]}${arrayToIter[i]}aisensyPrice`] =
              yearlyPriceTier2;
          }
        }
        if (partnerPrice) {
          myObj[`${arrayToIter[i]}${terms[j]}margin`] =
            partnerPrice[arrayToIter[i]][terms[j]] -
            myObj[`${terms[j]}${arrayToIter[i]}aisensyPrice`];
          myObj[`${arrayToIter[i]}${terms[j]}partnerPrice`] =
            partnerPrice[arrayToIter[i]][terms[j]];
        } else {
          myObj[`${arrayToIter[i]}${terms[j]}margin`] = 0;
          myObj[`${arrayToIter[i]}${terms[j]}partnerPrice`] =
            myObj[`${terms[j]}${arrayToIter[i]}aisensyPrice`];
        }
        let newObj = { ...myObj };
        if (arrayToIter[i] === "Tier1") {
          newArr.push(newObj);
        } else {
          myArr.push(newObj);
        }
      }
      if (arrayToIter[i] === "Tier1") {
        const newTestObj = { ...newArr };
        setTier1PlanPrice(newTestObj);
        console.log(newTestObj);
      } else {
        const newTestObj = { ...myArr };
        console.log(newTestObj);
        setTier2PlanPrice(newTestObj);
      }
    }
  }, []);

  const savePlan = () => {
    setIsLoading(true);
    const partnerPlans = { Tier1: {}, Tier2: {} };
    partnerPlans.Tier1.Monthly = tier1PlanPrice[0].Tier1MonthlypartnerPrice;
    partnerPlans.Tier1.Yearly = tier1PlanPrice[1].Tier1YearlypartnerPrice;
    partnerPlans.Tier2.Monthly = tier2PlanPrice[0].Tier2MonthlypartnerPrice;
    partnerPlans.Tier2.Yearly = tier2PlanPrice[1].Tier2YearlypartnerPrice;
    const key = props.item.e;

    axios
      .post(AddonURl + "/api/update-addon-plan", { partnerPlans, key })
      .then((res) => {
        setIsLoading(false);
        props.fetchPartnerDetails();
        setAlertType("success");
        setAlertMsg("plan update success");
        toggleDialog();
      })
      .catch((error) => {
        setIsLoading(false);
        setAlertType("error");
        setAlertMsg("plan update failed");
      });
  };

  const onSnackbarClose = () => {
    setAlertType("");
    setAlertMsg("");
  };

  console.log(openDialog, tier1PlanPrice, tier2PlanPrice);
  return Object.entries(tier1PlanPrice).length < 1 ? (
    <CircularProgress />
  ) : (
    <>
      <Dialog open={openDialog} closeDialog={toggleDialog} maxWidth={"sm"}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle className={classes.dialogTitle}>
            <Typography variant="h3">{props.item.name}</Typography>
          </DialogTitle>
        </Box>
        <Box
          style={{ padding: "24px 16px 16px 24px" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <DialogContent>
            <Grid
              container
              alignItems="center"
              className={classes.content}
              spacing={0}
            >
              <Grid container>
                <Grid item md={3}></Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    {Object.keys(this.props.tenantDetails).length
                      ? this.props.tenantDetails.name
                      : "AiSensy"}{" "}
                    Prices
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    Margin
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    Your Platform Prices
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    5 Flows
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    {tier1PlanPrice[0].plan}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    className={classes.input}
                    disabled="true"
                    margin="dense"
                    value={tier1PlanPrice[0].MonthlyTier1aisensyPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  {
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      onChange={(e) => {
                        let val = Number(e.target.value);
                        const partnerPrice =
                          tier1PlanPrice[0].MonthlyTier1aisensyPrice;
                        const newObj = { ...tier1PlanPrice };
                        newObj[0].Tier1Monthlymargin = Number(val);
                        newObj[0].Tier1MonthlypartnerPrice =
                          Number(partnerPrice) + Number(val);
                        setTier1PlanPrice(newObj);
                      }}
                      className={classes.input}
                      margin="dense"
                      value={tier1PlanPrice[0].Tier1Monthlymargin}
                      disabled={props.disableFields}
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    // onChange={(e) => {
                    //   this.setState({ contactName: e.target.value });
                    // }}
                    // className={classes.input}
                    // margin="dense"
                    value={tier1PlanPrice[0].Tier1MonthlypartnerPrice}
                    // InputProps={{
                    //   startAdornment: currency === "USD" ? "$" : "₹",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    {tier1PlanPrice[1].plan}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    // onChange={(e) => {
                    //   this.setState({ contactName: e.target.value });
                    // }}
                    className={classes.input}
                    disabled="true"
                    margin="dense"
                    value={tier1PlanPrice[1].YearlyTier1aisensyPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  {
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      onChange={(e) => {
                        const val = Number(e.target.value);
                        const partnerPrice =
                          tier1PlanPrice[1].YearlyTier1aisensyPrice;
                        const newObj = { ...tier1PlanPrice };
                        newObj[1].Tier1Yearlymargin = Number(val);
                        newObj[1].Tier1YearlypartnerPrice =
                          Number(partnerPrice) + Number(val);
                        setTier1PlanPrice(newObj);
                      }}
                      className={classes.input}
                      margin="dense"
                      value={tier1PlanPrice[1].Tier1Yearlymargin}
                      disabled={props.disableFields}
                      // defaultValue={yearlyPlanPrice.Yearlymargin}
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    // onChange={(e) => {
                    //   this.setState({ contactName: e.target.value });
                    // }}
                    className={classes.input}
                    margin="dense"
                    value={tier1PlanPrice[1].Tier1YearlypartnerPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    10 Flows
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    {tier2PlanPrice[0].plan}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    className={classes.input}
                    disabled="true"
                    margin="dense"
                    value={tier2PlanPrice[0].MonthlyTier2aisensyPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  {
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      onChange={(e) => {
                        const val = Number(e.target.value);
                        const partnerPrice =
                          tier2PlanPrice[0].MonthlyTier2aisensyPrice;
                        const newObj = { ...tier2PlanPrice };
                        newObj[0].Tier2Monthlymargin = Number(val);
                        newObj[0].Tier2MonthlypartnerPrice =
                          Number(partnerPrice) + Number(val);
                        setTier2PlanPrice(newObj);
                      }}
                      className={classes.input}
                      margin="dense"
                      value={tier2PlanPrice[0].Tier2Monthlymargin}
                      disabled={props.disableFields}
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    // onChange={(e) => {
                    //   this.setState({ contactName: e.target.value });
                    // }}
                    // className={classes.input}
                    // margin="dense"
                    value={tier2PlanPrice[0].Tier2MonthlypartnerPrice}
                    // InputProps={{
                    //   startAdornment: currency === "USD" ? "$" : "₹",
                    // }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    {tier2PlanPrice[1].plan}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    // onChange={(e) => {
                    //   this.setState({ contactName: e.target.value });
                    // }}
                    className={classes.input}
                    disabled="true"
                    margin="dense"
                    value={tier2PlanPrice[1].YearlyTier2aisensyPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  {
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      // value={yearlyPlanPrice.Yearlymargin}
                      onChange={(e) => {
                        const val = Number(e.target.value);
                        const partnerPrice =
                          tier2PlanPrice[1].YearlyTier2aisensyPrice;
                        const newObj = { ...tier2PlanPrice };
                        newObj[1].Tier2Yearlymargin = Number(val);
                        newObj[1].Tier2YearlypartnerPrice =
                          Number(partnerPrice) + Number(val);
                        setTier2PlanPrice(newObj);
                      }}
                      className={classes.input}
                      margin="dense"
                      value={tier2PlanPrice[1].Tier2Yearlymargin}
                      disabled={props.disableFields}
                      // defaultValue={yearlyPlanPrice.Yearlymargin}
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    // onChange={(e) => {
                    //   this.setState({ contactName: e.target.value });
                    // }}
                    className={classes.input}
                    margin="dense"
                    value={tier2PlanPrice[1].Tier2YearlypartnerPrice}
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: "24px 16px 8px 0px" }}>
            <Button
              variant={!props.disableFields ? "outlined" : "contained"}
              size="medium"
              color="primary"
              onClick={() => {
                toggleDialog();
              }}
            >
              {props.disableFields ? "Close" : "Cancel"}
            </Button>
            {!props.disableFields && (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={savePlan}
                diabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                Save
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={!!alertMsg}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={onSnackbarClose}
        className={classes.snackbar}
        autoHideDuration={3000}
      >
        <Alert severity={alertType} onClose={onSnackbarClose}>
          {alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

function Cell({
  classes,
  item,
  field,
  allBusiness,
  openCampaign,
  editPlan,
  editWccPlan,
  openPlanDialog,
  editFlowPlan,
  ...props
}) {
  switch (field) {
    case "remainingCredit":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100000}</Typography>
          </Box>
        </Grid>
      );
      break;

    case "templateCredit":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {item[field] ? item[field] / 100000 : 0}
            </Typography>
          </Box>
        </Grid>
      );
      break;
    case "createdAt":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {getFormattedDate(item[field])}
            </Typography>
          </Box>
        </Grid>
      );

      break;
    case "clientId":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1" noWrap>
            {item[field] && allBusiness[item[field]]?.name}
            {/* {columnData[key]} */}
            {/* {agents.find((i) => i._id === columnData[key])?.displayName || "-"} */}
          </Typography>
        </Box>
      );
      break;
    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            {Array.from(Array(20).keys()).map((i) => {
              return (
                <div key={i} className={classes.tableItem1}>
                  <Skeleton className={classes.skeleton} />
                </div>
              );
            })}
          </Box>
        </Grid>
      );
      break;
    case "whatsappNumber":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1" noWrap>
            {item[field] ? "+" + item[field] : "-"}
          </Typography>
        </Box>
      );
      break;

    case "excessUsage":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100000}</Typography>
          </Box>
        </Grid>
      );
      break;
    case " company":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100}</Typography>
          </Box>
        </Grid>
      );
      break;

    case "actions":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <VisibilityIcon
              onClick={
                item.e === "FLOW_BUILDER"
                  ? () => editFlowPlan(true, item, true)
                  : () => {
                      return editPlan(true, item, true);
                    }
              }
              color="primary"
            />
            <EditIcon
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={
                item.e === "FLOW_BUILDER"
                  ? () => editFlowPlan(true, item, false)
                  : () => editPlan(true, item, false)
              }
            />
          </Box>
        </Grid>
      );
    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field]}</Typography>
          </Box>
        </Grid>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  mainDiv: {
    background: "grey",
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  billTableContainer: {
    marginTop: "1.5em",
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    width: "100%",
  },
  tableContainer: {
    top: "60px",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      // height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
    flexWrap: "nowrap",
  },
  plandialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  tableItem: {
    background: "white",
    marginBottom: "8px",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
    },
  },
});

const connectAddonsPage = connect(
  (state) => ({
    partner: state.partner.partner,
    tenantDetails: state.tenant.tenant,
  }),
  { addAgentSize, fetchPartnerDetails }
)(AddonsPage);

export default withStyles(styles)(connectAddonsPage);
