import React, { useState, useEffect, useRef } from "react";
import {
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * Below is the RBAC object that represents the permissions for the Partner Agent Role.
 * The object is a nested structure where each key represents a permission category.
 * Each category can have three permissions: read, write, and export.
 * The value of each permission is a boolean that represents whether the permission is granted or not.
 * To add a new permission, you can add a new key to the object with the desired structure.
 */

const RBAC = {
  dashboard: {
    read: Boolean,
    // write: Boolean,
    // export: Boolean,
  },
  business: {
    read: Boolean,
    write: Boolean,
    // export: Boolean,
    // manageWaba: {
    //   read: Boolean,
    //   write: Boolean,
    //   export: Boolean,
    // },
  },
  plans: {
    read: Boolean,
    write: Boolean,
    // export: Boolean,
    // planFamily: {
    //   read: Boolean,
    //   write: Boolean,
    //   export: Boolean,
    // },
    // wccPlanFamily: {
    //   read: Boolean,
    //   write: Boolean,
    //   export: Boolean,
    // },
  },
  billings: {
    read: Boolean,
    // export: Boolean,
  },
  manage: {
    read: Boolean,
    write: Boolean,
    // export: Boolean,
    // setDefaults: {
    //   read: Boolean,
    //   write: Boolean,
    //   export: Boolean,
    // },
  },
};

// Create a deep copy of an object with all boolean values set to false
const createInitialPermissions = (obj) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      typeof value === "object"
        ? [key, createInitialPermissions(value)]
        : [key, false]
    )
  );

const PermissionsForm = ({
  onPermissionChange,
  disabled,
  activeRole,
  edit,
}) => {
  const [permissions, setPermissions] = useState(
    createInitialPermissions(RBAC)
  );

  const hasSetPermissions = useRef(false);

  useEffect(() => {
    if (edit && !hasSetPermissions.current) {
      setPermissions(activeRole.accessLevel);
      hasSetPermissions.current = true;
    }
    onPermissionChange(permissions);
  }, [permissions, edit, activeRole.accessLevel]);

  const getNestedProperty = (obj, path) => {
    return path.reduce(
      (xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null),
      obj
    );
  };

  const updatePermissions = (permissions, path, value) => {
    const [first, ...rest] = path;
    if (rest.length === 0) {
      // If we're at the end of the path, set the value
      return { ...permissions, [first]: value };
    } else {
      // Otherwise, update the nested object
      return {
        ...permissions,
        [first]: updatePermissions(permissions[first], rest, value),
      };
    }
  };

  const handleCheckboxChange = (path) => {
    setPermissions((prevPermissions) => {
      const value = !getNestedProperty(prevPermissions, path); // Toggle the value

      // If the clicked checkbox is "read" and "write" is selected, prevent unselecting "read"
      if (
        path[path.length - 1] === "read" &&
        getNestedProperty(prevPermissions, [...path.slice(0, -1), "write"])
      ) {
        return prevPermissions;
      }

      let newPermissions = updatePermissions(prevPermissions, path, value);

      // If the clicked checkbox is "write"
      if (path[path.length - 1] === "write") {
        // If "write" is selected and "read" is not selected, select "read"
        if (
          value &&
          !getNestedProperty(newPermissions, [...path.slice(0, -1), "read"])
        ) {
          newPermissions = updatePermissions(
            newPermissions,
            [...path.slice(0, -1), "read"],
            true
          );
        }
        // If "write" is unselected, unselect "read"
        else if (!value) {
          newPermissions = updatePermissions(
            newPermissions,
            [...path.slice(0, -1), "read"],
            false
          );
        }
      }

      return newPermissions;
    });
  };

  const renderPermissions = (permissions, path = []) =>
    Object.entries(permissions).map(([key, value]) => {
      const newPath = [...path, key];

      return (
        <div
          key={newPath.join(".")}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="overline"
            style={{ marginLeft: "2%", fontSize: "0.9rem" }}
          >
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "44%",
            }}
          >
            {value.read !== undefined && (
              <div>
                <FormControlLabel
                  key={`${newPath.join(".")}.read`}
                  control={
                    <Checkbox
                      checked={
                        edit
                          ? getNestedProperty(permissions, [...newPath, "read"])
                          : Object.keys(activeRole).length
                          ? getNestedProperty(activeRole.accessLevel, [
                              ...newPath,
                              "read",
                            ])
                          : value.read
                      }
                      onChange={() =>
                        handleCheckboxChange([...newPath, "read"])
                      }
                      disabled={disabled}
                      color="primary"
                    />
                  }
                  label="View"
                />
              </div>
            )}
            {value.write !== undefined && (
              <div style={{ marginRight: "7%" }}>
                <FormControlLabel
                  key={`${newPath.join(".")}.write`}
                  control={
                    <Checkbox
                      checked={
                        edit
                          ? getNestedProperty(permissions, [
                              ...newPath,
                              "write",
                            ])
                          : Object.keys(activeRole).length
                          ? getNestedProperty(activeRole.accessLevel, [
                              ...newPath,
                              "write",
                            ])
                          : value.write
                      }
                      onChange={() =>
                        handleCheckboxChange([...newPath, "write"])
                      }
                      disabled={disabled}
                      color="primary"
                    />
                  }
                  label="Edit"
                />
              </div>
            )}
            {/* {value.export !== undefined && (
              <div>
                <FormControlLabel
                  key={`${newPath.join(".")}.export`}
                  control={
                    <Checkbox
                      checked={
                        Object.keys(activeRole).length
                          ? getNestedProperty(activeRole.accessLevel, [
                              ...newPath,
                              "export",
                            ])
                          : value.write
                      }
                      onChange={() =>
                        handleCheckboxChange([...newPath, "export"])
                      }
                      disabled={disabled}
                      color="primary"
                    />
                  }
                  label="export"
                />
              </div>
            )} */}
          </div>
        </div>
      );
    });

  // const renderPermissions = (permissions, path = [], nested) =>
  //   Object.entries(permissions).map(([key, value]) => {
  //     const newPath = [...path, key];
  //     if (typeof value === "object") {
  //       return (
  //         <Accordion key={newPath.join(".")}>
  //           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
  //             {key}
  //           </AccordionSummary>
  //           <AccordionDetails>
  //             {renderPermissions(value, newPath, true)}
  //           </AccordionDetails>
  //         </Accordion>
  //       );
  //     } else {
  //       return (
  //         <FormControlLabel
  //           key={newPath.join(".")}
  //           control={
  //             <Checkbox
  //               checked={value}
  //               onChange={() => handleCheckboxChange(newPath)}
  //             />
  //           }
  //           label={key}
  //         />
  //       );
  //     }
  //   });

  return <div>{renderPermissions(permissions)}</div>;
};

export default PermissionsForm;
