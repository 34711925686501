import React, { useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Clear } from "@material-ui/icons";
import { URL } from "../../config/config";
import axios from "axios";

export default function ResetPasswordDialog(props) {
  const { clientId, setSnackbar, toggleDialog, showDialog, classes } = props;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  const resetPassword = () => {
    if (!password || !confirmPassword) {
      setSnackbar({ msg: "Please enter password", severity: "error" });
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      setSnackbar({ msg: "Your passwords donot match", severity: "error" });
      setLoading(false);
      return;
    }

    axios
      .post(URL + `/api/reset-business-password`, {
        clientId,
        password,
        confirmPassword,
      })
      .then((data) => {
        toggleDialog();
        setSnackbar({
          msg: "Password updated successfully",
          severity: "success",
        });
      })
      .catch((e) => {
        setSnackbar({ msg: "Something went wrong!", severity: "error" });
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={showDialog}
      maxWidth="xs"
      fullWidth
      onClose={toggleDialog}
      aria-labelledby="form-dialog-title"
      className={classes.plandialogContainer}
    >
      <Grid
        item
        container
        justifyContent="flex-end"
        xs={12}
        style={{
          height: 30,
          marginBottom: 10,
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 16,
          paddingRight: 8,
        }}
      >
        <Typography variant="h4">Reset Password</Typography>
        <IconButton onClick={toggleDialog}>
          <Clear />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid item xs={12} sm={12} style={{ marginBottom: "20px" }}>
          <Typography>Password</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "20px" }}>
          <TextField
            name="password"
            placeholder="Enter your password here"
            className={classes.textField}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} style={{ marginBottom: "20px" }}>
          <Typography>Confirm Password</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "20px" }}>
          <TextField
            name="confirmPassword"
            placeholder="Confirm your password here"
            type="password"
            className={classes.textField}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          xs={12}
          style={{
            alignItems: "flex-end",
            justifyContent: "end",
            paddingRight: "15px",
            marginBottom: "8px",
          }}
        >
          <Button
            // color="primary"
            variant="outlined"
            onClick={toggleDialog}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setLoading(true);
              resetPassword();
            }}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={20} /> : ""}
          >
            Reset
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
