import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "../../commons/ErrorPage/ErrorPage";
import BillingPage from "./BillingPage"
import BillingDetails from "./BillingDetails"
class BillingRoute extends Component {

    render() {
        return (
            <Switch>
                <Route exact path={"/billings"} component={BillingPage} />
                <Route
                    path={"/billings/:billingId"}
                    component={BillingDetails}
                />

            </Switch>
        );
    }
}

export default BillingRoute;
