import { stepTwo, stepThree } from "../pages/Dashboard/WabaForm/FormDetails";

const validateFirstStep = (props) => {
  const { name, number, email } = props;
  if (!name) return "Please enter a valid name";
  const validNumberPattern = /^\d+$/;
  if (!validNumberPattern.test(number))
    return "Please enter a valid phone number";
  const validEmailPattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!validEmailPattern.test(email))
    return "Please enter a valid email address";
};

const validateSecondStep = (props) => {
  const {
    entityName,
    website,
    businessVertical,
    companySize,
    headquarters,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
  } = props;
  if (!entityName) return "Please enter your Business Entity Name";
  const validWebsitePattern =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  if (!validWebsitePattern.test(website))
    return "Please enter a valid Website URL";
  if (!stepTwo.keys.businessVertical.options.includes(businessVertical))
    return "Please select one Business Vertical";
  if (!stepTwo.keys.companySize.options.includes(companySize))
    return "Please specify your Company Size";
  if (!headquarters) return "Please specify country of your Headquarters";
  if (!addressLine1) return "Please specify Official Address: Address line 1";
  if (!city) return "Please specify City";
  if (!state) return "Please specify State";
  if (!zipCode) return "Please specify Zip Code";
  if (!country) return "Please specify Country";
};

const validateThirdStep = (props) => {
  const { facebookPage, businessManagerId, isManagerVerified } = props;
  const validWebsitePattern =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  if (!validWebsitePattern.test(facebookPage))
    return "Please enter a valid Facebook Page";
  if (!businessManagerId) return "Please enter a FB Business Manager ID";
  if (!stepThree.keys.isManagerVerified.options.includes(isManagerVerified))
    return "Please select one option";
};

const validateFourthStep = (props) => {
  const { whatsappNumber, displayName } = props;
  const validNumberPattern = /^\d+$/;
  if (!validNumberPattern.test(whatsappNumber))
    return "Please enter a valid Whatsapp Phone Number";
  if (!displayName) return "Please enter a FB Business Manager ID";
};

export default {
  validateFirstStep,
  validateSecondStep,
  validateThirdStep,
  validateFourthStep,
};
