import { fetchPlanFamilies } from "../store/planFamilyState";
import { fetchWccPlans } from "../store/wccPlanState";
import store from "../store/store";
import { fetchWhatsappPaymentsPlans } from "../store/whatsappPaymentsPlanState";
const state = store.getState();
const dispatch = store.dispatch;

const setPlanFamilies = () => {
  const plans = state?.planFamilies?.allPlanFamilies;
  if (!plans || !Object.keys(plans).length) {
    const dispatch = store.dispatch;
    fetchPlanFamilies()(dispatch, store.getState);
  }
};

const setWccPlans = () => {
  const plans = state?.wccPlans?.allWccPlans;
  if (!plans || !Object.keys(plans).length) {
    fetchWccPlans()(dispatch, store.getState);
  }
};

const setWhatsappPaymentsPlans = () => {
  const plans = state?.whatsappPaymentsPlans?.allPlans;
  if (!plans || !Object.keys(plans).length) {
    fetchWhatsappPaymentsPlans()(dispatch, store.getState);
  }
};

const setAllPlans = () => {
  setPlanFamilies();
  setWccPlans();
  setWhatsappPaymentsPlans();
};

export default {
  setPlanFamilies,
  setWccPlans,
  setWhatsappPaymentsPlans,
  setAllPlans,
};
