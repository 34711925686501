import React from "react";

import Media from "../MessageTypes/Media";
import replacePrameters from "../../helpers/replaceParameters";
import { Grid, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import TouchAppIcon from "@material-ui/icons/TouchApp";

export default function TemplateMessagePreview(props) {
  const {
    parameters,
    media,
    selectedTemplate,
    maxHeight,
    bigIcon,
    showDisclaimer,
  } = props;
  const type = selectedTemplate.type;
  const text = replacePrameters(selectedTemplate.format, parameters);
  let ctaURL = selectedTemplate.templateCTAUrl;

  //For pre-existing templates
  if (!ctaURL) {
    const urlObject = (selectedTemplate.callToAction || []).filter(
      (e) => e.type === "URL"
    );

    if (urlObject.length > 0) {
      ctaURL = urlObject[0].buttonValue;
      if (parameters && parameters.length > 0) {
        const param = parameters[parameters.length - 1];
        if (param)
          ctaURL = ((string, replacement) => {
            return string.replace(/{{(\d+)}}/g, function (match, g1) {
              return `[${replacement}]`;
            });
          })(urlObject[0].buttonValue, param);
      }
    }
  }

  const payload = {
    caption: type !== "TEXT" ? text : "",
    text: type === "TEXT" ? text : "",
    url: type === "LOCATION" ? null : media.url,
    filename: type === "LOCATION" ? null : media.filename,
    latitude: type === "LOCATION" ? media?.latitude : null,
    longitude: type === "LOCATION" ? media?.longitude : null,
    locationName: type === "LOCATION" ? media?.name : null,
    address: type === "LOCATION" ? media?.address : null,
    parameters: parameters,
    actionType: selectedTemplate.actionType,
    headerText: selectedTemplate.headerText,
    footerText: selectedTemplate.footerText,
    callToAction: selectedTemplate.callToAction,
    templateCTAUrl: ctaURL,
  };

  return (
    <>
      <Media
        type={selectedTemplate.type}
        selectedTemplate={selectedTemplate}
        payload={payload}
        maxHeight={maxHeight}
        bigIcon={bigIcon}
        showDisclaimer={showDisclaimer}
      />
    </>
  );
}
