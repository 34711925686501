import axios from "axios";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BusinessDetailsForm, {
  minBusinessFieldsPartners,
} from "./BusinessDetailsForm";
import { URL, partnerURL } from "../../config/config";
import { createnewClient } from "./Clientstore";
import { Clear } from "@material-ui/icons";
import {
  withStyles,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  Tooltip,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import QontoStepIcon from "../../commons/Stepper/QontoStepIcon";
import QontoConnector from "../../commons/Stepper/QontoConnector";
import plansHelper from "../../helpers/plansHelper";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector-material-ui-new";
import { countries } from "../../commons/countries/countries";
import { isoToDialCode } from "../../config/dialCodes";
//update
const STEPS = ["Business Details", "Create Business"];
const states = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Foreign Country",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Other Territory",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
//currency fix
class CreateBusiness extends Component {
  constructor(props) {
    super(props);
    const { familyId, planName, firstWccPlan, firstWaPayPlan } =
      this.initalisePlans();
    this.state = {
      activeStep: 0,
      error: null,
      snack: false,
      snackMessage: "",
      snackStatus: "",
      email: null,
      display_name: "",
      website: "",
      description: "",
      company: "",
      contact: "",
      currency: this.props?.partner?.currency || "INR",
      password: null,
      timezone: "Asia/Calcutta GMT+05:30",
      buttonDisable: false,
      countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
      industry: "",
      companySize: "",
      showPassword: false,
      wccPlan: firstWccPlan,
      waPayPlan: firstWaPayPlan,
      plan: {
        newFamilyId: familyId,
        newPlanId: planName,
      },
      zohoCustomer: "",
      checked: true,
      gstNumber: "",
    };
  }

  initalisePlans = () => {
    const {
      partnerPlanFamily,
      partnerWccFamily,
      partnerWhatsappPaymentPlans,
      partner,
    } = this.props;

    const {
      defaultPlanFamily,
      defaultPlanName,
      defaultWccPlan,
      defaultWhatsappPaymentsPlan,
    } = partner || {};

    let familyId = defaultPlanFamily;
    let planName = defaultPlanName;
    let firstWccPlan = defaultWccPlan;
    let firstWaPayPlan = defaultWhatsappPaymentsPlan;

    if (Object.keys(partnerPlanFamily).length > 0) {
      familyId = !familyId ? Object.keys(partnerPlanFamily)[0] : familyId;
      const plans = partnerPlanFamily[familyId].plans;
      planName = !planName ? Object.keys(plans)[0] : planName;
      firstWccPlan = !firstWccPlan
        ? Object.keys(partnerWccFamily).length > 0 &&
          Object.keys(partnerWccFamily)[0]
        : firstWccPlan;
    }

    if (!firstWaPayPlan && Object.keys(partnerWhatsappPaymentPlans).length) {
      firstWaPayPlan = Object.keys(partnerWhatsappPaymentPlans)[0];
    }

    return { familyId, planName, firstWccPlan, firstWaPayPlan };
  };

  sendEmail = () => {
    axios
      .post(`${URL}/auth/send-password-reset-email`, {
        email: this.state.email,
      })
      .then((response) => {
        const snackStatus = "success";
        const snackMessage =
          "Account password setup link has been successfully sent to the business email";
        this.props.businessSnackDialog(snackStatus, snackMessage);
        this.props.businessDialog();
      });
  };

  handleChange = (event) => {
    this.setState({
      zohoCustomer: event.target.value,
    });
  };

  copyBillingAddress = (event) => {
    this.setState({ checked: event.target.checked });
    if (event.target.checked) {
      this.setState({ shippingAddress: this.state.billingAddress });
    }
  };

  copySnack = () => {
    this.setState({
      alertType: "success",
      alertMsg: "Hosted page payment link copied",
    });
  };

  selectCountry(val) {
    this.setState({
      billingAddress: { ...this.state.billingAddress, country: val },
    });
  }

  selectShippingCountry(val) {
    this.setState({
      shippingAddress: { ...this.state.shippingAddress, country: val },
    });
  }

  countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  };

  selectRegion(val) {
    this.setState({
      billingAddress: { ...this.state.billingAddress, state: val },
    });
    console.log(val);
  }

  selectShippingRegion(val) {
    this.setState({
      shippingAddress: { ...this.state.shippingAddress, state: val },
    });
    console.log(val);
  }

  projectSubmit = (id, data) => {
    const partnerId = this.props.user.id;
    const { plan, wccPlan, waPayPlan, display_name } = this.state;
    axios
      .post(partnerURL + `/partner/${partnerId}/business/${id}/project`, {
        name: display_name,
        planFamilyId: plan.newFamilyId,
        planName: plan.newPlanId,
        wccId: wccPlan,
        paymentPlanId: waPayPlan,
        zohoCustomer: this.state.zohoCustomer,
        billingAddress: this.state.billingAddress,
        shippingAddress: this.state.checked
          ? this.state.billingAddress
          : this.state.shippingAddress,
        gstNumber: this.state.gstNumber,
      })
      .then((response) => {
        const snackStatus = "success";
        const snackMessage = "Successfully created";

        if (this.props?.partner?.type !== "DIRECT") {
          this.sendEmail();
        }
        this.props.createnewClient(data);
        this.props.businessSnackDialog(snackStatus, snackMessage);
        this.props.businessDialog();
      })
      .catch((error) => {
        const snackStatus = "error";
        const snackMessage = error?.response?.data?.message;

        this.props.businessSnackDialog(snackStatus, snackMessage);
      });
  };

  createClientWithZoho = () => {
    this.setState({ isLoading: true });
    const partnerId = this.props.partner._id;
    const {
      email,
      display_name,
      company,
      contact,
      currency,
      companySize,
      timezone,
      password,
      plan,
      wccPlan,
      website,
      description,
      industry,
    } = this.state;

    axios
      .post(partnerURL + `/partner/zoho/${partnerId}/business`, {
        email,
        display_name,
        company,
        contact,
        currency,
        companySize,
        timezone,
        password,
        planFamilyId: plan.newFamilyId,
        planName: plan.newPlanId,
        wccId: wccPlan,
        zohoCustomer: this.state.zohoCustomer,
        billingAddress: this.state.billingAddress,
        shippingAddress: this.state.checked
          ? this.state.billingAddress
          : this.state.shippingAddress,
        gstNumber: this.state.gstNumber,
        website,
        description,
        industry,
      })
      .then((response) => {
        const snackStatus = "success";
        const snackMessage = "Successfully created";
        if (this.props?.partner?.type !== "DIRECT") {
          this.sendEmail();
        }
        // this.props.createnewClient(response.data);
        this.props.businessSnackDialog(snackStatus, snackMessage);
        this.props.businessDialog();
        this.setState({
          createEmail: null,
          createDisplay_name: null,
          createCompany: null,
          createContact: null,
          currency: this.props?.partner?.currency,
          timezone: "Asia/Calcutta GMT+05:30",
          countryCode: "+91",
          industry: null,
          companySize: null,
          password: null,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        const snackStatus = "error";
        const snackMessage = error?.response?.data?.message;
        this.props.businessSnackDialog(snackStatus, snackMessage);
      });
  };

  createClient = () => {
    const partnerId = this.props.user.id;
    const {
      email,
      display_name,
      company,
      contact,
      currency,
      companySize,
      timezone,
      password,
    } = this.state;
    const queryObj = {
      display_name,
      email,
      contact,
      company,
      timezone,
      currency,
      companySize,
    };

    if (password) queryObj.password = password;

    axios
      .post(partnerURL + `/partner/${partnerId}/business/`, queryObj)
      .then((response) => {
        this.projectSubmit(response.data.id, response.data);
        // this.setState({ clientDialog: !this.state.clientDialog, openProjectDialog: true });

        this.setState({
          createEmail: null,
          createDisplay_name: null,
          createCompany: null,
          createContact: null,
          currency: this.props?.partner?.currency,
          timezone: "Asia/Calcutta GMT+05:30",
          countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
          industry: null,
          companySize: null,
          password: null,
        });
      })
      .catch((error) => {
        console.error(error);
        const snackStatus = "error";
        const snackMessage = error?.response?.data?.message;
        this.props.businessSnackDialog(snackStatus, snackMessage);
      });
  };

  updateState = (keys, value) => {
    const returnObj = {};
    returnObj[keys] = value;
    this.setState(returnObj);
  };

  render() {
    const { classes, tags, partner } = this.props;
    const { activeStep, snackStatus } = this.state;

    return (
      <div className={classes.root}>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            color="primary"
            size="small"
            variant="filled"
            onClick={() => this.props.businessDialog()}
          >
            <Clear
              style={{
                position: "fixed",
              }}
            />
          </IconButton>
        </Grid>
        <Grid xs={12} md={12} lg={12}>
          <Grid
            xs={12}
            md={12}
            lg={12}
            container
            justify="center"
            className={classes.formContainer}
          >
            <Grid item xs={12} alignItems="center" style={{ paddingTop: 8 }}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
              >
                {STEPS.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>

            {/* Display content of active step */}
            <Grid
              container
              item
              xs={12}
              md={12}
              style={{ height: 500 }}
              className={classes.stepContainer}
              alignItems="flex-start"
            >
              {this.getStepContent(activeStep, tags)}
            </Grid>
            {/* Prev and Next button container */}
            {/* Prev and Next button container */}
            <Grid
              container
              item
              xs={12}
              md={12}
              className={classes.buttonContainer}
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                {activeStep === 0 && snackStatus !== "Success" ? (
                  ""
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    margin={0}
                    onClick={this.prevStep}
                    disabled={activeStep === 0}
                  >
                    Prev
                  </Button>
                )}
              </Grid>
              {activeStep < 1 && (
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    margin={0}
                    color="primary"
                    onClick={() => {
                      this.nextStep();
                    }}
                    disabled={activeStep == 0 && this.nextBtnDisableStatus()}
                  >
                    Next
                  </Button>
                </Grid>
              )}
              {activeStep == 1 && (
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    margin={0}
                    color="primary"
                    onClick={this.createClientWithZoho}
                    disabled={this.state.isLoading}
                  >
                    Create
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  getStepContent = (step, tags) => {
    const {
      partnerPlanFamily,
      partnerWccFamily,
      partnerWhatsappPaymentPlans,
      partner,
    } = this.props;
    const { type, addons: partnerAddons = {} } = partner || {};
    const { WHATSAPP_PAY } = partnerAddons;
    const { plan, wccPlan, waPayPlan } = this.state;
    const plans = partnerPlanFamily[plan.newFamilyId]?.plans || [];
    const { classes } = this.props;
    switch (step) {
      case 0:
        return (
          <BusinessDetailsForm
            updateState={this.updateState}
            email={this.state.email}
            display_name={this.state.display_name}
            website={this.state.website}
            description={this.state.description}
            company={this.state.company}
            contact={this.state.contact}
            currency={this.state.currency}
            password={this.state.password}
            timezone={this.state.timezone}
            countryCode={this.state.countryCode}
            industry={this.state.industry}
            companySize={this.state.companySize}
            partnerType={type}
          />
        );
      case 1:
        return (
          <Box>
            <Box>
              <Typography
                variant="h3"
                style={{
                  marginBottom: "16px",
                }}
                color="primary"
              >
                Project Name
              </Typography>
              <Tooltip
                enterTouchDelay={0}
                style={{ fontSize: "1.2em" }}
                title="First project of a business must have the same name as business"
              >
                <TextField
                  id="name"
                  disabled
                  // label="Project Name"
                  variant="outlined"
                  type="text"
                  name="display_name"
                  onChange={this.handleInput}
                  value={this.state.display_name}
                  className={this.props.classes.textFieldRoot}
                />
              </Tooltip>
            </Box>
            {type !== "DIRECT" && (
              <>
                <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                  <Typography>Plan Family</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    fullWidth
                    options={Object.keys(partnerPlanFamily)}
                    getOptionLabel={(option) => {
                      if (option && partnerPlanFamily)
                        return partnerPlanFamily[option].name;
                    }}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option" && value) {
                        this.setState({
                          plan: { ...plan, newFamilyId: value },
                        });
                      }
                    }}
                    defaultValue={plan?.newFamilyId}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select a plan family"
                        name="campaignName"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
                  <Typography>Plan</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    fullWidth
                    options={Object.keys(plans)}
                    getOptionLabel={(option) => {
                      return plans && plans[option]?.name;
                    }}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        this.setState({
                          plan: { ...plan, newPlanId: value },
                        });
                      }
                    }}
                    defaultValue={plan?.newPlanId}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select a plan"
                        name="campaignName"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                  <Typography>Wcc Plan Family</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    fullWidth
                    options={Object.keys(partnerWccFamily)}
                    getOptionLabel={(option) => {
                      return partnerWccFamily[option]?.name;
                    }}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        this.setState({ wccPlan: value });
                      }
                    }}
                    defaultValue={wccPlan}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select a wcc plan"
                        name="campaignName"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
                {WHATSAPP_PAY?.status === "active" && (
                  <Grid container>
                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography>WA Pay Plan Family</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Autocomplete
                        disableClearable
                        autoHighlight
                        fullWidth
                        options={Object.keys(partnerWhatsappPaymentPlans)}
                        getOptionLabel={(option) => {
                          return partnerWhatsappPaymentPlans[option]?.name;
                        }}
                        onChange={(e, value, reason) => {
                          if (reason === "select-option") {
                            this.setState({ waPayPlan: value });
                          }
                        }}
                        defaultValue={waPayPlan}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{ ...params.InputProps }}
                            placeholder="Select a wcc plan"
                            name="campaignName"
                          />
                        )}
                        style={{ width: "calc(100%)", paddingTop: "0px" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {this.props?.partner?.isZohoEnabled === true && (
                  <>
                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Enter GST Number
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        placeholder="Enter your address"
                        type="text"
                        style={{ width: "100%" }}
                        value={this.state.gstNumber}
                        onChange={(event) =>
                          this.setState({
                            gstNumber: event.target.value,
                          })
                        }
                        disabled={this.state.isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Billing Address
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Select Country
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Autocomplete
                        disableClearable
                        autoHighlight
                        fullWidth
                        options={countries}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, value, reason) => {
                          console.log(e, value, reason);
                          this.selectCountry(value && value.label);
                        }}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{this.countryToFlag(option.code)}</span>
                            {option.label} ({option.code}) +{option.phone}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            InputProps={{ ...params.InputProps }}
                            placeholder="Choose a country"
                          />
                        )}
                        style={{ width: "calc(100%)", paddingTop: "0px" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Select state
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      {this.state.billingAddress?.country === "India" && (
                        <Autocomplete
                          disableClearable
                          autoHighlight
                          fullWidth
                          options={states.map((option) => option)}
                          onChange={(e, value, reason) => {
                            this.selectRegion(value);
                          }}
                          value={this.state.billingAddress?.state}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="outlined"
                              placeholder="Choose a state"
                              InputProps={{ ...params.InputProps }}
                            />
                          )}
                          style={{ width: "calc(100%)", paddingTop: "0px" }}
                        />
                      )}

                      {this.state.billingAddress?.country !== "India" && (
                        <TextField
                          id="State"
                          label="Enter State"
                          variant="outlined"
                          type="text"
                          name="state"
                          value={this.state.billingAddress?.state}
                          onChange={(e) => this.selectRegion(e.target.value)}
                          className={classes.textFieldRoot}
                          // onBlur={() => {
                          //   trackEvent({
                          //     event: !isOtpVerified
                          //       ? "signup_company_name_filled"
                          //       : "new_signup_company_name_filled",
                          //     properties: {
                          //       company,
                          //     },
                          //   });
                          // }}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Enter Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        placeholder="Enter your address"
                        type="text"
                        style={{ width: "100%" }}
                        value={this.state.billingAddress?.street}
                        onChange={(event) =>
                          this.setState({
                            billingAddress: {
                              ...this.state.billingAddress,
                              street: event.target.value,
                            },
                          })
                        }
                        disabled={this.state.isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Enter City
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        placeholder="city"
                        type="text"
                        style={{ width: "100%" }}
                        value={this.state.billingAddress?.city}
                        onChange={(event) =>
                          this.setState({
                            billingAddress: {
                              ...this.state.billingAddress,
                              city: event.target.value,
                            },
                          })
                        }
                        disabled={this.state.isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" gutterBottom>
                        Enter Zip
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <TextField
                        placeholder="zip code"
                        type={"number"}
                        style={{ width: "100%" }}
                        value={this.state.billingAddress?.zip}
                        onChange={(event) =>
                          this.setState({
                            billingAddress: {
                              ...this.state.billingAddress,
                              zip: event.target.value,
                            },
                          })
                        }
                        disabled={this.state.isLoading}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                      <Typography variant="h4" style={{ marginTop: 24 }}>
                        Shipping Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginBottom: "20px" }}>
                      <Grid
                        md={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="h5" gutterBottom>
                          Use shipping address same as billing address
                        </Typography>
                        <Checkbox
                          checked={this.state.checked}
                          color="primary"
                          onChange={this.copyBillingAddress}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Grid>
                    </Grid>

                    {!this.state.checked && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Select Country
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <Autocomplete
                            disableClearable
                            autoHighlight
                            fullWidth
                            options={countries}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, value, reason) => {
                              console.log(e, value, reason);
                              this.selectShippingCountry(value && value.label);
                            }}
                            renderOption={(option) => (
                              <React.Fragment>
                                <span>{this.countryToFlag(option.code)}</span>
                                {option.label} ({option.code}) +{option.phone}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                InputProps={{ ...params.InputProps }}
                                placeholder="Choose a country"
                              />
                            )}
                            style={{ width: "calc(100%)", paddingTop: "0px" }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Select state
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          {this.state.shippingAddress?.country === "India" && (
                            <Autocomplete
                              disableClearable
                              autoHighlight
                              fullWidth
                              options={states.map((option) => option)}
                              onChange={(e, value, reason) => {
                                this.selectShippingRegion(value);
                              }}
                              value={this.state.shippingAddress?.state}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Choose a state"
                                  InputProps={{ ...params.InputProps }}
                                />
                              )}
                              style={{
                                width: "calc(100%)",
                                paddingTop: "0px",
                              }}
                            />
                          )}

                          {this.state.shippingAddress?.country !== "India" && (
                            <TextField
                              id="State"
                              label="Enter State"
                              variant="outlined"
                              type="text"
                              name="state"
                              value={this.state.shippingAddress?.state}
                              onChange={(e) =>
                                this.selectShippingRegion(e.target.value)
                              }
                              className={classes.textFieldRoot}
                              // onBlur={() => {
                              //   trackEvent({
                              //     event: !isOtpVerified
                              //       ? "signup_company_name_filled"
                              //       : "new_signup_company_name_filled",
                              //     properties: {
                              //       company,
                              //     },
                              //   });
                              // }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Enter Address
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <TextField
                            placeholder="Enter your address"
                            type="text"
                            style={{ width: "100%" }}
                            value={this.state.shippingAddress?.street}
                            onChange={(event) =>
                              this.setState({
                                shippingAddress: {
                                  ...this.state.shippingAddress,
                                  street: event.target.value,
                                },
                              })
                            }
                            disabled={this.state.isLoading}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Enter City
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <TextField
                            placeholder="city"
                            type="text"
                            style={{ width: "100%" }}
                            value={this.state.shippingAddress?.city}
                            onChange={(event) =>
                              this.setState({
                                shippingAddress: {
                                  ...this.state.shippingAddress,
                                  city: event.target.value,
                                },
                              })
                            }
                            disabled={this.state.isLoading}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "20px" }}
                        >
                          <Typography variant="h4" gutterBottom>
                            Enter Zip
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                          <TextField
                            placeholder="zip code"
                            type={"number"}
                            style={{ width: "100%" }}
                            value={this.state.shippingAddress?.zip}
                            onChange={(event) =>
                              this.setState({
                                shippingAddress: {
                                  ...this.state.shippingAddress,
                                  zip: event.target.value,
                                },
                              })
                            }
                            disabled={this.state.isLoading}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Typography
                  variant="body2"
                  color={"error"}
                  style={{ marginTop: "16px" }}
                >
                  {this.state.errorMessage}
                </Typography>
              </>
            )}
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  nextStep = () => {
    const { activeStep } = this.state;
    // if (activeStep <= 1) {
    // console.log("1st",window.btoa(this.props.previewImageUrl))
    this.setState({ activeStep: activeStep + 1 });
    // }
  };

  prevStep = () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: activeStep - 1 });
  };

  nextBtnDisableStatus = () => {
    const t = this.state;
    const partnerId = this.props.user.id;
    switch (t.activeStep) {
      // Campaign name has to be unique also. Verify it from store. minimum char length
      case 0:
        return !minBusinessFieldsPartners.includes(partnerId)
          ? !this.state.company ||
              !this.state.website ||
              !this.state.companySize ||
              !this.state.industry ||
              !this.state.display_name ||
              !this.state.contact ||
              this.state.buttonDisable ||
              !!(t.error && t.error.errorStep <= t.activeStep)
          : !this.state.display_name ||
              !this.state.email ||
              !this.state.contact ||
              !!(t.error && t.error.errorStep <= t.activeStep);

      default:
        return true;
    }
  };
}

const styles = (theme) => ({
  root: {
    // width: "100%",
    height: "100%",
    overflowY: "scroll",
  },

  formContainer: {
    borderRadius: "8px",
    // minHeight: 200,
    justifyContent: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "disabled",
    border: "2px solid disabled",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "disabled",
      // color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },

  formContainer1: {
    background: "white",
    borderRadius: "8px",
    // minHeight: 200,
    boxSizing: "border-box",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    marginBottom: "20px",
  },

  stepContainer: {
    overflow: "auto",
    marginBottom: "1em",
    display: "block",
  },

  buttonContainer: {
    // margin: "0% 8%",
    width: "100%",
    padding: "1em 0em",
    overflowX: "none",
  },

  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    background: "white",
    marginBottom: "20px",
  },
  option: {
    // backgroundColor: "white",
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
    myTextfield: {
      "&  MuiOutlinedInput-adornedEnd": {
        paddingRight: 0,
      },
    },
  },
  myStateClass: {
    "&::before": {
      content: '"some content"',
      display: "block",
      height: 60,
      marginTop: -60,
    },
    "&::after": {
      content: '"some content"',
      display: "block",
      height: 60,
      marginTop: -60,
    },
  },
});

const BusinessConnect = connect(
  (state) => ({
    user: state.login.user,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    partnerWccFamily: state.wccPlans.allWccPlans,
    partnerWhatsappPaymentPlans: state.whatsappPaymentsPlans.allPlans,
    partner: state.partner.partner,
  }),
  { createnewClient }
)(CreateBusiness);

export default withStyles(styles)(withRouter(BusinessConnect));
