import axios from "axios";
import { projectURL, URL } from "../config/config";

const initialState = {
  webhooks: {},
  isLoading: false,
  error: null,
};

const LOAD_START = "WEBHOOK/LOAD_START";
const LOAD_SUCCESS = "WEBHOOK/LOAD_SUCCESS";
const LOAD_FAILURE = "WEBHOOK/LOAD_FAILURE";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = ({ webhooks }) => ({
  type: LOAD_SUCCESS,
  payload: { webhooks },
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const fetchAssistantsWebhooks = (projectId) => (dispatch, getState) => {
  dispatch(loadStart());
  axios
    .get(projectURL + `/project/${projectId}/webhooks`)
    .then((response) => {
      let webhooks = response.data;
      let webhooksObj = {};
      webhooks.forEach((assistant) => {
        webhooksObj[assistant.project_id] = assistant;
      });
      dispatch(loadSuccess(webhooksObj));
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export default function AssistantsMessageReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        webhooks: payload.webhooks,
        isLoading: false,
        error: null,
      };

    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
