import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import axios from "axios";
import { fetchPartnerDetails } from "../../store/partnerState";
import Header from "../../commons/Header/Header";
import plansHelper from "../../helpers/plansHelper";
import moment from "moment";
import { partnerURL, TENANT_ID } from "../../config/config";
import DateFilter from "../../commons/Filters/DateFilter";
import { checkAccess } from "../../helpers/RBAC";
import LockIcon from "@material-ui/icons/Lock";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, "w"),
      endDate: moment(),
      usgage: {},
      focusedInput: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.fetchPartnerDetails();
    plansHelper.setAllPlans();
    this.getAnalytics();
  }

  redirectToPayment = (currency) => {
    let url;
    TENANT_ID
      ? (url =
          !currency || currency === "INR"
            ? "https://api.whatsapp.com/send?phone=918882171216&text=I%20want%20to%20recharge(%E2%82%B9)%20my%20Partner%20Dashboard%20account."
            : "https://api.whatsapp.com/send?phone=918882171216&text=I%20want%20to%20recharge(%24)%20my%20Partner%20Dashboard%20account.")
      : (url =
          !currency || currency === "INR"
            ? "https://api.whatsapp.com/send?phone=919149327854&text=I%20want%20to%20recharge(%E2%82%B9)%20my%20Partner%20Dashboard%20account."
            : "https://api.whatsapp.com/send?phone=919149327854&text=I%20want%20to%20recharge(%24)%20my%20Partner%20Dashboard%20account.");

    window.open(url, "_blank");
  };

  getAnalytics = async () => {
    try {
      const { startDate, endDate } = this.state;
      const { partnerDetail } = this.props;
      const { _id: partnerId, currency } = partnerDetail;
      let url = partnerURL + `/partner/${partnerId}/wcc-analytics?`;
      if (startDate) url += `&startDate=${startDate}`;
      if (endDate) url += `&endDate=${endDate}`;
      const response = await axios.get(url);

      const analytics = response.data?.wccAnalytics || [];

      const currencySign = currency === "USD" ? "$" : "₹";
      const roundNumber = (value) =>
        currencySign + (value ? (value / 100000).toFixed(2) : "0");

      const usage = {
        tcBic: 0,
        tcUic: 0,
        cbBic: 0,
        cbUic: 0,
        tcAc: 0,
        cbAc: 0,
        tcMc: 0,
        cbMc: 0,
        tcUc: 0,
        cbUc: 0,
        cbSc: 0,
        tcSc: 0,
      };

      for (const data of analytics) {
        usage.tcBic += data.bicTemplateCreditMetrics?.creditUsage || 0;
        usage.tcUic += data.uicTemplateCreditMetrics?.creditUsage || 0;
        usage.tcAc += data.acTemplateCreditMetrics?.creditUsage || 0;
        usage.tcMc += data.mcTemplateCreditMetrics?.creditUsage || 0;
        usage.tcUc += data.ucTemplateCreditMetrics?.creditUsage || 0;
        usage.tcSc += data.scTemplateCreditMetrics?.creditUsage || 0;

        usage.cbBic += data.bicCentralBalanceMetrics?.creditUsage || 0;
        usage.cbUic += data.uicCentralBalanceMetrics?.creditUsage || 0;
        usage.cbAc += data.acCentralBalanceMetrics?.creditUsage || 0;
        usage.cbMc += data.mcCentralBalanceMetrics?.creditUsage || 0;
        usage.cbUc += data.ucCentralBalanceMetrics?.creditUsage || 0;
        usage.cbSc += data.scCentralBalanceMetrics?.creditUsage || 0;
      }

      for (const key in usage) usage[key] = roundNumber(usage[key]);
      this.setState({ usage, isLoading: false });
    } catch (error) {
      console.log("Fetch analytics failed ->", error);
    }
  };

  setDateRange = (startDate, endDate) => {
    if (startDate && endDate) this.setState({ startDate, endDate });
  };

  setFocus = (focusedInput) => {
    const picker = document.getElementsByClassName("DateInput");
    if (picker[0])
      picker[0].style.border =
        focusedInput === "startDate" ? "1px solid grey" : "1px solid lightgrey";
    if (picker[1])
      picker[1].style.border =
        focusedInput === "endDate" ? "1px solid grey" : "1px solid lightgrey";
    this.setState({ focusedInput });
  };

  onButtonClick = () => {
    window.open("https://help.aisensy.com/en/", "_blank");
  };

  render() {
    const {
      classes,
      isPartnerDetailLoading,
      partnerDetail,
      agent,
      partnerAgents,
    } = this.props;

    const { currency, centralBalance } = partnerDetail;
    const { usage, startDate, endDate, focusedInput, isLoading } = this.state;
    const currencySign = currency === "USD" ? "$" : "₹";

    const hasReadAccess = checkAccess(
      partnerDetail,
      partnerAgents,
      agent,
      "dashboard",
      "read"
    );

    const hasWriteAccess = true;

    return (
      <div className={classes.root}>
        {isPartnerDetailLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className={classes.root}>
            <Header
              businessTitle={"Partner Dashboard"}
              hasReadAccess={hasReadAccess}
              hasWriteAccess={hasWriteAccess}
              buttonTitle={
                Object.keys(this.props.tenantDetails).length
                  ? null
                  : "Help Center"
              }
              onButtonClick={this.onButtonClick}
            />

            <Box my={3} />
            {/* Layout */}
            <Grid container justify="center">
              <Grid item xs={12} md={11}>
                {hasReadAccess ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      {!agent.isReferral ? (
                        <Box className={classes.container}>
                          <Typography variant="h4" color="primary">
                            Centralised Balance Management
                          </Typography>
                          <Box mt={3} />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="end"
                          >
                            <Typography variant="body1" color="textPrimary">
                              Current Balance
                            </Typography>
                            <Typography variant="h3" color="primary">
                              {currency === "USD" ? "$" : "₹"}
                              {centralBalance / 100000}
                            </Typography>
                          </Box>

                          <Box mt={2} />
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.redirectToPayment(partnerDetail?.currency)
                            }
                            style={{
                              maxWidth: "300px",
                              margin: "auto",
                              display: "block",
                            }}
                          >
                            Recharge Now
                          </Button>
                          {/* <Box textAlign="center" mt={1}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          onClick={() => {}}
                          className={classes.viewOldButton}
                        >
                          View Previous Invoices →
                        </Typography>
                      </Box> */}
                        </Box>
                      ) : (
                        <Box
                          className={classes.container}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "25px",
                          }}
                        >
                          <Typography variant="h4" color="primary">
                            Referral Link :-
                          </Typography>
                          <Typography variant="h3" color="primary">
                            {agent.referralLink}
                          </Typography>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              navigator.clipboard.writeText(agent.referralLink)
                            }
                            style={{
                              maxWidth: "100px",
                            }}
                          >
                            Copy Link
                          </Button>
                        </Box>
                      )}
                      {!agent.isReferral && (
                        <Box className={classes.container}>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: 8 }}
                            alignItems="center"
                            justifyContent={"space-between"}
                          >
                            <Typography
                              variant="h4"
                              color="primary"
                              xs={12}
                              sm={6}
                            >
                              WhatsApp Usage
                            </Typography>
                            <Box
                              xs={12}
                              sm={6}
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <DateFilter
                                startDate={startDate}
                                endDate={endDate}
                                focusedInput={focusedInput}
                                setDate={this.setDateRange}
                                setFocus={this.setFocus}
                                classes={classes}
                                minDate={moment(new Date("03/25/2023"))}
                              />
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  this.setState({ isLoading: true });
                                  this.getAnalytics();
                                }}
                                disabled={!startDate || !endDate}
                                style={{ marginLeft: 10, padding: "0px 20px" }}
                                endIcon={
                                  isLoading && (
                                    <CircularProgress
                                      style={{ color: "white" }}
                                      size={16}
                                    />
                                  )
                                }
                              >
                                Apply
                              </Button>
                            </Box>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.cbMc || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Marketing Central
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Balance Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.tcMc || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Marketing Template
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Credit Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.cbUc || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Utility Central
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Balance Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.tcUc || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Utility Template
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Credit Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.cbAc || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Authentication Central
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Balance Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.tcAc || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Authentication Template
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Credit Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.cbSc || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Service Central
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Balance Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.tcSc || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Service Template
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  Credit Usage
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>

                          {/* <Grid container spacing={2}> */}
                          {/* <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.cbBic || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  BIC Central Balance Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.tcBic || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  BIC Template Credit Usage
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.cbUic || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  UIC Central Balance Usage
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Box
                                border="1px solid lightgrey"
                                className={classes.container}
                                textAlign="center"
                              >
                                <Typography variant="h3" color="primary">
                                  {usage?.tcUic || `${currencySign}0`}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                  UIC Template Credit Usage
                                </Typography>
                              </Box>
                            </Grid> */}

                          {/*   <Grid item xs={12} md={6}>
                          <Box
                            border="1px solid lightgrey"
                            className={classes.container}
                            textAlign="center"
                          >
                            <Typography variant="h3" color="primary">
                              {usage?.cbUic || `${currencySign}0`}
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              UIC Central Balance Usage
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Box
                            border="1px solid lightgrey"
                            className={classes.container}
                            textAlign="center"
                          >
                            <Typography variant="h3" color="primary">
                              {usage?.tcUic || `${currencySign}0`}
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              UIC Template Credit Usage
                            </Typography>
                          </Box>
                        </Grid> */}
                          {/* </Grid> */}
                        </Box>
                      )}

                      {!agent.isReferral && (
                        <Grid
                          container
                          className={classes.container}
                          textAlign="center"
                        >
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h3" color="primary">
                              {currencySign}
                              {(partnerDetail?.minAllowedBalance ?? "0") /
                                100000}
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              Min Balance Allowance
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h3" color="primary">
                              {partnerDetail?.liveAccounts ?? "_"}
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              WhatsApp Account Live
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box className={classes.container} textAlign="center">
                          <Typography variant="h3" color="primary">
                            {partnerDetail?.liveAccounts ?? "_"}
                          </Typography>
                          <Typography variant="body1" color="textPrimary">
                            WhatsApp Account Live
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box className={classes.container} textAlign="center">
                          <Typography variant="h3" color="primary">
                            <FiberManualRecord
                              style={{
                                color: "#08CF65",
                                fontSize: 16,
                                position: "relative",
                                top: 1.5,
                                right: 3,
                              }}
                            />{" "}
                            Online
                          </Typography>
                          <Typography variant="body1" color="textPrimary">
                            WhatsApp API Status
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Box
                      className={classes.container}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h4" color="textPrimary">
                        Setup Your White Label Dashboard
                      </Typography>
                      <Button variant="contained" color="primary">
                        Apply Now
                      </Button>
                    </Box>

                    <Box
                      className={classes.container}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h4" color="textPrimary">
                        Explore WhatsApp API's
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href="https://aisensy.stoplight.io/docs/partner-api/67fb491f16f99-getting-started"
                      >
                        Explore Now
                      </Button>
                    </Box>

                    <Box className={classes.container}>
                      <Typography variant="h3" color="textPrimary">
                        Updates from WhatsApp
                      </Typography>
                      <Box mt={2} />
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        gutterBottom
                      >
                        • WhatsApp Commerce APIs are now Live
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        gutterBottom
                      >
                        • Soon Launching WhatsApp Payments
                      </Typography>
                    </Box>
                  </Grid> */}
                  </Grid>
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      height: "50vh",
                      alignItems: "center",
                      // backgroundColor: "#f5f5f5",
                      borderRadius: "10px",
                      padding: "20px",
                    }}
                  >
                    <LockIcon
                      style={{
                        fontSize: 90,
                        color: "grey",
                        marginBottom: "10px",
                      }}
                    />
                    <Typography variant="h4" color="textSecondary">
                      Access Denied
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Looks like you don't have the necessary permissions to
                      view this page
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      Please contact your administrator for more information.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box my={8} />
          </div>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
});

// export default withStyles(styles)(Dashboard);

const connectDashboard = connect(
  (state) => ({
    isPartnerDetailLoading: state.partner.isLoading,
    partnerDetail: state.partner.partner,
    agent: state.login.user,
    tenantDetails: state.tenant.tenant,
    partnerAgents: state.partnerAgents.partnerAgents,
  }),
  {
    fetchPartnerDetails,
  }
)(Dashboard);

export default withStyles(styles)(connectDashboard);
