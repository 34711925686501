import React, { useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Clear } from "@material-ui/icons";

export default function PlanChangeDialog(props) {
  const {
    classes,
    showDialog,
    toggleDialog,
    planFamilies,
    changePlan,
    defaultPlanFamily,
    defaultPlanName,
  } = props;

  let [newFamilyId, newPlanId] = [defaultPlanFamily, defaultPlanName];
  if (!defaultPlanFamily || !defaultPlanName) {
    newFamilyId = Object.keys(planFamilies)[0];
    const plans = planFamilies[newFamilyId].plans;
    newPlanId = Object.keys(plans)[0];
  }
  const [plan, setActivePlan] = useState({
    newFamilyId,
    newPlanId,
  });

  const plans = planFamilies?.[plan?.newFamilyId]?.plans;
  const [isLoading, setLoading] = useState(false);

  return (
    <Dialog
      open={showDialog}
      maxWidth="xs"
      onClose={toggleDialog}
      aria-labelledby="form-dialog-title"
      className={classes.plandialogContainer}
    >
      <Grid
        item
        container
        justifyContent="flex-end"
        xs={12}
        style={{
          height: 30,
          marginBottom: 10,
          marginTop: 4,
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 16,
          paddingRight: 8,
        }}
      >
        <Typography variant="h4">Select Plan Family</Typography>
        <IconButton onClick={toggleDialog}>
          <Clear />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            container
            style={{
              alignItems: "center",
              paddingRight: "8px",
              marginTop: "15px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
              <Typography>Plan Family</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Autocomplete
                disableClearable
                autoHighlight
                fullWidth
                options={Object.keys(planFamilies).map((option) => option)}
                getOptionLabel={(option) => {
                  return planFamilies && planFamilies[option].name;
                }}
                onChange={(e, value, reason) => {
                  if (reason === "select-option") {
                    setActivePlan((prev) => ({ ...prev, newFamilyId: value }));
                  }
                }}
                defaultValue={plan.newFamilyId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    InputProps={{ ...params.InputProps }}
                    placeholder="Select a plan family"
                    name="campaignName"
                  />
                )}
                style={{ width: "calc(100%)", paddingTop: "0px" }}
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ marginBottom: "20px" }}>
              <Typography>Plans</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Autocomplete
                disableClearable
                autoHighlight
                fullWidth
                options={plans && Object.keys(plans).map((option) => option)}
                getOptionLabel={(option) => {
                  return plans && plans[option]?.name;
                }}
                onChange={(e, value, reason) => {
                  if (reason === "select-option") {
                    setActivePlan((prev) => ({ ...prev, newPlanId: value }));
                  }
                }}
                defaultValue={plan.newPlanId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    InputProps={{ ...params.InputProps }}
                    placeholder="Select a plan"
                    name="campaignName"
                  />
                )}
                style={{ width: "calc(100%)", paddingTop: "0px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          xs={12}
          style={{
            alignItems: "flex-end",
            justifyContent: "end",
            paddingRight: "15px",
            marginBottom: "8px",
          }}
        >
          <Button
            // color="primary"
            variant="outlined"
            onClick={toggleDialog}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              setLoading(true);
              await changePlan(plan.newFamilyId, plan.newPlanId);
              setLoading(false);
            }}
            disabled={
              isLoading ||
              (defaultPlanFamily === plan.newFamilyId &&
                defaultPlanName === plan.newPlanId)
            }
            endIcon={isLoading ? <CircularProgress size={20} /> : ""}
          >
            Change Plan
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
