import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Radio,
  Snackbar,
  TextField,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  InputAdornment,
} from "@material-ui/core";
import { Edit, Clear } from "@material-ui/icons";
import { connect } from "react-redux";
import { Alert, Autocomplete } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { URL } from "../../config/config";
import { addAgentSize } from "../../store/partnerState";
import ColorPicker from "../../commons/ColorPicker/ColorPicker";
import { checkAccess } from "../../helpers/RBAC";

class ProfilePage extends Component {
  // Get colors
  // Case 1: Found
  // colors: primary, second
  // update obj
  // save (selective)

  // Case 2: not found -> Object.keys(response.data.colors) === 0
  // colors = {} - default values use
  // updateobj =  {...colors};
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      planSubmitLoader: false,
      colors: {
        primary: "#ffffff",
        background: "#ffffff",
      },
      updateObj: {},
      colorSaveLoader: false,
    };
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  componentDidMount = () => {
    this.setState({
      planSubmitLoader: true,
    });
    axios
      .get(URL + `/api/get-profile-color`)
      .then((response) => {
        this.setState({
          planSubmitLoader: false,
        });
        if (
          response.data.colorObj === undefined ||
          Object.keys(response.data.colorObj) === 0
        ) {
          this.setState({ updateObj: { ...this.state.colors } });
        } else {
          this.setState({ colors: { ...response.data.colorObj } });
        }
      })
      .catch((error) => {
        this.setState({
          planSubmitLoader: false,
          alert: true,
          alertSeverity: "error",
          alertMsg: error.response.data.message || "No profile color found",
        });
      });
  };

  setColor = (value, name) => {
    this.setState({
      colors: { ...this.state.colors, [name]: value },
      updateObj: { ...this.state.updateObj, [name]: value },
    });
  };

  handleColorInput = (e) => {
    const key = e.target.name;
    let value = e.target.value.trim();
    value = value[0] !== "#" ? "#" + value : value;
    // if (key === "btnBackground") {
    //   value = value === '#' ? "#4dc247" : value;
    // } else {
    //   value = value === '#' ? "#0A5F54" : value;
    // }
    this.setState({
      colors: { ...this.state.colors, [key]: value },
      updateObj: { ...this.state.updateObj, [key]: value },
    });
  };

  saveTheme = () => {
    this.setState({
      colorSaveLoader: true,
    });
    axios
      .post(URL + `/api/update-profile-color`, {
        colorObj: this.state.updateObj,
      })
      .then((response) => {
        this.setState({
          colorSaveLoader: false,
          alert: true,
          alertSeverity: "success",
          alertMsg: "Successfully changed profile's color",
        });
      })
      .catch((error) => {
        this.setState({
          alert: true,
          alertSeverity: "error",
          alertMsg:
            error?.response?.data?.message || "Profile colors change failed",
          colorSaveLoader: false,
        });
      });
  };

  render() {
    const { classes, match, partner, partnerAgents, user } = this.props;
    console.log(this.state.updateObj);
    const assistantId = match.params.projectId;
    const renderNow = [
      {
        heading: "Primary color",
        placeholder: "pick a color",
        name: "primary",
        onChange: this.handleColorInput,
        colorState: this.state.colors.primary,
        value: this.state.colors.primary,
        setColorFun: "primary",
      },
      {
        heading: "Background color",
        placeholder: "pick a color",
        name: "background",
        onChange: this.handleColorInput,
        colorState: this.state.colors.background,
        value: this.state.colors.background,
        setColorFun: "background",
      },
    ];

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "manage",
      "write"
    );

    return this.state.planSubmitLoader ? (
      <CircularProgress style={{ display: "none" }} />
    ) : (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Header businessTitle={"Configure Profile"} />
        <Box my={3} />
        {/* Layout */}
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Grid container className={classes.container} spacing={2}>
                  {renderNow.map((i) => {
                    return (
                      <>
                        <Grid item xs={12} md={5}>
                          <Typography variant="h4">{i.heading}</Typography>
                          <Box mt={3} />
                          <Box>
                            <Grid item xs={12} md={12}>
                              <TextField
                                fullWidth
                                type="text"
                                placeholder={i.placeholder}
                                name={i.name}
                                value={i.value}
                                onChange={i.onChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ColorPicker
                                        color={i.colorState}
                                        setColor={(value) =>
                                          this.setColor(value, i.setColorFun)
                                        }
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Box>
                          <Box mt={2} />
                        </Grid>
                      </>
                    );
                  })}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    {hasWriteAccess && (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={this.state.colorSaveLoader}
                        endIcon={
                          this.state.colorSaveLoader ? (
                            <CircularProgress size={20} />
                          ) : (
                            ""
                          )
                        }
                        onClick={() => this.saveTheme()}
                      >
                        Save
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box my={8} />
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  detailContainer: {
    marginTop: "20px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  primaryBackground: {
    background: "rgb(70 20 134 / 15%)",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerPlans: {
    marginTop: 24,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
  },
  containerButton: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "15px 15px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  cells: {
    padding: 24,
    color: "grey",
    borderBottom: "1px solid #ecebeb",
  },
  plandialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

const connectProfilePage = connect(
  (state) => ({
    partner: state.partner.partner,
    partnerAgents: state.partnerAgents.partnerAgents,
    user: state.login.user,
  }),
  { addAgentSize }
)(ProfilePage);

export default withStyles(styles)(connectProfilePage);
