import {
  Box,
  Typography,
  TextField,
  withStyles,
  Link,
} from "@material-ui/core";
import React from "react";

function FormField(props) {
  const {
    title,
    subText,
    hyperlink,
    isMandatory,
    stepKey,
    setKey,
    value,
    classes,
    placeholder,
  } = props;
  return (
    <Box>
      <Typography className={classes.title}>
        {title}
        {isMandatory && <span className={classes.mandatory}>*</span>}
      </Typography>
      {subText && (
        <Typography className={classes.subTitle}>{subText}</Typography>
      )}
      {hyperlink && (
        <Box className={classes.hyperlink}>
          <Link
            fullWidth
            href={hyperlink}
            target="_blank"
            style={{ color: "blue" }}
          >
            {hyperlink}
          </Link>
        </Box>
      )}
      <TextField
        variant="outlined"
        classes={{ root: classes.textFieldRoot }}
        placeholder={placeholder}
        fullWidth
        disabled="true"
        name={stepKey}
        defaultValue={value}
        onChange={(e) => setKey(stepKey, e.target.value)}
      />
    </Box>
  );
}

const styles = (theme) => ({
  title: {
    color: "#202124",
    fontWeight: 600,
    fontSize: 15,
    textDecoration: "none rgb(32,33,36)",
    margin: "24px 0 5px 0",
  },
  mandatory: {
    color: "red",
  },
  subTitle: {
    color: "#151514",
    margin: "0 0 6px 0",
    fontSize: 14,
    textDecoration: "none rgb(21,21,20)",
  },
  textFieldRoot: {
    backgroundColor: "rgb(240,240,240)",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    width: "100%",
    padding: 2,
    boxShadow: "none",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontSize: "15px",
    fontWeight: 500,
  },
  hyperlink: {
    width: "100%",
    color: "blue",
    fontSize: 14,
    textDecoration: "underline",
    marginBottom: 8,
  },
});

export default withStyles(styles)(FormField);
