import React, { useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Clear } from "@material-ui/icons";

export default function WccChangeDialog(props) {
  const {
    classes,
    showDialog,
    toggleDialog,
    wccPlans,
    defaultPlan,
    changePlan,
  } = props;
  const [plan, setPlan] = useState(defaultPlan ?? Object.keys(wccPlans)[0]);
  const [isLoading, setLoading] = useState(false);

  return (
    <Dialog
      open={showDialog}
      maxWidth="xs"
      onClose={toggleDialog}
      aria-labelledby="form-dialog-title"
      className={classes.plandialogContainer}
    >
      <Grid
        item
        container
        justifyContent="flex-end"
        xs={12}
        style={{
          height: 30,
          marginBottom: 10,
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: 16,
          paddingRight: 8,
        }}
      >
        <Typography variant="h4">Select Wcc Plan Family</Typography>
        <IconButton onClick={toggleDialog}>
          <Clear />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            container
            style={{
              alignItems: "center",
              paddingRight: "8px",
              marginTop: "15px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
              <Typography>Plans</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Autocomplete
                disableClearable
                autoHighlight
                fullWidth
                options={Object.keys(wccPlans).map((option) => option)}
                getOptionLabel={(option) => {
                  return wccPlans[option]?.name;
                }}
                onChange={(e, value, reason) => {
                  if (reason === "select-option") {
                    setPlan(value);
                  }
                }}
                defaultValue={plan}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    InputProps={{ ...params.InputProps }}
                    placeholder="Select a wcc plan"
                    name="campaignName"
                  />
                )}
                style={{ width: "calc(100%)", paddingTop: "0px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          xs={12}
          style={{
            alignItems: "flex-end",
            justifyContent: "end",
            paddingRight: "15px",
            marginBottom: "8px",
          }}
        >
          <Button
            // color="primary"
            variant="outlined"
            onClick={toggleDialog}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              setLoading(true);
              await changePlan(plan);
              setLoading(false);
            }}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={20} /> : ""}
          >
            Change Plan
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
