import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "../../commons/ErrorPage/ErrorPage";
import AssistantsDetail from "./AssistantsDetail";
// import AssistantsDetails from "./AssistantsDetails";
import AssistantsPage from "./AssistantsPage";

class AssistantsRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route exact path={"/assistants"} component={AssistantsPage} />
        <Route path={"/assistants/:projectId"} component={AssistantsDetail} />
        <Route component={ErrorPage} />
      </Switch>
    );
  }
}

export default AssistantsRoute;
