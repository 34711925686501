import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  TextField,
  Switch,
  StepLabel,
  Tooltip,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Add, Edit as EditIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { Edit, Clear } from "@material-ui/icons";
import { connect } from "react-redux";
import { Alert, Autocomplete } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import axios from "axios";
import { URL } from "../../config/config";
import { addAgentSize, fetchPartnerDetails } from "../../store/partnerState";
import AgentLimitDialog from "./AgentLimitDialog";
import QontoConnector from "../../commons/Stepper/QontoConnector";
import QontoStepIcon from "../../commons/Stepper/QontoStepIcon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PermissionsForm from "./PartnerAgents/CreateRoles";

const STEPS = ["Role Name", "Member Roles"];

const fields = [
  {
    name: "Name",
    size: 4,
    key: "name",
  },
  {
    name: "Created At",
    size: 4,
    key: "createdAt",
  },
  {
    name: "Actions",
    size: 4,
    key: "actions",
  },
];

class AgentRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      openDialog: false,
      activeStep: 0,
      name: "",
      allRoles: {},
      permissionsList: {},
      activeRole: {},
      disableFields: false,
      editFields: false,
    };
  }

  onPermissionChange = (e) => {
    this.setState({ permissionsList: e });
  };

  openRoleDialog = () => {
    this.setState({ openDialog: true });
  };

  closeRoleDialog = () => {
    this.setState({
      openDialog: false,
      activeStep: 0,
      name: "",
      disableFields: false,
      editFields: false,
      activeRole: {},
      permissionsList: {},
      roleName: "",
    });
  };

  setStep = (value) => {
    this.setState({ activeStep: value });
  };

  onSnackbarClose = () => {
    this.setState({ alertType: "", alertMsg: "" });
  };

  createAgentRole = () => {
    const { name, permissionsList } = this.state;
    axios
      .post(URL + "/api/create-role", {
        name: name,
        permissionsList,
      })
      .then(() => {
        this.closeRoleDialog();
        this.getRoles();
        this.props.fetchPartnerDetails();
        this.setState({
          alertMsg: "Role created successfully",
          alertType: "success",
        });
      })
      .catch((error) => {
        this.closeRoleDialog();
        console.error(error);
        this.setState({
          alertMsg: error?.response?.data?.message || "Unable to create role!",
          alertType: "error",
        });
      });
  };

  editAgentRole = () => {
    const { roleName, permissionsList } = this.state;
    axios
      .post(URL + "/api/edit-role", {
        roleName: roleName,
        permissionsList,
      })
      .then(() => {
        this.closeRoleDialog();
        this.getRoles();
        this.props.fetchPartnerDetails();
        this.setState({
          alertMsg: "Role updated successfully",
          alertType: "success",
        });
      })
      .catch((error) => {
        this.closeRoleDialog();
        console.error(error);
        this.setState({
          alertMsg: error?.response?.data?.message || "Unable to update role!",
          alertType: "error",
        });
      });
  };

  componentDidMount() {
    this.getRoles();
  }

  toggleDialog = () => {
    this.setState({ openDialog: true });
  };

  getRoles = () => {
    axios
      .get(URL + "/api/get-all-roles")
      .then((response) => {
        const { data } = response;
        const { roles } = data;
        this.setState({
          allRoles: roles || {},
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          alertMsg: error?.response?.data?.message || "Unable fetch roles!",
          alertType: "error",
        });
      });
  };

  viewRole = (isTrue, role, isFalse) => {
    this.setState({
      openDialog: isTrue,
      activeRole: role,
      disableFields: isFalse,
      activeStep: 1,
    });
  };

  editRole = (isTrue, role, isFalse, roleName) => {
    this.setState({
      openDialog: isTrue,
      activeRole: role,
      roleName: roleName,
      editFields: isFalse,
      activeStep: 1,
    });
  };

  hasTrueValue = (obj) => {
    return Object.values(obj).some((value) =>
      typeof value === "object" ? this.hasTrueValue(value) : value === true
    );
  };

  render() {
    const { classes, match, partner } = this.props;
    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Header businessTitle={"Team Roles"} />
        <Box my={3} />
        {/* Layout */}
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11}>
            <div className={classes.QuotaDisplay}>
              <Grid container>
                <Grid item xs>
                  <Box pb={1}>
                    <Typography variant="h5">Total Quota</Typography>
                  </Box>
                  <Grid container spacing={2} alignItems="flex-end">
                    <Grid item>
                      <Typography color="primary" variant="h3">
                        {partner.maxRoles || 5}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary">
                        Roles
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Box display="flex" alignItems="center">
                  <Tooltip
                    title="Roles limit reached!"
                    disableHoverListener={
                      (partner.maxRoles || 5) !==
                      Object.keys(this.state.allRoles).length
                    }
                  >
                    <span>
                      <Button
                        onClick={this.toggleDialog}
                        color="primary"
                        variant="contained"
                        startIcon={<Add />}
                        disabled={
                          (partner.maxRoles || 5) ===
                          Object.keys(this.state.allRoles).length
                        }
                        style={{
                          marginRight: 8,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Create
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Grid>
            </div>

            <div className={classes.tableContainer}>
              <Grid container>
                <Grid container item xs={12} md={12} lg={12}>
                  <Grid container>
                    <Box
                      className={classes.tableWrapper}
                      mb={{ xs: 2, md: 4 }}
                      mx={{ xs: 2, md: 0 }}
                    >
                      <Grid
                        item
                        container
                        className={classes.billTableContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          container
                          className={classes.tableHeader}
                        >
                          {fields.map((e) => (
                            <Grid
                              item
                              xs={e.size}
                              key={e.key}
                              style={{
                                width: "20%",
                              }}
                            >
                              <Box py={2} pl={2}>
                                <Typography variant="h5" color="primary">
                                  {e.name}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Box>
                    {Object.keys(this.state.allRoles).length > 0 ? (
                      Object.keys(this.state.allRoles).map((key) => {
                        const role = this.state.allRoles[key];
                        return (
                          <Grid
                            item
                            xs={12}
                            container
                            className={classes.tableItem}
                          >
                            {fields.map((field) => {
                              const item =
                                field.key === "name" ? key : role[field.key];
                              return (
                                <Grid
                                  item
                                  xs={field.size}
                                  style={{
                                    width: "20%",
                                  }}
                                >
                                  <Cell
                                    classes={classes}
                                    item={item}
                                    field={
                                      this.props.isLoading
                                        ? "Loading"
                                        : field.key
                                    }
                                    role={role}
                                    viewRole={this.viewRole}
                                    editRole={this.editRole}
                                    roleName={key}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        );
                      })
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                      >
                        <Box mt={4} textAlign="center">
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{ padding: 20 }}
                          >
                            No Roles Created!
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {/* Dialog */}
        <div>
          <Dialog
            open={this.state.openDialog}
            onClose={() => this.closeRoleDialog}
            maxWidth={"sm"}
            fullWidth
            PaperProps={{
              style: {
                width:
                  this.state.disableFields || this.state.editFields
                    ? "44%"
                    : "50%",
              },
            }}
          >
            <DialogTitle>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3">Create Role</Typography>
                <IconButton
                  color="primary"
                  size="small"
                  variant="filled"
                  onClick={() => this.closeRoleDialog()}
                >
                  <Clear
                    style={{
                      position: "fixed",
                    }}
                  />
                </IconButton>
              </Box>
            </DialogTitle>
            <Grid item xs={12} alignItems="center">
              {!this.state.disableFields && !this.state.editFields ? (
                <Stepper
                  alternativeLabel
                  activeStep={this.state.activeStep}
                  connector={<QontoConnector />}
                >
                  {STEPS.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ) : null}
            </Grid>

            <Box
              style={{ padding: "16px 35px 16px 50px" }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {this.state.activeStep === 0 ? (
                <DialogContent>
                  <Typography
                    variant="h3"
                    style={{
                      marginBottom: "16px",
                    }}
                    color="primary"
                  >
                    Role Name
                  </Typography>

                  <TextField
                    id="name"
                    placeholder="Enter role name here"
                    variant="outlined"
                    type="text"
                    name="display_name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                    className={classes.textFieldRoot}
                  />
                </DialogContent>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <PermissionsForm
                    onPermissionChange={this.onPermissionChange}
                    disabled={this.state.disableFields}
                    activeRole={this.state.activeRole}
                    edit={this.state.editFields}
                  />
                </div>
              )}

              <DialogActions style={{ padding: "24px 0px 8px 0px" }}>
                {this.state.disableFields ? null : this.state.editFields ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    disabled={!this.hasTrueValue(this.state.permissionsList)}
                    onClick={this.editAgentRole}
                  >
                    Save
                  </Button>
                ) : (
                  <>
                    {" "}
                    <Button
                      variant={"outlined"}
                      size="medium"
                      color="primary"
                      onClick={() => {
                        if (this.state.activeStep === 1) {
                          this.setStep(0);
                          this.setState({ name: "" });
                        } else this.closeRoleDialog();
                      }}
                    >
                      {this.state.activeStep === 1 ? "Back" : "Close"}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={
                        this.state.activeStep === 0
                          ? !this.state.name
                          : !this.hasTrueValue(this.state.permissionsList)
                      }
                      onClick={() => {
                        if (this.state.activeStep === 1) {
                          this.createAgentRole();
                        } else {
                          this.setStep(1);
                        }
                      }}
                    >
                      {this.state.activeStep === 0 ? "Next" : "Create"}
                    </Button>{" "}
                  </>
                )}
              </DialogActions>
            </Box>
          </Dialog>
        </div>

        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
        <Box my={8} />
      </div>
    );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

function Cell({ classes, item, field, viewRole, editRole, role, roleName }) {
  switch (field) {
    case "createdAt":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{getFormattedDate(item)}</Typography>
          </Box>
        </Grid>
      );

    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            {Array.from(Array(20).keys()).map((i) => {
              return (
                <div key={i} className={classes.tableItem1}>
                  <Skeleton className={classes.skeleton} />
                </div>
              );
            })}
          </Box>
        </Grid>
      );

    case "actions":
      return (
        <Grid item xs>
          <Box py={1} pl={1}>
            <VisibilityIcon
              onClick={() => {
                viewRole(true, role, true);
              }}
              color="primary"
            />
            <EditIcon
              color="primary"
              style={{ marginLeft: 16 }}
              onClick={() => editRole(true, role, true, roleName)}
            />
          </Box>
        </Grid>
      );

    case "name":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item}</Typography>
          </Box>
        </Grid>
      );

    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item}</Typography>
          </Box>
        </Grid>
      );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
  },
  QuotaDisplay: {
    padding: "15px 20px",
    backgroundColor: "rgb(237, 226, 252)",
    borderRadius: "8px",
    marginBottom: "0em",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 36px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cells: {
    padding: 24,
    color: "grey",
    borderBottom: "1px solid #ecebeb",
  },
  tableContainer: {
    top: "60px",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  tableWrapper: {
    overflowX: "auto",
    width: "100%",
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
    flexWrap: "nowrap",
    marginTop: "1.5em",
  },
  tableItem1: {
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
  },
  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
    // cursor: "pointer",
    // "&:hover": {
    //   background: "#efefef",
    // },
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "disabled",
    border: "2px solid disabled",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "disabled",
      // color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
});

const connectAgentRoles = connect(
  (state) => ({
    partner: state.partner.partner,
  }),
  { addAgentSize, fetchPartnerDetails }
)(AgentRoles);

export default withStyles(styles)(connectAgentRoles);
